import { debounceTime, filter, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { EntidadeService } from "../../../cadastro/core/services/entidades/entidade.service";
import { BehaviorSubject, map } from "rxjs";
import { Entidade } from "src/app/modulos/telecom/cadastro/core/models/entidade.model";
import { PaginatorStoreService } from "src/app/core/store/paginator.store.service";

@Injectable({
  providedIn: "root",
})
export class TableStoreService {
  private tableRowSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  private columnsListSubject: BehaviorSubject<any> = new BehaviorSubject("");

  constructor() {}

  get tableRow$() {
    return this.tableRowSubject.asObservable();
  }

  get columnsList$() {
    return this.columnsListSubject.asObservable();
  }

  get rowsSelected() {
    return this.tableRowSubject
      .asObservable()
      .pipe(
        map((dataArray: any[]) =>
          dataArray.filter((data: any) => data.isChecked)
        )
      );
  }

  get isAllSelected$() {
    return this.tableRowSubject.asObservable().pipe(
      map((data: any[]) => {
        if (data.length == 0) return false;

        let checkedItems: number = 0;
        let disabledItems: number = 0;

        data.forEach((data) => {
          checkedItems = data.isChecked ? checkedItems + 1 : checkedItems;
          disabledItems = !data.isEnabled ? disabledItems + 1 : disabledItems;
        });

        return data.length - disabledItems === checkedItems;
      })
    );
  }

  setColumnsList(route) {    
    this.columnsListSubject.next(route);
  }

  startTableRows(tableData: any[]) {
    if (!tableData) return;

    const newRows = tableData.map((data: any) => {
      data.isEnabled = data.status === 0 ? false : true;
      data.isChecked = false;

      return data;
    });

    this.tableRowSubject.next(newRows);
  }

  checkAllController(isChecked: boolean) {
    const checkAll = this.tableRowSubject.value.map((data: any) => {
      if (data.isEnabled === true) {
        data.isChecked = isChecked;
      } else {
        data.isChecked = false;
      }
      return data;
    });

    this.tableRowSubject.next(checkAll);
  }

  checkOne(tableRowIndex: number, singleCheck?: boolean) {
    let previousTableRow = this.tableRowSubject.value;

    if (singleCheck) {
      previousTableRow = previousTableRow.map((data: any) => {
        data.isEnabled = data.status === 0 ? false : true;
        data.isChecked = false;

        return data;
      });
    }

    let checkOne = this.tableRowSubject.value[tableRowIndex];
    checkOne.isChecked = !checkOne.isChecked;

    previousTableRow.splice(tableRowIndex, 1, checkOne);
    const splicedTableRows = previousTableRow;

    this.tableRowSubject.next(splicedTableRows);
  }
}
