import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/guards/auth.guard";
import { CyptolandingComponent } from "./cyptolanding/cyptolanding.component";
import { Page404Component } from "./extrapages/page404/page404.component";
import { LayoutComponent } from "./layouts/layout.component";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./account/account.module").then((m) => m.AccountModule),
  },
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: "tem",
    component: LayoutComponent,
    loadChildren: () =>
      import("./modulos/telecom/tem.module").then((m) => m.TemModule),
  },
  {
    path: "facilities",
    component: LayoutComponent,
    loadChildren: () =>
      import("./facilities/facilities.module").then((m) => m.FacilitiesModule),
  },
  {
    path: "tecnologia",
    component: LayoutComponent,
    loadChildren: () =>
      import("./modulos/tecnologia/tecnologia.module").then(
        (m) => m.TecnologiaModule
      ),
  },
  {
    path: "pages",
    loadChildren: () =>
      import("./extrapages/extrapages.module").then((m) => m.ExtrapagesModule),
    canActivate: [AuthGuard],
  },
  { path: "crypto-ico-landing", component: CyptolandingComponent },
  { path: "**", component: Page404Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
  providers: [
    // {
    //   provide: RouteReuseStrategy,
    //   useClass: CustomerRouterService
    // },
  ],
})
export class AppRoutingModule {}
