import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContaFatura } from '../models/contaFatura.model';
import { Pagination } from 'src/app/core/models/pagination.model';
import { Download } from '../models/download.model';




@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  baseUrl = environment.apiBaseHom +'/download';






  private _download = new BehaviorSubject<any[]>([]);
  get download() {
    return this._download.asObservable();
  }

  retornaParams(){

    let queryParams = new HttpParams();
    queryParams = queryParams.append("auth",environment.apiBaseHom)

    return queryParams;
  }

  constructor(private http: HttpClient) { }


  getDownloads(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaDownload: Download[],
    quantidade: number
  }> {
    return this.http.get<{
      listaDownload: Download[],
      quantidade: number
    }>(
      `${this.baseUrl}/ListDownload?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getDownloadsModal(
    pagination: Pagination,
    servicoId:number,
    entidadeModalId: number,
    fornecedorId:number

  ): Observable<{
    listaDownload: Download[],
    quantidade: number
  }> {
    return this.http.get<{
      listaDownload: Download[],
      quantidade: number
    }>(
      `${this.baseUrl}/ListDownloadModal?entidadeId=${entidadeModalId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&entidadeModalId=${entidadeModalId}&servicoId=${servicoId}&fornecedorId=${fornecedorId}`
    );
  }


  
  getDownloadPorId(donwloadId: number): Observable<Download> {
    return this.http.get<Download>(
      `${this.baseUrl}/DownloadPorId?id=${donwloadId}`
    );
  }
  verificaReferenciaDownloadConta(donwloadId: number): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.baseUrl}/VerificaReferenciaDownloadConta?downloadId=${donwloadId}`
    );
  }
  addDownload(donwloadNovo: Download): Observable<any> {
    return this.http.post(`${this.baseUrl}`, donwloadNovo)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }
  updateDownload(download: Download): Observable<any> {
    return this.http.put(`${this.baseUrl}/AtualizaDownload`, download).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of(error);
      })
    );
  }

  getPesquisaTodosDownload(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaDownload: Download[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaDownload: Download[];
    quantidadeTotal: number;
    }>(
      `${this.baseUrl}/downloadpesquisatodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  
  public delete = (
    downloadId: number
  ): Observable<Download | HttpErrorResponse> => {
    return this.http.delete<Download>(`${this.baseUrl}/${downloadId}`).pipe(
      map((res: Download) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };



  }

