import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Pagination } from 'src/app/core/models/pagination.model';
import { UsuarioEntidade } from '../models/usuarioEntidade.model';
import { UsuarioEntidadeDTO } from '../models/dto/usuarioEntidade.model';
import { AtivoCsv } from 'src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisicoCsv.model';
import { ServicoEnum } from 'src/app/core/models/enums/servicoEnum';

@Injectable({
  providedIn: 'root'
})
export class UsuarioEntidadeService {

  baseUrl = environment.apiBaseHom + '/usuarioentidade';


  constructor(private http: HttpClient) { }

  getUsuariosEntidadeModal(
    pagination: Pagination,
    entidadeId: number,
    servico: ServicoEnum = null,
  ): Observable<{
    itensList: UsuarioEntidade[],
    quantidadeTotal: number
  }> {
    return this.http.get<{
      itensList: UsuarioEntidade[],
      quantidadeTotal: number
    }>(
      `${this.baseUrl}/usuarioentidademodal?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}` + (servico ? `&Servico=${servico}` : '')
    );
  }

  getUsuariosEntidade(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    itensList: UsuarioEntidade[],
    quantidadeTotal: number
  }> {
    return this.http.get<{
      itensList: UsuarioEntidade[],
      quantidadeTotal: number
    }>(
      `${this.baseUrl}/?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPesquisaTodosUsuarioEntidade(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaUsuarioEntidade: UsuarioEntidade[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaUsuarioEntidade: UsuarioEntidade[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/usuarioentidadepesquisatodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }


  getPesquisaTodosUsuarioEntidadeModal(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaUsuarioEntidade: UsuarioEntidade[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaUsuarioEntidade: UsuarioEntidade[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/UsuarioEntidadePesquisaTodosModal?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&entidadeId=${entidadeId}`
    );
  }

  getUsuariosPorId(Id: number): Observable<UsuarioEntidadeDTO> {
    return this.http.get<any>(`${this.baseUrl}/usuarioporid?id=${Id}`)

  }

  getUsuariosLideres(entidadeId: number): Observable<UsuarioEntidade[]> {
    return this.http.get<any>(`${this.baseUrl}/usuariolideresentidade?entidadeId=${entidadeId}`)

  }


  addUsuarioEntidade(usuarioEntidadeNovo: UsuarioEntidade): Observable<any> {
    return this.http.post(`${this.baseUrl}`, usuarioEntidadeNovo)
      .pipe(
        map(response => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return of(error);
        })
      );
  }
  updateUsuarioEntidade(usuarioEntidadeEdit: UsuarioEntidade): Observable<any> {
    return this.http.put(`${this.baseUrl}/AtualizaUsuarioEntidade`, usuarioEntidadeEdit)
      .pipe(
        map(response => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          return of(err);
        })
      );
  }

  getAllRoles(): Observable<any> {
    return this.http.get(`${environment.apiBaseHom}/usuario/allroles`)
      .pipe(map(response => {
        return response
      }),
        catchError(error => {
          return of(false)
        })
      )
  }
  downloadUsuarioRecursoCsv(ativoCsv: AtivoCsv): Observable<any> {
    return this.http.post(`${this.baseUrl}/DownloadCsvUsuarioRecursos`, ativoCsv, {observe: 'response', responseType: 'blob'}).pipe(
      map((response) => {
        return { response: response, headers: response.headers };
      })
    );
  }

  downloadCsv(ativoCsv: AtivoCsv): Observable<any> {
    return this.http.post(`${this.baseUrl}/DownloadCsv`, ativoCsv, {observe: 'response', responseType: 'blob'}).pipe(
      map((response) => {
        return { response: response, headers: response.headers };
      })
    );
  }

  public delete = (
    usuarioId: number
  ): Observable<UsuarioEntidade | HttpErrorResponse> => {
    return this.http.delete<UsuarioEntidade>(`${this.baseUrl}/${usuarioId}`).pipe(
      map((res: UsuarioEntidade) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };


  getUsuarioGestoresPorEntidadeId = (entidadeId: number): Observable<UsuarioEntidade[]> => {
    return this.http.get<UsuarioEntidade[]>(`${this.baseUrl}/usuariosgestor?entidadeId=${entidadeId}`);
  }
  

}
