import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import type { MenuService } from 'src/app/layouts/sidebar-refac/menu.service';

@Injectable({
  providedIn: 'root',
})
export class paywallStoreService {
  private menuItemSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private accessSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get paywallSubject$() {
    return this.menuItemSubject.asObservable();
  }

  get accessSubject$() {
    return this.accessSubject.asObservable();
  }

  constructor(
    private router: Router,
  ){
    if(this.router.url.includes('relatorios')) {
      console.log('FUNÇÃO', this.router.url.includes('relatorios'))
      this.accessSubject.next(false);
    }
  }

  clickedItem(item: any) {
    this.menuItemSubject.next(item);
  }

  setAccess(access: boolean) {
    console.log('ACCESS', access)
    this.accessSubject.next(access);
  }
}
