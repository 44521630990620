import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { AtivoAcessorio } from "../../models/acessorio/ativoAcessorio.model";
import { Pagination } from "src/app/core/models/pagination.model";
import { ModeloDispositivo } from "../../models/dispositivo/modeloDispositivo.model";
import { AcessorioRequest } from "../../models/acessorio/AcessorioRequest";

@Injectable({
  providedIn: "root",
})
export class AtivoAcessorioService {
  baseUrl = environment.apiBaseHom + "/ativoacessorio";

  constructor(private http: HttpClient) {}

  getAcessorios(): Observable<AtivoAcessorio[]> {
    return this.http.get<any>(`${this.baseUrl}`);
  }

  getAcessoriosPorId(id: number): Observable<AtivoAcessorio> {
    return this.http.get<AtivoAcessorio>(
      `${this.baseUrl}/AcessorioPorID?id=${id}`
    );
  }

  getAcessoriosCategoria(
    pagination: Pagination,
    entidadeId: number
  ): Observable<AcessorioRequest> {
    return this.http.get<any>(
      `${this.baseUrl}/getacessoriocategoria?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPesquisaTodosAcessoriosCategoria(
    pagination: Pagination,
    entidadeId: number,
    searchText: string
  ): Observable<AcessorioRequest> {
    return this.http.get<any>(
      `${this.baseUrl}/getprocuratodosAcessoriocategoria?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getModelosDistinct(): Observable<ModeloDispositivo[]> {
    return this.http.get<ModeloDispositivo[]>(
      `${this.baseUrl}/modelodispositivodistinct`
    );
  }

  addAcessorio(acessorioNovo: AtivoAcessorio): Observable<any> {
    return this.http.post(`${this.baseUrl}`, acessorioNovo).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {        
        return of(false);
      })
    );
  }

  editAcessorio(acessorioNovo: AtivoAcessorio): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/atualizaacessorio`, acessorioNovo)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          return of(false);
        })
      );
  }
}
