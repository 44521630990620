import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LoadActionService {
  private loadActionSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private isFullSubject: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  get loadAction$() {
    return this.loadActionSubject.asObservable();
  }

  get isFull$() {
    return this.isFullSubject.asObservable();
  }

  constructor() {}

  startload() {
    this.loadActionSubject.next(true);
  }

  closeLoad() {
    setTimeout(() => {
      this.loadActionSubject.next(false);
    }, 900);
  }

  setFullScream() {
    this.isFullSubject.next(false);
  }
  removeFullScream() {
    this.isFullSubject.next(false);
  }
}
