import { Injectable } from "@angular/core";
import { EntidadeService } from "../../../cadastro/core/services/entidades/entidade.service";
import { BehaviorSubject, map } from "rxjs";
import { Entidade } from "src/app/modulos/telecom/cadastro/core/models/entidade.model";
import { EnumsService } from "src/app/core/services/enums.service";

@Injectable({
  providedIn: "root",
})
export class EntidadeStoreService {
  public entidadeSubject: BehaviorSubject<Entidade[]> = new BehaviorSubject<
    Entidade[]
  >([]);
  private entidadeGlobalSubject: BehaviorSubject<Entidade[]> = new BehaviorSubject<
    Entidade[]
  >([]);
  private titularidadeSubject: BehaviorSubject<Entidade[]> =
    new BehaviorSubject<Entidade[]>([]);
  private entidadeSelectedSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  get entidadeListGlobal$() {
    return this.entidadeGlobalSubject.asObservable();
  }

  get entidadeList$() {
    return this.entidadeSubject.asObservable();
  }

  get titularidadeList$() {
    return this.titularidadeSubject.asObservable();
  }

  get entidadeSelected$() {
    return this.entidadeSelectedSubject.asObservable();
  }

  constructor(
    private entidadeService: EntidadeService,
    private enumns: EnumsService
  ) {
    this.carregarEntidades();
    this.carregarEntidadesGlobal();
  }

  setEntidade(entidadeSelected: number) {
    this.entidadeSelectedSubject.next(entidadeSelected);

    localStorage.setItem("entidadeSelecionada", entidadeSelected?.toString());
  }

  carregarEntidades() {
    const entidadeSelecionada = localStorage.getItem("entidadeSelecionada");
    this.entidadeService.getEntidadesLista(+entidadeSelecionada).subscribe((result) => {
      this.entidadeSubject.next(result);
      this.titularidadeSubject.next([...result]);
    });
  }

  carregarEntidadesGlobal() {
    this.entidadeService.GetListaEntidadeGlobal().subscribe((result) => {      
      this.entidadeGlobalSubject.next(result);
    });
  }
}
