import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";
import { Pagination } from "src/app/core/models/pagination.model";
import { environment } from "src/environments/environment";
import { ContaLinha } from "../models/contaLinha.model";

@Injectable({
  providedIn: "root",
})
export class ContaLinhaService {
  constructor(private http: HttpClient) {}

  baseUrlContaLinha = environment.apiBaseHom + "/contaLinha";

  getContaLinha(pagination: Pagination): Observable<ContaLinha[]> {
    return this.http.get<any>(
      `${this.baseUrlContaLinha}/listcontalinha?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getContaLinhaFornecedor(pagination: Pagination, entidadeId: number, fornecedorId: number): Observable<ContaLinha[]> {
    return this.http.get<any>(
      `${this.baseUrlContaLinha}/listcontalinhafornecedor?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&fornecedorId=${fornecedorId}`
    );
  }

  addContaLinha(contaLinha: any): Observable<any> {
    return this.http.post(`${this.baseUrlContaLinha}`, contaLinha).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  updateContaLinha(contaLinha: any): Observable<any> {
    return this.http
      .put(`${this.baseUrlContaLinha}/atualizacontalinha`, contaLinha)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          return of(false);
        })
      );
  }
}
