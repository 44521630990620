import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { FileService } from '../../services/file.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Fatura } from 'src/app/modulos/telecom/conta-fatura/core/models/fatura.model';
import { FileModel } from 'src/app/core/models/fileModel';
import { SpinnerService } from '../../services/spinner.service';
import { ToastrService } from 'ngx-toastr';
// import { FaturaService } from 'src/app/tem/conta-fatura/core/services/fatura.service';
import { FaturaService } from 'src/app/modulos/telecom/conta-fatura/core/services/fatura.service';
import { map } from 'rxjs';
import { BlobUtils } from 'src/app/core/utils/BlobUtils';
import { ArquivoFatura } from 'src/app/modulos/telecom/conta-fatura/core/models/arquivoFatura.model';

@Component({
  selector: 'app-file-faturas-upload',
  templateUrl: './file-fatura-upload.component.html',
  styleUrls: ['./file-fatura-upload.component.css']
})
export class FileFaturasUploadComponent {
  @Input()dadosFatura:Fatura
  @Input() dadosArquivo: any;
  @Input() tipoUpload:string;
  @Input() dadosContaFatura:Fatura;
  @Input() fileModel:FileModel;
  @Input() tipoComponente:string;
  @Input() tiposPermitidos: string;
  @Input() habilitaUpload: boolean = false

  @Output() enviaArquivos = new EventEmitter<any>();
  @Output() dropArquivos = new EventEmitter<Blob[]>();
  progress = 0;
  message = "";
  currentFile?: File;
  showSpinner:boolean = false;
  files: any[] = [];
  isDivDisabled = true;
  state: 'WAITING' | 'PROCESSING'
  nomeArquivo: string = "";

  uploadedFiles: any[] = [];

  // File Upload
  imageURL: any;

  fileUrl;
  selectedFiles?: FileList;
  
  faturaId: number  
  public get processing() : boolean {
    return this.state === 'PROCESSING'
  }
  
  public get waiting() : boolean {
    return this.state === 'WAITING'
  }
  


  constructor(
    private fileService:FileService
    ,private toastr: ToastrService
    ,private spinnerService: SpinnerService
    ,private sanitizer: DomSanitizer
    ,private faturaService: FaturaService

  )  { }


  onFileDropped($event) {
    console.log('event:',$event)
    this.state = 'PROCESSING'
    this.prepareFilesList($event)
    this.state = 'WAITING'    
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files:Blob[]) {
    this.prepareFilesList(files);    
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.habilitaUpload = false;
  }  
  // checkFile(blob: Blob) {
  //   const headers = ["CLIENTE","CNPJ CLIENTE","FORNECEDOR","TIPO DE SERVIÇO","CONTA","INICIO CICLO","FINAL CICLO","REFERÊNCIA","VENCIMENTO","IDENTIFICADOR","ESTADO","PERFIL","STATUS","DATA DE ATIVAÇÃO","DATA INATIVAÇÃO","DATA BLOQUEIO","DATA DESBLOQUEIO","DIAS COBRADOS","CLASSE DE SERVIÇO","SERVIÇO","SEMANA","FAIXA","MÁSCARA","TIPO","DATA","HORA","NÚMERO DESTINO","C.S.P","DURAÇÃO CHAMADAS","DURAÇÃO INTERNET","DURAÇÃO CONVERTIDA","R$ ORIGINAL"]    
  //   const head = blob.slice(0,headers.join(";").length + 16,this.getFileType(blob))
  //   head.text()
  // }
  /**
   * Simulate the upload process
   */
  async uploadFiles()
  {
    this.showSpinner = true;
    // this.loading = true
    const uploadResponse = await this.faturaService.upload({
      faturaId: this.dadosFatura.faturaId
      ,files:this.files
      ,filemodel: this.fileModel})
    uploadResponse.subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round((100 * event.loaded) / event.total);
        } else if (event instanceof HttpResponse) {
          this.message = event.body.message;

          // this.fileInfos = this.uploadService.getFiles();
        }
      },
      error: (err: any) => {
        if (err.error && err.error.message) {
          this.message = err.error.message;

        } else {
          this.message = "Could not upload the file!";


        }
        this.toastr.error("Upload Falhou", "Verificar arquivo");
        this.currentFile = undefined;
        this.progress = 0;
      },
      complete: () => {
        this.currentFile = undefined;

        this.showSpinner = false;

        this.toastr.success("Upload feito com Sucesso", "Upload");

        console.log('this.enviaArquivos.emit(this.files = ',this.files,')')
        this.enviaArquivos.emit(this.files)
      //  this.enviaExtensaoArquivo.emit()
      }
    });
  }
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<Blob>) {
    
    console.log('files:',files)
    for (const item of files) {
     // item.progress = 0;
      this.files.push(item);
    }
    this.habilitaUpload = true;
    this.dropArquivos.emit(this.files)
  //  this.uploadFilesSimulator(0);
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadDetalhado(): void {
    this.progress = 0;
    this.enviaArquivos.emit(this.files)
    // this.faturaService.uploadDetalhado
    if (!this.files) {
      return;
    }
     // const file: File | null = this.selectedFiles.item(0);

     // if (file) {
       // this.currentFile = file;

    this.fileService.uploadDetalhado(this.files,this.fileModel)
    .subscribe(
      (event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progress = Math.round(100 * event.loaded / event.total);

        } else if (event instanceof HttpResponse) {
          // const blob = new Blob([event.body], { type: 'application/octet-stream' });
          const blob = new Blob([event.body],   { type: event.type.toString() });
          const contentDispositionHeader = event.headers.get('content-disposition');

          let content = contentDispositionHeader?.split(";")
          this.nomeArquivo = content? content[1].replace("filename=",""): "";
          this.nomeArquivo = this.nomeArquivo.trim();

          this.showSpinner = false;


        // saveAs(event, event.bo);
          this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
          let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  document.body.appendChild(a);
                  a.setAttribute('style', 'display: none');
                  a.href = url;
                  a.download = this.nomeArquivo;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove();
          // this.message = event.body.message;
        //  this.fileInfos = this.uploadService.getFiles();


        }
      },
      (err: any) => {

        this.progress = 0;
        this.showSpinner = false;

        if (err.error && err.error.message) {
          this.message = err.error.message;
        } else {
          this.message = 'Could not upload the file!';
        }

        this.currentFile = undefined;
      });

    //  }

    this.selectedFiles = undefined;

  }

  uploadDetalhadoFatura(): void {
    if(!this.habilitaUpload){
      alert('upload não esta habilitado!')
      return 
    }
    this.progress = 0;
    // this.spinnerService.show();
    const onSpinner = (func) => {
      try {
        this.progress = 0
        this.spinnerService.show()
        const value = func()
        this.spinnerService.hide()
        this.progress = 100;
        return value
      }
      catch(error){
        console.error(error);
        this.progress = 100
        this.spinnerService.hide()
      }
    }    
    onSpinner(() => {
      this.fileModel.fileType = this.tipoUpload == 'txt'? 3 :5// verifica se é txt ou febraban
      if (!this.files) {
        return;
      }
      this.toastr.info("Tentando fazer upload do arquivo a ser processado...","Iniciando do upload")
      this.faturaService.uploadDetalhado({
        uploadedFile: this.files[0]
        ,fornecedor: this.fileModel.fornecedor
        ,source: 'ArquivosEletronico'
        ,faturaId: this.dadosFatura.faturaId
      })
      .subscribe(res => {        
        if(!res.success){
          console.error('Um erro durante o upload do arquivo a ser detalhado: ',res.message)
          this.toastr.error("Não foi possivel realizar o upload do arquivo de lote para geração do detalhado","Falha no Upload do Lote")
          // this.
          return;
        }
        this.enviaArquivos.emit(this.files)
        this.toastr.success("Upload do arquivo foi realizado","Upload Realizado")
        this.toastr.info("Upload do arquivo foi realizado","Upload Realizado")
        const model = res.value
        this.faturaService.detalharArquivo({
          faturaId: model.arquivoFatura.faturaId
          ,arquivoFaturaId: model.arquivoFatura.arquivoFaturaId
          ,interno: this.tipoUpload?.toLowerCase() === 'csv'
        }).subscribe((res) => {
          if(!res.success){
            console.error(res)            
            this.toastr.error(`Conseguimos realizar o upload, mas parece que não foi possivel processar o arquivo, o servidor retornou o seguinte erro: ${res.message}`,"Falha no processamento do arquivo")
            return
          }
          this.showSpinner = false
          this.spinnerService.hide()
          this.habilitaUpload = false
          this.toastr.success("Upload feito com Sucesso", "Upload")
          const header = res.value          
          /* this.faturaService.exportDetalhadoPorId(header.faturaHeaderId)
            .subscribe(res => {
              const blobUrl = BlobUtils.createBlobObjectUrl({blob: res})
              BlobUtils.addBlobUrlToDownloads({
                nomeArquivoDownload: model.arquivoFatura.nomeArquivo
                ,url: blobUrl
                ,sanitizer: this.sanitizer.bypassSecurityTrustResourceUrl})
            })
            */
          // this.enviaArquivos.emit(this.files)
          // this.enviaArquivos.emit(this.files)
          return res
        }
      )
    })})

    this.selectedFiles = undefined;
  }

}
