import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, debounceTime, map, of } from "rxjs";
import { Pagination } from "src/app/core/models/pagination.model";
import { FilterStoreService } from "./filter.store.service";

@Injectable({
  providedIn: "root",
})
export class PaginatorGenericStoreService {
  private listSubject: BehaviorSubject<any[]>;
  private dataObservable: BehaviorSubject<Observable<any>>;
  private paginationSubject: BehaviorSubject<Pagination>;

  private totalOfItensController: number = 0;
  private totalController: any[];

  get dataObservable$() {
    return this.dataObservable.asObservable();
  }

  get list$() {
    return this.listSubject.asObservable();
  }

  get pagination$() {
    return this.paginationSubject.asObservable();
  }

  get filteredList$() {
    return this.filterStore.filteredTableList$;
  }

  get totalItems$() {
    return this.paginationSubject.asObservable().pipe(
      map((item: Pagination) => {
        const totalItems = Math.ceil(
          this.totalOfItensController / item.pageSize
        );

        return totalItems == 0 ? 1 : totalItems;
      })
    );
  }

  constructor(private filterStore: FilterStoreService) {
    this.listSubject = new BehaviorSubject<any[]>([]);
    this.dataObservable = new BehaviorSubject<Observable<any>>(of([]));

    this.paginationSubject = new BehaviorSubject<Pagination>({
      pageNumber: 1,
      pageSize: 50,
    } as Pagination);

    this.activeFilterList();
  }

  updatePage(data: any[], totalItens: number) {
    this.totalController = data;
    this.listSubject.next(data);
    this.totalOfItensController = totalItens;
  }

  updateQuantidadeLinha(pageSize: number) {
    if (this.paginationSubject.value.pageSize !== pageSize) {
      this.paginationSubject.next({
        pageNumber: 1,
        pageSize,
      } as Pagination);
    }
  }

  updatePaginaPosicao(pageNumber: number) {
    const lastPage = Math.ceil(
      this.totalOfItensController / this.paginationSubject.value.pageSize
    );
    const newPage = this.paginationSubject.value.pageNumber + pageNumber;

    if (newPage <= lastPage && newPage >= 1) {
      this.paginationSubject.next({
        pageNumber: this.paginationSubject.value.pageNumber + pageNumber,
        pageSize: this.paginationSubject.value.pageSize,
      } as Pagination);
    }
  }

  firstOrLastPage(isFirstPage: boolean) {
    const lastPage = Math.ceil(
      this.totalOfItensController / this.paginationSubject.value.pageSize
    );

    this.paginationSubject.next({
      pageNumber: !isFirstPage ? lastPage : 1,
      pageSize: this.paginationSubject.value.pageSize,
    } as Pagination);    
  }

  getDataObservable(data: Observable<any>) {    
    if(this.filterStore.queryController) return;

    this.dataObservable.next(data);
  }

  private activeFilterList() {
    this.filteredList$.subscribe((list) => {
      if (this.filterStore.hasQuery) {
        this.listSubject.next(list.listaAtivo);
        this.totalOfItensController = list.quantidadeTotal;
      } else this.listSubject.next(this.totalController);
    });
  }

  onDestroy() {
    this.listSubject.next([]);
    this.dataObservable.next(null);
    this.paginationSubject.next({
      pageNumber: 1,
      pageSize: 50,
    } as Pagination);
    this.totalOfItensController = 0;
    this.totalController = [];
  }
}
