import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StyleManagerService {
  private root = document.documentElement.style;
  private iconUrlsubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    ""
  );

  get IconUrl$() {
    return this.iconUrlsubject.asObservable();
  }

  constructor() { }

  public setVariables = (vars: string) => {
    var styles: string[];

    if (!vars || vars == "undefined") {
      this.setRoot();
    } else {
      styles = vars.split(",");
      this.setRoot(styles[0], styles[1], styles[2], styles[3], styles[4]);
    }



    // if (vars !== "undefined") {
    //   this.root.setProperty("--primary", styles[0]);
    //   this.root.setProperty("--secundary", styles[1]);
    //   this.root.setProperty("--fontColor", styles[2]);
    //   this.root.setProperty("--defaultTheme", styles[3]);
    //   this.iconUrlsubject.next(styles[2]);
    // }
    // else {
    //   this.root.setProperty("--primary", "#090b66");
    //   this.root.setProperty("--secundary", "#FFFFFF");
    //   this.root.setProperty("--fontColor", "#FFFFFF");
    //   this.root.setProperty("--defaultTheme", "dark");
    //   this.iconUrlsubject.next("assets/images/Mobit-Logo.png");
    // }

    if (vars !== "undefined") {
      this.root.setProperty("--primary", "#090b66");
      this.root.setProperty("--secundary", "#FFFFFF");
      this.root.setProperty("--fontColor", "#FFFFFF");
      this.root.setProperty("--defaultTheme", "dark");
      this.iconUrlsubject.next("assets/images/Mobit-Logo.png");
    }
    else {
      this.root.setProperty("--primary", "#090b66");
      this.root.setProperty("--secundary", "#FFFFFF");
      this.root.setProperty("--fontColor", "#FFFFFF");
      this.root.setProperty("--defaultTheme", "dark");
      this.iconUrlsubject.next("assets/images/Mobit-Logo.png");
    }

    if(localStorage.getItem('theme') === 'dark') {
      this.root.setProperty("--fontColor", "#FFFFFF");
    } else {
      this.root.setProperty("--fontColor", "#090b66");
    }
  };
  private setRoot(
    primary = "#090b66",
    secundary = "#000",
    fontColor = "#FFFFFF",
    defaultTheme = "dark",
    logo = "assets/images/Mobit-Logo.png"
  ) {
    this.root.setProperty("--primary", primary);
    this.root.setProperty("--secundary", secundary);
    this.root.setProperty("--fontColor", fontColor);
    this.root.setProperty("--defaultTheme", defaultTheme);
    this.iconUrlsubject.next(logo);
  }
}
