import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-action-btn",
  templateUrl: "./actionBtn.component.html",
  styleUrls: ["./actionBtn.component.scss"],
})
export class ActionBtnComponent implements OnInit {
  @Input() breadcrumbItems;
  @Input() title: string;

  @Input() route: any;
  @Input() queryParams: any;
  @Input() text: string;
  @Input() icon: string;
  @Input() type: string;
  @Input() isInput: boolean;
  @Input() tooltip: string;
  @Input() isDisable: boolean = false;
  @Input() insertURL: string = "";
  @Input() updateURL: string = "";
  @Input() isHidden : boolean = false;
  prohibitedNamesForNonAdmin : string[] = [
    'editar muitos',
    'enviar arquivo',
    'selecionar',
    'novo',
    'desvincular'
  ]

  isOpen: boolean = false;

  constructor() {}

  ngOnInit() {
    const userRoles = localStorage.getItem('roles')
    const rolesArray = userRoles.split(',').map((val)=> val.toLowerCase())

    const textInLowerCase = this.text.toLowerCase()

    if (!(rolesArray.includes('admin') || userRoles.toLowerCase() === 'admin') && this.prohibitedNamesForNonAdmin.includes(textInLowerCase)) {
      this.isHidden = true
      this.isDisable = true
    }

    console.log('ACTION BTN', this.tooltip)
  }

  onFileSelected() {
    this.editModal();
  }

  editModal() {
    this.isOpen = !this.isOpen;
  }
}
