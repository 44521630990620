import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

// Auth Services
import { AuthenticationService } from "../services/auth.service";
import { AuthfakeauthenticationService } from "../services/authfake.service";
import { environment } from "../../../environments/environment";
import { TokenStorageService } from "../services/token-storage.service";
import { ToastrService } from "ngx-toastr";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private tokenStorageService: TokenStorageService,
    private authFackservice: AuthfakeauthenticationService,
    private toasterService: ToastrService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      // logged in so return true

      console.log("CURRENT USER");
      console.log(currentUser);
      if (this.checkUserLogin(route)) return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(["/auth/login"], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }

  checkUserLogin(route: ActivatedRouteSnapshot): boolean {
    if (this.tokenStorageService.isLoggedIn()) {
      if (route.data.role) {
        const userRoles = this.tokenStorageService.getRoles();
        let userRolesList = userRoles.split(",");
        const routeRole = route.data.role.split(",");

        console.log('userRoles', userRoles)
        console.log('route', route)

        if (routeRole === undefined || userRolesList.includes("ADMIN")) {
          return true;
        } else {
          if (Array.isArray(userRolesList)) {
            if (userRolesList.some((x) => routeRole.includes(x))) {
              return true;
            } else {
              this.toasterService.warning(
                "Você não tem permissão para acessar o módulo",
                "Aviso",
                { positionClass: "toast-top-center" }
              );
              return false;
            }
          } else {
            if (routeRole.includes(userRolesList)) {
              return true;
            } else {
              this.toasterService.warning(
                "Você não tem permissão para acessar o módulo",
                "Aviso",
                { positionClass: "toast-top-center" }
              );
              return false;
            }
          }
        }
      } else {
        return true;
      }
    }

    /// this.router.navigate(['/dashboard']);
    return false;
  }
}
