import { Injectable } from '@angular/core';

Injectable({
    providedIn: 'root'
  })
  export class DataService {
    private entidade: any;
  
    constructor() {
      // Initialize your data
      this.entidade = null ;
    }
  
    getDataEntidade(): any {
      return this.entidade;
    }
  
    setDataEntidade(data: any): void {
      this.entidade = data;
    }
  }
