import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, of, throwError } from "rxjs";
import { Pagination } from "src/app/core/models/pagination.model";
import { TipoAtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/tipoAtivoFisico.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class TipoAtivoService {
  baseUrl = environment.apiBaseHom + "/tipoativo";

  constructor(private http: HttpClient) {}



  getListTiposAtivosTodos(): Observable<TipoAtivoFisico[]> {
    return this.http.get<TipoAtivoFisico[]>(
      `${this.baseUrl}/ListTiposAtivosTodos`
    );
  }

  getTiposAtivos(pagination: Pagination): Observable<{
    listaTiposAtivos: TipoAtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaTiposAtivos: TipoAtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/listtiposativos?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getTipoAtivoId(tipoAtivoId: number): Observable<TipoAtivoFisico> {
    return this.http.get<TipoAtivoFisico>(
      `${this.baseUrl}/tipoativoporid?id=${tipoAtivoId}`
    );
  }
  getTipoAtivoPorIdCategoria(categoriaId: number): Observable<TipoAtivoFisico[]> {
    return this.http.get<TipoAtivoFisico[]>(
      `${this.baseUrl}/TipoAtivoPorCategoriaId?categoriaId=${categoriaId}`
    );
  }


  addTipoAtivo(tipoAtivoNovo: TipoAtivoFisico): Observable<any> {
    return this.http.post(`${this.baseUrl}`, tipoAtivoNovo);
  }

  updateTipoAtivo(tipoAtivo: TipoAtivoFisico): Observable<any> {
    return this.http.put(`${this.baseUrl}/atualizatipo`, tipoAtivo).pipe(
      map((response) => {
        return response;
      }),
      catchError((err: unknown) => {
        return throwError(() => err);
      })
    );
  }

  public getPesquisaTodosTiposAtivos = (
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<any> => {    
    return this.http.get(
      `${this.baseUrl}/pesquisatodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  };

  public delete = (
    tipoAtivoFisicoId: number
  ): Observable<TipoAtivoFisico | HttpErrorResponse> => {
    return this.http.delete<TipoAtivoFisico>(`${this.baseUrl}/${tipoAtivoFisicoId}`).pipe(
      map((res: TipoAtivoFisico) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };

}
