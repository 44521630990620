import { AtivoFisicoService } from "src/app/modulos/telecom/ativo-fisico/core/service/ativo-fisico.service";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { EntidadeStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/entidade.store.service";
import { AtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model";
import { ModalTabsService } from "./service/modal-tabs.service";
import { FabricanteDispositivo } from "src/app/modulos/telecom/ativo-fisico/models/dispositivo/fabricanteDispositivo.model";
import { TipoAtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/tipoAtivoFisico.model";
import { FormFisicoService } from "src/app/modulos/telecom/ativo-fisico/core/service/form/form-fisico.service";
import { AlterEntidadeService } from "src/app/modulos/telecom/ativo-fisico/core/service/alter-entidade.service";
import { UnidadeEntidadeService } from "src/app/modulos/telecom/cadastro/core/services/entidades/unidadeEntidade.service";
import { Entidade } from "src/app/modulos/telecom/cadastro/core/models/entidade.model";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { NotaFiscal } from "src/app/modulos/telecom/ativo-fisico/models/notaFiscal.model";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { TokenStorageService } from "src/app/core/services/token-storage.service";
import { tipoDispositivoEnum } from "src/app/core/models/enums/tipoDispositivoEnum";
import { tecAcessorioService } from "src/app/modulos/tecnologia/dispositivo-fisico/core/service/acessorios/tec-acessorio.service";
import { temAcessorioService } from "src/app/modulos/telecom/ativo-fisico/core/service/acessorios/tem-acessorio.service";

@Component({
  selector: "app-modal-tab",
  templateUrl: "./modal-tab.component.html",
  styleUrls: ["./modal-tab.component.css"],
  providers: [AlterEntidadeService],
})
export class ModalTabComponent {
  @Input() isOpen: boolean = false;
  @Input() isLinha: boolean = false;
  @Input() title: string;
  @Input() ativoTitle: string;
  @Input() component: string;
  @Input() selectedAtivos: AtivoFisico[];
  @Input() tipoDispositivoEnum?: tipoDispositivoEnum;

  @Output() closeModal = new EventEmitter<any>();

  // @Output() close = new EventEmitter<any>();

  updateAtivo: FormGroup;

  // Método de Editar
  fabricanteId: number;
  chipId: number;

  currentRoute: boolean = this.router.url.includes("dispositivo");

  // Listas
  listaFabricantes: FabricanteDispositivo[];
  listaTiposAtivoFisico: TipoAtivoFisico[];
  listaTiposDispositivo: TipoAtivoFisico[];
  listaUnidadeEntidades: any[];
  public modalConfirmIsOpen: boolean = false;
  public entidadeSelecionada: number = 0;
  public txtConfirmacao: string = "";
  public mostrarCamposRelacionadosEntidade: boolean = false;
  public listaModelos: any[] = [];
  private actualRoute: string;

  private user: any;

  constructor(
    private tokenService: TokenStorageService,
    private entidadeStore: EntidadeStoreService,
    private formService: FormFisicoService,
    private modalTabsService: ModalTabsService,
    private ativoFisicoService: AtivoFisicoService,
    private entidadeStoreService: EntidadeStoreService,
    private alterEntidadeService: AlterEntidadeService,
    private unidadeEntidadeService: UnidadeEntidadeService,
    private toastr: ToastrService,
    private readonly tableStoreService: TableStoreService,
    private readonly tablePaginatorService: PaginatorGenericStoreService,
    private readonly tecAcessorioService: tecAcessorioService,
    private readonly activatedRoute: ActivatedRoute,
    private router: Router,
    private readonly temAcessorioService: temAcessorioService
  ) {
    this.user = this.tokenService.getUser();
  }

  get entidadeList$() {
    return this.entidadeStoreService.entidadeList$;
  }

  get form() {
    return this.updateAtivo.controls;
  }

  get tipo() {
    return this.updateAtivo.get("tipo").value;
  }

  get entidade() {
    return this.updateAtivo.get("entidade").value;
  }

  get fabricante() {
    return this.updateAtivo.get("fabricante").value;
  }

  get entidadesList$() {
    return this.entidadeStore.entidadeList$;
  }

  ngOnInit() {
    this.fetchData();
    this.updateAtivo = this.modalTabsService.getAtivoFisicoForm();

    const idEntidadesSelecionadas = [
      ...new Set(this.selectedAtivos.map((x) => x.entidadeId)),
    ];

    if (idEntidadesSelecionadas.length > 1) {
      this.mostrarCamposRelacionadosEntidade = false;
    } else {
      this.mostrarCamposRelacionadosEntidade = true;
      const controlEntidade = this.updateAtivo.get("entidade");
      //   controlEntidade.disable();

      this.updateAtivo.patchValue({
        entidade: idEntidadesSelecionadas[0],
      });

      this.carregarListaUnidadesEntidadePorEntidadeId(
        idEntidadesSelecionadas[0]
      );
    }

    this.activatedRoute.url.subscribe((urlSegments) => {
      this.actualRoute = urlSegments.map((segment) => segment.path).join("/");
    });
  }

  mudarEntidade(entidade: Entidade) {
    if (entidade !== undefined) {
      this.entidadeSelecionada = entidade.entidadeId;
      // this.registerForm.patchValue({
      //  localizacaoUnidadeEntidade: '',
      //  alocacaoUnidadeEntidade: '',
      // });
      this.carregarListaUnidadesEntidadePorEntidadeId(entidade.entidadeId);
    }
  }

  editModal() {
    this.closeModal.emit();
  }

  fetchData() {
    if (!this.tipoDispositivoEnum) {
      this.ativoFisicoService.getTiposAtivosFisicos(2).subscribe((result) => {
        this.listaTiposAtivoFisico = result;
      });

      this.ativoFisicoService.getTiposAtivosFisicos(1).subscribe((result) => {
        this.listaTiposDispositivo = result;
      });
    }

    if (this.tipoDispositivoEnum === tipoDispositivoEnum.AcessorioTecnologia) {
      this.tecAcessorioService.getTipoAtivoAcessorio().subscribe((result) => {
        this.listaTiposDispositivo = result;
      });
    }

    if (this.tipoDispositivoEnum === tipoDispositivoEnum.AcessorioTelecom) {
      this.temAcessorioService.getTipoAtivoAcessorio().subscribe((result) => {
        this.listaTiposDispositivo = result;
      });
    }
  }

  // Busca o dado quando o usuário preenche o campo tipo
  carregarFabricantes() {
    this.loadFabricantesAtivoFisico();

    this.updateAtivo.controls.fabricante.enable();
  }

  loadFabricantesAtivoFisico() {
    if(!this.tipoDispositivoEnum) {
      this.ativoFisicoService
      .getFabricantesAtivosFisicos(this.currentRoute ? 1 : 2, this.tipo)
      .subscribe((result) => {
        this.listaFabricantes = result;
      });
    }

    if(this.tipoDispositivoEnum === tipoDispositivoEnum.AcessorioTelecom) {
      this.ativoFisicoService
      .getFabricantesAtivosFisicos(1, this.tipo)
      .subscribe((result) => {
        this.listaFabricantes = result;
      });
    }
  }

  carregarModelos() {
    this.fabricanteId = this.updateAtivo.value.fabricante;

    this.ativoFisicoService
      .getModeloAtivoFisicoPorCategoriaIdFabricanteId(1, this.fabricanteId)
      .subscribe((result) => {
        this.listaModelos = result;
      });
  }

  avisarUsuario() {
    if (this.updateAtivo.controls.fabricante.disabled) {
      this.toastr.warning("Selecione um tipo primeiro!");
    }
  }

  carregarListaUnidadesEntidadePorEntidadeId(entidadeId: number) {
    this.unidadeEntidadeService
      .getListaUnionUnidadeEntidadesPorEntidadeId(entidadeId)
      .subscribe((result) => {
        this.listaUnidadeEntidades = result;
      });
  }

  preSubmit() {
    this.modalConfirmIsOpen = true;
    this.selectedAtivos.forEach((element) => {
      if (
        this.entidadeSelecionada &&
        this.entidadeSelecionada !== element.entidadeId
      ) {
        this.txtConfirmacao =
          "Você mudou a entidade de um dos itens selecionados, com isso você irá mudar a entidade do ativo físico, você tem certeza disso?";
      } else {
        this.txtConfirmacao =
          "Você tem certeza que alterar em massa esse(s) item(s)";
      }
    });
  }

  public confirmSubmit = (action: boolean): void => {
    action ? this.runRequest() : false;
    this.modalConfirmIsOpen = false;
  };

  private runRequest() {
    let pegarDadosAtivoFisico = this.modalTabsService.pegarDadosChip(
      this.updateAtivo,
      this.user.email
    );

    const updateProperties = [
      "entidadeId",
      "status",
      "tipoAtivoId",
      "titularidadeId",
      "alocacaoUnidadeEntidadeId",
      "alocacaoEntidadeId",
      "localidadeUnidadeEntidadeId",
      "localidadeEntidadeId",
      "centroCustoEntidade",
      "numeroLinha",
      "ddd",
      "identificacao",
      "hlr",
      "estoque",
      "modeloDispositivoId",
      "notasFiscais",
      "chamadoReservado",
      "comentarios",
      "fabricanteDispositivoId",
      "numeroNotaEntrada",
      "numeroNFSaida",
      "valorNF",
      "valorNFsaida",
      "dataEmissaoSaida",
      "numeroNF",
    ];

    console.log(this.actualRoute);

    // console.log("ANTES DO LOOP");
    // console.log(this.selectedAtivos);

    this.selectedAtivos?.map((obj: any) => {
      updateProperties.forEach((prop) => {
        if (prop === "titularidadeId") {
          const entidadeId = pegarDadosAtivoFisico["entidadeId"];
          const titularidadeId = Number(
            pegarDadosAtivoFisico["titularidadeId"]
          );

          // Se a entidade e a titularidade existem e a titularidade é 2, definir titularidadeId como entidadeId
          if (entidadeId && titularidadeId === 2) {
            obj["titularidadeId"] = entidadeId;
            return;
          }

          // Se a titularidade é 2 e a entidadeId não está definida, definir titularidadeId como entidadeId do obj
          if (titularidadeId === 2 && !entidadeId) {
            obj["titularidadeId"] = obj["entidadeId"];
            return;
          }

          //Selecinou Titularidade Mobit
          if (titularidadeId === 1) {
            obj["titularidadeId"] = 1;
          }

          // Se a entidadeId está definida e a titularidade não está, definir titularidadeId com base na condição
          if (entidadeId && !titularidadeId) {
            obj["titularidadeId"] === 1
              ? (obj["titularidadeId"] = 1)
              : entidadeId;
          }

          // Se nenhum valor foi selecionado, manter a titularidadeId atual
          if (!entidadeId && !titularidadeId) {
            obj[prop] = obj[prop];
          }
        } else if (prop === "notasFiscais") {
          const notaFiscalEntrada = obj?.notasFiscais?.filter(
            (x) => x.categoriaNF === "E"
          )[0];
          const notaFiscalSaida = obj?.notasFiscais?.filter(
            (x) => x.categoriaNF === "S"
          )[0];

          const numeroNotaFiscalEntrada = obj.numeroNF
            ? obj.numeroNF
            : notaFiscalEntrada?.numeroNF;
          const numeroNotaFiscalSaida = obj.numeroNFSaida
            ? obj.numeroNFSaida
            : notaFiscalSaida?.numeroNF;

          const tipoNfEntrada = obj.tipoNFEntrada
            ? obj.tipoNFEntrada
            : notaFiscalEntrada?.tipoNF;
          const tipoNfSaida = obj.tipoNFSaida
            ? obj.tipoNFSaida
            : notaFiscalSaida?.tipoNF;

          const valorNfEntrada = obj.valorNF
            ? obj.valorNF?.toString()?.replace(",", ".")
            : notaFiscalEntrada?.valorNF;
          const valorNfSaida = obj.valorNF
            ? obj.valorNFsaida?.toString()?.replace(",", ".")
            : notaFiscalSaida?.valorNF;

          const dataNf = obj.dataNF ? obj.dataNF : notaFiscalEntrada?.dataNF;
          const dataNfSaida = obj.dataEmissaoSaida
            ? obj.dataEmissaoSaida
            : notaFiscalSaida?.dataNF;

          const produto = obj.produto
            ? obj.produto
            : notaFiscalEntrada?.produto;

          const notasFiscais: NotaFiscal[] = [
            // Nota de Entrada
            {
              categoriaNF: "E",
              numeroNF:
                this.updateAtivo.get(["numeroNotaEntrada"]).value ??
                numeroNotaFiscalEntrada,
              tipoNF:
                this.updateAtivo.get(["tipoNotaEntrada"]).value ??
                tipoNfEntrada,
              valorNF:
                this.updateAtivo.get(["valorNotaEntrada"]).value ??
                valorNfEntrada,
              dataNF:
                this.updateAtivo.get(["dataEmissaoEntrada"]).value ?? dataNf,
              produto: produto,
              ativo: true,
              usuarioCadastro: this.user.email,
              usuarioLog: this.user.email,
            },
            // Nota de Saída
            {
              categoriaNF: "S",
              numeroNF:
                this.updateAtivo.get(["numeroNotaSaida"]).value ??
                numeroNotaFiscalSaida,
              tipoNF:
                this.updateAtivo.get(["tipoNotaSaida"]).value ?? tipoNfSaida,
              // motivoSaida: this.updateAtivo.get(["motivoSaida"]).value,
              valorNF:
                this.updateAtivo.get(["valorNotaSaida"]).value ?? valorNfSaida,
              dataNF:
                this.updateAtivo.get(["dataEmissaoSaida"]).value ?? dataNfSaida,
              // produto: updateAtivo.get(['produto']).value,
              ativo: true,
              usuarioCadastro: this.user.email,
              usuarioLog: this.user.email,
            },
          ];
          const numeroNotaFiscalAttMassa = pegarDadosAtivoFisico[prop];

          obj["notasFiscais"] = notasFiscais;
        } else {
          obj[prop] =
            pegarDadosAtivoFisico[prop] !== null
              ? pegarDadosAtivoFisico[prop]
              : obj[prop];
        }
      });

      obj.usuarioLog = this.user.email;
    });

    if (this.component === "chip") {
      this.ativoFisicoService
        .updateAtivoFisicoChip(this.selectedAtivos)
        .subscribe((result) => {
          if (result instanceof HttpErrorResponse) {
            if (
              result.status !== HttpStatusCode.Conflict &&
              result.status !== HttpStatusCode.NotAcceptable
            ) {
              this.toastr.error("Erro ao editar os chip's", "Error");
            }
            return;
          }
          this.router.navigate(["/tem/ativoFisico/chips/lista"]);
          this.toastr.success("Chip editado com sucesso!", "Chip");
          this.tableStoreService.checkAllController(false);
          this.tablePaginatorService.firstOrLastPage(true);

          this.updateAtivo.reset();
          this.isOpen = false;
        });
    } else {
      console.log(this.selectedAtivos);
      this.ativoFisicoService
        .updateAtivoFisicoDispositivo(this.selectedAtivos)
        .subscribe((result) => {
          if (result instanceof HttpErrorResponse) {
            if (
              result.status !== HttpStatusCode.Conflict &&
              result.status !== HttpStatusCode.NotAcceptable
            ) {
              this.toastr.error("Erro ao editar os dispositivos's", "Error");
            }
            return;
          }

          this.toastr.success(
            "Dispositivo editado com sucesso!",
            "Dispositivo"
          );

          this.tablePaginatorService.firstOrLastPage(true);
          this.tableStoreService.checkAllController(false);
          this.updateAtivo.reset();
          this.isOpen = false;
        });
    }
  }
}
