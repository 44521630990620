import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appUppercase]',
})

export class UppercaseDirective {
  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const start = input.selectionStart;
    const end = input.selectionEnd;

    // Convert the input value to uppercase
    input.value = input.value.toUpperCase();

    // Forçar a atualização do valor no modelo Angular
    this.control.control?.setValue(input.value);

    // Restaurar a posição do cursor
    input.setSelectionRange(start, end);
  }
}