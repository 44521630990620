import { Directive} from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';

@Directive({
  selector: '[appReadonlyAll]',
})
export class ReadonlyAllDirective {

  constructor(private controlContainer : ControlContainer) {}

  ngOnInit(): void {
    const formGroup = this.controlContainer.control as FormGroup

    let isAdmin : boolean = false;
    const myRoles = localStorage.getItem('roles')
    let listOfRoles = myRoles.split(',').map(role => role.trim().toLowerCase());
    
    if (myRoles !== null || myRoles !== undefined) {
      if ((listOfRoles !== null || listOfRoles !== undefined) && listOfRoles.includes('admin')) {
        isAdmin = true
      }
  
      if (myRoles.toLowerCase() === 'admin') {
        isAdmin = true
      }
      
      if (isAdmin === false) {
        Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control) {
            control.disable()
          }
        })
      }
    }
  }
}
