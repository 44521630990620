<ng-template #contentTemplate>
  <p-accordion [multiple]="true" [activeIndex]="[0]" id="menu-accordion">
    @for (menuItem of menuItems; track $index) { @if(menuItem.isTitle == true){
    <p>{{ menuItem.label | translate }}</p>
    } @else {
    <p-accordionTab [header]="menuItem?.label | translate">
      @for(subItem of menuItem.subItems; track $index) { @if(subItem?.subItems)
      {
      <p-accordionTab [header]="subItem?.label | translate">
        @for(subItem of subItem.subItems; track $index) {
        <a [href]="subItem.link">{{ subItem?.label | translate }}</a>
        }
      </p-accordionTab>
      }@else {
      <a [href]="subItem.link">{{ subItem?.label | translate }}</a
      >'' } }
    </p-accordionTab>

    } }
  </p-accordion>
</ng-template>
<div
  class="vertical-menu"
  [ngStyle]="{
    backgroundColor:
      this.tokenStorageService.getTheme() === 'dark'
        ? 'var(--primary)'
        : '#fbf8f7'
  }"
>
  <ngx-simplebar class="h-100" #componentRef>
    @if(isCondensed){
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }@else {
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }
  </ngx-simplebar>
</div>
