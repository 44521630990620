<p-dialog
  style="width: 100%"
  [(visible)]="isOpen"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  (onHide)="fecharModal()"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <div
      class="dialog-header"
      style="width: 100%; display: flex; justify-content: space-between"
    >
      <h6>Alterar DDD</h6>
      <div class="btn-close" (click)="fecharModal()"></div>
    </div>
  </ng-template>
  <ng-container [ngTemplateOutlet]="contaFatura"></ng-container>
  <ng-template pTemplate="footer">
    <div style="display: flex; justify-content: space-between">
      <app-action-btn
        icon="fa fa-check font-size-20"
        [type]="''"
        [disabled]="chipSelecionado === undefined || ddd === ''"
        [pTooltip]="
          chipSelecionado === undefined
            ? 'Por favor selecionar um chip primeiro'
            : 'Alterar DDD e chip'
        "
        text="Alterar DDD do chip"
        (click)="selecionarValor(chipSelecionado)"
      />
    </div>
  </ng-template>
</p-dialog>

<ng-template style="min-height: auto" #contaFatura>
  <form [formGroup]="registerForm" *ngIf="registerForm">
    <section class="modal-container">
      <div class="input-default input-lg">
        <label for="Identificador">Identificador</label>
        <input
          formControlName="identificador"
          type="text"
          id="formrow-email-input"
          placeholder="Escreva um identificador (Número)"
          mask="(00) 00000-0000"
          (blur)="onChangeNumeroLinha()"
        />
      </div>

      <div class="input-default input-md">
        <label for="ativacao">Ativação</label>
        <input formControlName="ativacao" type="date" id="ativacao" />
      </div>

      <div class="input-default input-md">
        <label for="ddd">DDD</label>
        <input
          formControlName="DDD"
          disabled
          placeholder="DDD"
          type="text"
          maxlength="2"
          name="number"
          style="cursor: no-drop"
        />
      </div>

      <div class="input-default input-sm">
        <label for="UF">UF</label>
        <input
          formControlName="UF"
          disabled
          type="text"
          maxlength="2"
          placeholder="UF"
          style="cursor: no-drop"
        />
      </div>
    </section>
  </form>
  <section class="modal-container-gap">
    <app-action-btn
      icon="mdi mdi-account-plus font-size-20"
      [type]="'outlined'"
      text="Selecionar Chip"
      (click)="editModalChips()"
    />

    @if(chipSelecionado){
    <div class="vinculos-table-item" style="justify-self: flex-end">
      <app-action-btn
        [icon]="'mdi mdi-eye font-size-20 '"
        [type]="'outlined'"
        [text]="'Visualizar'"
        [route]="
          '/tem/ativoFisico/chips/form/' + chipSelecionado?.ativoFisicoId
        "
      ></app-action-btn>
    </div>

    <div>
      <div class="grid-wrapper-col-4">
        <div class="vinculos-table-header">Fabricante</div>
        <div class="vinculos-table-header">Identificação</div>
        <div class="vinculos-table-header">Entidade</div>
        <div class="vinculos-table-header">Chamado</div>
      </div>

      <div class="grid-wrapper-col-4" style="margin: 5px 0px">
        <div class="vinculos-table-item">
          {{ chipSelecionado?.fabricanteDispositivo?.descricao }}
        </div>
        <div class="vinculos-table-item">
          {{ chipSelecionado?.descricao }}
        </div>
        <div class="vinculos-table-item">
          {{ chipSelecionado?.entidade?.nomeUsual }}
        </div>
        <div class="vinculos-table-item">
          {{ chipSelecionado?.chamadoReservado }}
        </div>
      </div>
    </div>
    }
  </section>
</ng-template>

<app-modal-list-chips
  *ngIf="openModalChips"
  title="Escolha um Chip"
  [entidadeId]="ativoLinha?.linha?.entidadeId"
  [fornecedor]="ativoLinha?.linha?.fornecedor"
  (selecionarValorEvent)="selecionarValorChip($event)"
  (fecharModalEvent)="editModalChips()"
  [labelBtn]="'Selecionar'"
  [numeroDaLinha]="ativoLinha?.linha?.descricao"
  [todosMaisLinha]="true"
  [titularidadeId]="titularidadeId"
/>
