import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  map,
} from "rxjs";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { PaginatorStoreService } from "src/app/core/store/paginator.store.service";
import { AtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FilterStoreService } from "src/app/core/store/filter.store.service";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AtivoLinhaService } from "src/app/modulos/telecom/ativo-linha/core/services/ativo-linha.service";
import { AtivoFisicoService } from "src/app/modulos/telecom/ativo-fisico/core/service/ativo-fisico.service";
import { AtivoCsv } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisicoCsv.model";
import { DomSanitizer } from "@angular/platform-browser";
import { SearchRequest } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/searchRequest.model";
import { UsuarioEntidadeService } from "src/app/modulos/telecom/cadastro/core/services/usuarioEntidade.service";
import { LoadActionService } from "src/app/core/actions/load-action.service";
import { BollingerBands } from "@amcharts/amcharts5/.internal/charts/stock/indicators/BollingerBands";
import { ProgessCsvService } from "../../services/progess-csv.service";
import { ToastrService } from "ngx-toastr";
import { FaturaService } from 'src/app/modulos/telecom/conta-fatura/core/services/fatura.service';
import { BlobUtils } from "src/app/core/utils/BlobUtils";
import { ExportModel } from "src/app/core/models/exportModel";
import { DispositivoFisicoService } from "src/app/modulos/tecnologia/ativo-linha/core/services/ativo-linha.service";
import { CsvDownloadService } from "../../services/csv-download.service";
import { FrotaService } from "src/app/facilities/frotas/core/services/frota.service";
import { ContaFaturaService } from "src/app/modulos/telecom/conta-fatura/core/services/contaFatura.service";

//https://primeng.org/table#customers
@Component({
  selector: "app-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  // providers: [LoadActionService]
})
export class DataTableComponent implements OnDestroy {
  private subscription: Subscription[] = [];
  private searchSubject = new Subject<string>();
  selection: any;
  isChecked: boolean = false;
  loteIsOpen: boolean = false;
  selectedAtivos: AtivoFisico[] = [];
  searchTextGlobal: string = null;

  registerForm: FormGroup;
  exportForm: FormGroup;
  columnsControler: any[] = [
    {
      column: "",
      operator: "",
      validation: "",
      searchText: "",
      isNgSelectedInput: false,
      valuesForSelectedInput: [],
      maskTextFilter: "",
      prefix: "",
      thousandSeparator: "",
      decimalMarker: "",
      dropSpecialCharacters: true,
    },
  ];
  columnsList: string[] = [];

  isEmpty: boolean = false;
  searchRequestData: SearchRequest;

  nomeArquivo: string;
  fileUrl: any;
  exportacaoForm: FormGroup;

  isForm: boolean = false;

  @Input() grid: string;
  @Input() tipoSearch: number;
  @Input() isOpen: boolean = false;
  @Input() data: BehaviorSubject<any>;
  @Input() title: string;
  @Input() request: SERVICES_NAMES;
  @Input() searchRequest: SERVICES_NAMES;
  @Input() dataTableHeaders: string[];
  @Input() insertURL: string;
  @Input() updateURL: string;
  @Input() addRoute: string;
  @Input() queryParams: any;
  @Input() sortFields: { name: string; value: string }[];
  @Input() exportCsvRoute: string;
  @Input() headerAlign: string[];

  @Input() exibirActionButtons: boolean = false;
  @Input() exibirFilterButton: boolean = true;
  @Input() placeholderPesquisaGlobal: string = "Pesquisa Global";
  @Input() exibirEditarMuitos: boolean = true;
  @Input() exibirPesquisa: boolean = true;

  // Exportação CSV
  // Id da categoria ou serviço associado
  @Input() agrupamentoId: number;
  @Input() exibirExport: boolean = false;

  // Dependendo do componente deve se esconder o checkbox
  @Input() exibirCheckbox: boolean = true;

  @Input() filterContext?: string = "";

  @Output() openModal: EventEmitter<any> = new EventEmitter();
  @Output() getData: EventEmitter<any> = new EventEmitter();

  url: string = location.href;
  filterParameters: any;

  isUsuario: boolean = false;
  comRecursos: boolean = false;

  actualRoute: boolean = ["/tem/ativoFisico/lista",].some((item) =>
    this.url.includes(item)
  );

  public labelSearchInput: string = "";
  public maskTextFilter: string = "";
  public thousandSeparator: string = "";
  public decimalMarker: string = "";
  public prefix: string = "";
  public dropSpecialCharacters: boolean = true;
  public isNgSelectedInput: boolean = false;
  public valuesForSelectedInput: string[] = [];

  totalItems: string;
  isOpenExportModal: boolean = false;

  tableData: any[] = [];
  isModalOpen: boolean;
  sort: any;
  sortSelected: string;
  btnExportDisabled: boolean = false;
  btnUploadDisabled: boolean = false;

  constructor(
    private paginatorStore: PaginatorStoreService,
    private tableStore: TableStoreService,
    private formBuilder: FormBuilder,
    private filterStore: FilterStoreService,
    private paginatorGenericStore: PaginatorGenericStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private ativoFisicoService: AtivoFisicoService,
    private ativoLinhaService: AtivoLinhaService,
    private dispositivoFisicoService: DispositivoFisicoService,
    private usuarioEntidadeService: UsuarioEntidadeService,
    private frotaService: FrotaService,
    private sanitizer: DomSanitizer,
    private loadService: LoadActionService,
    private readonly progressCsvService: ProgessCsvService,
    private readonly toastr: ToastrService,
    private readonly faturaService: FaturaService,
    private readonly contaFaturaService: ContaFaturaService,
    private readonly csvDownloadService: CsvDownloadService
  ) {}

  get list$() {
    return this.paginatorStore.list$;
  }

  get totalItemsFilterStore$() {
    return this.filterStore.totalItemsSubject.asObservable();
  }

  get filterList$() {
    return this.filterStore.constest$;
  }

  get pagination$() {
    return this.paginatorGenericStore.pagination$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  get isAllSelected$() {
    return this.tableStore.isAllSelected$;
  }

  get totalItems$() {
    return this.paginatorGenericStore.totalItems$;
  }

  get filteredList$() {
    return this.filterStore.filteredTableList$;
  }

  get oneOf$() {
    return this.paginatorStore.oneOf$;
  }

  get columnslist$() {
    return this.tableStore.columnsList$;
  }

  get isLoading$() {
    return this.loadService.loadAction$;
  }

  ngOnInit() {
    window.location.pathname.includes("form")
      ? (this.isForm = true)
      : (this.isForm = false);
    this.filterStore.onDestroy();

    this.isUsuario = location.pathname.includes("usuarioentidade")
      ? true
      : false;

    this.registerForm?.controls[`column0`]?.setValue(null);
    this.registerForm?.controls[`validation0`]?.setValue(1);

    this.exportacaoForm = this.formBuilder.group({
      valorExport: "umaPagina",
      recursoOption: "semRecursos",
    });

    this.progressCsvService.showProgressCsvObservable.subscribe((res) => {
      if (res.metodo === "Upload") {
        if (res.value) {
          this.btnUploadDisabled = true;
        } else {
          this.btnUploadDisabled = false;
        }
      }
    });
    if (this.filterContext === "") {
      console.log("CAIU NO IF");
      location.pathname.includes("ativoLinha")
        ? this.filterStore.changeContext("ativoLinha")
        : "";
      location.pathname.includes("ativoFisico")
        ? this.filterStore.changeContext("ativoFisico")
        : "";
      location.pathname.includes("usuarioentidade")
        ? this.filterStore.changeContext("usuarioentidade")
        : "";
      location.pathname.includes("listaDownload")
        ? this.filterStore.changeContext("download")
        : "";
      location.pathname.includes("listaConta")
        ? this.filterStore.changeContext("contafatura")
        : "";
      location.pathname.includes("listaFatura")
        ? this.filterStore.changeContext("faturaconta")
        : "";
      location.pathname.includes("listaFatura")
        ? this.filterStore.changeContext("faturaconta")
        : "";
      location.pathname.includes("frotas")
        ? this.filterStore.changeContext("gestaofrotas")
        : "";
      location.pathname.includes("modelos_fabricantes") &&
        this.columnslist$.subscribe((result) => {
          result === "fabricante"
            ? this.filterStore.changeContext("fabricante")
            : this.filterStore.changeContext("modelodispositivo");
        });
    }
    else {
      console.log("CAIU NO ELSE");
      console.log(this.filterContext);
      this.filterStore.changeContext(this.filterContext);
    }

    if (this.data != null) {
      this.subscription.push(
        this.data?.subscribe((data) => {
          this.subscription.push(
            data?.subscribe((d) => {
              this.paginatorGenericStore.updatePage(
                d.itensList,
                d.quantidadeTotal
              );

              if (d.itensList?.length > 0 || d.itensList?.length === 0) {
                this.loadService.closeLoad();
              } else {
                this.loadService.startload();
              }

              if (d.itensList?.length === 0) this.isEmpty = true;
              else this.isEmpty = false;

              this.tableData = d.itensList;

              this.tableStore.startTableRows(d.itensList);
              this.totalItems = d.quantidadeTotal;
            })
          );
        })
      );
    } else {
      this.subscription.push(
        this.list$.pipe(debounceTime(1000)).subscribe((data: any[]) => {
          this.isEmpty = data?.length === 0;
          this.tableData = data;
        })
      );
      this.tableStore.startTableRows(this.tableData);
    }

    this.subscription.push(
      this.paginatorGenericStore.pagination$.subscribe((pagination) => {
        this.filterStore.inicializeFilter(pagination, this.tipoSearch);
        this.getData.emit({
          paginator: pagination,
          searchText: this.searchTextGlobal,
        });
        this.loadService.closeLoad();
      })
    );

    if (this.exibirFilterButton) {
      this.filterStore.columnsList$.subscribe((columns) => {
        this.columnsList = columns;
      });

      this.subscription.push(
        this.filterStore.filteredTableList$.subscribe((data: any) => {
          this.filterStore.hasQuery &&
            this.tableStore.startTableRows(data.itensList);

          this.paginatorGenericStore.updatePage(
            data?.itensList,
            data?.quantidadeTotal
          );

          this.loadService.closeLoad();
        })
      );
    }

    this.subscription.push(
      this.pagination$.subscribe((result) => {
        this.filterParameters = result;
      })
    );

    this.subscription.push(
      this.list$.subscribe(
        (data: any[]) =>
          !this.filterStore.hasQuery && this.tableStore.startTableRows(data)
      )
    );

    this.subscription.push(
      this.isAllSelected$.subscribe((data: boolean) => (this.isChecked = data))
    );

    this.subscription.push(
      this.rowsSelected.subscribe((data: any) => (this.selectedAtivos = data))
    );

    this.subscription.push(
      this.searchSubject
        .pipe(distinctUntilChanged(), debounceTime(300))
        .subscribe((event) => {
          this.getData.emit({
            paginator: {
              pageNumber: 1,
              pageSize: this.filterParameters.pageSize,
            },
            searchText: event,
          });
        })
    );

    this.paginatorStore.UpdateSearchFuncitions(
      this.request,
      this.searchRequest
    );

    this.registerForm = this.formBuilder.group({
      column0: null,
      validation0: null,
      searchText0: null,
    });

    this.registerForm?.controls?.column0.setValue(null);
    this.registerForm?.controls?.validation0.setValue("1");

    this.exportForm = this.formBuilder.group({
      exportColumns: null,
      valorExport: "umaPagina",
    });

    if (this.exibirFilterButton)
      this.filterStore.carregarColunasFilter(this.exibirFilterButton);

    this.paginatorStore.tipoSearch = this.tipoSearch;

    this.totalItemsFilterStore$.subscribe((data) => {
      this.totalItems = data.toString();
    });

    this.progressCsvService.showProgressCsvObservable.subscribe((res) => {
      if (res.metodo == "Download") {
        this.btnExportDisabled = res.value;
      }
    });
  }

  getNestedValue(obj, field) {
    return field.split(".").reduce((acc, part) => acc && acc[part], obj);
  }
  onRecursoOption(comRecursos) {
    this.comRecursos = comRecursos;

    if (this.comRecursos) {
      this.filterStore.changeContext("usuarioentidaderecursos");
    } else {
      this.filterStore.changeContext("usuarioentidade");
    }
  }

  sortTable(field) {
    this.sort = !this.sort;
    const sortedList = this.tableData?.sort((a, b) => {
      const aValue = this.getNestedValue(a, field);
      const bValue = this.getNestedValue(b, field);

      if (this.sort) {
        if (aValue > bValue) {
          return -1;
        } else if (aValue < bValue) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (aValue < bValue) {
          return -1;
        } else if (aValue > bValue) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    // const unsortedList = this.tableData?.sort((a, b) => {
    //   if (a.ativoFisicoId < b.ativoFisicoId) {
    //     console.log('TESTE', -1)
    //     return -1
    //   }
    // })
    this.tableStore.startTableRows(sortedList);
  }

  addFilter() {
    this.registerForm.addControl(
      `operators${this.columnsControler.length}`,
      new FormControl("1", [Validators.required])
    );
    this.registerForm.addControl(
      `column${this.columnsControler.length}`,
      new FormControl(null, [Validators.required])
    );
    this.registerForm.addControl(
      `validation${this.columnsControler.length}`,
      new FormControl("1", [Validators.required])
    );
    this.registerForm.addControl(
      `searchText${this.columnsControler.length}`,
      new FormControl(null, [Validators.required])
    );

    this.columnsControler.push({
      column: "",
      operator: "",
      validation: "",
      searchText: "",
      isNgSelectedInput: false,
      valuesForSelectedInput: [],
      maskTextFilter: "",
      prefix: "",
      thousandSeparator: "",
      decimalMarker: "",
      dropSpecialCharacters: true,
    });
  }

  removeFilter(e: any) {
    this.registerForm.get(`searchText${e}`).reset();
    this.registerForm.get(`column${e}`).reset();

    if (this.columnsControler.length === 1) {
      return;
    }

    this.columnsControler = this.columnsControler.filter(
      (column, index) => index != e
    );

    // let index = this.columnsControler.indexOf(e);
    // this.columnsControler.length > 1 && this.columnsControler.splice(index, 1);
  }

  applyFilters() {
    const columnValue: string[] = [];
    const validationValue: string[] = [];
    const searchTextValue: string[] = [];
    const operatorsValue: string[] = [];

    for (let index = 0; index < this.columnsControler.length; index++) {
      columnValue.push(this.registerForm.get(`column${index}`).value);
      validationValue.push(this.registerForm.get(`validation${index}`).value);
      searchTextValue.push(this.registerForm.get(`searchText${index}`).value);
      if (this.registerForm.get(`operators${index}`)?.value)
        operatorsValue.push(this.registerForm.get(`operators${index}`).value);
    }

    this.filterStore.createQuerry({
      column: columnValue,
      validation: validationValue,
      searchText: searchTextValue,
      operators: operatorsValue,
    } as SearchRequest);

    this.searchRequestData = {
      column: columnValue,
      validation: validationValue,
      searchText: searchTextValue,
      operators: operatorsValue,
    };

    if (this.data != null) {
      this.paginatorStore.firstOrLastPage(true);
    } else {
      this.paginatorGenericStore.firstOrLastPage(true);
    }
  }

  editModal() {
    this.openModal.emit();
    this.isModalOpen = true;
  }

  loteModal(event?: any) {
    console.log('ESTOOOOU FECHANDOOOO O MODAAAL')
    this.loteIsOpen = !this.loteIsOpen;
  }

  exportModal() {
    this.isOpenExportModal = !this.isOpenExportModal;
    console.log('ESTOOOOU FECHANDOOOO O MODAAAL')
  }

  onCheck() {
    this.tableStore.checkAllController(!this.isChecked);
    console.log('ESTOOOOU FECHANDOOOO O MODAAAL')
  }

  firstOrLastPage(event: boolean) {
    if (this.data != null) this.paginatorGenericStore.firstOrLastPage(event);
    else this.paginatorStore.firstOrLastPage(event);
  }

  alterarLinhas(event: number) {
    if (this.data != null)
      this.paginatorGenericStore.updateQuantidadeLinha(event);
    else this.paginatorStore.updateQuantidadeLinha(event);
  }

  alterarPagina(event: any) {
    if (this.data != null)
      this.paginatorGenericStore.updatePaginaPosicao(event);
    else this.paginatorStore.updatePaginaPosicao(event);
  }

  pesquisaGlobal(event, searchText: string) {
    this.searchTextGlobal = searchText;

    if (this.data != null) this.searchSubject.next(encodeURIComponent(searchText));
    else this.paginatorStore.pesquisaTodosAtivoUpdate(encodeURIComponent(searchText));
  }

  pesquisaGlobalEnter(event, searchText) {
    if (event.key === "Enter" && searchText) {
      if (this.data != null) this.searchSubject.next(searchText);
      else this.paginatorStore.pesquisaTodosAtivoUpdate(searchText);
    }
  }

  exportCsv() {
    if (!this.exportForm.value.exportColumns) {
      this.toastr.warning("Selecione os campos desejados!", "Campos Pendentes");
      return;
    }
    this.progressCsvService.showProgressCsvSubject.next({
      value: true,
      metodo: "Download",
    });
    const entidadeSelecionada = Number(
      localStorage.getItem("entidadeSelecionada")
    );
    if (this.exportForm.controls.valorExport.value === "todasPaginas") {
      this.filterParameters.pageNumber = 1;
      this.filterParameters.pageSize = 99999;
    }
    if (entidadeSelecionada) {
      this.filterParameters.entidadeId = entidadeSelecionada;
    }
    //Nota: Mudando isso de lugar, pois isso não é algo relacionado somente ao Ativo: é o modelo de exportação
    let exportModel: ExportModel<SearchRequest> = {
      colunas: this.exportForm.controls.exportColumns.value,
      filterParameters: this.filterParameters,
      globalSearch: this.searchTextGlobal,
    };
    if (entidadeSelecionada) {
      exportModel.entidadeId = entidadeSelecionada;
    }

    // Verifico se tem algum filtro aplicado
    // Caso sim envio esses filtros para o backend exportar o CSV com filtro
    if (this.filterStore.returnQuery()) {
      exportModel.searchRequest = this.searchRequestData;
    }
    let url = this.route.snapshot["_routerState"].url;
    if(url.includes('tem/contaFatura/listaFatura')){
      this.isOpenExportModal = !this.isOpenExportModal;
      return this.exportByModel({ativoCsv: exportModel,downloadCsv: (req) => this.faturaService.exportCsv(req) })
    }
    if(url.includes('tem/contaFatura/listaConta')){
      this.isOpenExportModal = !this.isOpenExportModal;
      return this.exportByModel({ativoCsv: exportModel,downloadCsv: (req) => this.contaFaturaService.downloadCsv(req) })
    }
    if(url.includes('tem/contaFatura/listaDownload')){
      this.isOpenExportModal = !this.isOpenExportModal;
      return this.exportByModel({ativoCsv: exportModel,downloadCsv: (req) => this.csvDownloadService.downloadCsv('/download/export',req) })
    }
    let categoria = 0;
    if (url.includes("dispositivos")) categoria = 1;
    else if (url.includes("chips")) categoria = 2;

    let servico = 0;
    if (url.includes("movel")) servico = 1;
    else if (url.includes("fixa")) servico = 2;
    else if (url.includes("banda_larga")) servico = 3;
    else if (url.includes("link")) servico = 4;

    let ativo = "";
    if (url.includes("ativoFisico")) ativo = "fisico";
    else if (url.includes("ativoLinha")) ativo = "linha";
    else ativo = "usuario";
    if (ativo === "fisico") {
      exportModel.categoriaId = categoria;
      this.isOpenExportModal = !this.isOpenExportModal;
      return this.exportByModel({ativoCsv: exportModel,downloadCsv: (req) => this.ativoFisicoService.downloadCsv(req)});
    } else if (ativo === "linha") {
      exportModel.servicoId = servico;
      this.isOpenExportModal = !this.isOpenExportModal;
      return this.exportByModel({ativoCsv: exportModel,downloadCsv: (req) => this.ativoLinhaService.downloadCsv(req)});
    } else {
      exportModel.servicoId = servico;
      const downloadCsvFunc = this.comRecursos
        ? (req) => this.usuarioEntidadeService.downloadUsuarioRecursoCsv(req)
        : (req) => this.usuarioEntidadeService.downloadCsv(req)
        this.isOpenExportModal = !this.isOpenExportModal;
        return  this.exportByModel({ativoCsv: exportModel,downloadCsv: (req) => downloadCsvFunc(req)})
      }
   }
  private exportByModel(params:{ativoCsv: AtivoCsv,downloadCsv: (AtivoFisico) => Observable<any>}) {
    const {ativoCsv,downloadCsv} = params
    downloadCsv(ativoCsv)
      .subscribe((result) => {
        this.retornarBlob(result)
        this.progressCsvService.showProgressCsvSubject.next({
          value: false,
          metodo: 'Download'
        })
      })
  }

  retornarBlob(respostaApi: any) {
    const blob = new Blob([respostaApi.response.body], {
      type: respostaApi.headers.get("content-type"),
    });
    const contentDispositionHeader = respostaApi.headers.get(
      "content-disposition"
    );

    let content = contentDispositionHeader?.split(";");
    this.nomeArquivo = content ? content[1].replace("filename=", "") : "";
    this.nomeArquivo = this.nomeArquivo.trim();

    this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );

    let urlBlob = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = urlBlob;
    a.download = this.nomeArquivo;
    a.click();
    window.URL.revokeObjectURL(urlBlob);
    a.remove();
  }

  cleanSearch() {
    if (this.searchTextGlobal === null || this.searchTextGlobal === "") {
      if (this.data != null) this.searchSubject.next("");
      else this.paginatorStore.pesquisaTodosAtivoUpdate("");
    }
  }

  public clearAllFilters = (): void => {
    this.searchTextGlobal = "";
    this.sortSelected = null;
    this.columnsControler = [1];
    this.filterStore.cleanQuery();
    this.loadService.closeLoad();
    this.paginatorStore.firstOrLastPage(true);

    this.subscription.push(
      this.paginatorGenericStore.pagination$.subscribe((pagination) => {
        this.filterStore.inicializeFilter(pagination, this.tipoSearch);
        this.getData.emit({
          paginator: pagination,
          searchText: this.searchTextGlobal,
        });
        this.loadService.closeLoad();
      })
    );
  };

  public onChangeFieldFilter = (value: string, index: number): void => {
    console.log(value);

    const textFormatt = value.toLowerCase().trim();

    const decimalValues = ["valor", "multa"];
    const dateValues = [
      "data",
      "recebimento",
      "fim da vigência",
      "reativação",
      "ativação",
      "previsão de reativação",
      "inativação",
    ];
    const selectedValues = ["disponibilidade", "estoque", "status"];

    if (decimalValues.includes(textFormatt)) {
      this.applyDecimalFilter(index);
    } else if (dateValues.includes(textFormatt)) {
      this.applyDateFilter(index);
    } else if (selectedValues.includes(textFormatt)) {
      this.applySelectedValuesFilter(textFormatt, index);
    } else {
      this.resetFilter(index);
    }
  };

  private applyDecimalFilter(index: number): void {
    this.columnsControler[index].maskTextFilter = "separator.2";
    this.columnsControler[index].prefix = "R$";
    this.columnsControler[index].thousandSeparator = ".";
    this.columnsControler[index].decimalMarker = ",";
    this.columnsControler[index].dropSpecialCharacters = true;
    this.columnsControler[index].isNgSelectedInput = false;
  }

  private applyDateFilter(index: number): void {
    this.columnsControler[index].maskTextFilter = "00/00/0000";
    this.columnsControler[index].prefix = null;
    this.columnsControler[index].thousandSeparator = null;
    this.columnsControler[index].decimalMarker = null;
    this.columnsControler[index].dropSpecialCharacters = false;
    this.columnsControler[index].isNgSelectedInput = false;
  }

  private applySelectedValuesFilter(textFormatt: string, index: number): void {
    const location = this.router.url;
    this.columnsControler[index].isNgSelectedInput = true;

    const valuesMap = {
      disponibilidade: ["Disponível", "Não Disponível"],
      estoque: ["Em Estoque", "Fora de Estoque", "Reservado"],
      status: this.getStatusValues(location),
    };

    this.columnsControler[index].valuesForSelectedInput =
      valuesMap[textFormatt] || [];
  }

  private getStatusValues(location: string): string[] {
    if (location.includes("ativoFisico")) {
      return ["Ativo", "Estoque", "Inativo", "Novo", "Roubo", "Usado"];
    } else if (location.includes("ativoLinha")) {
      return [
        "Ativa",
        "Bloqueada",
        "Bloqueio Pre Ativacão",
        "Inativa",
        "Reativada Apos Suspensao",
        "Suspensao",
      ];
    } else {
      return ["Ativo", "Inativo"];
    }
  }

  private resetFilter(index: number): void {
    this.columnsControler[index].maskTextFilter = null;
    this.columnsControler[index].prefix = null;
    this.columnsControler[index].thousandSeparator = null;
    this.columnsControler[index].decimalMarker = null;
    this.columnsControler[index].dropSpecialCharacters = true;
    this.columnsControler[index].isNgSelectedInput = false;
  }

  ngOnDestroy() {
    this.subscription.forEach((result) => result?.unsubscribe());
    this.paginatorGenericStore.onDestroy();
    this.filterStore.onDestroy();
    // this.paginatorStore.onDestroy();
  }
}
