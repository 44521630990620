export enum ColunasCsvEnum {
    AtivoFisicoTodos = 1,
    Chip = 2,
    Dispositivo = 3,
    AtivoLinhaTodos = 4,
    Movel = 5,
    PacoteRI = 6,
    Links = 7,
    BandaLarga = 8,
    Fixa = 9,
    Padrao = -1
}