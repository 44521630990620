import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { DDDEstadosEnum } from "src/app/core/models/enums/dddEstadosEnum";
import { AtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model";
import { AtivoLinhaResult } from "src/app/modulos/telecom/ativo-linha/core/models/ativos-linha/ativoLinhaResult.model";

@Component({
  selector: "app-ddd-change-template",
  templateUrl: "./ddd-change-template.component.html",
  styleUrls: ["./ddd-change-template.component.scss"],
})
export class DddChangeTemplateComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() ativoLinha: AtivoLinhaResult = {} as AtivoLinhaResult;
  @Input() chipVinculado: AtivoLinhaResult = {} as AtivoLinhaResult;
  @Input() isOpen: boolean = false;
  @Input() titularidadeId: number;
  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<{
    novaLinha: string;
    AtivoFisicoId: number;
    dataAtivacao: string;
  }>();

  public chipSelecionado: AtivoFisico;
  public openModalChips = false;
  public registerForm: FormGroup;

  constructor(
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      identificador: [null, [Validators.required, Validators.minLength(11)]],
      DDD: [null, [Validators.required]],
      UF: [null, [Validators.required]],
      ativacao: [null, [Validators.required]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.identificador?.currentValue) {
      this.ativoLinha = changes?.ativoLinha?.currentValue;
      this.chipVinculado = changes?.chipVinculado?.currentValue;
      this.isOpen = changes?.isOpen?.currentValue;
    }

    this.registerForm = this.formBuilder.group({
      identificador: [
        null,
        [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(11),
        ],
      ],
      DDD: [null, [Validators.required]],
      UF: [null, [Validators.required]],
      ativacao: [null, [Validators.required]],
    });

    this.registerForm.get("DDD").disable();
    this.registerForm.get("UF").disable();
  }

  selecionarValor() {
    if (!this.chipSelecionado) {
      this.toastr.warning(
        "Por favor selecionar um chip primeiro",
        "Alteração de DDD",
        {
          positionClass: "toast-top-center",
        }
      );
      return;
    }

    console.log(this.registerForm.controls);
    console.log(this.registerForm.get("ativacao")?.value);

    this.selecionarValorEvent?.emit({
      novaLinha: this.registerForm.get("identificador").value,
      AtivoFisicoId: this.chipSelecionado.ativoFisicoId,
      dataAtivacao: this.registerForm.get("ativacao")?.value,
    });
    this.fecharModal();
  }

  selecionarValorChip(event: AtivoFisico) {
    this.chipSelecionado = event;
  }

  editModalChips() {
    this.openModalChips = !this.openModalChips;
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  public onChangeNumeroLinha = (): void => {
    const value: string = this.registerForm.get("identificador").value;

    if (value?.length === 11) {
      this.changeUF();
      return;
    }

    this.registerForm.get("DDD").reset();
    this.registerForm.get("UF").reset();

    value?.length === 11 ? this.changeUF() : false;
  };

  private changeUF = (): void => {
    const value: string = this.registerForm.get("identificador").value;
    const ddd = Number(value.substring(0, 2));

    const estado = DDDEstadosEnum[ddd];
    const uf = estado.length > 2 ? `${estado[0]}${estado[1]}` : estado;

    this.registerForm.get("DDD").setValue(ddd);
    this.registerForm.get("UF").setValue(uf);
  };

  ngOnDestroy(): void {
    this.registerForm = null;
  }
}
