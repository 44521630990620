import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Table } from "primeng/table";
import { Subscription } from "rxjs";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { Pagination } from "src/app/core/models/pagination.model";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { ContaFatura } from "src/app/modulos/telecom/conta-fatura/core/models/contaFatura.model";
import { ContaFaturaService } from "src/app/modulos/telecom/conta-fatura/core/services/contaFatura.service";

@Component({
  selector: "app-conta-fatura-modal",
  templateUrl: "./conta-fatura-modal.component.html",
  styleUrls: ["./conta-fatura-modal.component.css"],
})
export class ContaFaturaModalComponent {
  @Input() headerTitle: string;
  @Input() buttonFunction: () => void;
  @Input() buttonText: string;
  @Input() reference: string;
  @Input() itemSelecionadoFromParent: ContaFatura;
  @Input() servicoId: number;
  @Input() entidadeId: number;
  @Input() titularidadeId: number = 1;
  @Input() fornecedorId: number;
  @Input() nomeFornecedor: string = "''";

  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<any>();
  @Output() limparValorEvent = new EventEmitter<any>();

  contaSelecionado: ContaFatura;

  breadCrumbItems: Array<{}>;
  tooltipText = "pesquisa da pagina atual";
  isOpen: boolean = true;

  grid: string =
    "0.5fr minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)";
  dataTableHeaders: string[] = [
    
    "Tipo Conta",
    "Ciclo Faturamento",
    "Entidade",
    "Fornecedor",
    "CNPJ",
    "Identificador",
    "Situação",
  ];

  valorSelecionado!: any;

  @ViewChild("showModal", { static: false }) showModal?: ModalDirective;
  @ViewChild("dt", { static: true }) dt: Table;
  @ViewChild("removeItemModal", { static: false })
  removeItemModal?: ModalDirective;

  isHistoryOpen = false;
  isChecked: boolean = false;
  selectedFaturas: ContaFatura[] = [];
  enumTeste = SERVICES_NAMES;

  historicController = "";

  private subscription: Subscription[] = [];

  constructor(
    private paginatorStore: PaginatorGenericStoreService,
    private tableStore: TableStoreService,
    private toastr: ToastrService,
    // private deletionService: AtivoFisicoDeletionService,
    private contaFaturaService: ContaFaturaService
  ) {}

  get data$() {
    return this.paginatorStore.dataObservable$;
  }

  get tableRow$() {
    return this.tableStore.tableRow$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  ngOnInit() {
    this.subscription.push(
      this.rowsSelected.subscribe((data: any) => {
        this.valorSelecionado = data[0];
      })
    );

    if (this.itemSelecionadoFromParent !== null) {
      this.contaSelecionado = this.itemSelecionadoFromParent;
    }
  }

  sendData(event$: { paginator: Pagination; searchText: string }) {
    if (event$.searchText !== null) {
      this.paginatorStore.getDataObservable(
        this.contaFaturaService.getPesquisaTodosContaFaturaModal(
          event$.paginator,
          event$.searchText,
          this.entidadeId,
          this.fornecedorId,
          this.nomeFornecedor,
          this.titularidadeId
        )
      );
    } else {
      this.paginatorStore.getDataObservable(
        this.contaFaturaService.getContaFaturaModal(
          event$.paginator,
          this.entidadeId,
          this.servicoId,
          this.fornecedorId,
          this.nomeFornecedor,
          this.titularidadeId
        )
      );
    }
  }

  checkOne(index: number) {
    this.tableStore.checkOne(index, true);
  }

  editModal() {
    if (this.selectedFaturas.length > 0) {
      this.isOpen = !this.isOpen;
    } else {
      this.toastr.warning(
        "É necessário escolher um ou mais Dispositivos para poder editar."
      );
    }
  }

  closeLog() {
    this.historicController = "";
    this.isHistoryOpen = false;
  }

  selecionarValor() {
    let value: any;
    let type: string;

    value = this.valorSelecionado;
    type = "Conta";

    this.selecionarValorEvent.emit({ value, type });
    this.fecharModal();
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  navigate(descricao: string) {
    this.historicController = descricao;
    this.isHistoryOpen = true;
  }

  ngOnDestroy(): void {
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
