import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Pagination } from "src/app/core/models/pagination.model";
import { PerfilCatalogoService } from "src/app/modulos/telecom/ativo-linha/core/services/perfil-catalogo.service";
import { PerfilCatalogo } from "src/app/modulos/telecom/ativo-linha/core/models/ativos-linha/perfilCatalogo.model";
import { Fornecedor } from "src/app/modulos/telecom/cadastro/core/models/fornecedor.model";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { Subscription } from "rxjs";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";

@Component({
  selector: "app-modal-list-perfis",
  templateUrl: "./modal-list-perfis.component.html",
  styleUrls: ["./modal-list-perfis.component.css"],
})
export class ModalListPerfisComponent {
  @Input() headerTitle: string;
  @Input() buttonFunction: () => void;
  @Input() buttonText: string;

  @Input() entidade: number;
  @Input() fornecedor: Fornecedor;
  @Input() servico: number;
  @Input() perfilSelecionadoExterno: PerfilCatalogo;
  @Input() titularidadeId: number = 1;

  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<any>();
  @Output() limparValorEvent = new EventEmitter<any>();

  pagination: Pagination = <Pagination>{};
  entidadeID: number = 1;

  isOpen: boolean = true;

  perfilSelecionado: PerfilCatalogo[] = [];

  grid: string = "0.3fr 2.5fr 1.5fr 1fr 1fr 1fr 1fr";
  dataTableHeaders: string[] = [
    "",
    // "Ação",
    "Nome",
    "Entidade",
    "Fornecedor",
    "Multa",
    "Vigência",
  ];
  isChecked: boolean = false;

  enumTeste = SERVICES_NAMES;

  // Listas
  listaPerfil: PerfilCatalogo[];

  private subscription: Subscription[] = [];

  constructor(
    private paginatorStore: PaginatorGenericStoreService,
    private perfilService: PerfilCatalogoService,
    private tableStore: TableStoreService
  ) {}

  get data$() {
    return this.paginatorStore.dataObservable$;
  }

  get tableRow$() {
    return this.tableStore.tableRow$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  ngOnInit() {
    this.subscription.push(
      this.rowsSelected.subscribe(
        (data: any) => (this.perfilSelecionado = data)
      )
    );

    if (this.perfilSelecionadoExterno !== null) {
      this.perfilSelecionado.push(this.perfilSelecionadoExterno);
    }
  }

  sendData(event$: { paginator: Pagination; searchText: string }) {
    if (event$.searchText !== null) {
      console.log('this:',this.entidade,this.fornecedor.fornecedorId,this.servico,this.titularidadeId)
      this.paginatorStore.getDataObservable(
        this.perfilService.PerfilCatalogoEntidadeFornecedorModalPesquisaTodos(
          event$.paginator,
          event$.searchText,
          this.entidade,
          this.fornecedor.fornecedorId,
          this.servico,
          this.titularidadeId
        )
      );
    } else {
      this.paginatorStore.getDataObservable(
        this.perfilService.getPerfilPorFornecedorEntidade(
          event$.paginator,
          this.entidade,
          this.fornecedor.fornecedorId,
          this.servico,
          this.titularidadeId
        )
      );
    }
  }

  checkOne(index: number) {
    this.tableStore.checkOne(index, true);
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  selecionarValor() {
    let value: any;
    let type: string;

    value = this.perfilSelecionado[0];
    type = "Perfil";
    
    this.selecionarValorEvent.emit({ value, type });
    this.fecharModal();
  }

  limparValor() {
    this.limparValorEvent.emit("Perfil");
  }
}
