@for(item of menuItems; track $index) {
  <div class="accordion-container" id="{{!item?.isModulo && item?.id}}" [ngClass]="{'spacing': item?.icone}">
    <!-- Cabeçalho do Accordion -->
    <div 
      class="accordion-header" 
      id="{{!item?.isModulo && item?.id}}" 
      [ngClass]="{'spacing': item?.isOpen && item?.subItems?.length > 0, 'isAccordionOpen': item?.isOpen && item?.subItems?.length > 0}" 
      (click)="toggleItem(item)"
    >
      @if(item?.link === null || item?.subItems?.link === null && item?.subItems?.length > 0) {
        @if(!item?.isModulo) {
          <div class="icon-text-wrapper" id="{{!item?.isModulo && item?.id}}">
            <i class="{{item.icone}}" *ngIf="item?.icone" [ngClass]="item?.isOpen ? 'isSelected' : ''"></i>
            <p id="{{!item?.isModulo && item?.id}}" [ngClass]="item?.isOpen ? 'isSelected' : 'no-link-title'">
              {{ item.titulo }}
            </p>
          </div>
          <i class="bx bx-chevron-down" id="{{item?.id}}" [ngClass]="{'isSelected-Icon': item?.isOpen}"></i>
        }
        @else {
          <p [ngClass]="'module-title'">{{ item.titulo }}</p>
        }
      }
      @else {
        <div class="icon-text-wrapper" id="{{item?.id}}">
          <i class="{{item.icone}}" *ngIf="item?.icone" [ngClass]="item?.isOpen ? 'isSelected' : ''"></i>
          <a [href]="item.link" [routerLinkActive]="'isSelected'" id="{{item?.id}}" class="link-title" style="padding-bottom: 10px;">
            {{ item.titulo }}
          </a>
        </div>
      }
    </div>

    <!-- Conteúdo do Accordion -->
    @if(item.subItems?.length > 0) {
      <div id="{{item?.subItems?.id}}" [ngClass]="item?.isOpen ? 'isOpen' : 'accordion-content'">
        <app-menu-accordion [menuItems]="item.subItems"></app-menu-accordion>
      </div>
    }
  </div>
}
