import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appCopyDate]'
})
export class CopyDateDirective {

  constructor(private el: ElementRef) {}

  @HostListener('click')
  onClick() {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    inputElement.select();
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    
    // Copy value on Ctrl + C
    if (event.ctrlKey && event.key === 'c') {
      navigator.clipboard.writeText(inputElement.value);
      alert('Date copied: ' + inputElement.value);
    }
  }
}