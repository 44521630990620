import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function checkDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const dataInserida = new Date(control.value)
        const dataAtual = new Date();

        dataAtual.setHours(0,0,0,0)
        dataInserida.setHours(0,0,0,0)

        const dataInvalida = dataInserida > dataAtual;

        return dataInvalida ? {dataFutura: { value: control.value}  }: null
    }
}