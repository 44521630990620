import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LocalStorageUtilsService {
  constructor() {}

  getEntidadeIdFromLocalStorage(): string | null {
    // Get currentUser from localStorage
    const currentUser = localStorage.getItem("currentUser");

    // Parse accessToken from currentUser
    if (currentUser) {
      try {
        const { accessToken } = JSON.parse(currentUser);
        if (accessToken) {
          // Assuming accessToken is in JWT format
          const tokenPayload = accessToken.split(".")[1];
          const decodedToken = JSON.parse(atob(tokenPayload));
          const { entidadeId } = decodedToken; // Extract entidadeId
          return entidadeId;
        }
      } catch (error) {
        return null;
      }
    }
  }
}
