import { Component, Input } from "@angular/core";
import type { menuItem, menuItemModulos } from "../../types/menu-accordion.type";
import { paywallStoreService } from "src/app/core/store/paywall.store.service";
import { MenuService } from "../../menu.service";

@Component({
  selector: "app-menu-accordion",
  templateUrl: "./menu-accordion.component.html",
  styleUrls: ["./menu-accordion.component.scss"],
})

export class MenuAccordionComponent {
  @Input() menuItems: menuItem[] = [];

  constructor(private paywallStoreService: paywallStoreService, private menuService: MenuService) {}
  // Alterna o estado do item clicado
  toggleItem(item: menuItem): void {
    if (item.isModulo) {return}

    this.paywallStoreService.clickedItem(item);
    // Fecha os outros itens de nível atual
    this.menuItems.forEach(menu => {
      if (menu !== item) {
        this.menuService.updateLocalStorage(menu,false)
        menu.isOpen = false;
      }
    });

    // Alterna o estado do item clicado
    item.isOpen = !item.isOpen;
    this.menuService.updateLocalStorage(item,item.isOpen)
  }
}