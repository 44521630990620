import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]'
})
export class OnlyNumbersDirective {

    constructor() { }
  
    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
      if (['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'Delete', 'Enter'].includes(event.key)) {
        return;
      }
    
      if (event.ctrlKey && ['v', 'a', 'x', 'c'].includes(event.key)) { 
        return;
      }
      
      
      const isNumber = /^[0-9]$/.test(event.key);
      if (!isNumber) {
        event.preventDefault();
      }
    }
  
    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
      // Obter o conteúdo do clipboard
      const clipboardData: any = event.clipboardData || window.Clipboard;
      const pastedText = clipboardData.getData('text');
  
      // Verificar se o texto colado contém apenas números
      if (!/^[0-9]*$/.test(pastedText)) {        
        event.preventDefault();
      }
    }
  }