export const environment = {
  production: false,
  preview: true,
  defaultauth: "fakebackend",
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    databaseURL: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },

  //apiBaseHom: "http://201.49.219.20:7001/api/mobit/v1",
  // apiBaseHom: "https://backend-5rj9.onrender.com/api/mobit/v1",
  apiBaseHom: "https://backmobit.adnangonzagaci.com/api/mobit/v1",
};
