import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Table } from "primeng/table";
import { Subscription } from "rxjs";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { AtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model";
import { STATUS } from "src/app/modulos/telecom/ativo-fisico/models/enum/status.enum";
import { Pagination } from "src/app/core/models/pagination.model";
import { AtivoFisicoService } from "src/app/modulos/telecom/ativo-fisico/core/service/ativo-fisico.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { EntidadeStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/entidade.store.service";
import { Entidade } from "src/app/modulos/telecom/cadastro/core/models/entidade.model";

@Component({
  selector: "app-modal-list-dispositivos",
  templateUrl: "./dispositivos.component.html",
  styleUrls: ["./dispositivos.component.css"],
})
export class DispositivosModalComponent {
  dispositivoSelecionado: AtivoFisico;

  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<any>();
  @Output() limparValorEvent = new EventEmitter<any>();

  @Input() entidade: number;
  @Input() labelBtn: string = "Selecionar";

  @ViewChild("dt", { static: true }) dt: Table;
  @ViewChild("showModal", { static: false }) showModal?: ModalDirective;
  @ViewChild("removeItemModal", { static: false })
  removeItemModal?: ModalDirective;

  breadCrumbItems: Array<{}>;
  tooltipText = "pesquisa da pagina atual";

  isOpen: boolean = true;
  status: any = STATUS;
  grid: string =
    "minmax(30px, 0fr) minmax(100px, 1fr) minmax(100px, 1.5fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)";
  dataTableHeadersDispositivos: string[] = [
    "",
    "Nome",
    "Situação",
    "Fabricante",
    "Modelo",
    "Entidade",
    "Titularidade",
    "Chamado",
  ];

  isChecked: boolean = false;

  enumTeste = SERVICES_NAMES;

  valorSelecionado!: any;

  formSelects: FormGroup;

  basePagination: Pagination = { pageNumber: 1, pageSize: 50 };

  entidadeIdSelecionada: number = 0;
  titularidadeIdSelecionada: number = 0;

  private subscription: Subscription[] = [];

  get data$() {
    return this.paginatorStore.dataObservable$;
  }

  get tableRow$() {
    return this.tableStore.tableRow$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  get entidadeList$() {
    return this.entidadeStoreService.entidadeList$;
  }

  constructor(
    private paginatorStore: PaginatorGenericStoreService,
    private toastr: ToastrService,
    private tableStore: TableStoreService,
    private ativoFisicoService: AtivoFisicoService,
    private entidadeStoreService: EntidadeStoreService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.formSelects = this.fb.group({
      entidade: [null],
      titularidade: [null],
    });

    this.subscription.push(
      this.rowsSelected.subscribe((data: any) => (this.valorSelecionado = data))
    );
  }

  sendData(event$: { paginator: Pagination; searchText: string }) {
    if (event$.searchText !== null) {
      this.paginatorStore.getDataObservable(
        this.ativoFisicoService.getPesquisaTodoAtivosFisicosDispostivosEstoque(
          event$.searchText,
          this.entidade,
          1,
          event$.paginator
        )
      );
    } else {
      this.paginatorStore.getDataObservable(
        this.ativoFisicoService.getAtivosFisicoDispositivosEstoque(
          event$.paginator,
          this.entidade
          // event$.searchText,
        )
      );
    }
  }

  guardarEntidade(event: Entidade) {
    if (!event) {
      this.entidadeIdSelecionada = 0;
      this.sendData({ paginator: this.basePagination, searchText: null });
      return;
    }
    this.entidadeIdSelecionada = event.entidadeId;

    this.paginatorStore.getDataObservable(
      this.ativoFisicoService.getAtivosFisicoDispositivosPorEntidadeTitularidade(
        { pageNumber: 1, pageSize: 50 },
        this.entidade,
        this.entidadeIdSelecionada,
        this.titularidadeIdSelecionada
        // event$.searchText,
      )
    );
  }

  guardarTitularidade(event: Entidade) {
    if (!event) {
      this.titularidadeIdSelecionada = 0;
      this.sendData({ paginator: this.basePagination, searchText: null });
      return;
    }


    this.titularidadeIdSelecionada = event.entidadeId;

    this.paginatorStore.getDataObservable(
      this.ativoFisicoService.getAtivosFisicoDispositivosPorEntidadeTitularidade(
        { pageNumber: 1, pageSize: 50 },
        this.entidade,
        this.entidadeIdSelecionada,
        this.titularidadeIdSelecionada
        // event$.searchText,
      )
    );
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  selecionarValor() {
    this.dispositivoSelecionado = this.valorSelecionado[0];

    if (this.dispositivoSelecionado.estoque === 0) {
      this.toastr.error(
        "Ativo não está em estoque, selecione outro",
        "Dispositivo Inválido"
      );
      return;
    }

    this.selecionarValorEvent.emit(this.dispositivoSelecionado);
    this.fecharModal();
  }

  limparValor() {
    this.limparValorEvent.emit();
  }

  checkOne(index: number) {
    this.tableStore.checkOne(index, true);
  }
}
