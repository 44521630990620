import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable } from "rxjs";
import { AtivoCsv } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisicoCsv.model";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CsvDownloadService {
  private readonly baseUrl =  environment.apiBaseHom;

  constructor(private readonly http: HttpClient) {}

  public downloadCsv = (url: string, ativoCsv: AtivoCsv): Observable<any> => {
    return this.http
      .post(`${this.baseUrl}${url}`, ativoCsv, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((response) => {
          return { response: response, headers: response.headers };
        })
      );
  };
}
