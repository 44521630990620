import { Component, ElementRef, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AreaEntidade } from 'src/app/modulos/telecom/cadastro/core/models/areaEntidade.model';

import { CargoEntidade } from 'src/app/modulos/telecom/cadastro/core/models/cargoEntidade.model';
import { Entidade } from 'src/app/modulos/telecom/cadastro/core/models/entidade.model';
import { UnidadeEntidade } from 'src/app/modulos/telecom/cadastro/core/models/unidadeEntidade.model';
import { AreaEntidadeService } from 'src/app/modulos/telecom/cadastro/core/services/entidades/areaEntidade.service';
import { CargoEntidadeService } from 'src/app/modulos/telecom/cadastro/core/services/entidades/cargoEntidade.service';
import { CentroCustoEntidadeService } from 'src/app/modulos/telecom/cadastro/core/services/entidades/centroCustoEntidade.service';
import { EntidadeService } from 'src/app/modulos/telecom/cadastro/core/services/entidades/entidade.service';
import { FormCadastroPatchService } from 'src/app/modulos/telecom/cadastro/core/services/form/form-cadastro-patch.service';
import { FormCadastroSubmitService } from 'src/app/modulos/telecom/cadastro/core/services/form/form-cadastro-submit.service';
import { FormCadastroService } from 'src/app/modulos/telecom/cadastro/core/services/form/form-cadastro.service';


@Component({
  selector: 'app-modal-cadastro-form',
  templateUrl: './modal-cadastro-form.component.html',
  styleUrls: ['./modal-cadastro-form.component.css']
})
export class ModalCadastroFormComponent {

  @Input() headerTitle: string;
  @Input() buttonFunction: () => void;
  @Input() buttonText: string;
  @Input() reference: string;
  @Input() dadosEntidade:Entidade
  @Input() term:string;

  registerUnidadeForm: FormGroup;
  registerCargoForm: FormGroup;
  registerAreaForm: FormGroup;
  registerCentroCustoForm: FormGroup;

       // Listas
       listaEntidade: Entidade[];


  @Output() fecharModalEvent = new EventEmitter<string>();
  @Output() selecionarValorEvent = new EventEmitter<any>();

  modalRef?: BsModalRef;

  loading: boolean = true;

  entidadeID: number = 1;


  unidadeSelecionada!: UnidadeEntidade;
  cargoSelecionada!: CargoEntidade;


  constructor(private modalService: BsModalService,private entidadeService: EntidadeService,private centroCustoService:CentroCustoEntidadeService,
    private areaEntidadeService:AreaEntidadeService,private cargoEntidadeService:CargoEntidadeService,
    private formService: FormCadastroService, private formCadastroSubmitService:FormCadastroSubmitService,
    private formPatchService: FormCadastroPatchService,private router: Router, private toastr: ToastrService) {

  }

  ngOnInit() {

    this.fetchData();


    this.retornarTemplate();
  }

  fecharModal(tipoCadastro) {
    this.fecharModalEvent.emit(tipoCadastro);
  }

  get form() {

    if(this.reference === 'Unidade') {

      return this.registerUnidadeForm.controls;

    }
    else if(this.reference === 'Cargo') {

      return this.registerCargoForm.controls;
    }
    else if(this.reference === 'Area') {

      return this.registerAreaForm.controls;
    }
    else if(this.reference === 'CentroCusto') {

      return this.registerCentroCustoForm.controls;
    }


  }





  fetchData() {


    this.entidadeService.getEntidadesLista().subscribe(
      result => {
        this.listaEntidade = result;

      }
    )


  }


  retornarTemplate() {

    if(this.reference === 'Unidade') {

      this.registerUnidadeForm = this.formService.getUnidadeEntidadeForm()

      this.formPatchService.patchUnidadeEntidadeForm(this.registerUnidadeForm,null,this.term);

    }
    else if(this.reference === 'Cargo') {

      this.registerCargoForm = this.formService.getCargoEntidadeForm()

      this.formPatchService.patchCargoEntidadeForm(this.registerCargoForm,null,this.term);
    }
    else if(this.reference === 'Area') {

      this.registerAreaForm = this.formService.getAreaEntidadeForm()

      this.formPatchService.patchAreaEntidadeForm(this.registerAreaForm,null,this.term);
    }
    else if(this.reference === 'CentroCusto') {

      this.registerCentroCustoForm = this.formService.getCentroCustoEntidadeForm()

      this.formPatchService.patchCentroCustoEntidadeForm(this.registerCentroCustoForm,null,this.term);
    }

  }

submit(reference){

  if(reference=="Unidade"){

    const _unidadeEntidade = this.formCadastroSubmitService.pegarDadosUnidadeEntidade(this.registerUnidadeForm);


    this.entidadeService.addUnidadeEntidade(_unidadeEntidade).subscribe((result) => {
      this.fecharModal(reference);

      this.toastr.success("Cadastro Bem Sucedido", "Unidade ");
    });

  }else if(reference=="Cargo"){

    const _cargoEntidade = this.formCadastroSubmitService.pegarDadosCargoEntidade(this.registerCargoForm);


    this.cargoEntidadeService.addCargoEntidade(_cargoEntidade).subscribe((result) => {
      this.fecharModal(reference);

      this.toastr.success("Cadastro Bem Sucedido", "Cargo");
    });

  }
  else{

    const _centroCustoEntidade = this.formCadastroSubmitService.pegarCentroCustoEntidade(this.registerCentroCustoForm);


    this.centroCustoService.addCentroCustoEntidade(_centroCustoEntidade).subscribe((result) => {
      this.fecharModal(reference);

      this.toastr.success("Cadastro Bem Sucedido", "Centro de Custo ");
    });

  }


}



}

