<form [formGroup]="updateAtivo" *ngIf="updateAtivo">
  <p-dialog
    header="Header"
    [(visible)]="isOpen"
    [modal]="true"
    [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    (onHide)="editModal()"
    [draggable]="false"
  >
    <ng-template pTemplate="header">
      <div class="inline-flex align-items-center justify-content-center gap-2">
        <span class="font-bold white-space-nowrap">{{ title }}</span>
      </div>
    </ng-template>
    <p-tabView>
      <p-tabPanel header="{{ ativoTitle }}">
        @if (component === "chip") {
        <div class="input-wrapper">
          @if(mostrarCamposRelacionadosEntidade) {
          <div class="input-default input-xl">
            <label for="entidade">Entidade</label>
            <ng-select
              id="campoEntidade"
              (change)="mudarEntidade($event)"
              formControlName="entidade"
              [items]="entidadeList$ | async"
              bindLabel="razaoSocial"
              bindValue="entidadeId"
              placeholder="Escolha..."
            >
            </ng-select>
          </div>
          }
          <div class="input-default input-lg">
            <label for="tipo">Tipo</label>
            <ng-select
              formControlName="tipo"
              [items]="listaTiposAtivoFisico"
              bindLabel="descricao"
              bindValue="tipoAtivoId"
              placeholder="Escolha..."
              id="campoTipo"
              (change)="carregarFabricantes()"
            >
            </ng-select>
          </div>
          <div
              class="input-default input-lg"              
            >
              <label for="form-select">Fabricante</label>
              <ng-select
                (change)="carregarModelos()"
                formControlName="fabricante"
                [items]="listaFabricantes"
                bindLabel="descricao"
                bindValue="fabricanteId"
                placeholder="Escolha..."
              >
              </ng-select>
              @if(submitted && form.fabricante.errors){
              <div class="invalid-feedback">
                @if(form.fabricante.errors.required){
                <span>Informe o fabricante</span>
                }
              </div>
              }
            </div>
          <div class="input-default input-sm">
            <label for="status">Status</label>
            <ng-select formControlName="status" placeholder="Escolha...">
              <ng-option [value]="0">INATIVO</ng-option>
              <ng-option [value]="1">ATIVO</ng-option>
              <ng-option [value]="2">ESTOQUE </ng-option>
              <ng-option [value]="3">NOVO </ng-option>
              <!-- <ng-option [value]="4">REPARO </ng-option> -->
              <ng-option [value]="5">ROUBO </ng-option>
              <ng-option [value]="6">USADO </ng-option>
            </ng-select>
          </div>
          <div class="input-default input-md">
            <label for="estoque">Estoque</label>
            <ng-select
              formControlName="estoque"
              [clearable]="true"
              placeholder="Escolha..."
            >
              <ng-option [value]="1">EM ESTOQUE</ng-option>
              <ng-option [value]="2">RESERVADO</ng-option>
              <ng-option [value]="0">FORA DE ESTOQUE</ng-option>
            </ng-select>
          </div>
          @if(mostrarCamposRelacionadosEntidade) {
          <div class="input-default input-xl">
            <label for="localizacao">Localização</label>
            <ng-select
              formControlName="localizacaoUnidadeEntidade"
              [items]="listaUnidadeEntidades"
              bindLabel="descricao"
              bindValue="unidadeEntidadeId"
              placeholder="Escolha..."
            >
            </ng-select>
          </div>
          }
          <div class="input-default input-xl">
            <label for="chamadoIdentificacao">Chamado de Identificação</label>
            <input
              formControlName="chamadoIdentificacao"
              type="text"
              mask="0000000000"
              id="chamadoIdentificacao"
              placeholder="Insira um Chamado"
            />
            @if(submitted && form.chamadoIdentificacao.errors){
            <div class="invalid-feedback">
              @if(form.chamadoIdentificacao.errors.required){
              <span>Informe o chamado</span>
              }
            </div>
            }
          </div>
          <div class="input-default input-xl">
            <label for="chamadoReservado">Chamado Reservado</label>
            <input
              formControlName="chamadoReservado"
              type="text"
              mask="0000000000"
              id="chamadoReservado"
              placeholder="Insira um Chamado"
            />
            @if(submitted && form.chamadoReservado.errors){
            <div class="invalid-feedback">
              @if(form.chamadoReservado.errors.required){
              <span>Informe o chamado reservado</span>
              }
            </div>
            }
          </div>
          <div class="input-default input-lg">
            <label for="observacao" class="textFieldLabel"> Observações </label>
            <input
              formControlName="observacao"
              type="text"
              id="observacao"
              placeholder="Escreva uma Observação"
            />
          </div>
          <hr />
          <div class="input-default input-md">
            <label for="titularidade">Titularidade</label>
            <ng-select formControlName="titularidade" placeholder="Escolha...">
              <ng-option value="1">MOBIT SOLUÇÕES</ng-option>
              <ng-option value="2">CLIENTE</ng-option>
            </ng-select>
          </div>
          <!-- <div class="input-default input-md">
            <label for="alocacao">Alocação</label>
            <ng-select
              formControlName="alocacaoUnidadeEntidade"
              [items]="listaUnidadeEntidades"
              bindLabel="descricao"
              bindValue="unidadeEntidadeId"
              placeholder="Escolha..."
            >
            </ng-select>
          </div> -->
          <!-- <div class="input-default input-md">
            <label for="numeroLinha">Número linha</label>
            <input
              formControlName="numeroLinha"
              type="text"
              id="numeroLinha"
              placeholder="Número da linha"
            />
          </div> -->
          <div class="input-default input-md">
            <label for="ddd">DDD</label>
            <input
              formControlName="ddd"
              type="text"
              id="ddd"
              placeholder="DDD"
            />
          </div>
          <div class="input-default input-md">
            <label for="hlr">HLR</label>
            <input
              formControlName="hlr"
              type="text"
              id="hlr"
              placeholder="HLR"
            />
          </div>
        </div>

        } @if (component === "dispositivo") {
        <div style="min-height: 200px">
          <div class="input-wrapper">
            @if(mostrarCamposRelacionadosEntidade) {
            <div class="input-default input-lg">
              <label for="entidade">Entidade</label>
              <ng-select
                (change)="mudarEntidade($event)"
                formControlName="entidade"
                [items]="entidadeList$ | async"
                bindLabel="razaoSocial"
                bindValue="entidadeId"
                placeholder="Escolha..."
              >
              </ng-select>
            </div>
            }
            <div class="input-default input-lg">
              <label for="tipo">Tipo</label>
              <ng-select
                formControlName="tipo"
                [items]="listaTiposDispositivo"
                bindLabel="descricao"
                bindValue="tipoAtivoId"
                placeholder="Escolha..."
                (change)="carregarFabricantes()"
              >
              </ng-select>
            </div>
            <div class="input-default input-md">
              <label for="status">Status</label>
              <ng-select formControlName="status" placeholder="Escolha...">
                <ng-option [value]="0">INATIVO</ng-option>
                <ng-option [value]="1">ATIVO</ng-option>
                <ng-option [value]="2">DESCARTE </ng-option>
                <ng-option [value]="3">NOVO </ng-option>
                <ng-option [value]="4">REPARO </ng-option>
                <ng-option [value]="5">ROUBO </ng-option>
                <ng-option [value]="6">USADO </ng-option>
              </ng-select>
            </div>
            <div class="input-default input-md">
              <label for="estoque">Estoque</label>
              <ng-select
                formControlName="estoque"
                [clearable]="true"
                placeholder="Escolha..."
              >
                <ng-option [value]="1">EM ESTOQUE</ng-option>
                <ng-option [value]="2">RESERVADO</ng-option>
                <ng-option [value]="0">FORA DE ESTOQUE</ng-option>
              </ng-select>
            </div>
            <!-- <div class="input-wrapper"> -->
            @if(mostrarCamposRelacionadosEntidade) {
            <div class="input-default input-lg">
              <label for="localizacao">Localização</label>
              <ng-select
                formControlName="localizacaoUnidadeEntidade"
                [items]="listaUnidadeEntidades"
                bindLabel="descricao"
                bindValue="unidadeEntidadeId"
                placeholder="Escolha..."
              >
              </ng-select>
            </div>
            }
            <div
              class="input-default input-lg"
              (click)="avisarUsuario('Fabricante')"
            >
              <label for="form-select">Fabricante</label>
              <ng-select
                (change)="carregarModelos()"
                formControlName="fabricante"
                [items]="listaFabricantes"
                bindLabel="descricao"
                bindValue="fabricanteId"
                placeholder="Escolha..."
              >
              </ng-select>
              @if(submitted && form.fabricante.errors){
              <div class="invalid-feedback">
                @if(form.fabricante.errors.required){
                <span>Informe o fabricante</span>
                }
              </div>
              }
            </div>
            <div
              class="input-default input-xl"
              (click)="avisarUsuario('Modelo')"
            >
              <label for="form-select">Modelo</label>
              <ng-select
                formControlName="modeloDispositivoId"
                [items]="listaModelos"
                bindLabel="descricao"
                bindValue="modeloId"
                placeholder="Escolha..."
              >
              </ng-select>
              @if(submitted && form.modelo.errors){
              <div class="invalid-feedback">
                @if(form.modelo.errors.required){
                <span>Informe o modelo</span>
                }
              </div>
              }
            </div>
            <div class="input-default input-md">
              <label for="chamadoIdentificacao">Chamado de Identificação</label>
              <input
                formControlName="chamadoIdentificacao"
                type="text"
                id="chamadoIdentificacao"
                placeholder="Insira um Chamado"
              />
              @if(submitted && form.chamadoIdentificacao.errors){
              <div class="invalid-feedback">
                @if(form.chamadoIdentificacao.errors.required){
                <span>Informe o chamado de identificação</span>
                }
              </div>
              }
            </div>
            <div class="input-default input-md">
              <label for="chamadoReservado">Chamado Reservado</label>
              <input
                formControlName="chamadoReservado"
                type="text"
                mask="0000000000"
                id="chamadoReservado"
                placeholder="Insira um Chamado"
              />
              @if(submitted && form.chamadoReservado.errors){
              <div class="invalid-feedback">
                @if(form.chamadoReservado.errors.required){
                <span>Informe o chamado reservado</span>
                }
              </div>
              }
            </div>
            <div class="input-default input-full-width">
              <label for="observacao">Observação</label>
              <input
                formControlName="observacao"
                type="text"
                id="formrow-email-input"
                placeholder="Escreva uma Observação"
              />
            </div>
            <div class="input-default input-lg">
              <label for="titularidade">Titularidade</label>
              <ng-select
                formControlName="titularidade"
                placeholder="Escolha..."
              >
                <ng-option value="1">MOBIT SOLUÇÕES</ng-option>
                <ng-option value="2">CLIENTE</ng-option>
              </ng-select>
            </div>
            <div class="input-default input-md">
              <label for="alocacao">Alocação</label>
              <ng-select
                formControlName="alocacaoUnidadeEntidade"
                [items]="listaUnidadeEntidades"
                bindLabel="descricao"
                bindValue="unidadeEntidadeId"
                placeholder="Escolha..."
              >
              </ng-select>
            </div>
          </div>
          <!-- </div> -->
        </div>

        }
      </p-tabPanel>
      <p-tabPanel header="NF Entrada">
        <div class="input-wrapper" #nfEntrada>
          <div class="input-default">
            <label for="numeroNotaEntrada">Número da NF</label>
            <input
              formControlName="numeroNotaEntrada"
              type="text"
              id="numeroNotaEntrada"
              placeholder="Insira o Número"
            />
          </div>
          <div class="input-default">
            <label for="tipoNotaEntrada">Tipo NF</label>
            <ng-select
              formControlName="tipoNotaEntrada"
              placeholder="Escolha..."
            >
              <ng-option [value]="'CP'">COMPRA</ng-option>
              <ng-option [value]="'DO'">DOAÇÃO</ng-option>
              <ng-option [value]="'RE'">REMESSA </ng-option>
              <ng-option [value]="'NI'">NÃO INFORMADO </ng-option>
              <ng-option [value]="'SN'">SEM NF </ng-option>
              <ng-option [value]="'VD'">VENDA </ng-option>
              <ng-option [value]="'RB'">RECIBO </ng-option>
              <ng-option [value]="'OS'">OUTRAS SAÍDAS </ng-option>
            </ng-select>
          </div>
          <div class="input-default">
            <label for="valorNotaEntrada">Valor NF</label>
            <input
              currencyMask
              formControlName="valorNotaEntrada"
              id="valorNotaEntrada"
              placeholder="Insira o Valor"
            />
          </div>
          <div class="input-default">
            <label for="dataEmissaoEntrada">Data Emissão</label>
            <input
              formControlName="dataEmissaoEntrada"
              type="date"
              id="dataEmissaoEntrada"
            />
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="NF Saída">
        <div class="input-wrapper" #nfSaida>
          <div class="input-default">
            <label for="numeroNotaSaida">Número da NF</label>
            <input
              type="text"
              formControlName="numeroNotaSaida"
              id="numeroNotaSaida"
              placeholder="Insira o Número"
            />
          </div>
          <div class="input-default">
            <label for="tipoNotaSaida">Tipo NF</label>
            <ng-select formControlName="tipoNotaSaida" placeholder="Escolha...">
              <ng-option [value]="'CP'">COMPRA</ng-option>
              <ng-option [value]="'DO'">DOAÇÃO</ng-option>
              <ng-option [value]="'RE'">REMESSA </ng-option>
              <ng-option [value]="'NI'">NÃO INFORMADO </ng-option>
              <ng-option [value]="'SN'">SEM NF </ng-option>
              <ng-option [value]="'VD'">VENDA </ng-option>
              <ng-option [value]="'RB'">RECIBO </ng-option>
              <ng-option [value]="'OS'">OUTRAS SAÍDAS </ng-option>
            </ng-select>
          </div>
          <div class="input-default">
            <label for="valorNotaSaida">Valor NF</label>
            <input
              currencyMask
              formControlName="valorNotaSaida"
              id="valoorNotaSaida"
              placeholder="Insira o Valor"
            />
          </div>
          <div class="input-default">
            <label for="dataEmissaoSaida">Data Emissão</label>
            <input
              formControlName="dataEmissaoSaida"
              type="date"
              id="dataEmissaoSaida"
            />
          </div>
        </div>
      </p-tabPanel>
    </p-tabView>
    <ng-template pTemplate="footer">
      <!-- <button (click)="submitForm()" class="btn btn-modal">
        <i class="fa fa-check"></i> Salvar
      </button> -->
      <div style="display: flex; justify-content: flex-end">
        <app-action-btn
          icon="fa fa-check font-size-20"
          type=""
          text="Salvar"
          (click)="preSubmit()"
        />
      </div>
    </ng-template>
  </p-dialog>
</form>

<app-modal-confirmation
  [isOpen]="modalConfirmIsOpen"
  [title]="'Confirmação'"
  [text]="txtConfirmacao"
  (fecharModalEvent)="confirmSubmit($event)"
></app-modal-confirmation>
