<div class="page-title">
  <div>
    @if(detailed) {
    <app-action-btn
      [icon]="'mdi mdi-arrow-left font-size-20 '"
      [type]="''"
      [text]="'Voltar'"
      (click)="back()"
    ></app-action-btn
    >}
    <h4>{{ title }}</h4>
  </div>
  @if(detailed) {<i (click)="modal()" class="mdi mdi-history"></i>}
</div>
