export enum STATUS {
  "INATIVO" = 0,
  "ATIVO" = 1,
  "ESTOQUE" = 2,
  "NOVO" = 3,
  "REPARO" = 4,
  "ROUBO" = 5,
  "USADO" = 6,
  "INATIVADO POR ERRO" = 7,
  "True" = "ATIVO",
  "False" = "INATIVO",
}
