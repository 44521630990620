import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CargoEntidade } from '../../models/cargoEntidade.model';
import { Pagination } from 'src/app/core/models/pagination.model';



@Injectable({
  providedIn: 'root'
})
export class CargoEntidadeService {

  baseUrl = environment.apiBaseHom +'/cargoEntidade';
  //baseUrl = sessionStorage.getItem('urlAPI')+'/v1/deposito/pedidovenda'





  private _cargo = new BehaviorSubject<any[]>([]);
  get cargo() {
    return this._cargo.asObservable();
  }

  retornaParams(){

    let queryParams = new HttpParams();
    queryParams = queryParams.append("auth",environment.apiBaseHom)

    return queryParams;
  }

  constructor(private http: HttpClient) { }


  getCargoEntidades(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaCargoEntidade: CargoEntidade[],
    quantidade: number
  }> {
    return this.http.get<{
      listaCargoEntidade: CargoEntidade[],
      quantidade: number
    }>(
      `${this.baseUrl}?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
  


  getPesquisaTodosCargoEntidades(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaEntidade: CargoEntidade[],
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaEntidade: CargoEntidade[],
    quantidadeTotal: number;
    }>(
      `${this.baseUrl}/CargoEntidadePesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getCargos(): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}`)

  }

  getCargoPorId(cargoEntidadeId:number): Observable<CargoEntidade> {
    return this.http.get<any>(`${this.baseUrl}/CargoPorID?Id=${cargoEntidadeId}`)

  }


  getCargosPorIdEntidade(entidadeId:number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/cargosporentidade?entidadeId=${entidadeId}`)

  }
  addCargoEntidade(cargoEntidadeNovo: CargoEntidade): Observable<any> {
    return this.http.post(`${this.baseUrl}`, cargoEntidadeNovo)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  updatCargoEntidade(cargoEntidadeEdit: CargoEntidade): Observable<any> {
    return this.http.put(`${this.baseUrl}`, cargoEntidadeEdit)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  public delete = (
    cargoId: number
  ): Observable<CargoEntidade | HttpErrorResponse> => {
    return this.http.delete<CargoEntidade>(`${this.baseUrl}/${cargoId}`).pipe(
      map((res: CargoEntidade) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };






  }

