import { Component, OnInit } from "@angular/core";

import { MessageService } from "primeng/api";
import { ProgessCsvService } from "../../services/progess-csv.service";

@Component({
  selector: "app-progess-csv",
  templateUrl: "./progess-csv.component.html",
  styleUrls: ["./progess-csv.component.css"],
  providers: [MessageService],
})
export class ProgessCsvComponent implements OnInit {
  constructor(
    private messageService: MessageService,
    private readonly progressCsvService: ProgessCsvService
  ) {}

  visible: boolean = false;

  ngOnInit() {
    this.progressCsvService.showProgressCsvObservable.subscribe((res) => {
      
    //Download
    if (res.metodo === 'Download') {
      if (res.value) {
        this.showConfirmDownload();
      } else 
      {
          this.onRejectDownload(); 
      }
    }
    //Upload
    if (res.metodo === 'Upload') {
      if (res.value) {
        this.showConfirmUpload();
      } else {
        this.onRejectUpload();
      }
    }
    });
  }

  //Upload
  showConfirmUpload() {
    this.messageService.add({
      key: "confirmUpload",
      sticky: true,
      closeIcon: "false",
      severity: "success",
      summary: "Can you send me the report?",
    })
  }

  onRejectUpload() {
    this.messageService.clear("confirmUpload");
  }

  //Download
  showConfirmDownload() {
    this.messageService.add({
      key: "confirmDownload",
      sticky: true,
      closeIcon: "false",
      severity: "success",
      summary: "Can you send me the report?",
    });
  }

  onRejectDownload() {
    this.messageService.clear("confirmDownload");
  }


    // if (res) {
    //   this.showConfirm();
    // } else {
    //   this.onReject();
    // }

  // onConfirmDownload() {
  //   this.messageService.clear("confirm");
  //   this.visible = false;
  // }
}
