<p-dialog style="width: 100%" [(visible)]="isOpen" [modal]="true" [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" (onHide)="fecharModal()" [draggable]="false"
  [resizable]="false">
  <ng-container [ngTemplateOutlet]="contaFatura"></ng-container>
  <ng-template pTemplate="footer">
    <div style="display: flex; justify-content: space-between;">

      <div>
        <button (click)="limparValor()" class="btn btn-modal">
          <i class="fa fa-times"></i> Limpar
        </button>
        <!-- Vincular ou Selecionar -->
        <button (click)="selecionarValor()" class="btn btn-modal">
          <i class="fa fa-check"></i> Selecionar
        </button>
      </div>
    </div>
  </ng-template>
</p-dialog>

<ng-template #contaFatura>
  <app-table
    [data]="data$"
    (getData)="sendData($event)"
    [grid]="grid"
    [isOpen]="isOpen"
    (openModal)="editModal()"
    [title]="'Downloads'"
    [dataTableHeaders]="dataTableHeaders"
    [addRoute]="'../form'"
    [insertURL]="'fatura'"
    [updateURL]="'/ativofisico/AtualizaAtivoFisicoChipLote'"
    [agrupamentoId]="2"
    [exibirExport]="true"
    [placeholderPesquisaGlobal]="'Pesquisa em Download'"
    [exibirFilterButton]="false"
    [exibirActionButtons]="false"
    [exibirCheckbox]="false"
  >
    <div
      class="dataTable-body"
      [ngStyle]="{ 'grid-template-columns': grid }"
      *ngFor="let items of tableRow$ | async; let i = index"
    >
      <div>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="items.isChecked"
          [disabled]="!items.isEnabled"
          (click)="checkOne(i)"
          [(ngModel)]="items[i]"
        />
      </div>
      <!-- <div style="gap: 5px">
        <a  [routerLink]="'/tem/contaFatura/formDownload/' + items.downloadId">
          <button class="table-edit-btn" [pTooltip]="'Editar'">
            <i class="mdi mdi-pencil"></i>
          </button>
        </a>

        <i
          (click)="navigate(items.downloadId)"
          class="mdi mdi-history"
          style="cursor: pointer; font-size: 18px; color: var(--primary)"
          [pTooltip]="'Histórico'"
        ></i>
      </div> -->


      <div>{{ items?.servico?.descricao }}</div>
      <div>{{ items?.entidade?.razaoSocial }}</div>
      <div>{{ items?.fornecedor?.razaoSocial }}</div>
      <div>{{ items?.fornecedor?.numeroTipoPessoa  | formatCNPJModals}}</div>
      <div>{{ items?.tipoBusca  }}</div>

      <div style="display: flex; align-items: center">
        <span
          style="width: 90px"
          [ngClass]="
            items.statusLogin == 'BLOQUEADO'
              ? 'active badge bg-danger me-1'
              : 'unactive badge bg-success me-1'
          "
        >
          {{  items?.statusLogin }}
        </span>

      </div>

    </div>
  </app-table>
</ng-template>

