import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AtivoLinhaPequeno } from "src/app/modulos/telecom/ativo-linha/core/models/ativoLinhaPequeno.model copy";
import { AtivoLinhaUtilsService } from "src/app/modulos/telecom/ativo-linha/core/services/utils/utils.service";

@Component({
  selector: "app-vinculo-list",
  templateUrl: "./vinculo-list.component.html",
  styleUrls: ["./vinculo-list.component.css"],
})
export class VinculoListComponent {
  @Input() linhasVinculadas: AtivoLinhaPequeno[] = [];
  @Input() linhaSelecionada: any = null;
  @Input() ativoAtual: any = null;
  @Input() hiddenBottons?: boolean = false;
  @Input() vinculosTableHeader: string[] = [];
  @Input() metodo: string = "Novo";

  @Output() removerVinculo: EventEmitter<{
    vinculo: string;
    vinculoId: number;
  }> = new EventEmitter<{
    vinculo: string;
    vinculoId: number;
  }>();

  constructor(private ativoLinhaUtilsService: AtivoLinhaUtilsService) {}

  // Retorna a rota com base na descrição do serviço linha
  retornarRota(descricao: string): string {
    return this.ativoLinhaUtilsService.retornarLink(descricao);
  }

  removerVinculoIndividual(vinculo: string, vinculoId?: number) {
    this.removerVinculo.emit({ vinculo, vinculoId });
  }
}
