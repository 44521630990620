import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
} from "rxjs";
import { Pagination } from "src/app/core/models/pagination.model";
import { LoadActionService } from "src/app/core/actions/load-action.service";
import { AtivoFisicoService } from "src/app/modulos/telecom/ativo-fisico/core/service/ativo-fisico.service";
import { AtivoAcessorioService } from "src/app/modulos/telecom/ativo-fisico/core/service/ativo-acessorio.service";
import { AtivoLinhaService } from "src/app/modulos/telecom/ativo-linha/core/services/ativo-linha.service";
import { SERVICES_NAMES } from "../models/enums/listagemServicesEnum";
import { TokenStorageService } from "../services/token-storage.service";
import { UsuarioEntidadeService } from "src/app/modulos/telecom/cadastro/core/services/usuarioEntidade.service";
import { ContaFaturaService } from "src/app/modulos/telecom/conta-fatura/core/services/contaFatura.service";
import { DownloadService } from "src/app/modulos/telecom/conta-fatura/core/services/download.service";
import { FaturaService } from "src/app/modulos/telecom/conta-fatura/core/services/fatura.service";
import { DataService } from "../services/data.service";
import { FilterStoreService } from "./filter.store.service";
import { PerfilCatalogoService } from "src/app/modulos/telecom/ativo-linha/core/services/perfil-catalogo.service";
import { ContaLinhaService } from "src/app/modulos/telecom/ativo-linha/core/services/conta-linha.service";
import { PacoteLinhaService } from "src/app/modulos/telecom/ativo-linha/core/services/pacote-linha.service";
import { EntidadeService } from "src/app/modulos/telecom/cadastro/core/services/entidades/entidade.service";
import { AreaEntidadeService } from "src/app/modulos/telecom/cadastro/core/services/entidades/areaEntidade.service";
import { TipoAtivoService } from "src/app/modulos/telecom/cadastro/core/services/tipo-ativo.service";
import { FornecedorService } from "src/app/modulos/telecom/cadastro/core/services/fornecedor.service";
import { DataTableComponent } from "src/app/shared/ui/table/table.component";

@Injectable({
  providedIn: "root",
})
export class PaginatorStoreService {
  private textoDeProcuraSubject: BehaviorSubject<string>;
  private paginationSubject: BehaviorSubject<Pagination>;
  private listSubject: BehaviorSubject<any[]>;
  private listSubjectEntidade: BehaviorSubject<string>;

  private totalController: Array<any>;
  private totalSearchController: Array<any>;
  private totalOfItensController: number;
  private requestFunctionsList: any;
  private requestActual: SERVICES_NAMES;
  private requestSearchActual: SERVICES_NAMES;
  public tipoSearch:number = 0;

  get list$() {
    return this.listSubject.asObservable();
  }
  get pagination$() {
    return this.paginationSubject
      .asObservable()
      .pipe(distinctUntilChanged(), debounceTime(300));
  }
  get textoDeProcura$() {
    return this.textoDeProcuraSubject
      .asObservable()
      .pipe(distinctUntilChanged(), debounceTime(300));
  }
  get filteredList$() {
    return this.filterStore.filteredTableList$;
  }
  get oneOf$() {
    return this.totalOfItensController;
  }

  get totalItems$() {
    return this.paginationSubject.asObservable().pipe(
      map((item: Pagination) => {
        console.log(this.totalOfItensController);
        const totalItems = Math.ceil(
          this.totalOfItensController / item.pageSize
        );

        return totalItems == 0 ? 1 : totalItems;
      })
    );
  }

  constructor(
    private loadActions: LoadActionService,
    private ativoFisicoService: AtivoFisicoService,
    private ativoAcessorioService: AtivoAcessorioService,
    private ativoLinhaService: AtivoLinhaService,
    private usuarioEntidadeService: UsuarioEntidadeService,
    private contaFaturaService: ContaFaturaService,
    private downloadService: DownloadService,
    private faturaService: FaturaService,
    private entidadeService: EntidadeService,
    private areaEntidadeService: AreaEntidadeService,
    private tokenService: TokenStorageService,
    private dataService: DataService,
    private filterStore: FilterStoreService,
    private perfilService: PerfilCatalogoService,
    private contaLinhaService: ContaLinhaService,
    private pacoteRIService: PacoteLinhaService,
    private readonly tipoAtivoService: TipoAtivoService,
    private readonly fornecedorService: FornecedorService,    
  ) {
    this.listSubject = new BehaviorSubject<any[]>([]);
    this.textoDeProcuraSubject = new BehaviorSubject<string>(null);
    this.listSubjectEntidade = new BehaviorSubject<string>(null);

    this.paginationSubject = new BehaviorSubject<Pagination>({
      pageNumber: 1,
      pageSize: 50,
    } as Pagination);

    this.loadActions.startload();
    this.ativarProcuraGlobal();
    this.startRequestList();
    this.activeList();
    this.activeFilterList();
  }

  private activeList() {
    this.pagination$.subscribe((value) => {
      this.listSubject.next([]);

      const shearchText = this.textoDeProcuraSubject.value;
      const entidade = this.dataService.getDataEntidade();
      this.loadActions.startload();

      if (this.filterStore.hasQuery) {        
        this.filterStore.inicializeFilter(value, this.tipoSearch);
      } else if (shearchText && shearchText !== "") {
        this.listSubject.next(this.totalSearchController);
        this.getPesquisaTodosAtivos(shearchText);
      } else if (entidade !== null) {
        this.listSubject.next(this.totalController);
        this.totalSearchController = null;
        this.carregarLista(+entidade);
      } else {
        this.totalSearchController = null;
        this.carregarLista(this.tokenService.getUser().entidadeId);
      }
    });
  }

  private carregarLista(entidadeID: number) {
    if (this.requestActual && !this.requestFunctionsList[this.requestActual]) {
      return;
    }

    if (!this.requestActual) return;

    this.requestFunctionsList[this.requestActual](
      this.paginationSubject.value,
      entidadeID
    )
      .pipe(finalize(() => this.loadActions.closeLoad()))
      .subscribe((result) => {
        this.totalOfItensController = result.quantidadeTotal;
        this.totalController = result.itensList;
        this.listSubject.next(this.totalController);
      });
  }

  private ativarProcuraGlobal() {
    this.textoDeProcura$.subscribe((seachText) => {
      if (seachText && seachText !== "") {
        this.getPesquisaTodosAtivos(seachText);
      }
    });
  }

  private getPesquisaTodosAtivos(seachText: string) {
    this.requestFunctionsList[this.requestSearchActual](
      1,
      seachText,
      this.paginationSubject.value
    )
      .pipe(finalize(() => this.loadActions.closeLoad()))
      .subscribe((result) => {
        this.totalOfItensController = result.quantidadeTotal;

        this.totalSearchController = result.itensList;
        this.listSubject.next(this.totalSearchController);
      });
  }

  updateQuantidadeLinha(pageSize: number) {
    if (this.paginationSubject.value.pageSize !== pageSize) {
      this.totalController = null;

      this.paginationSubject.next({
        pageNumber: 1,
        pageSize,
      } as Pagination);
    }
  }

  updatePaginaPosicao(pageNumber: number) {
    const lastPage = Math.ceil(
      this.totalOfItensController / this.paginationSubject.value.pageSize
    );
    const newPage = this.paginationSubject.value.pageNumber + pageNumber;

    if (newPage <= lastPage && newPage >= 1) {
      this.paginationSubject.next({
        pageNumber: this.paginationSubject.value.pageNumber + pageNumber,
        pageSize: this.paginationSubject.value.pageSize,
      } as Pagination);
    }
  }

  firstOrLastPage(isFirstPage: boolean) {    
    const lastPage = Math.ceil(
      this.totalOfItensController / this.paginationSubject.value.pageSize
    );

    this.paginationSubject.next({
      pageNumber: !isFirstPage ? lastPage : 1,
      pageSize: this.paginationSubject.value.pageSize,
    } as Pagination);
  }

  pesquisaTodosAtivoUpdate(seachText: string) {
    this.textoDeProcuraSubject.next(seachText.trim());

    this.paginationSubject.next({
      pageSize: this.paginationSubject.value.pageSize,
      pageNumber: 1,
    } as Pagination);
  }

  carregaListaPorEntidade(entidade: string) {
    this.listSubjectEntidade.next(entidade);

    this.paginationSubject.next({
      pageSize: this.paginationSubject.value.pageSize,
      pageNumber: 1,
    } as Pagination);
  }

  UpdateSearchFuncitions(
    searchFunction: SERVICES_NAMES,
    requestSearchActual: SERVICES_NAMES
  ) {
    this.loadActions.startload();

    this.requestActual = searchFunction;

    this.requestSearchActual = requestSearchActual;

    this.totalController = null;
    this.totalSearchController = null;
    this.totalOfItensController = null;
    this.textoDeProcuraSubject.next(null);

    this.paginationSubject.next({
      pageSize: this.paginationSubject.value.pageSize,
      pageNumber: 1,
    } as Pagination);
  }

  private activeFilterList() {
    this.filteredList$.subscribe((list) => {
      if (list?.listaAtivo?.length >= 0) {
        this.listSubject.next(list.listaAtivo);
        this.totalOfItensController = list.quantidadeTotal;
      } else this.listSubject.next(this.totalController);
    });
  }

  private startRequestList() {
    this.requestFunctionsList = {
      ativoFisicoRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoFisicoService.getAtivosFisicos(pagination, entidadeId),
      ativoFisicoSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoFisicoService.getPesquisaTodosAtivosFisicos(
          entidadeId,
          searchText,
          pagination
        ),
      chipRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoFisicoService.getAtivosFisicoChips(pagination, entidadeId),
      chipEstoqueRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoFisicoService.getAtivosFisicoChipsEstoque(
          pagination,
          entidadeId
        ),
      chipSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoFisicoService.getPesquisaTodosAtivoFisicoChips(
          entidadeId,
          searchText,
          pagination
        ),
      dispositivosRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoFisicoService.getAtivosFisicoDispositivos(
          pagination,
          entidadeId
        ),
      dispositivosEstoqueRequest: (
        pagination: Pagination,
        entidadeId: number
      ) =>
        this.ativoFisicoService.getAtivosFisicoDispositivosEstoque(
          pagination,
          entidadeId
        ),
      dispositivosSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoFisicoService.getPesquisaTodosAtivoFisicoDispositivos(
          entidadeId,
          searchText,
          pagination
        ),
      acessoriosRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoAcessorioService.getAcessoriosCategoria(
          pagination,
          entidadeId
        ),
      acessoriosSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoAcessorioService.getPesquisaTodosAcessoriosCategoria(
          pagination,
          entidadeId,
          searchText
        ),
      ativoLinhaRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoLinhaService.getAtivosLinha(pagination, entidadeId),
      ativoLinhaSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoLinhaService.getPesquisaTodosAtivosLinha(
          pagination,
          searchText,
          entidadeId
        ),
      movelRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoLinhaService.getAtivosLinhaMovel(pagination, entidadeId),
      movelEstoqueRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoLinhaService.getAtivosLinhaMovelEstoque(
          pagination,
          entidadeId
        ),
      movelSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoLinhaService.getPesquisaTodosAtivosLinhaMovel(
          pagination,
          searchText,
          entidadeId
        ),
      linksRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoLinhaService.getAtivosLinks(pagination, entidadeId),
      linksSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoLinhaService.getPesquisaTodosAtivosLinks(
          searchText,
          pagination,
          entidadeId
        ),
      bandaRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoLinhaService.getAtivosLinhaBandaLarga(pagination, entidadeId),
      bandaSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoLinhaService.getPesquisaTodosAtivosLinhaBandaLarga(
          pagination,
          entidadeId,
          searchText
        ),
      fixaRequest: (pagination: Pagination, entidadeId: number) =>
        this.ativoLinhaService.getAtivosLinhaFixo(pagination, entidadeId),
      fixaSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.ativoLinhaService.getPesquisaTodosAtivosLinhaFixo(
          pagination,
          searchText,
          entidadeId
        ),
      usuarioEntidadeRequest: (pagination: Pagination, entidadeId: number) =>
        this.usuarioEntidadeService.getUsuariosEntidade(pagination, entidadeId),
      usuarioEntidadeSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.usuarioEntidadeService.getPesquisaTodosUsuarioEntidade(
          pagination,
          searchText,
          entidadeId
        ),
      contaFaturaRequest: (pagination: Pagination, entidadeId: number) =>
        this.contaFaturaService.getContaFatura(pagination, entidadeId),
      contaFaturaSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.contaFaturaService.getPesquisaTodosContaFatura(
          pagination,
          searchText,
          entidadeId
        ),
      downloadRequest: (pagination: Pagination, entidadeId: number) =>
        this.downloadService.getDownloads(pagination, entidadeId),
      downloadSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.downloadService.getPesquisaTodosDownload(
          pagination,
          searchText,
          entidadeId
        ),
      faturaRequest: (pagination: Pagination, entidadeId: number) =>
        this.faturaService.getFatura(pagination, entidadeId),
      faturaSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.faturaService.getPesquisaTodosFatura(
          pagination,
          searchText,
          entidadeId
        ),
      entidadeRequest: (pagination: Pagination, entidadeId: number) =>
        this.entidadeService.getEntidades(pagination, entidadeId),
      entidadeSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.entidadeService.getPesquisaTodosEntidades(
          pagination,
          searchText,
          entidadeId
        ),
      areaEntidadeRequest: (pagination: Pagination, entidadeId: number) =>
        this.areaEntidadeService.getAreaEntidades(pagination, entidadeId),
      areaEntidadeSearchRequest: (
        entidadeId: number,
        searchText: string,
        pagination: Pagination
      ) =>
        this.entidadeService.getPesquisaTodosEntidades(
          pagination,
          searchText,
          entidadeId
        ),

      // PERFIL, CONTA, PACOTE
      perfilRequest: (pagination: Pagination, entidadeId: number) =>
        this.perfilService.getPerfilTodos(pagination, entidadeId),
      contaLinhaRequest: (pagination: Pagination, entidadeId: number) =>
        this.contaLinhaService.getContaLinha(pagination),
      pacoteRIRequest: (pagination: Pagination, entidadeId: number) =>
        this.pacoteRIService.getPacoteRI(pagination, entidadeId),

      //Tipos Ativos
      tipoAtivoSearchRequest: (
        entidadeId: number,
        seachText: string,
        pagination: Pagination
      ) =>
        this.tipoAtivoService.getPesquisaTodosTiposAtivos(
          pagination,
          seachText,
          entidadeId
        ),
      fornecedorRequest: (pagination: Pagination) =>
        this.fornecedorService.getPagedFornecedor(pagination),
      fornecedorSearchRequest: (pagination: Pagination, searchText: string) =>
        this.fornecedorService.getSearchTextFornecedor(pagination, searchText),
    };
  }

  onDestroy() {
    this.listSubject.next([]);
    this.listSubjectEntidade.next(null);
    this.totalSearchController = [];
    this.totalOfItensController = 0;
    this.requestFunctionsList = null;
    this.textoDeProcuraSubject.next(null);
    this.requestActual = null;
    this.requestSearchActual = null;

    this.paginationSubject.next({
      pageNumber: 1,
      pageSize: 50,
    } as Pagination);
    this.totalOfItensController = 0;
    this.totalController = [];
  }
}
