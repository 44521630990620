import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { checkDateValidator } from 'src/app/shared/formValidators/checkDateValidator';
import { futureDateValidator } from 'src/app/shared/formValidators/futureDateValidator';


@Injectable({
  providedIn: 'root'
})
/**
 * Serviço responsável pela criação dos formulários.
 */
export class FormCadastroService {
  user: any;
  constructor(
    private formBuilder: FormBuilder,
    private tokenService: TokenStorageService,
  ) {
    this.user = this.tokenService.getUser()
  }

  getUsuarioForm() {
    return this.formBuilder.group({
      nomeCompleto: [null, [Validators.required]],
      matricula: [null, [Validators.required]],
      entidade: [null],
      email: [null, [Validators.required]],
      documento: [null, [Validators.required]],
      cnh: [null],
      vencimentoCNH: [null],
      vip: [null],
      lider: [null],
      acessoSistema: [null],
      cargo: [null, [Validators.required]],
      area: [null, [Validators.required]],
      subArea: [null],
      centroCusto: [null, [Validators.required]],
      responsavel: [null],
      unidadeEntidade: [null],
      canal:[null],
      cep:[null],
      logradouro:[null],
      uf:[null],
      bairro:[null],
      cidade:[null],
      numero:[null],
      complemento:[null],
      status:[null],
      roles: [],
      visibilidadeUnidadesId: [],
      visibilidadeAreaId: [],
      visibilidadeCentroDeCustoId: [],
      visibilidadeCanalId:[],
      visibilidadeTodos: [false],
    })
  }

  getSubAreaEntidadeForm() {

    return this.formBuilder.group({
      entidade: [null, [Validators.required, Validators.pattern("[0-9]+")]],
      descricao: [null, [Validators.required]],
      status: [1, [Validators.required]]

    })
  }


  getEntidadeForm() {

    return this.formBuilder.group({
      segmentoEntidade:[null, [Validators.required]],
      razaoSocial: [null, [Validators.required]],
      nomeUsual: [null, [Validators.required]],
      canal: [null],
      tipoPessoa:[null],
      numeroTipoPessoa:[null, [Validators.required]],
      inscricaoEstadual:[null],
      cep:[null, [Validators.required]],
      logradouro:[null, [Validators.required]],
      uf:[null, [Validators.required]],
      bairro:[null, [Validators.required]],
      cidade:[null, [Validators.required]],
      numero:[null, [Validators.required]],
      complemento:[null],
      entidadePrincipal:[null],
      matriculaObrigatoria:[null],
      tipoAtualizacao:['1'],
      status: [1, [Validators.required]]


    })
  }

  getUnidadeEntidadeForm() {

    return this.formBuilder.group({
      entidade:[null, [Validators.required]],
      segmentoEntidade:[null, [Validators.required]],
      razaoSocial: [null, [Validators.required]],
      nomeUsual: [null, [Validators.required]],
      canal: [null],
      tipoPessoa:[null],
      numeroTipoPessoa:[null, [Validators.required]],
      inscricaoEstadual:[null],
      storage:[null],
      unidadePrincipal:[null],
      cep:[null],
      logradouro:[null],
      uf:[null],
      bairro:[null],
      cidade:[null],
      numero:[null],
      complemento:[null],
      status: [1, [Validators.required]]


    })
  }

  getCanalEntidadeForm() {

    return this.formBuilder.group({
      entidade:[null, [Validators.required]],    
      razaoSocial: [null, [Validators.required]],
      nomeUsual: [null, [Validators.required]],    
      tipoPessoa:[null],
      numeroTipoPessoa:[null, [Validators.required]],
      inscricaoEstadual:[null],
    
      cep:[null],
      logradouro:[null],
      uf:[null],
      bairro:[null],
      cidade:[null],
      numero:[null],
      complemento:[null],
      status: [1, [Validators.required]]


    })
  }
  getCargoEntidadeForm() {

    return this.formBuilder.group({
      entidade: [null, [Validators.required]],
      areaEntidade:[null],
      descricao: [null, [Validators.required]],
      status: [1, [Validators.required]]

    })
  }

  getAreaEntidadeForm() {

    return this.formBuilder.group({
      entidade: [null, [Validators.required, Validators.pattern("[0-9]+")]],
      descricao: [null, [Validators.required]],
      status: [1, [Validators.required]]

    })
  }


  getCentroCustoEntidadeForm() {

    return this.formBuilder.group({
      entidade: [null, [Validators.required, Validators.pattern("[0-9]+")]],
      areaEntidade:[null],
      codigo: [null, [Validators.required]],
      nome: [null, [Validators.required]],
      unidade:[null],
      area: [null],
      tarifavel: [null],
      usuarioResponsavelId: [null],
      status: [1, [Validators.required]]

    })
  }


  // Tipos - Fabricantes - Modelos

  getTipoAtivoForm() {
    return this.formBuilder.group({
      modulo:[null, [Validators.required]],
      descricao: [null, [Validators.required]],
      categoriaAtivo: [null, [Validators.required]],    
      status: [1, [Validators.required]]
    })
  }

  getFabricanteModeloForm() {
    return this.formBuilder.group({
      modulo:[null, [Validators.required]],
      descricao: [null, Validators.required],
      categoriaAtivo: [null, [Validators.required]],
      tipoAtivo: [{ value: null, disabled: true }, [Validators.required]],
      status: [1, [Validators.required]]
    })
  }

  getModeloDispositivoForm() {
    return this.formBuilder.group({
      descricao: [null, Validators.required],
      categoriaAtivo: [null, [Validators.required]],
      tipoAtivo: [{ value: null, disabled: true }, [Validators.required]],
      fabricanteDispositivo: { value: null, disabled: true },
      memoria: [null],
      memoriaUnidade: ['GB'],
      conexao: [null],
      cor: [null],
      processador: [null],
      tamanhoDiscoRigido: [null],
      tamanhoDiscoRigidoUnidade: ['GB'],
      numeroSerie: [null],
      status: [1, [Validators.required]]
    })
  }

  getPacoteRIForm() {
    return this.formBuilder.group({
      descricaoPlano: [null, [Validators.required]],
      entidadeId: [null, [Validators.required]],
      fornecedorId: [null, [Validators.required]],
      regiao: [null, [Validators.required]],
      vigencia: [null, [Validators.required]],
      status: [1, [Validators.required]],
      valorTTCliente: [null, [Validators.required]],
      valorTTMobit: [null, [Validators.required]],
      incluso: [null ],
      sugestaoOutros: [null],
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
      multa: [null, [Validators.required]]
    })
  }

  getFornecedorForm(){
    return this.formBuilder.group({
      fornecedorId: [null, []],
      tipoPessoa: [null, [Validators.required]],
      tipo: [null, [Validators.required]],
      razaoSocial: [null, [Validators.required, Validators.maxLength(500)]],
      nomeUsual: [null, [Validators.required, Validators.maxLength(150)]],
      numeroTipoPessoa: [null, [Validators.maxLength(30), Validators.required]],
      status: [1, [Validators.required]]
    })
  }

  getPerfilCatalogoForm() {
    return this.formBuilder.group({
      //Dados Perfil
      descricao: [null, [Validators.required]],
      fornecedorId: [null, [Validators.required]],
      ttRevisao: [null, [Validators.required]],
      entidadeId: [null, [Validators.required]],
      servicoId: [null, [Validators.required]],
      dataCriacaoPerfil: [null, [Validators.required, checkDateValidator()]],
      inicioVigencia: [null, [Validators.required]],
      fimVigencia: [{ value: null, disabled: true }, [Validators.required]],
      vigencia: [null, [Validators.required]],
      dataUltimoReajuste: [null, []],
      multa: [null, [Validators.required]],
      totalAssinaturaMensalUnitaria: [null, []],
      totalAssinaturaSemAparelho: [null, []],
      status: [null, []],
      titularidadeId: [null, [Validators.required]],
      tipoRenovacao: [null, []],
      indiceReajuste: [null, []],
      valorTotalMensal: [null, []],
      assinaturaUnitariaMensal: [null, []],
      valorOriginal: [null, []],
      chamado: [null, []],
      qtdePacoteDadosDisponivelGestorOnline: [null, []],
      observacoes: [null, []],

      //Assinaturas Mensais Perfil - Accordion
      aparelho: [null, []],
      valorAparelho: [null, []],
      descontoAparelho: [null, []],
      protecaoRouboFurto: [null, [Validators.maxLength(10)]],
      qtDeParcelas: [null, []],
      assinaturaMensal: [null, []],
      gestaoSmartEmpresasDados: [null, [Validators.maxLength(10)]],
      gestaoSmartEmpresasVoz: [null, []],
      vivoNewPlus: [null, []],
      gestaoEquipeCompleto: [null, []],
      gestaoOnlineValor: [null, []],
      vivoSegurancaOnlineVivoNews: [null, []],
      valorFranquiaMinutosIndividual: [null, []],
      qtDeMinutosFranquiaIndividual: [null, []],
      descontoMarketingInternet: [null, []],
      excedenteGb: [null, []],
      valorPacotesDados: [null, []],
      qtdePacoteDadosContratado: [null, []],
      valorPacotesRecorrentesRI: [null, []],
      inclusoPacoteRI: [null, []],

      //Descrições Assinatura Mensal
      consumoMinimo: [null, []],
      qtdRamais: [null, []],
      qtdE1: [null, []],
      servicosInclusos: [null, []],
      qtdMinMovel: [null, []],
      totalInternet: [null, []],
      tarifacaoInicial: [null, []],
      cadencia: [null, []],

      //Definição de Bloqueios
      chamadaLocalVC1: [null, []],
      chamadaLongaDistanciaVC2VC3: [null, []],
      recebimentoACobrarVC1VC2VC3: [null, []],
      chamadaLongaDistanciaInternacional: [null, []],
      chamaRoamingInternacional: [null, []],
      mensagemTextoSMS: [null, []],
      mensagemTextoRecebimentoSMS: [null, []],
      mensagemTextoRoamingInternacionalSMS: [null, []],
      dadosNacional: [null, []],
      dadosRoamingInternacional: [null, []],
      restricaoCSP: [null, []],
      caixaPostal: [null, []],
      gestorOnline: [null, []],
      numerosEspeciais: [null, []],
      internet: [null, []],
      csp: [null, []],
      chamadaLocalFixoFixo: [null, []],
      chamadaLocalFixoMovel: [null, []],
      chamadaLongaDistanciaFixoFixo: [null, []],
      chamadaLongaDistanciaFixoMovel: [null, []],
      chamadaInternacional: [null, []],

      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    })
  }
}
