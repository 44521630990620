// format-cnpj.pipe.ts
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCNPJ' })
export class FormatCNPJPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    const cnpj = value.padStart(14, '0'); // Garantir que o valor tem 14 dígitos
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }
}