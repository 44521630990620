import { Location } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-page-title',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})
export class PagetitleComponent implements OnInit {
  @Input() breadcrumbItems;
  @Input() title: string;

  @Input() detailed: boolean;
  @Input() value: string;
  @Input() route: string;
  @Input() queryParams: any;

  @Input() urlRoute: string;
  indexTab: number;
  entidadeId: number;

  @Output() modalControl = new EventEmitter<boolean>();


  constructor(
    private _location: Location,
    private router: Router,
    private actualRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.indexTab = Number(this.actualRoute.snapshot.queryParamMap.get('tab'));
    this.entidadeId = Number(this.actualRoute.snapshot.queryParamMap.get('entidadeId'));
  }

  modal() {

    this.modalControl.emit()
  }

  back() {
    this.router.navigate(
      [this.urlRoute],
      {
        queryParams: {
          tab: this.indexTab,
          entidadeId: this.entidadeId
        }
      }
    );
  }

}
