import { Component, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-modal-confirmation",
  templateUrl: "./modal-confirmation.component.html",
  styleUrls: ["./modal-confirmation.component.scss"],
})
export class ModalConfirmationComponent {
  @Input() isOpen: boolean = false;
  @Input() title: string = "";
  @Input() text: string = "";
  @Input() type: string = "";
  @Input() textAuxiliar?: string = "";
  @Input() exibirBotaoConfirmar: boolean = true;
  @Input() exibirBotaoCancelar: boolean = true;
  @Input() labelBotaoConfirmar: string = "Confirmar";
  @Input() labelBotaoCancelar: string = "Cancelar";
  @Input() desabilitado: boolean = false;

  @Output() fecharModalEvent = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  fecharModal(confirm: boolean) {
    this.fecharModalEvent.emit(confirm);
  }
}
