const JobApplydata = [
    {
        id: 1,
        title: "Magento Developer",
        company: "Creative Agency",
        type: "Full Time",
        date: "02 June 2021",
        status: "Active",
    },
    {
        id: 2,
        title: "Apple School & College",
        company: "Themesbrand",
        type: "Part Time",
        date: "15 June 2021",
        status: "New",
    },
    {
        id: 3,
        title: "Marketing Director",
        company: "Web Technology pvt.Ltd",
        type: "Full Time",
        date: "02 June 2021",
        status: "Close",
    },
    {
        id: 4,
        title: "HTML Developer",
        company: "Skote Technology pvt.Ltd",
        type: "Full Time",
        date: "02 June 2021",
        status: "Active",
    },
    {
        id: 5,
        title: "Product Sales Specialist",
        company: "New Technology pvt.Ltd",
        type: "Part Time",
        date: "25 June 2021",
        status: "New",
    },
    {
        id: 6,
        title: "Magento Developer",
        company: "Themesbrand",
        type: "Freelance",
        date: "25 June 2021",
        status: "Close",
    },
    {
        id: 7,
        title: "Magento Developer",
        company: "Web Technology pvt.Ltd",
        type: "Part Time",
        date: "25 June 2021",
        status: "Active",
    },
    {
        id: 8,
        title: "Magento Developer",
        company: "Web Technology pvt.Ltd",
        type: "Full Time",
        date: "02 June 2021",
        status: "Close",
    },
    {
        id: 9,
        title: "Magento Developer",
        company: "Adobe Agency",
        type: "Freelance",
        date: "02 June 2021",
        status: "New",
    },
    {
        id: 10,
        title: "Magento Developer",
        company: "Web Technology pvt.Ltd",
        type: "Internship",
        date: "02 June 2021",
        status: "Active",
    },
];

export { JobApplydata };
