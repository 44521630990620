import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationService } from "../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    public toastService: ToastrService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          // auto logout if 401 response returned from api

          let msgErro =
            err.error === null
              ? "Você não tem permissão para esse módulo"
              : err.error.erros[0];

          this.toastService.error(msgErro, "Autenticação", {
            timeOut: 5000,
            progressBar: true,
          });
          this.authenticationService.logout();
          this.router.navigate(["/"]);

          // location.reload();
        } else if (err.status === 403) {
          // auto logout if 401 response returned from api

          this.toastService.error(
            "Você não tem permissão para consultar essa página",
            "Autenticação",
            { timeOut: 5000, progressBar: true }
          );
          this.authenticationService.logout();
          this.router.navigate(["/"]);
          // location.reload();
        }
        const error = err;
        return throwError(() => error);
      })
    );
  }
}
