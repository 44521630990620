import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Pagination } from 'src/app/core/models/pagination.model';
import { Frota } from '../models/frota.model';
import { FrotaCsv } from '../models/frotacsv.model';
import { UsuarioFrotaVeiculoVinculo } from 'src/app/modulos/telecom/ativo-fisico/models/vinculos/usuarioFrotaVeiculoVinculo.model';

@Injectable({
  providedIn: 'root'
})
export class FrotaService {

  baseUrl = environment.apiBaseHom + '/gestaofrotas';
  //baseUrl = sessionStorage.getItem('urlAPI')+'/v1/deposito/pedidovenda'

  private _frota = new BehaviorSubject<any[]>([]);
  get frota() {
    return this._frota.asObservable();
  }

  retornaParams() {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("auth", environment.apiBaseHom)

    return queryParams;
  }

  constructor(private http: HttpClient) { }


  getFrota(
    pagination: Pagination,
    entidadeId: number,
    unidadeId: number
  ): Observable<{
    listaFrota: Frota[],
    quantidade: number
  }> {
    return this.http.get<{
      listaFrota: Frota[],
      quantidade: number
    }>(
      `${this.baseUrl}?entidadeId=${entidadeId}&UnidadeID${unidadeId}PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  updateVinculos(usuarios: UsuarioFrotaVeiculoVinculo[], entidadeId: number){
    return this.http.put(`${this.baseUrl}/atualizavinculolista/${entidadeId}`, usuarios);
  }

  getFrotaPorId(frotaId: number): Observable<Frota> {
    return this.http.get<Frota>(
      `${this.baseUrl}/${frotaId}`
    );
  }

  addFrota(frotaNovo: Frota[]): Observable<any> {
    return this.http.post(`${this.baseUrl}`, frotaNovo)
      .pipe(
        map(result => {

          return result;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  updateFrota(frota: Frota, id: number): Observable<any> {
    return this.http.put(`${this.baseUrl}\\${id}`, frota);
  }

  downloadCsv(ativoCsv: FrotaCsv): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/downloadcsv`, ativoCsv, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((response) => {
          return { response: response, headers: response.headers };
        })
      );
  }

  intativarFrota(id: number){
    return this.http.delete(`${this.baseUrl}\\${id}`);
  }

  getPesquisaTodosFrota(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaFrota: Frota[],
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaFrota: Frota[],
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/FrotaPesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
}

