import { Component } from "@angular/core";
import { menu } from "./menu-mock";
import type { menuItem } from "./types/menu-accordion.type";
import { MenuService } from "./menu.service";

@Component({
  selector: "app-sidebar-refac",
templateUrl: "./sidebar-refac.component.html",
  styleUrls: ["./sidebar-refac.component.scss"],
})
export class SidebarRefacComponent {
  menu: menuItem[];
  
  constructor(private menuService: MenuService){}

  ngOnInit(): void {
    let rolesStorage = localStorage.getItem('roles');
    
    let roles = rolesStorage.split(',');
    // console.log('ROLES', roles)

    this.menuService.getMenu(roles).subscribe((res) => {
      // console.log('MENU', res.modulos)
      this.menu = res.modulos;
    })

    console.log("MENU")
    console.log(this.menu)
  }
}
