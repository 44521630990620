import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { progressCSVInfo } from 'src/app/core/models/progressCSVInfo';

@Injectable({
  providedIn: 'root'
})
export class ProgessCsvService {

  public showProgressCsvSubject: BehaviorSubject<progressCSVInfo> = new BehaviorSubject<progressCSVInfo>({
    value: false,
    metodo: ''
  });
  
  public showProgressCsvObservable: Observable<progressCSVInfo> = this.showProgressCsvSubject.asObservable();
  
  constructor() { }
}
