import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TokenStorageService } from 'src/app/core/services/token-storage.service';
import { AtivoLinha } from 'src/app/modulos/telecom/ativo-linha/core/models/ativoLinha.model';

@Injectable({
  providedIn: 'root'
})
export class ModalEditLinhaFormService {

  private user: any;
  constructor(private formBuilder: FormBuilder, private tokenService: TokenStorageService) { 
    this.user = this.tokenService.getUser();
  }

  
  getAtivoLinhaMovelEditForm() {
    return this.formBuilder.group({
      entidade: [null],
      titularidade: [null],
      status: [null],
      fornecedor: [null],
      conta: [null],
      perfil: [null],
      ddd: [null],
      uf: [null],
      pacoteRI: [null],

      recebimento: [null],
      // inicioVigencia: [null],
      // fimVigencia: [null],
      protocoloCancelamento: [null],
      suspensao: [null],
      protocoloBloqueio: [null],
      motivoBloqueio: [null],

      previsaoReativacao: [null],
      reativacao: [null],      
      ativacao: [null],
      inativacao: [null],
    })
  }

  pegarDadosMovelEditForm(form: FormGroup, contaLinhaId: number, perfilCatalogoId: number, pacoteRIId: number) {
    let linhaMovel: AtivoLinha = {
      entidadeId: form.get(['entidade']).value,
      titularidadeId: form.get(['titularidade']).value,
      status: form.get(['status']).value,
      fornecedorId: form.get(['fornecedor']).value,
      contaFaturaId: contaLinhaId ?? null,
      perfilCatalogoId: perfilCatalogoId ?? null,
      ddd: form.get(['ddd']).value,
      uf: form.get(['uf']).value,
      pacoteRIId: pacoteRIId ?? null,
      recebimentoLinha: form.get(['recebimento']).value,
      // inicioVigencia: form.get(['inicioVigencia']).value,
      // fimVigencia: form.get(['fimVigencia']).value,
      protocoloCancelamento: form.get(['protocoloCancelamento']).value,
      bloqueioSuspensao: form.get(['suspensao']).value,
      protocoloBloqueio: form.get(['protocoloBloqueio']).value,
      motivoBloqueio: form.get(['motivoBloqueio']).value,
      previsaoReativacaoAutomatico: form.get(['previsaoReativacao']).value,
      desbloqueioReativacao: form.get(['reativacao']).value,
      ativacaoLinhaOperadora: form.get(['ativacao']).value,
      inativacaoLinhaOperadora: form.get(['inativacao']).value,
      usuarioLog: this.user.email
    }

    return linhaMovel;
  }

  getAtivoLinhaFixaDadosEditForm() {
    return this.formBuilder.group({
      categoria: [null],
      entidade: [null],
      titularidade: [null],
      status: [null],
      fornecedor: [null],
      conta: [null],
      perfil: [null],
      uf: [null],

      nomeBandaLarga: [null],
      enderecoInstalacao: [null], // Ainda não deve fazer nada pois a tabela não foi alterada - 11/04
      ativacao: [null],
      inativacao: [null],
      recebimento: [null],
      // inicioVigencia: [null],
      // fimVigencia: [null]
    })
  }

  pegarDadosLinhaFixaEditForm(form: FormGroup, contaLinhaId: number, perfilCatalogoId: number) {
    let ativoLinha: AtivoLinha = {
      servicoId: form.get(['categoria']).value,
      entidadeId: form.get(['entidade']).value,
      titularidadeId: form.get(['titularidade']).value,
      status: form.get(['status']).value,
      fornecedorId: form.get(['fornecedor']).value,
      contaFaturaId: contaLinhaId ?? null,
      perfilCatalogoId: perfilCatalogoId ?? null,
      uf: form.get(['uf']).value,

      nomeFixaLinkDados: form.get(['nomeBandaLarga']).value,
      enderecoInstalacao: form.get(['enderecoInstalacao']).value,
      ativacaoLinhaOperadora: form.get(['ativacao']).value,
      inativacaoLinhaOperadora: form.get(['inativacao']).value,
      recebimentoLinha: form.get(['recebimento']).value,
      // inicioVigencia: form.get(['inicioVigencia']).value,
      // fimVigencia: form.get(['fimVigencia']).value,
      usuarioLog: this.user.email
    }

    return ativoLinha;
  }
}
