import { Component, OnInit } from "@angular/core";
import { StyleManagerService } from "./core/services/style-manager.service";
import { TokenStorageService } from "./core/services/token-storage.service";
import { CustomerRouterService } from "./shared/services/customer-router.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private tokenStorageService: TokenStorageService,
    private styleManager: StyleManagerService,
    private readonly customerRouterService: CustomerRouterService
  ) { }

  ngOnInit() {
    // this.styleManager.setVariables('#090b66,#0ca8eb, #fff, dark, url_image');
    const darkColors = '#090b66,#0ca8eb, #fff, dark, url_image'
    const lightColors = '#090b66,#0ca8eb, #090b66, light, url_image'


    if (this.tokenStorageService.getUser().entidadeId === "1" &&
      localStorage.getItem("theme") === 'dark' ||
      localStorage.getItem("theme") === '') {

      this.styleManager.setVariables(darkColors);
    } else {

      this.styleManager.setVariables(lightColors);
    }

    if (this.tokenStorageService.getUser().entidadeId !== "1") {

      this.styleManager.setVariables(this.tokenStorageService.getIdentidadeVisualEntidade());
    }

  }
}
