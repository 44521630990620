import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model";
import { Pagination } from "src/app/core/models/pagination.model";
import { AtivoLinha } from "src/app/modulos/telecom/ativo-linha/core/models/ativoLinha.model";
import { UsuarioEntidade } from "src/app/modulos/telecom/cadastro/core/models/usuarioEntidade.model";
import { LocalStorageUtilsService } from "../utils/LocalStorageUtils.service";
import { ColunasCsvEnum } from "../models/enums/colunasCsvEnum";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  private baseUrl = environment.apiBaseHom;

  constructor(
    private localStorageUtils: LocalStorageUtilsService,
    private http: HttpClient
  ) {}

  getFilters(
    queryController: string,
    pagination: Pagination,
    route: string,
    tipoAtivo: number
  ): Observable<{
    listaAtivo: AtivoFisico[] | AtivoLinha[] | UsuarioEntidade[];
    quantidadeTotal: number;
  }> {
    const entidadeId = this.localStorageUtils.getEntidadeIdFromLocalStorage();

    const routeCategory = route.includes("ativoFisico")
      ? "CategoriaId"
      : "tipoAtivo";

    return this.http.get<any>(
      `${this.baseUrl}/${route}/${route}genericsearch?${queryController}PageNumber=${pagination.pageNumber}&entidadeId=${entidadeId}&PageSize=${pagination.pageSize}&${routeCategory}=${tipoAtivo}`
    );
  }

  getColumnsList(route: string): Observable<string[]> {
    const servicoCategoriaId = this.getServiceIdPathRoute();
    if (route == "usuarioentidaderecursos") {
      return this.http.get<any>(
        `${this.baseUrl}/usuarioentidade/getusuarioentidaderecursoscolumn?servicoCategoriaId=${servicoCategoriaId}`
      );
    } else {
      return this.http.get<any>(
        `${this.baseUrl}/${route}/get${route}column?servicoCategoriaId=${servicoCategoriaId}`
      );
    }
  }

  getServiceIdPathRoute(): number {
    const pathList = location.pathname.split("/");
    let servicoCategoriaId: ColunasCsvEnum = ColunasCsvEnum.Padrao;
    const penultimoItemLista = pathList[pathList.length - 2];

    //Ativo Fisico
    penultimoItemLista === "dispositivos" ? (servicoCategoriaId = ColunasCsvEnum.Dispositivo) : false;
    penultimoItemLista === "chips" ? (servicoCategoriaId = ColunasCsvEnum.Chip) : false;
    penultimoItemLista === "ativoFisico" ? (servicoCategoriaId = ColunasCsvEnum.AtivoFisicoTodos): false;

    //Ativo Linha
    penultimoItemLista === "movel" ? (servicoCategoriaId = ColunasCsvEnum.Movel) : false;
    penultimoItemLista === "link" ? (servicoCategoriaId = ColunasCsvEnum.Links) : false;
    penultimoItemLista === "banda_larga" ? (servicoCategoriaId = ColunasCsvEnum.BandaLarga) : false;
    penultimoItemLista === "fixa" ? (servicoCategoriaId = ColunasCsvEnum.Fixa) : false;
    penultimoItemLista === "ativoLinha" ? (servicoCategoriaId = ColunasCsvEnum.AtivoLinhaTodos): false;
    

    return servicoCategoriaId;
  }
}
