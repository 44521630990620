<!-- <a [href]="[route]" target="_blank"> -->
@if(!isInput && text !== "Visualizar" && !isDisable && !isHidden) {
<a
  [routerLink]="route"
  [queryParams]="queryParams"
  [tooltip]="tooltip"
  [ngClass]="[type]"
  for="file"
>
  <i [ngClass]="[icon]"></i>
  {{ text }}
</a>
} @if(isInput && !isDisable && !isHidden) {
<a
  [routerLink]="route"
  [queryParams]="queryParams"
  [tooltip]="tooltip"
  [ngClass]="[type]"
  target="_blank"
  for="file"
  (click)="onFileSelected()"
>
  <i [ngClass]="[icon]"></i>
  {{ text }}
</a>
} @if(text === "Visualizar" && !isDisable && !isHidden) {
<a
  [href]="route"
  [tooltip]="tooltip"
  target="_blank"
  [ngClass]="[type]"
  for="file"
>
  <i [ngClass]="[icon]"></i>
  {{ text }}
</a>
}
@if(isDisable && !isHidden) {
<button
  [routerLink]="route"
  [queryParams]="queryParams"
  [tooltip]="tooltip"
  class="disabled-link"
  for="file"
  [disabled]="true"
>
  <i [ngClass]="[icon]"></i>
  {{ text }}
</button>
}
@if (isDisable && isHidden) {}
<!-- <app-file-upload
  (closeModal)="editModal()"
  [insertURL]="insertURL"
  [updateURL]="updateURL"
  [isOpen]="isOpen"
  [title]="'Lote'"
/> -->
<!-- </a> -->
