import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap } from 'rxjs/operators'
import { menuItem, menuItemModulos } from "./types/menu-accordion.type";
import { Observable, catchError, map, of } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})

export class MenuService {
  baseUrl = environment.apiBaseHom + "/acessomenurole";

  dadosMenu: menuItemModulos = { modulos: [] }

  constructor(private http: HttpClient) {}
  
  private addParentId(items: menuItem[], parentId: number | null = null): menuItem[] {
    return items.map(item => {
      const newItem: menuItem = { ...item, parentId };

      if (newItem.subItems && newItem.subItems.length > 0) {
        newItem.subItems = this.addParentId(newItem.subItems, newItem.id!);
      }

      return newItem;
    });
  }

  saveDadosMenu(): void {
    console.log('O menu dadosMenu foi salvo')
    localStorage.setItem('dadosMenu',JSON.stringify(this.dadosMenu))
  }

  updateLocalStorage(newItem: menuItem, value: boolean): void {
    this.dadosMenu = JSON.parse(localStorage.getItem('dadosMenu'))
    function updateItem(items: menuItem[]): menuItem[] {
      return items.map(i => {
        if (i.id === newItem.id) {
          return { ...i, isOpen: value };
        } else if (i.subItems) {
          return { ...i, subItems: updateItem(i.subItems) };
        }
        return i;
      });
    }
    this.dadosMenu = { modulos: updateItem(this.dadosMenu.modulos) };
    this.saveDadosMenu()
  }

  getMenu(role: string[]): Observable<menuItemModulos> {
    const roleParams = role.map(r => `roleName=${encodeURIComponent(r)}`).join('&');
    const dadosMenu = localStorage.getItem('dadosMenu');

    if (dadosMenu) {
      return of(JSON.parse(dadosMenu) as menuItemModulos);
    } else {
      return this.http.get<menuItemModulos>(
        `${this.baseUrl}/?${roleParams}`
      ).pipe(
        tap(dados => {
          // Adiciona o parentId nos dados dos módulos
          const dadosComParentId: menuItemModulos = {
            modulos: this.addParentId(dados.modulos)
          };
          localStorage.setItem("dadosMenu", JSON.stringify(dadosComParentId));
        })
      );
    }
  }
}
