import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Table } from "primeng/table";
import { Subscription } from "rxjs";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { Pagination } from "src/app/core/models/pagination.model";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { Download } from "src/app/modulos/telecom/conta-fatura/core/models/download.model";
import { DownloadService } from "src/app/modulos/telecom/conta-fatura/core/services/download.service";

@Component({
  selector: "app-download-modal",
  templateUrl: "./download-modal.component.html",
  styleUrls: ["./download-modal.component.css"],
})
export class DownloadModalComponent {
  @Input() headerTitle: string;
  @Input() buttonFunction: () => void;
  @Input() buttonText: string;
  @Input() reference: string;
  @Input() itemSelecionadoFromParent: Download;
  @Input() servicoId: number;
  @Input() entidadeId: number;
  @Input() fornecedorId: number;

  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<any>();
  @Output() limparValorEvent = new EventEmitter<any>();

  downloadSelecionado: Download;

  breadCrumbItems: Array<{}>;
  tooltipText = "pesquisa da pagina atual";
  isOpen: boolean = true;

  grid: string =
    "0.1fr minmax(50px, 0.6fr)  minmax(50px, 0.9fr) minmax(50px, 0.9fr)  minmax(100px, 0.5fr)minmax(50px, 0.6fr)minmax(50px, 0.6fr)";
  dataTableHeaders: string[] = [
    "Ação",
    "Serviço",
    "Entidade",
    "Fornecedor",
    "CNPJ",
    "Tipo Busca",
    "Situação",
  ];

  valorSelecionado!: any;

  @ViewChild("showModal", { static: false }) showModal?: ModalDirective;
  @ViewChild("dt", { static: true }) dt: Table;
  @ViewChild("removeItemModal", { static: false })
  removeItemModal?: ModalDirective;

  isHistoryOpen = false;
  isChecked: boolean = false;
  selectedDownload: Download[] = [];
  enumTeste = SERVICES_NAMES;

  historicController = "";

  private subscription: Subscription[] = [];

  constructor(
    private paginatorStore: PaginatorGenericStoreService,
    private tableStore: TableStoreService,
    private toastr: ToastrService,
    // private deletionService: AtivoFisicoDeletionService,
    private downloadService: DownloadService
  ) {}

  get data$() {
    return this.paginatorStore.dataObservable$;
  }

  get tableRow$() {
    return this.tableStore.tableRow$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  ngOnInit() {
    this.subscription.push(
      this.rowsSelected.subscribe((data: any) => (this.valorSelecionado = data))
    );
  }

  sendData(event$: { paginator: Pagination; searchText: string }) {
    if (event$.searchText !== null) {
      this.paginatorStore.getDataObservable(
        this.downloadService.getPesquisaTodosDownload(
          event$.paginator,
          event$.searchText,
          1
        )
      );
    } else {
      this.paginatorStore.getDataObservable(
        this.downloadService.getDownloadsModal(
          event$.paginator,
          this.servicoId,
          this.entidadeId,
          this.fornecedorId
        )
      );
    }
  }

  checkOne(index: number) {
    this.tableStore.checkOne(index);
  }

  editModal() {
    if (this.selectedDownload.length > 0) {
      this.isOpen = !this.isOpen;
    } else {
      this.toastr.warning(
        "É necessário escolher um ou mais Dispositivos para poder editar."
      );
    }
  }

  closeLog() {
    this.historicController = "";
    this.isHistoryOpen = false;
  }

  selecionarValor() {
    this.downloadSelecionado = this.valorSelecionado[0];

    this.selecionarValorEvent.emit(this.downloadSelecionado);
    this.fecharModal();
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  // deletarItem(ativoFisicoId: number) {
  //   this.deletionService.confirmarDelecao(ativoFisicoId);
  // }

  navigate(descricao: string) {
    this.historicController = descricao;
    this.isHistoryOpen = true;
  }

  ngOnDestroy(): void {
    this.subscription.forEach((subscription) => subscription.unsubscribe());
  }
}
