import { Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
  selector: "app-modal-confirmation-btn",
  templateUrl: "./modal-confirmation-btn.component.html",
  styleUrls: ["./modal-confirmation-btn.component.scss"],
})
export class ModalConfirmationBtnComponent {
  @Input() btnLabel: string = "";
  @Input() customClassBtn: string = ""
  @Input() titleModal: string = "Confirmar";
  @Input() textModal: string = `Confirmar a ação que está fazendo?`;
  @Input() icon: string = ""
  @Input() isLoading: boolean = false;
  @Input() isHidden: boolean = false;
  @Output() modalEventEmitter: EventEmitter<boolean> = new EventEmitter();

  prohibitedTextModalListForNonAdmin : string[] = [
    'cadastrar',
    'atualizar'
  ]

  public modalConfirmationOpen: boolean = false;

  ngOnInit() {
    const userRoles = localStorage.getItem('roles')
    const rolesArray = userRoles.split(',').map((res) => res.toLowerCase())

    const btnLabelTextToLowerCase = this.btnLabel.toLowerCase()

    console.log(btnLabelTextToLowerCase)
    if (!(rolesArray.includes('admin') || userRoles.toLowerCase() === 'admin') && this.prohibitedTextModalListForNonAdmin.includes(btnLabelTextToLowerCase)) {
      this.isHidden = true
    }

  }

  public onClickBtn = (): void => {
    this.modalConfirmationOpen = true;
  };

  public onCloseModal = (confirm: boolean): void => {
    this.modalConfirmationOpen = false;
    this.modalEventEmitter.emit(confirm);
  };
}
