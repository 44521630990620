<p-dialog
  style="width: 100%"
  header="Linhas Fixas"
  [(visible)]="isOpen"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  (onHide)="fecharModal()"
  [draggable]="false"
  [resizable]="false"
>
  <ng-container [ngTemplateOutlet]="linhas"></ng-container>
  <ng-template pTemplate="footer">
    <div style="display: flex; justify-content: flex-end; gap: 10px;">
      <app-action-btn
        [icon]="'fa fa-times font-size-20 '"
        [type]="'outlined'"
        [text]="'Limpar'"
        (click)="limparValor()"
      ></app-action-btn>
      <app-action-btn
        [icon]="'fa fa-check font-size-20 '"
        [type]="''"
        [text]="'Selecionar'"
        (click)="selecionarValor()"
      ></app-action-btn>
    </div>
  </ng-template>
</p-dialog>

<ng-template #linhas>
  <app-table
    [data]="data$"
    [grid]="grid"
    [isOpen]="isOpen"
    (getData)="sendData($event)"
    (openModal)="editModal()"
    [title]="''"
    [dataTableHeaders]="dataTableHeaders"
    [exibirCheckbox]="false"
    [filterContext]="'ativoLinha'"
    [tipoSearch]="2"
    [placeholderPesquisaGlobal]="'Pesquisa em LInhas Fixas'"
    [exibirFilterButton]="false"
  >
    <div
      class="dataTable-body"
      [ngStyle]="{ 'grid-template-columns': grid }"
      *ngFor="let items of tableRow$ | async; let i = index"
    >
      <div>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="items.isChecked"
          [disabled]="!items.isEnabled"
          (click)="checkOne(i)"
          [(ngModel)]="items[i]"
        />
      </div>
      <div>{{ items.descricao }}</div>
      <div>
        <span
          [ngClass]="
            items.status == 0
              ? 'active badge bg-danger me-1'
              : items.status == 1
              ? 'unactive badge bg-success me-1'
              : items.status == 2
              ? 'unactive badge bg-warning me-1'
              : items.status == 3
              ? 'unactive badge bg-primary me-1'
              : items.status == 4 || items.status == 5
              ? 'unactive badge bg-danger me-1'
              : 'unactive badge bg-warning me-1'
          "
        >
          {{
            items.status == 0
              ? "INATIVO"
              : items.status == 1
              ? "ATIVO"
              : items.status == 2
              ? "DESCARTE"
              : items.status == 3
              ? "NOVO"
              : items.status == 4
              ? "REPARO"
              : items.status == 5
              ? "ROUBO"
              : "USADO"
          }}
        </span>
      </div>
      <div>{{ items.fornecedor?.nomeUsual }}</div>
      <div>{{ items.nomeFixaLinkDados }}</div>
      <div>{{ items.entidade?.razaoSocial }}</div>
      <div>{{ items.titularidade?.razaoSocial }}</div>
      <div>{{ items.perfilCatalogo?.descricao }}</div>
      <!-- <div>{{ items.identificacao }}</div> -->
    </div>
  </app-table>
</ng-template>
