import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { env } from 'process';
const getEnvVars = (params:{prefix?: string}) => {
  const prefix = params.prefix ?? "MOBIT_"
  const keys = Object.keys(environment)
  const envKeys = keys.map(key => `${prefix}${key}`)
  const envVars = envKeys.map(key => env[key]).filter(f => f)
  // envVars.forEach(var ) 
}

console.log(environment.apiBaseHom);

if (environment.production) {
  enableProdMode();
  const dummyConsole = {
    log: (...message) => {},
  };
  Object.keys({}).forEach((key) => (window.console[key] = dummyConsole[key]));
  window.console.log = (...message) => {};
  window.console.error = (...message) => {};
  window.console.info = (...message) => {};
  window.console.warn = (...message) => {};
  window.console.debug = (...message) => {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
