<div *ngIf="showSpinner" class="d-flex justify-content-center"> 
    <div class="spinner-border" role="status"> 
        <span class="sr-only" id="loading"></span> 
    </div> 
</div> 
<div *ngIf="processing" class="d-flex justify-content-center"> 
    <div class="spinner-border" role="status"> 
        <span class="sr-only" id="loading"></span> 
    </div> 
</div>
<!-- data from API is displayed  -->
<!-- <div *ngIf="showMessageModal" class="d-flex justify-content-center">
	<div></div>		
</div> -->
<div  class="container" appDnd (fileDropped)="onFileDropped($event)">
	<input type="file" [accept]="tiposPermitidos"  #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
    <i class="bx bx bx-cloud-upload bx-lg"></i>

	<h5>Solte e arraste o arquivo aqui</h5>
	<h3>ou</h3>
	<h5>Clique aqui</h5>

  

	<!-- <label for="">Browse for file</label> -->
</div>
<div class="files-list">
	<div class="single-file" *ngFor="let file of files; let i = index">
		<div class="file-icon" style="width: 50px">
			<i class="bx bxs-file-pdf bx-lg"></i>
	
		</div>
		<div class="info">
			<h4 class="name">
				{{ file?.name }}
			</h4>
			<p class="size">
				{{ formatBytes(file?.size) }}
			</p>
			<app-progress [progress]="progress"></app-progress>
		</div>


		<div class="delete" (click)="deleteFile(i)">
			<svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
				<path fill="#B1B1B1" fill-rule="nonzero"
					d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
			</svg>
		</div>
	
	</div>
	<div class="row" >
		<div class="col-4">

		</div>
		<div class="col-3">
			<div >
				<button [disabled]="!habilitaUpload" class="btn btn-primary btn-custom" (click)="tipoUpload=='pdf'? uploadFiles(): tipoComponente =='fatura'? uploadDetalhadoFatura():uploadDetalhado()"  >
					<i class="fas fa-upload"></i> Upload
					
				  </button>
				<!-- <app-action-btn
					[icon]="'mdi mdi-upload font-size-20'"
					[type]="'send'"
					[text]="'Upload'"
					(click)="tipoUpload=='pdf'? uploadFiles(): tipoComponente =='fatura'? uploadDetalhadoFatura():uploadDetalhado()" 
			></app-action-btn> -->

		</div>
	
		</div>
		<div class="col-3">

		</div>

	</div>


</div>
