@if((isLoading$ | async) && !isModalOpen) {
<div [ngClass]="isForm ? 'loader-table-modal' : 'loader-table'">
  <app-style-loader></app-style-loader>
</div>
} @else {
<div [ngClass]="{ isEmpty: isEmpty, '': !isEmpty }">
  <div class="page-header">
    <div class="title-search">
      <h6>{{ title }}</h6>
      @if(exibirPesquisa){
      <div class="global-search">
        <!-- <span class="p-input-icon-left"> -->
        <!-- <i class="bx bx-search-alt"></i> -->
        <input
          (keypress)="pesquisaGlobalEnter($event, searchTextGlobal)"
          [(ngModel)]="searchTextGlobal"
          pInputText
          type="text"
          [placeholder]="placeholderPesquisaGlobal"
          (blur)="cleanSearch()"
        />
        <!-- </span> -->

        <app-action-btn
          icon="bx bx-search-alt font-size-20"
          type=""
          text=""
          pTooltip=""
          (click)="pesquisaGlobal($event, searchTextGlobal)"
        ></app-action-btn>
      </div>
      }
    </div>

    <div class="table-actions-container">
      <div class="d-flex gap-2">
        @if(exibirActionButtons) {
        <app-action-btn
          [icon]="'mdi mdi-plus d-block font-size-20'"
          [type]="'success'"
          [text]="'Novo'"
          [route]="addRoute"
          [queryParams]="queryParams"
          id="Novo_Item"
        ></app-action-btn>
        @if(exibirEditarMuitos) {

        <app-action-btn
          [ngStyle]="{
            visibility: !actualRoute ? 'visible' : 'hidden'
          }"
          [icon]="'mdi mdi-pencil d-block font-size-20'"
          [type]="'edit'"
          [text]="'Editar Muitos'"
          id="botaoEditarMuitos"
          (click)="editModal()"
        ></app-action-btn>
        }
        <app-action-btn
          [ngStyle]="{
            visibility: !actualRoute ? 'visible' : 'hidden'
          }"
          id="botaoEnviarArquivo"
          (click)="loteModal($event)"
          [icon]="
            btnUploadDisabled
              ? 'mdi mdi-account-plus font-size-20 mdi-spin mdi-loading'
              : 'mdi mdi-upload d-block font-size-20'
          "
          [pTooltip]="btnUploadDisabled ? 'Upload em andamento' : ''"
          [type]="'send'"
          [text]="btnUploadDisabled ? '' : 'Enviar Arquivo'"
          [isDisable]="btnUploadDisabled"
          [isInput]="true"
          [isInput]="true"
          [insertURL]="insertURL"
          [updateURL]="updateURL"
        ></app-action-btn>
        }
      </div>

      <div class="table-manipulation">
        <!-- <ng-select
          placeholder="Ordenar por"
          class="input-default input-md"
          (change)="sortTable(sortSelected)"
          [(ngModel)]="sortSelected"
        >
          @for(item of sortFields; track $index) {
          <ng-option [value]="item?.value">{{ item?.name }}</ng-option>
          }
        </ng-select> -->
        @if(exibirExport) {
        <app-action-btn
          [icon]="
            btnExportDisabled
              ? 'mdi mdi-account-plus font-size-20 mdi-spin mdi-loading'
              : 'mdi mdi-content-save font-size-20 '
          "
          [type]="''"
          [text]="btnExportDisabled ? '' : 'Exportar'"
          [pTooltip]="btnExportDisabled ? 'Download em andamento' : ''"
          (click)="exportModal()"
          [isDisable]="btnExportDisabled"
          id="botaoExportar"
        ></app-action-btn>
        } @if(exibirFilterButton !== false) {
        <app-action-btn
          [icon]="'mdi mdi-filter font-size-20 '"
          [type]="''"
          [text]="'Filtrar'"
          id="botaoFiltrar"
          [pTooltip]="''"
          (click)="op.toggle($event)"
        ></app-action-btn>
        }
        <app-action-btn
          [icon]="'mdi mdi-format-clear font-size-20 '"
          [type]="''"
          [text]="'Limpar Filtros'"
          [pTooltip]=""
          (click)="clearAllFilters()"
          id="botaoLimparFiltros"
        ></app-action-btn>
        <div
          style="display: flex; flex-wrap: wrap; align-items: center; gap: 16px"
        >
          <div class="table-pagination">
            <button
              class="btn-change-page"
              [disabled]="(pagination$ | async)?.pageNumber === 1"
              (click)="alterarPagina(-1)"
            >
              <i class="mdi mdi-chevron-left font-size-20"></i>
            </button>
            <button
              class="btn-change-page"
              *ngIf="(pagination$ | async)?.pageNumber !== 1"
              (click)="firstOrLastPage(true)"
            >
              <div class="table-pages">
                {{ 1 }}
              </div>
            </button>
            <div class="table-pages">
              {{ (pagination$ | async)?.pageNumber }}
            </div>
            <button
              class="btn-change-page"
              *ngIf="
                (pagination$ | async)?.pageNumber !== (totalItems$ | async)
              "
              (click)="firstOrLastPage(false)"
            >
              <div class="table-pages">
                {{ totalItems$ | async }}
              </div>
            </button>
            <button
              class="btn-change-page"
              [disabled]="
                (totalItems$ | async) === (pagination$ | async)?.pageNumber
              "
              (click)="alterarPagina(1)"
            >
              <i class="mdi mdi-chevron-right font-size-20"></i>
            </button>
          </div>
          <div><b>Total de itens:</b> {{ totalItems }}</div>
          <ng-select
            (change)="alterarLinhas(selection)"
            [(ngModel)]="selection"
            placeholder="50"
            [searchable]="false"
            [clearable]="false"
          >
            <ng-option
              placeholder="Escolha..."
              *ngFor="let rowsPerPage of [15, 30, 50, 100, 500, 1000]"
              [value]="rowsPerPage"
              >{{ rowsPerPage }}</ng-option
            >
          </ng-select>
        </div>
      </div>
    </div>
  </div>
  @if(isEmpty){
  <div class="data-not-found-card">
    <h3>Dados não encontrados!</h3>
    <img
      src="../../../../assets/images/data-not-found.svg"
      width="200px"
      height="200px"
    />
    <app-action-btn
      [icon]="'mdi mdi-keyboard-backspace font-size-20 '"
      [type]="''"
      [text]="'Voltar para a página inicial'"
      [routerLink]="'/'"
    ></app-action-btn>
  </div>
  } @else {
  <div class="datatable-container">
    <div
      class="datatable-header"
      [ngStyle]="{ 'grid-template-columns': grid && grid }"
    >
      @if(!actualRoute && exibirCheckbox) {
      <div>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="isChecked"
          (click)="onCheck()"
          [(ngModel)]="isChecked"
        />
      </div>
      }
      <div
        *ngFor="let items of dataTableHeaders; index as i"
        (click)="sortTable(i)"
        [ngStyle]="{ 'justify-content': headerAlign ? headerAlign[i] : '' }"
      >
        {{ items }}
        @if(sort) {
        <i class="bx bx-sort-up"></i>
        } @else {
        <i class="bx bx-sort-down"></i>
        }
      </div>
    </div>
    <ng-content />
  </div>
  }
  <p-overlayPanel #op>
    <ng-template pTemplate="content">
      <div class="filter-wrapper">
        <div class="table-filter-header">
          <h6>Filtrar {{ title }}</h6>
          <div (click)="op.toggle($event)" class="filter-dialog">
            <i class="mdi mdi-close"></i>
          </div>
        </div>
        <ng-container *ngIf="registerForm">
          <div
            style="justify-content: flex-end"
            class="input-wrapper"
            *ngFor="let a of columnsControler; let i = index"
            [formGroup]="registerForm"
          >
            <ng-select
              *ngIf="i !== 0"
              [formControlName]="'operators' + i"
              placeholder="operador"
              class="input-default input-sm"
            >
              <ng-option value="1">E</ng-option>
              <ng-option value="0">OU</ng-option>
            </ng-select>
            <ng-select
              [formControlName]="'column' + i"
              placeholder="Coluna"
              class="input-default input-md"
              (change)="onChangeFieldFilter($event, i)"
            >
              @for(item of columnsList; track $index) {
              <ng-option [value]="item">{{ item }}</ng-option>
              }
            </ng-select>
            <ng-select
              [formControlName]="'validation' + i"
              placeholder="Função"
              class="input-default input-sm"
            >
              <ng-option [value]="'1'">Tem</ng-option>
              <ng-option [value]="'0'">Não tem</ng-option>
            </ng-select>
            <div class="input-default input-md">
              <input
                *ngIf="!a.isNgSelectedInput"
                [formControlName]="'searchText' + i"
                type="text"
                id="tipoAtivo"
                placeholder="Texto"
                [mask]="a.maskTextFilter ?? ''"
                [prefix]="a.prefix ?? ''"
                [thousandSeparator]="a.thousandSeparator ?? ''"
                [dropSpecialCharacters]="a.dropSpecialCharacters"
              />
              <ng-select
                *ngIf="a.isNgSelectedInput"
                [formControlName]="'searchText' + i"
                class="input-default input-sm"
                placeholder="Selecione..."
              >
                @for (item of a.valuesForSelectedInput; track $index) {
                <ng-option [value]="item">{{ item }}</ng-option>
                }
              </ng-select>
            </div>

            <div (click)="addFilter()" style="font-size: 20px">
              <i class="mdi mdi-plus-circle success"></i>
            </div>
            <div (click)="removeFilter(i)" style="font-size: 20px">
              <i class="mdi mdi-delete remove"></i>
            </div>
          </div>
        </ng-container>
        <div class="table-filter-footer">
          <app-action-btn
            [icon]="'mdi mdi-filter font-size-20 '"
            [type]="''"
            [text]="'Filtrar'"
            (click)="applyFilters()"
          ></app-action-btn>
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
}

<p-dialog
  style="width: 100%"
  [(visible)]="isOpenExportModal"
  [modal]="true"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
  class="exportCSV"
>
  <div class="table-filter-header">
    <h6>Exportar {{ title }}</h6>
    <div class="btn-close" (click)="exportModal()"></div>
  </div>
  <ng-template pTemplate="content">
    <div [formGroup]="exportForm" *ngIf="exportForm" style="margin-top: 20px">
      <div *ngIf="isUsuario" class="input-xl">
        <div class="switches-wrapper">
          <div class="switch-container">
            <h5 class="font-size-12 mb-3;" style="color: #16149e">
              Com Recursos ?
            </h5>
            <ui-switch
              color="#16149e"
              (change)="onRecursoOption($event)"
              [checked]="false"
              uncheckedLabel="Não"
              checkedLabel="Sim"
              size="small"
              class="me-1"
            ></ui-switch>
          </div>
        </div>
      </div>
      <div class="input-lg mt-3">
        <h6>Página/extensão</h6>
        <ng-select
          style="margin-bottom: 10px"
          formControlName="valorExport"
          class="exportacao"
          placeholder="Exportar em..."
          [searchable]="false"
          [clearable]="false"
        >
          <ng-option value="umaPagina">Página atual (CSV)</ng-option>
          <ng-option value="todasPaginas">Todas as páginas (CSV)</ng-option>
        </ng-select>
      </div>
      <div class="input-full-width">
        <h6>Campos selecionados</h6>
        <p-multiSelect
          class="input-full-width"
          [options]="columnsList"
          formControlName="exportColumns"
          placeholder="Selecione os campos"
          display="chip"
          [maxSelectedLabels]="undefined"
          [showClear]="true"
        >
        </p-multiSelect>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="btn-group">
      <app-action-btn
        [type]="'danger'"
        [icon]="'mdi mdi-close'"
        [text]="'Cancelar'"
        (click)="exportModal()"
      ></app-action-btn>
      <app-action-btn
        [icon]="'mdi mdi-export font-size-20 '"
        [type]="''"
        [text]="'Confirmar'"
        (click)="exportCsv()"
      ></app-action-btn>
    </div>
  </ng-template>
</p-dialog>

<app-file-upload
  (closeModal)="loteModal()"
  [insertURL]="insertURL"
  [updateURL]="updateURL"
  [isOpen]="loteIsOpen"
  [title]="title"
/>
