<p-dialog
  style="width: 100%"
  [header]="'Linhas Móveis'"
  [(visible)]="isOpen"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  (onHide)="fecharModal()"
  [draggable]="false"
  [resizable]="false"
>
  <ng-container [ngTemplateOutlet]="linhas"></ng-container>
  <ng-template pTemplate="footer">
    <div style="display: flex; justify-content: flex-end; gap: 10px;">
      <app-action-btn
        [icon]="'fa fa-times font-size-20 '"
        [type]="'outlined'"
        [text]="'Limpar'"
        (click)="limparValor()"
      ></app-action-btn>
      <app-action-btn
        [icon]="'fa fa-check font-size-20 '"
        [type]="''"
        [text]="labelBtn"
        (click)="selecionarValor()"
      ></app-action-btn>
    </div>
  </ng-template>
</p-dialog>

<ng-template #linhas>
  <app-table
    [data]="data$"
    [grid]="grid"
    [isOpen]="isOpen"
    (getData)="sendData($event)"
    (openModal)="editModal()"
    [title]="''"
    [dataTableHeaders]="dataTableHeaders"
    [exibirCheckbox]="false"
    [filterContext]="'ativoLinha'"
    [tipoSearch]="0"
    [exibirFilterButton]="false"
    [placeholderPesquisaGlobal]="'Pesquisa em Linhas Móveis'"
  >
    <div
      class="dataTable-body"
      [ngStyle]="{ 'grid-template-columns': grid }"
      *ngFor="let items of tableRow$ | async; let i = index"
    >
      <div>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="items.isChecked"
          [disabled]="!items.isEnabled"
          (click)="checkOne(i)"
          [(ngModel)]="items[i]"
        />
      </div>
      <div>{{ items.descricao }}</div>
      <div
        style="display: flex; flex-direction: column; align-items: flex-start"
      >
        <span
          style="width: 80%; margin-bottom: 3px"
          [ngClass]="
            items.status == 0
              ? 'active badge bg-danger me-1'
              : items.status == 1
              ? 'unactive badge bg-success me-1'
              : items.status == 2
              ? 'unactive badge bg-warning me-1'
              : items.status == 3
              ? 'unactive badge bg-warning me-1'
              : items.status == 4
              ? 'unactive badge bg-success me-1'
              : 'unactive badge bg-danger me-1'
          "
        >
          {{
            items.status == 0
              ? "INATIVA"
              : items.status == 1
              ? "ATIVA"
              : items.status == 2
              ? "BLOQUEADA GESTOR"
              : items.status == 3
              ? "BLOQUEIO PRE ATIVAÇÃO"
              : items.status == 4
              ? "REATIVADA APOS SUSPENSÃO"
              : "SUSPENSÃO"
          }}
        </span>
        <span
          style="width: 80%"
          [ngClass]="
            items.estoque == 0
              ? 'active badge bg-danger me-1'
              : 'active badge bg-info me-1'
          "
        >
          {{ items.estoque == 0 ? "NÃO DISPONÍVEL" : "DISPONÍVEL" }}
        </span>
      </div>
      <div>{{ items.entidade?.razaoSocial }}</div>
      <div>{{ items.titularidade?.razaoSocial }}</div>
      <div>{{ items.fornecedor?.nomeUsual }}</div>
      <div>{{ items.perfilCatalogo?.descricao }}</div>
    </div>
  </app-table>
</ng-template>

<!-- <ng-template #linhas>
  <p-table
    #dt
    [value]="ativoLinhaMovelList$ | async"
    [paginator]="true"
    [showCurrentPageReport]="true"
    [(selection)]="linhaSelecionada"
    selectionMode="single"
    dataKey="ativoLinhaId"
    [tableStyle]="{ 'min-width': '50rem' }"
    [rows]="(pagination$ | async)?.pageSize"
    (rowsChange)="alterarLinhas($event)"
    (onPage)="alterarPagina($event)"
    [rowsPerPageOptions]="[15, 30, 50, 100, 500, 1000]"
    currentPageReportTemplate="Mostrando {first} - {last} de {totalRecords}"
    [globalFilterFields]="[
      'descricao',
      'entidade.razaoSocial',
      'titularidade.razaoSocial',
      'perfilLinha.descricao',
      'identificacao'
    ]"
  >
    <ng-template pTemplate="caption">
      <div
        style="display: flex; justify-content: flex-end; padding: 0px"
        class="table-header"
      >
        <div class="global-search">
          <p>Lista de Linhas Móveis</p>
          <span class="p-input-icon-left">
            <i class="bx bx-search-alt"></i>
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Pesquisa Global"
            />
          </span>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width: 1%"></th>
        <th></th>
        <th pSortableColumn="descricao">Nome</th>
        <th pSortableColumn="status">Situação</th>
        <th pSortableColumn="entidade.razaoSocial">Entidade</th>
        <th pSortableColumn="titularidade.razaoSocial">Titularidade</th>
        <th pSortableColumn="fornecedor.nomeUsual">Fornecedor</th>
        <th pSortableColumn="perfilLinha.descricao">Perfil</th>
        <th pSortableColumn="identificacao">Chamado</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-retorno>
      <tr [pSelectableRow]="retorno" *ngIf="retorno?.ativoLinhaId">
        <td>
          <p-tableCheckbox
            [pSelectableRow]="retorno"
            [value]="retorno"
          ></p-tableCheckbox>
        </td>
        <td>
          <button
            class="table-edit-btn"
            [routerLink]="
              '/tem/ativoLinha/movel/form/' + retorno.ativoLinhaId
            "
            style="font-size: 1.5em; color: rgb(87, 87, 87)"
          >
            <i class="mdi mdi-pencil"></i>
            <p>Editar</p>
          </button>
        </td>
        <td>{{ retorno.descricao ?? "" }}</td>
        <td>
          <div style="display: flex; align-items: center">
            <span
              style="width: 50px"
              [ngClass]="
                retorno.status == 0
                  ? 'active badge bg-danger me-1'
                  : retorno.status == 1
                  ? 'unactive badge bg-success me-1'
                  : retorno.status == 2
                  ? 'unactive badge bg-warning me-1'
                  : retorno.status == 3
                  ? 'unactive badge bg-primary me-1'
                  : retorno.status == 4 || retorno.status == 5
                  ? 'unactive badge bg-danger me-1'
                  : 'unactive badge bg-warning me-1'
              "
            >
              {{
                retorno.status == 0
                  ? "INATIVO"
                  : retorno.status == 1
                  ? "ATIVO"
                  : retorno.status == 2
                  ? "DESCARTE"
                  : retorno.status == 3
                  ? "NOVO"
                  : retorno.status == 4
                  ? "REPARO"
                  : retorno.status == 5
                  ? "ROUBO"
                  : "USADO"
              }}
            </span>
            <span
              style="width: 100px"
              [ngClass]="
                retorno.estoque == 0
                  ? 'active badge bg-danger me-1'
                  : 'active badge bg-info me-1'
              "
            >
              {{
                retorno.estoque == 0 ? "FORA DE ESTOQUE" : "EM ESTOQUE"
              }}
            </span>
          </div>
        </td>
        <td>{{ retorno.entidade.razaoSocial }}</td>
        <td>{{ retorno.titularidade.razaoSocial }}</td>
        <td>{{ retorno.fornecedor?.nomeUsual }}</td>
        <td>{{ retorno.perfilCatalogo?.descricao }}</td>
        <td>{{ retorno.identificacao }}</td>
      </tr>
    </ng-template>
    <ng-template pTemplate="paginatorleft">
      <p-button
        type="button"
        icon="pi pi-plus"
        styleClass="p-button-text"
      ></p-button>
    </ng-template>
    <ng-template pTemplate="paginatorright">
      <p-button
        type="button"
        icon="pi pi-cloud"
        styleClass="p-button-text"
      ></p-button>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">Nenhuma linha encontrada</td>
      </tr>
    </ng-template>
  </p-table>
</ng-template> -->
