import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EnumsService {
  baseUrl = environment.apiBaseHom + "/enums";

  constructor(private http: HttpClient) {}

  GetEntidade = (): Observable<any[]> =>
    this.http.get<any[]>(`${this.baseUrl}/GetEntidade`);
  GetTipoAtivo = (): Observable<any[]> =>
    this.http.get<any[]>(`${this.baseUrl}/GetTipoAtivo`);
  GetCategoriaAtivo = (): Observable<any[]> =>
    this.http.get<any[]>(`${this.baseUrl}/GetCategoriaAtivo`);
  GetModeloDispositivo = (): Observable<any[]> =>
    this.http.get<any[]>(`${this.baseUrl}/GetModeloDispositivo`);
  GetFabricanteDispositivo = (): Observable<any[]> =>
    this.http.get<any[]>(`${this.baseUrl}/GetFabricanteDispositivo`);
  GetServico = (): Observable<any[]> =>
    this.http.get<any[]>(`${this.baseUrl}/GetServico`);
  GetFornecedor = (): Observable<any[]> =>
    this.http.get<any[]>(`${this.baseUrl}/GetFornecedor`);
}
