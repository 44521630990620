import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Table } from "primeng/table";
import { Subscription } from "rxjs";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { ServicoEnum } from "src/app/core/models/enums/servicoEnum";
import { Pagination } from "src/app/core/models/pagination.model";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { EntidadeStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/entidade.store.service";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { AtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model";
import { STATUS } from "src/app/modulos/telecom/ativo-fisico/models/enum/status.enum";
import { UsuarioEntidadeService } from "src/app/modulos/telecom/cadastro/core/services/usuarioEntidade.service";

@Component({
  selector: "app-modal-list-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.css"],
})
export class UsuariosModalComponent {
  chipSelecionado: AtivoFisico;

  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<any>();
  @Output() limparValorEvent = new EventEmitter<any>();

  @Input() entidade: number;
  @Input() entidadeId: number;
  @Input() labelBtn: string = "Selecionar";
  @Input() servico: ServicoEnum = null;

  breadCrumbItems: Array<{}>;
  tooltipText = "pesquisa da pagina atual";

  updateChip: FormGroup;

  isOpen: boolean = true;

  grid: string =
    "minmax(50px, 0.5fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)";
  dataTableHeadersUsuarios: string[] = [
    "Ação",
    "Entidade",
    "Unidade",
    "Nome Completo",
    "Matrícula",
    "Área",
    "Centro de Custo",
    "Cargo",
    "E-mail",
    "Data de Cadastro",
    "Status",
  ];

  isChecked: boolean = false;

  enumTeste = SERVICES_NAMES;

  valorSelecionado!: any;

  formSelects: FormGroup;

  basePagination: Pagination = { pageNumber: 1, pageSize: 50 };

  entidadeIdSelecionada: number = 0;
  titularidadeIdSelecionada: number = 0;

  private subscription: Subscription[] = [];

  get data$() {
    return this.paginatorStore.dataObservable$;
  }

  get tableRow$() {
    return this.tableStore.tableRow$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  get entidadeList$() {
    return this.entidadeStoreService.entidadeList$;
  }

  @ViewChild("showModal", { static: false }) showModal?: ModalDirective;
  @ViewChild("dt", { static: true }) dt: Table;
  @ViewChild("removeItemModal", { static: false })
  removeItemModal?: ModalDirective;

  constructor(
    private paginatorStore: PaginatorGenericStoreService,
    private toastr: ToastrService,
    private tableStore: TableStoreService,
    private usuarioEntidadeService: UsuarioEntidadeService,
    private entidadeStoreService: EntidadeStoreService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.formSelects = this.fb.group({
      entidade: [null],
      titularidade: [null],
    });

    this.subscription.push(
      this.rowsSelected.subscribe((data: any) => (this.valorSelecionado = data))
    );
  }
  sendData(event$: { paginator: Pagination; searchText: string }) {
    if (event$.searchText === null) {
      this.paginatorStore.getDataObservable(
        this.usuarioEntidadeService.getUsuariosEntidadeModal(
          event$.paginator,
          this.entidadeId,
          this.servico
        )
      );
    } else {
      this.paginatorStore.getDataObservable(
        this.usuarioEntidadeService.getPesquisaTodosUsuarioEntidadeModal(
          event$.paginator,
          event$.searchText,
          this.entidadeId
        )
      );
    }
  }

  // Transformar numero em texto
  transformarStatus(valor: number) {
    return STATUS[valor];
  }

  checkOne(index: number) {
    this.tableStore.checkOne(index, true);
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  selecionarValor() {    
    this.chipSelecionado = this.valorSelecionado[0];

    if (this.chipSelecionado.estoque === 0) {
      this.toastr.error(
        "Ativo não está em estoque, selecione outro",
        "Chip Inválido"
      );
      return;
    }

    this.selecionarValorEvent.emit(this.chipSelecionado);
    this.fecharModal();
  }

  limparValor() {
    this.limparValorEvent.emit();
  }
}
