<p-dialog
  style="width: 100%"
  [(visible)]="isOpen"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <ng-template pTemplate="header">{{ title }}</ng-template>
  <div>{{ text }}</div>
  @if(textAuxiliar !== ""){
  <div>{{ textAuxiliar }}</div>
  }
  <div style="margin-top: 10px"><ng-content /></div>
  <ng-template pTemplate="footer">
    <div class="btn-group">
      <app-action-btn
        [type]="'danger'"
        [icon]="'mdi mdi-close'"
        [text]="labelBotaoCancelar"
        [isDisabled]="desabilitado"
        (click)="fecharModal(false)"        
        *ngIf="exibirBotaoCancelar"
      ></app-action-btn>
      <app-action-btn
        *ngIf="exibirBotaoConfirmar"
        [type]="'success'"
        [icon]="'mdi mdi-check'"
        [text]="labelBotaoConfirmar"
        [isDisabled]="desabilitado"
        (click)="fecharModal(true)"
        id="confirmActionBtnModal"
      ></app-action-btn>
    </div>
  </ng-template>
</p-dialog>
