import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, of } from "rxjs";
import { Pagination } from "src/app/core/models/pagination.model";
import { environment } from "src/environments/environment";
import { PerfilLinha } from "../models/ativos-linha/perfilLinha.model";
import { PerfilFixaDados } from "../models/perfilFixaDados.model";
import { PerfilCatalogo } from "../models/ativos-linha/perfilCatalogo.model";

@Injectable({
  providedIn: "root",
})
export class PerfilCatalogoService {
  baseUrl = environment.apiBaseHom + "/perfilcatalogo";

  constructor(private http: HttpClient) {}

  getPerfilTodos(
    pagination: Pagination,
    entidadeId: number
  ): Observable<PerfilCatalogo[]> {
    return this.http.get<PerfilCatalogo[]>(
      `${this.baseUrl}/listperfillinha?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }


  
  getPerfilAtivoFisico(    
  ): Observable<PerfilCatalogo[]> {
    return this.http.get<PerfilCatalogo[]>(
      `${this.baseUrl}/ListPerfilAtivoFisico`
    );
  }

  getPerfilCatalogoPesquisaTodos(
    searchText: string,
    pagination: Pagination,
    entidadeId: number
  ): Observable<PerfilCatalogo[]> {
    return this.http.get<PerfilCatalogo[]>(
      `${this.baseUrl}/perfilcatalogopesquisatodos?searchText=${searchText}&EntidadeId=${entidadeId}&UnidadeId=1&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPerfilPorFornecedor(
    pagination: Pagination,
    entidadeId: number,
    fornecedorId: number,
    servicoId: number
  ): Observable<PerfilCatalogo[]> {
    return this.http.get<PerfilCatalogo[]>(
      `${this.baseUrl}/listperfilcatalogofornecedor?EntidadeId=${entidadeId}&servicoId=${servicoId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&fornecedorId=${fornecedorId}`
    );
  }

  getPerfilPorFornecedorEntidade(
    pagination: Pagination,
    entidadeId: number,
    fornecedorId: number,
    servicoId: number,
    titularidadeId: number
  ): Observable<PerfilCatalogo[]> {
    return this.http.get<PerfilCatalogo[]>(
      `${
        this.baseUrl
      }/ListPerfilCatalogoEntidadeFornecedorModal?entidadeSelecionadaId=${entidadeId}&servicoId=${servicoId}&PageNumber=${
        pagination.pageNumber
      }&PageSize=${
        pagination.pageSize
      }&fornecedorId=${fornecedorId}&titularidadeId=${1 ?? 0}`
    );
  }

  PerfilCatalogoEntidadeFornecedorModalPesquisaTodos(
    pagination: Pagination,
    searchText: string,
    entidadeId: number,
    fornecedorId: number,
    servicoId: number,
    titularidadeId: number
  ): Observable<{
    listaPerfil: PerfilCatalogo[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaPerfil: PerfilCatalogo[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/PerfilCatalogoEntidadeFornecedorModalPesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&servicoLinhaId=${servicoId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&fornecedorId=${fornecedorId}&titularidadeId=${titularidadeId}`
    );
  }

  getPerfilById(id: number): Observable<PerfilCatalogo> {
    return this.http.get<PerfilCatalogo>(
      `${this.baseUrl}/getoneperfilcatalogo?id=${id}`
    );
  }

  addPerfilCatalogo(
    perfilCatalogo: PerfilCatalogo
  ): Observable<PerfilCatalogo> {
    return this.http.post<PerfilCatalogo>(
      `${this.baseUrl}/insereperfilcatalogo`,
      perfilCatalogo
    );
  }

  updatePerfilCatalogo(
    perfilCatalogo: PerfilCatalogo
  ): Observable<PerfilCatalogo> {
    return this.http.put<PerfilCatalogo>(
      `${this.baseUrl}/atualizaperfilcatalogo`,
      perfilCatalogo
    );
  }

  deletePerfilCatalogo(perfilCatlogoId: number): Observable<PerfilCatalogo | boolean> {
    return this.http.delete<PerfilCatalogo>(`${this.baseUrl}/${perfilCatlogoId}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((err) => {
        return of(false);
      })
    );
  }
}
