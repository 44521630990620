import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FileItem, FileUploader, ParsedResponseHeaders } from "ng2-file-upload";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { AlterEntidadeService } from "src/app/modulos/telecom/ativo-fisico/core/service/alter-entidade.service";
import { HttpEventType, HttpResponse } from "@angular/common/http";
import { FileService } from "../../services/file.service";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { PaginatorStoreService } from "src/app/core/store/paginator.store.service";
import { NgSelectComponent } from "@ng-select/ng-select";
import { ProgessCsvService } from "../../services/progess-csv.service";

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.scss"],
  providers: [AlterEntidadeService],
})
export class FileUploadComponent {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Input() isOpen: boolean = false;
  @Input() title: string;
  @Input() insertURL: string = "";
  @Input() updateURL: string = "";
  @Output() closeModal = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Input() dadosArquivo: any;

  errorList = [];
  isUpdate = false;
  baseUrl = `${environment.apiBaseHom}${this.insertURL}`;
  hasBaseDropZoneOver: boolean;
  uploader: FileUploader;
  fileName = "";
  isDragOver = false;
  progress = 0;
  message = "";
  loading: boolean = false;
  uploadedFiles: any[] = [];
  currentFile?: File;
  load = false;
  metodo: any = null;

  constructor(
    private toastr: ToastrService,
    private fileService: FileService,
    private alterEntidadeService: AlterEntidadeService,
    private paginationGenericStore: PaginatorGenericStoreService,
    private paginationStore: PaginatorStoreService,
    private progressCSVService : ProgessCsvService
  ) {}

  ngOnInit() {
    this.baseUrl = `${environment.apiBaseHom}${this.insertURL}`;
    this.uploader = this.newUploader();
  }

  onSelectFile(): string {
    if (this.uploader.queue.length === 0) return "Choose file";
    else {
      this.fileName = "";
      var uploader = this.uploader.queue;
      for (let i = 0; i < uploader.length; i++) {
        this.fileName = this.fileName.concat(uploader[i].file.name, ", ");
      }
      return this.fileName;
    }
  }

  fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  upLoad() {
    console.log('this.uploader:',this.uploader)
    this.uploader?.queue[0]?.upload();
    this.load = true;
    this.loading = true;

    // this.metodo = null;
  }

  retornaModal() {
    this.closeModal.emit("nome arquivo");
  }

  onDragEnter() {
    this.isDragOver = true;
  }

  onDragLeave() {
    this.isDragOver = false;
  }

  closeLoteModal() {
    this.errorList = [];
    this.uploader = this.newUploader();
    this.closeModal.emit();

    this.metodo = null;
    this.ngSelectComponent.handleClearClick();
  }

  // uploadFiles() {
  //   this.loading = true;
  //   this.fileService
  //     .upload(this.uploadedFiles, "faturas-claro",this.fi )
  //     .subscribe({
  //       next: (event: any) => {
  //         if (event.type === HttpEventType.UploadProgress) {
  //           this.progress = Math.round((100 * event.loaded) / event.total);
  //         } else if (event instanceof HttpResponse) {
  //           this.message = event.body.message;
  //         }
  //       },
  //       error: (err: any) => {
  //         if (err.error && err.error.message) {
  //           this.message = err.error.message;
  //         } else {
  //           this.message = "Could not upload the file!";
  //         }

  //         this.currentFile = undefined;
  //         this.progress = 0;
  //       },
  //       complete: () => {
  //         this.loading = false;
  //         this.currentFile = undefined;
  //       },
  //     });
  // }

  onTabChange() {
    this.isUpdate = !this.isUpdate;
    this.baseUrl = `${environment.apiBaseHom}${
      this.isUpdate ? this.updateURL : this.insertURL
    }`;
    this.uploader = this.newUploader();
  }

  mudarLote(event) {
    console.log(event)

    this.metodo = event;

    this.baseUrl = `${environment.apiBaseHom}${
      event === 1 ? this.updateURL : this.insertURL
    }`;
    this.uploader = this.newUploader();

    console.log(this.metodo)
  }

  private newUploader(): FileUploader {
    this.errorList = [];
    this.load = false;

    const fileUpdloader = new FileUploader({
      url: this.baseUrl,
      headers: [
        {
          name: "Authorization",
          value: `Bearer ${localStorage.getItem("token")}`,
        },
      ],
      disableMultipart: true,
      formatDataFunctionIsAsync: false,
      formatDataFunction: (item) => {
        const formData: FormData = new FormData();
        formData.append("FileDetails", item.file.rawFile);
        formData.append("FileType", "4");
        formData.append("FileTipoAtivo", "1");
        formData.append("FileOperadora", "1");
        formData.append("NomeEntidade", "");

        this.progressCSVService.showProgressCsvSubject.next({
          value: true,
          metodo: 'Upload'
        })

        return formData;
      },
    });

    this.newUploaderMetods(fileUpdloader);

    return fileUpdloader;
  }

  private newUploaderMetods(fileUpdloader: FileUploader) {
    fileUpdloader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      this.uploader = this.newUploader();

      this.errorList.push(...JSON?.parse(response));

      item.isError = true;
      this.toastr.warning(
        "Não foi posivel terminar o processamento do arquivo",
        "Operação Inválida",
        { timeOut: 5000, progressBar: true }
      );

      this.progressCSVService.showProgressCsvSubject.next({
        value: false,
        metodo: 'Upload'
      })

      return item;
    };

    fileUpdloader.onCompleteItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      this.loading = false;


      this.progressCSVService.showProgressCsvSubject.next({
        value: false,
        metodo: 'Upload'
      })

      if (status === 304) {
        this.uploader.queue[0].url += "?AcceptChangeEntidade=true";
        this.alterEntidadeService.confirmarDelecao();
        return;
      }

      if (status === 200) {
        this.load = false;
        this.uploader = this.newUploader();

        this.toastr.success(
          "Processo realizado com sucesso",
          "Operação bem sucedida"
        );
        this.paginationGenericStore.firstOrLastPage(true);
        this.paginationStore.firstOrLastPage(true);
        // this.closeModal.emit();
        this.closeModal.emit();

        this.metodo = null;
      }
    };
  }
}
