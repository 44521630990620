<!-- src/app/components/app-stat/app-stat.component.html -->
<div class="card mini-stats-wid">
  <div class="card-body">
    <div class="d-flex">
      <div class="flex-grow-1">
        <p class="text-muted fw-medium">{{title}}</p>
        <h4 class="mb-0">{{value}}</h4>
      </div>
      <div class="flex-shrink-0 align-self-center">
        <div class="mini-stat-icon avatar-sm rounded-circle custom-bg">
          <span class="avatar-title">
            <i class="bx {{icon}} font-size-24 custom-icon" routerLink="{{route}}" ></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!-- end card-body -->
<!-- </div> -->
<!-- end card -->

<!-- <div class="stat-container">
  <div class="stat-content">
    <div class="stat-header">
      <h2>{{title}}</h2>
    </div>
    <div class="stat-stock">
      <div class="stat-out-of-stock">

      </div>
      <div class="stat-in-stock">

      </div>
    </div>
  </div>
  <i class="bx {{icon}} font-size-24 custom-icon" routerLink="{{route}}" ></i>
</div> -->
