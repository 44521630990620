import { TokenStorageService } from './../services/token-storage.service';
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";

import { AuthenticationService } from "../services/auth.service";
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  public entidadeQueryParam: number = 0;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private tokenStorageService: TokenStorageService,
  ) {
    const urlObj = new URL(window.location.href);
    const params = new URLSearchParams(urlObj.search);
    const entidadeId = params.get('entidadeId');
    this.entidadeQueryParam = +entidadeId;    
   }


  entidadeAtual = this.tokenStorageService.getUser().entidadeId;

  substituirEntidadeId(input) {    
    const actualRoute = this.router.url;
    const inputFormatter = input.match(/\/api\/mobit\/v1\/contaFatura\/ListContaFaturaModal/)?.[0];
    const routeModalConta = actualRoute === "/tem/ativoLinha/movel/lista"

    if(routeModalConta && inputFormatter) {
      return "";
    }

    // if(inputFormatter && thi)
    

    // if (!window.location.href.includes("form")) {
      let entidadeSelecionada = localStorage.getItem("entidadeSelecionada");
      // Expressão regular para encontrar o padrão EntidadeId=qualquer_coisa& ou suas variações
      const regex =
        /EntidadeId=[^&]*&|EntidadeID=[^&]*&|entidadeID=[^&]*&|entidadeid=[^&]*&|entidadeId=[^&]*&/gi;

      // Função de callback para substituir pelo mesmo padrão encontrado
      const resultado = input.replace(regex, (match) => {
        // Identificar a parte antes do '=' e substituir pelo valor de entidadeSelecionada
        return match.split("=")[0] + "=" + entidadeSelecionada + "&";
      });
      
      return resultado;
    // }
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // if (environment.defaultauth === 'firebase') {
    //     // add authorization header with jwt token if available
    //     let currentUser = this.authenticationService.currentUser();
    //     if (currentUser && currentUser.accessToken) {
    //         request = request.clone({
    //             setHeaders: {
    //                 Authorization: `Bearer ${currentUser.accessToken}`,
    //             },
    //         });
    //     }
    // } else {
    // add authorization header with jwt token if available
    const currentUser = this.authenticationService.currentUserValue;

    if (+this.entidadeAtual === 1 && !window.location.href.includes("form") && !this.entidadeQueryParam && this.entidadeQueryParam !== 1 && currentUser && currentUser.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.accessToken}`,
        },
        url: this.substituirEntidadeId(request.url),
      });
    }

    if (currentUser && currentUser.accessToken) {      
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.accessToken}`,
          url: this.substituirEntidadeId(request.url),
        },
      });
    }
    //}
    return next.handle(request);
  }
}
