@for (item of menuItems; track $index) {
    <div class="accordion-container">
        <div 
            class="accordion-header"
            id="{{!item?.isModulo && item?.id}}" 
        >
            @if (item.link) {
                <!-- se obter link é um botão -->
                <div class="icon-text-wrapper" id="{{item?.id}}">
                    @if (item?.icone) {
                        <i class="{{item.icone}}" [ngClass]="item?.isOpen ? 'isSelected' : ''"></i>
                    }
                    <a [routerLink]="item.link" [routerLinkActive]="'isSelected'" id="{{item?.id}}" class="link-title" style="padding-bottom: 10px;">
                        {{item.titulo}}
                    </a>
                </div>
            } @else {
                @if (item?.isModulo) {
                    <!-- se for um modulo -->
                    <p [ngClass]="'module-title'">{{item.titulo}}</p>
                    <app-accordion-menu-oficial [menuItems]="item.subItems"></app-accordion-menu-oficial>
                } @else {
                    @if (item.subItems.length > 0) {
                         <!-- se for um accordion -->
                        <p-accordion [activeIndex]="item?.isOpen ? 0 : -1" (onOpen)="clickOnAccordion(item,true)" (onClose)="clickOnAccordion(item,false)" id="vertical-menu-accordion-tab">
                            <p-accordionTab>
                                <ng-template pTemplate="header">
                                    <div class="accordion-tab-title">
                                        <i class="{{item?.icone}}" [ngClass]="item?.isOpen ? 'isSelected' : ''"></i>
                                        <p id="{{!item?.isModulo && item?.id}}" class="accordion-title" [ngClass]="item?.isOpen ? 'isSelected' : ''">
                                            {{ item.titulo }}
                                        </p>
                                    </div>
                                </ng-template>
                                <app-accordion-menu-oficial [menuItems]="item.subItems"></app-accordion-menu-oficial>
                            </p-accordionTab>
                        </p-accordion>
                    }
                }
            }
    
        </div>
    </div>
}