import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AtivoFisico } from 'src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model';
import { NotaFiscal } from 'src/app/modulos/telecom/ativo-fisico/models/notaFiscal.model';

@Injectable({
  providedIn: 'root'
})
export class ModalTabsService {

  constructor(private formBuilder: FormBuilder) { }

  getAtivoFisicoForm() {
    return this.formBuilder.group({
      nome: [null],
      entidade: [null],
      tipo: [null],
      status: [null],
      estoque: [null],
      localizacaoUnidadeEntidade: [null],
      fabricante: [{value: null, disabled: true}],
      chamadoIdentificacao: [null],
      chamadoReservado: [null],
      numeroLinha: [null],
      ddd: [null],
      hlr: [null],
      titularidade: [null],
      alocacaoUnidadeEntidade: [null],
      centroCustoEntidade: [null],

      observacao: [null],
      numeroNotaEntrada: [null],
      tipoNotaEntrada: [null],
      posicao: [null],
      valorNotaEntrada: [null],
      dataEmissaoEntrada: [null],
      modeloDispositivoId: [null],

      numeroNotaSaida: [null],
      tipoNotaSaida: [null],
      // motivoSaida: [null],
      valorNotaSaida: [null],
      dataEmissaoSaida: [null],
      // Vinculo Usuário
      nomeUsuario: [{value: null, disabled: true}],
      documentoUsuario: [{value: null, disabled: true}],
      matriculaUsuario: [{value: null, disabled: true}],
      vip: [{value: null, disabled: true}],
      // Vínculo Dispositivo
      tipoDispositivo: [{value: null, disabled: true}],
      marcaDispositivo: [{value: null, disabled: true}],
      modeloDispositivo: [{value: null, disabled: true}],
      imeiDispositivo: [{value: null, disabled: true}],
      // Vínculo Linha
      operadora: [{value: null, disabled: true}],
      numeroLinhaVinculo: [{value: null, disabled: true}],
      dddVinculo: [{value: null, disabled: true}],
      perfilLinha: [{value: null, disabled: true}],
      // Novos vínculos
      categoriaAtivo: [null],
      tipoAtivo: [{value: null, disabled: true}],
      fabricanteVinculo: [{value: null, disabled: true}],
      identificacaoAtivo: [{value: null, disabled: true}],
    });
  }

  pegarDadosChip(
    updateAtivo: FormGroup,
    nomeUsuario: string,
  ): any {
    // Verificar se o if funciona corretamente, depois adicionar validators no HTML

    var notasFiscais: NotaFiscal[] = [
      // Nota de Entrada
      {
        categoriaNF: "E",
        numeroNF: updateAtivo.get(["numeroNotaEntrada"]).value,
        tipoNF: updateAtivo.get(["tipoNotaEntrada"]).value,
        valorNF: updateAtivo.get(["valorNotaEntrada"]).value,
        dataNF: updateAtivo.get(["dataEmissaoEntrada"]).value,
        // Sem produto na NF de entrada?
        // produto: updateAtivo.get(['produto']).value,
        ativo: true,
        usuarioCadastro: nomeUsuario,
        usuarioLog: nomeUsuario,
      },
      // Nota de Saída
      {
        categoriaNF: "S",
        numeroNF: updateAtivo.get(["numeroNotaSaida"]).value,
        tipoNF: updateAtivo.get(["tipoNotaSaida"]).value,
        // motivoSaida: updateAtivo.get(["motivoSaida"]).value,
        valorNF: updateAtivo.get(["valorNotaSaida"]).value,
        dataNF: updateAtivo.get(["dataEmissaoSaida"]).value,
        // produto: updateAtivo.get(['produto']).value,
        ativo: true,
        usuarioCadastro: nomeUsuario,
        usuarioLog: nomeUsuario,
      },
    ];

    let _identificacao = updateAtivo.get(["chamadoIdentificacao"]).value;
    _identificacao = _identificacao?.replace(/\s+/g, '')

    let _reservado = updateAtivo.get(["chamadoReservado"]).value;
    _reservado = _reservado?.replace(/\s+/g, '')

    const itemAtivoFisico: any = {
      tipoAtivoId: updateAtivo.get(["tipo"]).value,
      entidadeId: updateAtivo.get(["entidade"]).value,
      alocacaoEntidadeId: updateAtivo.get(["alocacaoUnidadeEntidade"]).value === updateAtivo.get(["entidade"]).value ?updateAtivo.get(["alocacaoUnidadeEntidade"]).value:null,
      alocacaoUnidadeEntidadeId: updateAtivo.get(["alocacaoUnidadeEntidade"]).value !== updateAtivo.get(["entidade"]).value ?updateAtivo.get(["alocacaoUnidadeEntidade"]).value:null,
      status: updateAtivo.get(["status"]).value,
      
      titularidadeId: updateAtivo.get(["titularidade"]).value,
      localidadeEntidadeId: updateAtivo.get(["localizacaoUnidadeEntidade"]).value === updateAtivo.get(["entidade"]).value ? updateAtivo.get(["localizacaoUnidadeEntidade"]).value : null,
      localidadeUnidadeEntidadeId: updateAtivo.get(["localizacaoUnidadeEntidade"]).value !== updateAtivo.get(["entidade"]).value ?updateAtivo.get(["localizacaoUnidadeEntidade"]).value:null,
      


      // Pedir devolução na hora do cadastro?
      numeroLinha: updateAtivo.get(["numeroLinha"]).value,
      ddd: updateAtivo.get(["ddd"]).value,
      hlr: updateAtivo.get(["hlr"]).value,
      identificacao: _identificacao ?? updateAtivo.get(["chamadoIdentificacao"]).value,
      chamadoReservado: _reservado ?? updateAtivo.get(["chamadoReservado"]).value,
      comentarios: updateAtivo.get(["observacao"]).value,
      fabricanteDispositivoId: updateAtivo.get(["fabricante"])?.value,
      modeloDispositivoId: updateAtivo.get(["modeloDispositivoId"])?.value,

      // Notas Fiscais
      notasFiscais: notasFiscais.length > 0 ? notasFiscais : null,

      usuarioLog: nomeUsuario,
      estoque: updateAtivo.get(["estoque"]).value,
      valorNF: updateAtivo.get(["valorNotaEntrada"]).value,
      valorNFsaida: updateAtivo.get(["valorNotaSaida"]).value
    };
    return itemAtivoFisico;
  }

  getAtivoFisicoDispositivoForm() {
    return this.formBuilder.group({

    })
  }

  getAtivoFisicoAcessorioForm() {
    return this.formBuilder.group({

    })
  }
}
