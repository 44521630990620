<p-dialog
  header="Header"
  [(visible)]="isOpen"
  [modal]="true"
  [style]="{ width: '70rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  (onHide)="editModal()"
  [draggable]="false"
>
  <ng-template pTemplate="header">
    <div class="inline-flex align-items-center justify-content-center gap-2">
      <span class="font-bold white-space-nowrap">{{ title }}</span>
    </div>
  </ng-template>
  <ng-container
    [ngTemplateOutlet]="ativoTitle === 'Movel' ? movel : fixalinkdados"
  ></ng-container>
  <ng-template pTemplate="footer">
    <div style="display: flex; justify-content: flex-end">
      <app-action-btn
        icon="fa fa-check font-size-20"
        type=""
        text="Salvar"
        (click)="preSubmit()"
      />
    </div>
  </ng-template>
</p-dialog>

<ng-template #movel>
  <form [formGroup]="formAtual" *ngIf="formAtual">
    <div class="input-wrapper">
      @if(mostrarCamposRelacionadosEntidade) {
      <div class="input-default input-xl">
        <label for="entidade">Entidade</label>
        <ng-select
          formControlName="entidade"
          [items]="entidadesList$ | async"
          bindLabel="razaoSocial"
          bindValue="entidadeId"
          placeholder="Escolha..."
          (change)="mudarEntidade($event)"
        >
        </ng-select>
      </div>
      <div class="input-default input-xl">
        <label for="titularidade">Titularidade</label>
        <ng-select
          formControlName="titularidade"
          [items]="filtredList$ | async"
          bindLabel="razaoSocial"
          bindValue="entidadeId"
          placeholder="Escolha..."
          (change)="mudarTitularidade($event)"
        >
        </ng-select>
      </div>
      }
      <div class="input-default input-md">
        <label for="status">Status</label>
        <ng-select formControlName="status" placeholder="Escolha...">
          <ng-option [value]="0">INATIVA</ng-option>
          <ng-option [value]="1">ATIVA</ng-option>
          <ng-option [value]="2">BLOQUEADA GESTOR </ng-option>
          <ng-option [value]="3">BLOQUEIO PRE ATIVAÇÃO </ng-option>
          <ng-option [value]="4">REATIVADA APOS SUSPENSÃO </ng-option>
          <ng-option [value]="5">SUSPENSÃO </ng-option>
        </ng-select>
      </div>
      <div class="input-default input-xl">
        <label for="fornecedor">Fornecedor</label>
        <ng-select
          formControlName="fornecedor"
          [items]="fornecedorList$ | async"
          bindLabel="nomeUsual"
          bindValue="fornecedorId"
          placeholder="Escolha..."
          (change)="mudarFornecedor($event)"
        >
        </ng-select>
      </div>

      @if(mostrarCamposRelacionadosEntidade) {
      <div class="input-default com-select input-lg">
        <label for="conta">Conta</label>
        <input
          formControlName="conta"
          type="text"
          id="conta"
          placeholder="Conta"
        />
        <div class="abrir-modal" (click)="editModalConta()">
          <i class="bx bx-plus"></i>
        </div>
      </div>
      <div class="input-default com-select input-lg">
        <label for="perfil">Perfil</label>
        <input
          formControlName="perfil"
          type="text"
          id="perfil"
          placeholder="Perfil"
        />
        <div class="abrir-modal" (click)="editModalPerfil()">
          <i class="bx bx-plus"></i>
        </div>
      </div>
      }
      <!-- <div class="input-default">
        <label for="ddd">DDD</label>
        <input formControlName="ddd" type="text" id="ddd" placeholder="DDD" />
      </div>
      <div class="input-default">
        <label for="uf">UF</label>
        <input
          formControlName="uf"
          type="text"
          id="uf"
          placeholder="UF"
          [maxLength]="2"
          appUppercase
        />
      </div> -->
      @if(mostrarCamposRelacionadosEntidade) {
      <div class="input-default com-select">
        <label for="pacoteRI">Pacote RI</label>
        <input
          style="width: unset"
          formControlName="pacoteRI"
          type="text"
          id="pacoteRI"
          placeholder="Pacote"
        />
        <div class="abrir-modal" (click)="editModalPacote()">
          <i class="bx bx-plus"></i>
        </div>
      </div>
      }
    </div>
    <hr />
    <div class="input-wrapper">
      <div class="input-default">
        <label for="recebimento">Data Recebimento</label>
        <input formControlName="recebimento" type="date" id="recebimento" />
      </div>
      <div class="input-default">
        <label for="protocoloCancelamento">Protocolo de Cancelamento</label>
        <input
          formControlName="protocoloCancelamento"
          type="text"
          id="protocoloCancelamento"
          placeholder="Protocolo"
        />
      </div>
      <div class="input-default">
        <label for="suspensao">Data de Suspensão</label>
        <input formControlName="suspensao" type="date" id="suspensao" />
      </div>
      <div class="input-default">
        <label for="protocoloBloqueio">Protocolo de Suspensão</label>
        <input
          formControlName="protocoloBloqueio"
          type="text"
          id="protocoloBloqueio"
          placeholder="Protocolo"
        />
      </div>
      <div class="input-default input-md">
        <label for="motivoBloqueio">Motivo de Bloqueio</label>
        <ng-select formControlName="motivoBloqueio" placeholder="Escolha...">
          <ng-option [value]="0">NÃO INFORMADO</ng-option>
          <ng-option [value]="1">COLABORADOR DESLIGADO</ng-option>
          <ng-option [value]="2">IDENTIFICAÇÃO DE USUARIO</ng-option>
          <ng-option [value]="3">PERDA</ng-option>
          <ng-option [value]="4">RETORNO AO ESTOQUE</ng-option>
          <ng-option [value]="5">ROUBO/FURTO </ng-option>
          <ng-option [value]="6">TRANFERENCIA DE TITULARIDADE PJ-PF</ng-option>
        </ng-select>
      </div>
      <div class="input-default">
        <label for="previsaoReativacao">Previsão de Reativação</label>
        <input
          formControlName="previsaoReativacao"
          type="date"
          id="previsaoReativacao"
        />
      </div>
      <div class="input-default">
        <label for="reativacao">Data de Reativação</label>
        <input formControlName="reativacao" type="date" id="reativacao" />
      </div>
      <div class="input-default">
        <label for="ativacao">Data de Ativação</label>
        <input formControlName="ativacao" type="date" id="ativacao" />
      </div>
      <div class="input-default input-md">
        <label for="inativacao">Data de Inativação</label>
        <input formControlName="inativacao" type="date" id="inativacao" />
      </div>
    </div>
  </form>
</ng-template>

<ng-template #fixalinkdados>
  <form [formGroup]="formAtual" *ngIf="formAtual">
    <div class="input-wrapper">
      @if(ativoTitle === 'Link') {
      <div class="input-default input-lg">
        <label for="categoria">Categoria</label>
        <ng-select formControlName="categoria" placeholder="Escolha...">
          <ng-option [value]="4">VPN IP</ng-option>
          <ng-option [value]="5">LINK DEDICADO</ng-option>
          <ng-option [value]="6">LINK</ng-option>
        </ng-select>
      </div>
      }
      @if(mostrarCamposRelacionadosEntidade) {
        <div class="input-default input-lg">
          <label for="entidade">Entidade</label>
          <ng-select
            formControlName="entidade"
            [items]="entidadesList$ | async"
            bindLabel="razaoSocial"
            bindValue="entidadeId"
            placeholder="Escolha..."
            (change)="mudarEntidade($event)"
          >
          </ng-select>
        </div>
        <div class="input-default input-lg">
          <label for="titularidade">Titularidade</label>
          <ng-select
            formControlName="titularidade"
            [items]="filtredList$ | async"
            bindLabel="razaoSocial"
            bindValue="entidadeId"
            placeholder="Escolha..."
          >
          </ng-select>
        </div>
      }
      
      
      <div class="input-default input-md">
        <label for="status">Status</label>
        <ng-select formControlName="status" placeholder="Escolha...">
          <ng-option [value]="0">INATIVA</ng-option>
          <ng-option [value]="1">ATIVA</ng-option>
          <ng-option [value]="2">BLOQUEADA GESTOR </ng-option>
          <ng-option [value]="3">BLOQUEIO PRE ATIVAÇÃO </ng-option>
          <ng-option [value]="4">REATIVADA APOS SUSPENSÃO </ng-option>
          <ng-option [value]="5">SUSPENSÃO </ng-option>
        </ng-select>
      </div>      
      @if(mostrarCamposRelacionadosEntidade) {
        <div class="input-default input-lg">
          <label for="fornecedor">Fornecedor</label>
          <ng-select
            formControlName="fornecedor"
            [items]="fornecedorList$ | async"
            bindLabel="nomeUsual"
            bindValue="fornecedorId"
            placeholder="Escolha..."
            (change)="mudarFornecedor($event)"
          >
          </ng-select>
        </div>
        <div class="input-default com-select input-lg">
          <label for="conta">Conta</label>
          <input
            formControlName="conta"
            type="text"
            id="conta"
            placeholder="Conta"
            [class]="isServiceIquals ? 'disable-input-color' : ''"
          />
          <div
            [class]="!isServiceIquals ? 'abrir-modal' : 'abrir-modal-disabled'"
            (click)="editModalConta()"
          >
            <i class="bx bx-plus"></i>
          </div>
        </div>
        <div class="input-default com-select input-lg">
          <label for="perfil">Perfil</label>
          <input
            formControlName="perfil"
            type="text"
            id="perfil"
            placeholder="Perfil"
            [class]="isServiceIquals ? 'disable-input-color' : ''"
          />
          <div
            [class]="!isServiceIquals ? 'abrir-modal' : 'abrir-modal-disabled'"
            (click)="editModalPerfil()"
          >
            <i class="bx bx-plus"></i>
          </div>
        </div>
      }
      
      <!-- <div class="input-default input-sm">
        <label for="uf">UF</label>
        <input formControlName="uf" type="text" id="uf" placeholder="UF" />
      </div> -->
    </div>
    <hr />
    <div class="input-wrapper">
      <div class="input-default">
        <label for="nomeBandaLarga">Nome Banda Larga</label>
        <input
          formControlName="nomeBandaLarga"
          type="text"
          id="nomeBandaLarga"
          placeholder="Nome Banda Larga"
        />
      </div>
      <div class="input-default">
        <label for="enderecoInstalacao">Endereço de Instalação</label>
        <input
          formControlName="enderecoInstalacao"
          type="text"
          id="enderecoInstalacao"
          placeholder="Endereço"
        />
      </div>
      <div class="input-default input-md">
        <label for="recebimento">Data de Recebimento</label>
        <input formControlName="recebimento" type="date" id="recebimento" />
      </div>
      <div class="input-default input-md">
        <label for="ativacao">Data de Ativação</label>
        <input formControlName="ativacao" type="date" id="ativacao" />
      </div>
      <div class="input-default input-md">
        <label for="inativacao">Data de Inativação</label>
        <input formControlName="inativacao" type="date" id="inativacao" />
      </div>
    </div>
  </form>

  @if(isServiceIquals) {
  <div style="color: #ff6f61; margin-top: 10px; font-size: 12px">
    <span>
      "Para alterar Entidade, Titularidade, Conta e Perfil é necessario
      selecionar somente um tipo de entidade"
    </span>
  </div>

  }
</ng-template>

<app-conta-fatura-modal
  *ngIf="openModalConta"
  (fecharModalEvent)="editModalConta()"
  (limparValorEvent)="limparValor($event)"
  (selecionarValorEvent)="salvarValor($event)"
  [servicoId]="1"
  [fornecedorId]="fornecedorSelecionado?.fornecedorId"
  [nomeFornecedor]="fornecedorSelecionado.nomeUsual"
  [entidadeId]="titularidadeSelecionada"
  [titularidadeId]="titularidadeSelecionada"
/>

<app-modal-list-perfis
  *ngIf="openModalPerfil"
  [reference]="'PerfilFixa'"
  (fecharModalEvent)="editModalPerfil()"
  (limparValorEvent)="limparValor($event)"
  [headerTitle]="'Perfis:'"
  (selecionarValorEvent)="salvarValor($event)"
  [buttonText]="'Selecionar'"
  [titularidadeId]="titularidade"
  [servico]="1"
  [fornecedor]="fornecedorSelecionado"
  [entidade]="entidadeSelecionada"
/>

<app-modal-list
  *ngIf="openModalPacote"
  [reference]="'PacoteRI'"
  (fecharModalEvent)="editModalPacote()"
  (limparValorEvent)="limparValor($event)"
  [headerTitle]="'PacotesRI:'"
  (selecionarValorEvent)="salvarValor($event)"
  [buttonText]="'Selecionar'"
  [fornecedor]="fornecedorSelecionado"
  [titularidade]="titularidade"
  [servicoId]="categoria"
  [entidade]="entidadeSelecionada"
/>
<app-modal-confirmation
  [isOpen]="modalConfirmIsOpen"
  [title]="'Confirmação'"
  [text]="txtConfirmacao"
  (fecharModalEvent)="confirmSubmit($event)"
></app-modal-confirmation>
