<header id="page-topbar">
  <!-- @if(actualTheme !== 'dark' || this.tokenStorageService.getUser().entidadeId
  !== '1') { -->
  <hr
    [ngStyle]="{
  backgroundColor:
    this.tokenStorageService.getUser().entidadeId === '1' || this.tokenStorageService.getUser().entidadeId !== '1'
      ? 'var(--primary)'
      : 'white',
}"
  />
  <!-- } -->
  <!-- <hr *ngIf="this.tokenStorageService.getUser().entidadeId !== '1'"/> -->
  <div
    class="navbar-header"
    [ngStyle]="
      topbarChanged === 'false'
        ? {
            backgroundColor:

              actualTheme === 'dark'
                ? 'var(--primary)'
                : '#fbf8f7'
          }
        : {
            backgroundColor: actualTheme === 'dark' ? '#e2e2e2' : '#fbf8f7'
          }
    "
  >
    <div class="d-flex align-items-center">
      <!-- LOGO -->
      <div
        style="
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        class="navbar-brand-box"
        [ngStyle]="
          topbarChanged === 'false'
            ? {
                backgroundColor:
                  actualTheme === 'dark' ? 'transparent' : 'transparent',
                paddingTop: actualTheme !== 'dark' ? '10px' : '5px'
              }
            : {
                backgroundColor:
                  actualTheme !== 'dark' ? '#fbf8f7' : 'var(--primary)'
              }
        "
      >
        <a routerLink="/tem/relatorios/dashboards" class="logo logo-dark">
          <span
            class="logo-sm"
            style="
              filter: drop-shadow(0 0 0.1rem var(--primary) aa);
              mix-blend-mode: plus-lighter;
              height: 40px;
            "
          >
            <!-- #fbf8f7 COLOR -->
            <img
              src="assets/images/Mobit-Logo.png"
              alt=""
              height="22"
              width="10"
            />
          </span>
          <span class="logo-lg">
            <img src="assets/images/Mobit-Logo-white.png" alt="" height="17" />
          </span>
        </a>

        @if(actualTheme !== 'dark'){
        <a
          routerLink="/tem/relatorios/dashboards"
          class="logo logo-light"
          [ngStyle]="{ background: 'transparent' }"
        >
          <span class="logo-sm" style="">
            <img
              src="assets/images/mobVisionIcon-blue.png"
              alt=""
              height="40px"
            />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/mobVision.png"
              alt=""
              style="margin-top: 10px"
              width="210px"
            />
          </span>
        </a>
        } @else {
        <a
          routerLink="/tem/relatorios/dashboards"
          class="logo logo-light"
          [ngStyle]="{ background: 'transparent' }"
        >
          <span class="logo-sm">
            <img
              src="assets/images/mobVisionIcon-blue.png"
              alt=""
              height="40px"
            />
          </span>
          <span class="logo-lg">
            <img src="assets/images/mobVision-white.png" alt="" width="210px" />
          </span>
        </a>
        }
      </div>
      <!-- <div
        *ngIf="this.tokenStorageService.getUser().entidadeId === '18'"
        class="navbar-brand-box"
      >
        <a routerLink="/tem/relatorios/dashboards" class="logo logo-dark">
          <span class="logo-sm">
            <img
              src="assets/images/schneider.png"
              alt=""
              height="22"
              width="2"
            />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/schneider.png"
              alt=""
              height="17"
              width="2"
            />
          </span>
        </a>

        <a routerLink="/tem/relatorios/dashboards" class="logo logo-light">
          <span
            class="logo-sm"
            style="
              filter: drop-shadow(0 0 0.1rem var(--primary) aa);
              mix-blend-mode: plus-lighter;
              height: 40px;
              width: 10px;
            "
          >
            <img [src]="icon" alt="" height="22" width="10" />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/schneider.png"
              alt=""
              height="100"
              width="200"
            />
          </span>
        </a>
      </div>
      <div
        *ngIf="this.tokenStorageService.getUser().entidadeId === '17'"
        class="navbar-brand-box"
      >
        <a routerLink="/tem/relatorios/dashboards" class="logo logo-dark">
          <span class="logo-sm">
            <img
              src="assets/images/befly-logo.png"
              alt=""
              height="22"
              width="2"
            />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/logo-befly-branco.png"
              alt=""
              height="17"
              width="2"
            />
          </span>
        </a>

        <a routerLink="/tem/relatorios/dashboards" class="logo logo-light">
          <span class="logo-sm" style="">
            <img src="assets/images/befly-logo.png" alt="" width="80" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-befly-branco.png" alt="" height="50" />
          </span>
        </a>
      </div>

      <div
        *ngIf="this.tokenStorageService.getUser().entidadeId === '36'"
        class="navbar-brand-box"
      >
        <a routerLink="/tem/relatorios/dashboards" class="logo logo-dark">
          <span class="logo-sm">
            <img
              src="assets/images/logo-ecorodovias-full.svg"
              alt=""
              height="22"
              width="2"
            />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/logo-ecorodovias.svg"
              alt=""
              height="17"
              width="2"
            />
          </span>
        </a>

        <a routerLink="/tem/relatorios/dashboards" class="logo logo-light">
          <span class="logo-sm">
            <img
              src="assets/images/logo-ecorodovias-full.svg"
              alt=""
              width="120"
            />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-ecorodovias.svg" alt="" height="50" />
          </span>
        </a>
      </div>

      <div
        *ngIf="this.tokenStorageService.getUser().entidadeId === '84'"
        class="navbar-brand-box"
      >
        <a routerLink="/tem/relatorios/dashboards" class="logo logo-dark">
          <span class="logo-sm">
            <img
              src="assets/images/logo-comporte-full.svg"
              alt=""
              height="22"
              width="1"
            />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/logo-comporte.svg"
              alt=""
              height="17"
              width="50"
            />
          </span>
        </a>

        <a routerLink="/tem/relatorios/dashboards" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-comporte.svg" alt="" width="50" />
          </span>
          <span class="logo-lg">
            <img
              src="assets/images/logo-comporte-full.svg"
              alt=""
              height="50"
            />
          </span>
        </a>
      </div> -->

      <!-- <button
        type="button"
        class="btn btn-sm px-3 font-size-16 header-item"
        [ngStyle]="{
          color:

            actualTheme === 'dark'
              ? 'white'
              : 'var(--primary)'
        }"
        [ngStyle]="
          topbarChanged === 'false'
            ? {
                color:

                  actualTheme === 'dark'
                    ? 'white'
                    : 'var(--primary)'
              }
            : {
                color: 'var(--primary)'
              }
        "
        [ngClass]="{ 'd-none': theme === 'horizontal' }"
        id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)"
      >
        <i class="fa fa-fw fa-bars"></i>
      </button> -->

      <!-- App Search-->
      <!-- <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input
            type="text"
            class="form-control"
            placeholder="{{ 'HEADER.SEARCH' | translate }}"
          />
          <span class="bx bx-search-alt"></span>
        </div>
      </form> -->

      <!-- <div class="dropdown-mega d-none d-lg-block ms-2" dropdown>
        <button
          type="button"
          class="btn header-item"
          style="color: var(--primary)"
          dropdownToggle
          data-toggle="dropdown"
          aria-haspopup="false"
          aria-expanded="false"
        >
          {{ "HEADER.MEGA_MENU" | translate }}
          <i class="mdi mdi-chevron-down" style="color: var(--primary)"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" *dropdownMenu>
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.UI.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.LIGHTBOX" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RANGE_SLIDER" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.SWEET_ALERT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RATING" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.FORMS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.TABLES" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.CHARTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.APPLICATIONS.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.ECOMMERCE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.CALENDAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.EMAIL" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.PROJECTS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.TASKS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.APPLICATIONS.CONTACTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.EXTRA_PAGES.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.LIGHT_SIDEBAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.COMPACT_SIDEBAR" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.MAINTENANCE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.COMING SOON" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.TIMELINE" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.EXTRA_PAGES.FAQ" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0">
                    {{ "HEADER.UI.TITLE" | translate }}
                  </h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.LIGHTBOX" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RANGE_SLIDER" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.SWEET_ALERT" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.RATING" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.FORMS" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.TABLES" | translate
                      }}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{
                        "HEADER.UI.CHARTS" | translate
                      }}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img
                      src="assets/images/megamenu-img.png"
                      alt=""
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <p-dropdown
        [options]="menuItems"
        (ngModel)="(selectedMenuItem)"
        placeholder="Components"
        [group]="true"
      >
        <ng-template let-group pTemplate="group">
          <div class="flex align-items-center">
            <a [routerLink]="group.link">{{ group.label | translate }}</a>
            @if(group.subItems) {
            <ul>
              @for (item of group.subItems; track $index) {
              <li>
                <a [routerLink]="item.link">
                  {{ item.label | translate }}
                </a>
              </li>
              }
            </ul>
            }
          </div>
        </ng-template>
      </p-dropdown> -->
    </div>

    <div class="d-flex" style="align-items: center">
      <div class="dropdown d-inline-block d-lg-none ms-2" dropdown>
        <!-- <button
          type="button"
          class="btn header-item noti-icon"
          style="color: var(--primary); width: 50%"
          id="page-header-search-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          dropdownToggle
          aria-expanded="false"
        >
          <i class="mdi mdi-magnify"></i>
        </button> -->
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          *dropdownMenu
          aria-labelledby="page-header-search-dropdown"
        >
          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search ..."
                  aria-label="Recipient's username"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit">
                    <i class="mdi mdi-magnify"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      @if(this.tokenStorageService.getUser().entidadeId === '1') {
      <form [formGroup]="entidadeForm">
        <p-dropdown
          filterBy="nomeUsual"
          formControlName="entidade"
          optionLabel="nomeUsual"
          placeholder="Selecione uma entidade"
          [filter]="true"
          (onChange)="mudarEntidade($event)"
          [options]="entidadeListGlobal$ | async"
        />
      </form>

      }

      <!-- {{entidadeSelected$ | async}} -->

      <div class="dropdown d-inline-block" dropdown>
        <button
          type="button"
          class="btn header-item"
          id="page-header-user-dropdown"
          dropdownToggle
        >
          @if(flagvalue === undefined){
          <img src="{{ valueset }}" alt="Header Language" height="16" />
          }@else{
          <img src="{{ flagvalue }}" alt="Header Language" height="16" />
          }
        </button>
        <div
          class="dropdown-menu dropdown-menu-end"
          style="color: var(--primary)"
          *dropdownMenu
        >
          {{ item }} @for (item of listLang; track $index) {<a
            href="javascript:void(0);"
            class="dropdown-item notify-item"
            style="color: var(--primary); width: 120px"
            (click)="setLanguage(item.text, item.lang, item.flag)"
            [ngClass]="{ active: cookieValue === item.lang }"
          >
            <img
              src="{{ item.flag }}"
              alt="user-image"
              class="me-1"
              height="12"
            />
            <span class="align-middle">{{ item.text }}</span> </a
          >}
        </div>
      </div>

      <!-- <div class="dropdown d-none d-lg-inline-block ms-1" dropdown>
        <button
          type="button"
          class="btn header-item noti-icon"
          style="color: var(--primary)"
          dropdownToggle
        >
          <i class="bx bx-customize" style="color: var(--primary)"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end"
          *dropdownMenu
        >
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="Github" />
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img
                    src="assets/images/brands/bitbucket.png"
                    alt="bitbucket"
                  />
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble" />
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox" />
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img
                    src="assets/images/brands/mail_chimp.png"
                    alt="mail_chimp"
                  />
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack" />
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button
          type="button"
          class="btn header-item noti-icon waves-effect"
          data-bs-toggle="fullscreen"
          (click)="fullscreen()"
        >
          <i class="bx bx-fullscreen" style="color: var(--primary)"></i>
        </button>
      </div> -->

      <div class="dropdown" dropdown>
        <!-- <button
          type="button"
          class="btn header-item noti-icon"
          id="page-header-notifications-dropdown"
          dropdownToggle
        >
          <i class="bx bx-bell bx-tada" style="color: var(--primary)"></i>
          <span class="badge bg-danger rounded-pill">3</span>
        </button> -->
        <div
          class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown"
          *dropdownMenu
        >
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">
                  {{ "HEADER.NOTIFICATIONS.TITLE" | translate }}
                </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{
                  "HEADER.NOTIFICATIONS.VIEW_ALL" | translate
                }}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-primary rounded-circle font-size-16"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1" style="color: aqua">
                    {{ "HEADER.NOTIFICATIONS.FIRST.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.FIRST.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i>
                      {{ "HEADER.NOTIFICATIONS.FIRST.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <img
                  src="assets/images/users/avatar-3.jpg"
                  class="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.SECOND.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.SECOND.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.SECOND.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <div class="avatar-xs me-3">
                  <span
                    class="avatar-title bg-success rounded-circle font-size-16"
                  >
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.THIRD.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.THIRD.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.THIRD.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="d-flex">
                <img
                  src="assets/images/users/avatar-4.jpg"
                  class="me-3 rounded-circle avatar-xs"
                  alt="user-pic"
                />
                <div class="flex-grow-1">
                  <h6 class="mb-1">
                    {{ "HEADER.NOTIFICATIONS.FOUR.TITLE" | translate }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">
                      {{ "HEADER.NOTIFICATIONS.FOUR.TEXT" | translate }}
                    </p>
                    <p class="mb-0">
                      <i class="mdi mdi-clock-outline"></i
                      >{{ "HEADER.NOTIFICATIONS.FOUR.TIME" | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a
              class="btn btn-sm btn-link font-size-14 text-center"
              href="javascript:void(0)"
            >
              <i class="mdi mdi-arrow-right-circle me-1"></i>
              <span key="t-view-more">{{
                "HEADER.NOTIFICATIONS.LOAD_MORE" | translate
              }}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown" dropdown>
        <button
          type="button"
          class="btn header-item d-flex flex-column justify-content-center"
          dropdownToggle
          id="page-header-user-dropdown"
        >
          <!-- <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/avatar-1.jpg"
            alt="Header Avatar"
          /> -->
          <span
            class="d-none d-xl-inline-block ms-1 fw-bold"
            [ngStyle]="
              topbarChanged === 'false'
                ? {
                    color:

                      actualTheme === 'dark'
                        ? 'white'
                        : 'var(--primary)'
                  }
                : {
                    color: 'var(--primary)'
                  }
            "
            >{{ tokenStorageService.getUser().nomeCompleto }}
          </span>
          <span
            class="d-none d-xl-inline-block ms-1"
            [ngStyle]="
              topbarChanged === 'false'
                ? {
                    color:

                      actualTheme === 'dark'
                        ? 'white'
                        : 'var(--primary)'
                  }
                : {
                    color: 'var(--primary)'
                  }
            "
            >{{ tokenStorageService.getUser().email
            }}<i
              class="mdi mdi-chevron-down d-none d-xl-inline-block"
              [ngStyle]="
                topbarChanged === 'false'
                  ? {
                      color:

                        actualTheme === 'dark'
                          ? 'white'
                          : 'var(--primary)'
                    }
                  : {
                      color: 'var(--primary)'
                    }
              "
            ></i
          ></span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
          <!-- item-->
          <a
            class="dropdown-item"
            routerLink="/contacts/profile"
            style="width: 70%"
          >
            <i class="bx bx-user font-size-16 align-middle me-1"></i>
            <span>Perfil</span>
          </a>
          <!-- <a
            class="dropdown-item"
            routerLink="/crypto/wallet"
            href="javascript: void(0);"
            ><i class="bx bx-wallet font-size-16 align-middle me-1"></i
            >{{ "HEADER.LOGIN.MY_WALLET" | translate }}</a
          > -->
          <a
            class="dropdown-item d-block"
            href="javascript: void(0);"
            (click)="toggleRightSidebar()"
            style="width: 70%"
          >
            <i class="bx bx-wrench font-size-16 align-middle me-1"></i>
            <span>Configurações</span>
          </a>

          <!-- @if(this.tokenStorageService.getUser().entidadeId === '1'){ -->
          @if(actualTheme === "light") {
          <button
            class="theme-switch-btn-dark"
            href="javascript: void(0);"
            (click)="toggleTheme('dark')"
            style="width: 70%"
          >
            <i class="bx bxs-moon font-size-16 align-middle me-1"></i>
            <span>Dark mode</span>
          </button>
          } @else {
          <button
            class="theme-switch-btn-light"
            href="javascript: void(0);"
            (click)="toggleTheme('light')"
            style="width: 70%"
          >
            <i class="bx bxs-sun font-size-16 align-middle me-1"></i>
            <span>Light mode</span>
          </button>
          } @if(topbarChanged === 'true'){
          <button
            class="theme-switch-btn-light"
            (click)="toggleTopbar(false)"
            style="width: 70%; margin-top: 10px"
          >
            <i class="bx bxs-sun font-size-16 align-middle me-1"></i>
            <span>Mudar cores</span>
          </button>
          } @else {
          <button
            class="theme-switch-btn-light"
            (click)="toggleTopbar(true)"
            style="width: 70%; margin-top: 10px"
          >
            <i class="bx bxs-sun font-size-16 align-middle me-1"></i>
            <span>Mudar cores</span>
          </button>
          }
          <!-- } -->
          <!-- <a class="dropdown-item" routerLink="/pages/lock-screen-1"
            ><i
              class="bx bx-lock-open font-size-16 align-middle me-1"
              style="color: var(--primary)"
            ></i>
            {{ "HEADER.LOGIN.LOCK_SCREEN" | translate }}</a
          > -->
          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            style="width: 70%"
            href="javascript: void(0);"
            (click)="logout()"
            ><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"
            ></i>
            {{ "HEADER.LOGIN.LOGOUT" | translate }}</a
          >
        </div>
      </div>

      <!-- <div class="dropdown d-inline-block">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          (click)="toggleRightSidebar()"
        >
          <i class="bx bx-cog bx-spin" style="color: var(--primary)"></i>
        </button>
      </div> -->
    </div>
  </div>
  <app-progess-csv></app-progess-csv>
</header>
