import { HttpClient, HttpErrorResponse, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContaFatura } from '../models/contaFatura.model';
import { Pagination } from 'src/app/core/models/pagination.model';
import { Fatura, FaturaHeader } from '../models/fatura.model';
import { FileModel } from 'src/app/core/models/fileModel';
import { ArquivoFatura } from '../models/arquivoFatura.model';
import { OperationResult, toOpResult } from 'src/app/core/models/operationResult.model';
import { ExportModel } from 'src/app/core/models/exportModel';


export interface UploadDetalhadoResponse {
  arquivoFatura: ArquivoFatura
  fileDetailsId: number
}
// export interface 
@Injectable({
  providedIn: 'root'
})
export class FaturaService {  

  baseUrl = environment.apiBaseHom +'/faturaConta';
  baseDetalhadoUrl = environment.apiBaseHom +'/fatura';
  //baseUrl = sessionStorage.getItem('urlAPI')+'/v1/deposito/pedidovenda'

  private _fatura = new BehaviorSubject<any[]>([]);
  get fatura() {
    return this._fatura.asObservable();
  }

  retornaParams(){

    let queryParams = new HttpParams();
    queryParams = queryParams.append("auth",environment.apiBaseHom)

    return queryParams;
  }

  constructor(private http: HttpClient) { }


  getFatura(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaFatura: Fatura[],
    quantidade: number
  }> {
    return this.http.get<{
      listaFatura: Fatura[],
      quantidade: number
    }>(
      `${this.baseUrl}/ListFatura?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
  getMapEntries() : Observable<{
    id: number
    sourceField: string
    targetField: string
    from: string
    to: string
   }> {
    const url = `${this.baseUrl}/maps`
    return this.http.get<{
      id: number
      sourceField: string
      targetField: string
      from: string
      to: string
     }>(url)
  }
  updateMapEntries(entriesToUpdate: {}) : Observable<Object> {
    const url = `${this.baseUrl}/maps`
    // const body = 
    throw Error("Not Implemented")

  }
  getFaturaPorId(faturaId: number): Observable<Fatura> {
    return this.http.get<Fatura>(
      `${this.baseUrl}/FaturaPorId?id=${faturaId}`
    );
  }
  addFatura(faturaNovo: Fatura): Observable<any> {
    return this.http.post(`${this.baseUrl}`, faturaNovo)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }
  updateFatura(fatura: Fatura): Observable<any> {
    return this.http.put(`${this.baseUrl}/AtualizaFatura`, fatura).pipe(
      map((res) => {
        return res;
      }),
      catchError(err => {
        return of(false);
      })
    );
  }

  getPesquisaTodosFatura(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaFatura: Fatura[],
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaFatura: Fatura[],
    quantidadeTotal: number;
    }>(
      `${this.baseUrl}/FaturaPesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getFaturaDetalhadoPorId(faturaId: number): Observable<any> {
    return this.http.get<any>(
      `${this.baseDetalhadoUrl}/faturadetalhadoporheader?faturaHeaderId=${faturaId}`
    );
  }
  
  exportDetalhado(fileModel: FileModel) : Observable<HttpEvent<any>> {
    // if(fileModel.arquivoFaturaId == )
    const formData: FormData = new FormData();
    formData.append('Content-Type', 'application/json');    
    formData.append('FileType', fileModel.fileType.toString());  
    formData.append('Fornecedor',fileModel.fornecedor);    
    formData.append('NomeEntidade', fileModel.nomeEntidade.toString());
    formData.append('NomeArquivoDownload', fileModel.nomeArquivoDownload.toString());
    formData.append('BucketName',fileModel.bucketName.toString());    
    formData.append('ArquivoFaturaId',fileModel.arquivoFaturaId?.toString());
    const req = new HttpRequest('POST', `${this.baseDetalhadoUrl}/DownloadArquivoEletronico`, formData, {
      reportProgress: true,
      responseType: 'blob' 
    });
    return this.http.request(req);
  }
  public delete = (
    faturaId: number
  ): Observable<Fatura | HttpErrorResponse> => {
    return this.http.delete<Fatura>(`${this.baseUrl}/${faturaId}`).pipe(
      map((res: Fatura) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };
  // downloadArquivoFaturaPorId(id: number) : Observable<OperationResult<ArquivoFatura>> {
  //   const url = `${this.baseUrl}/${id}`
  //   return this.http.get(url)
  //     .pipe(map(toOpResult))
  // }
  uploadDetalhado(arq:{uploadedFile: Blob,faturaId: number,source: string, fornecedor: string }): Observable<OperationResult<UploadDetalhadoResponse>> 
  {    
    const url = `${this.baseDetalhadoUrl}/detalhado/upload`
    const body = new FormData();
    body.append("faturaId",arq.faturaId.toString())
    body.append("fornecedor",arq.fornecedor)
    body.append("source",arq.source)
    body.append("uploadedFile",arq.uploadedFile)
    return this.http.post<OperationResult<UploadDetalhadoResponse>>(url,body)
  }
  detalharArquivo(req:{ arquivoFaturaId: number, faturaId: number, interno:boolean  }): Observable<OperationResult<FaturaHeader>>{
    const url = `${this.baseDetalhadoUrl}/bulk/fatura/febraban`
    const formData = new FormData();
    formData.append('ArquivoFaturaId',req.arquivoFaturaId.toString())
    formData.append('faturaId',req.faturaId.toString())
    return this.http.post<OperationResult<FaturaHeader>>(url,formData)
      .pipe(
        map((res) => res)
        ,catchError((err) => of(toOpResult(err.error)))
      )
  }
  uploadArquivoFatura(arq:{uploadedFile: Blob,faturaId: number,source: string, fornecedor: string }){
    const url = `${this.baseDetalhadoUrl}/detalhado/upload`
    const body = new FormData();
    body.append("faturaId",arq.faturaId.toString())
    body.append("fornecedor",arq.fornecedor)
    body.append("source",arq.source)
    body.append("uploadedFile",arq.uploadedFile)
    return this.http.post(url,body)
  }
  deletarDetalhadoPorArquivoFaturaId(idArquivoSendoDeletado: number) : Observable<OperationResult> {
    const url = `${this.baseUrl}/file/${idArquivoSendoDeletado}`
    return this.http.delete(url)
      .pipe(map(toOpResult),
      catchError((err) => of(toOpResult({value: null, success: false, message: err.message}))))
  }
  exportDetalhadoPorId(faturaHeaderId: number) : Observable<Blob> {
    const reqBody = {
      faturaHeaderId
    }
    const url = `${this.baseDetalhadoUrl}/export/detalhado`
    return this.http.post(url,reqBody,{      
      responseType: 'blob' 
    })
  }
  exportCsv(exportReq: ExportModel<any>)
    : Observable<Object> 
  {
    // const params = Object
    //   .keys(exportReq)
    //   .map(param => `${param}=${exportReq[param]}`)
    //   .join("&")
    // const url = `${this.baseDetalhadoUrl}/export?${params}`
    const url = `${this.baseDetalhadoUrl}/export`
    return this.http.post(url,exportReq,{      
      responseType: 'blob'
      ,observe: 'response'
    })
    .pipe(map((response) => {
      return {response: response,headers: response.headers}
    }));
  }
  
  async upload(params: { faturaId: number, files: File[], filemodel: FileModel }): Promise<Observable<HttpEvent<any>>> {
    const { faturaId, files, filemodel } = params;
    const formData: FormData = new FormData();

    // Aqui removemos o uso de `encodeURIComponent` e mantemos o nome do arquivo original
    const uploadableFiles = files.map(async file => {
        const fileContent = await file.arrayBuffer();
        return new File([fileContent], file.name, { type: file.type, lastModified: file.lastModified });
    });

    for (const filePromise of uploadableFiles) {
        try {
            const uploadableFile = await filePromise;
            formData.append('ListFileDetails', uploadableFile);
        } catch (err) {
            console.error("Não foi possível fazer o upload do arquivo", err);
        }
    }

    formData.append('Source', filemodel.source.toString());
    formData.append('Fornecedor', filemodel.fornecedor.toString());
    formData.append('NomeEntidade', filemodel.nomeEntidade.toString());
    formData.append('MesReferencia', filemodel.mesReferencia.toString());

    const req = new HttpRequest('POST', `${this.baseUrl}/${faturaId}/upload`, formData, {
        reportProgress: true,
        responseType: 'blob'
    });
    
    return this.http.request(req);
  }
  validarCsvDetalhado(params:{
      faturaId: number
      , csvFile: Blob }) : Observable<OperationResult<any,any>> {
    const { faturaId, csvFile } = params
    const formData = new FormData()
    formData.append('UploadedFile',csvFile)
    formData.append('FaturaId',faturaId.toString())
    const url = `${this.baseDetalhadoUrl}/bulk/csv/validate`
    return this.http.post<OperationResult<any,any>>(url,formData)
        .pipe(
          map((value) => value),
          catchError((err) => {
            const result = err.error
            const columns = result.errorData.headersReaded.join(',')
            const res:OperationResult<any,any> = toOpResult({ value: null,message: result.message, success: false })
            return of(res)
          })
        )
  }

}

