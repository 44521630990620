import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Operadora } from "../../../ativo-linha/core/models/operadora.model";
import { Pagination } from "src/app/core/models/pagination.model";
import { OperadoraService } from "../../../ativo-linha/core/services/operadora.service";
import { Fornecedor } from "../models/fornecedor.model";
import { FornecedorService } from "../services/fornecedor.service";

@Injectable({
  providedIn: "root",
})
export class FornecedorStoreService {
  private fornecedorListSubject: BehaviorSubject<Fornecedor[]> =
    new BehaviorSubject<Fornecedor[]>([]);

  private fornecedorTipoListSubject: BehaviorSubject<string[]> =
    new BehaviorSubject<string[]>([]);

  get $FornecedorList() {
    return this.fornecedorListSubject.asObservable();
  }

  get tipoFornecedorList$() {
    return this.fornecedorTipoListSubject.asObservable();
  }

  pagination: Pagination = <Pagination>{};

  constructor(private fornecedorService: FornecedorService) {
    this.pagination.pageSize = 500;
    this.pagination.pageNumber = 1;

    this.carregarListaOperadoras();
    this.carregaListaTiposFornecedors();
  }

  carregarListaOperadoras(tipo?: string) {
    console.log(tipo);
    this.fornecedorService.getFornecedor(tipo).subscribe((result) => {
      this.fornecedorListSubject.next(result);
    });
  }

  carregaListaTiposFornecedors() {
    this.fornecedorService.getTipoFornecedorList().subscribe((res) => {
      this.fornecedorTipoListSubject.next(res);
    });
  }
}
