<div bsModal #showModal="bs-modal" [config]="{ show: true }"
 class="modal fade" id="showModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="font-bold">{{ headerTitle }}</h5>
            <i (click)="fecharModal(reference)" class="fa fa-times"></i>
        </div>
        <div class="modal-body">
            <ng-container
            [ngTemplateOutlet]="(reference === 'Unidade') ? unidades :
                                (reference === 'Cargo') ? cargos :
                                (reference === 'Area') ? areas : centroCustos">
        </ng-container>

        <!-- end modal body -->
      </div>
      <div class="modal-footer">
        <!-- Vincular ou Selecionar -->
        <button (click)="submit(reference)" class="btn"><i class="fa fa-check"></i>{{buttonText}}</button>
    </div>
    </div>
  </div>
  <!--End Modal -->


<!-- Unidade -->
<ng-template #unidades>

    <form  class="needs-validation" [formGroup]="registerUnidadeForm" *ngIf="registerUnidadeForm">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom03">Entidade</label>
                  <div class="mb-3 row">
                    <div class="col-md-12">
                        <ng-select formControlName="entidade" [items]="listaEntidade" (change)="fetchDropsDownPorEntidade($event)"
                            bindLabel="razaoSocial" bindValue="entidadeId" placeholder="Escolha...">
                        </ng-select>
                    </div>
                  </div>
                  @if(submit && form.entidade.errors){
                  <div class="invalid-feedback">
                    @if(form.entidade.errors.required){
                    <span>Please provide a valid city.</span>}
                  </div>}
                </div>
              </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="validationCustom01">Nome Unidade</label>
              <input type="text" class="form-control" id="validationCustom01" formControlName="razaoSocial" placeholder="Razão Social"
                [ngClass]="{'is-valid': submit && form.razaoSocial.errors}">
              @if(submit && form.razaoSocial.errors){
              <div class="valid-feedback">
                @if(form.razaoSocial.errors.required){
                <span>Looks good!</span>
                }
              </div>}
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="validationCustom02">Nome Usual</label>
              <input type="text" class="form-control" id="validationCustom02" formControlName="nomeUsual"
              placeholder="Nome Usual" [ngClass]="{'is-valid': submit && form.nomeUsual.errors}">
              @if(submit && form.nomeUsual.errors){
              <div class="valid-feedback">
                @if(form.nomeUsual.errors.required){
                <span>Looks good!</span>}
              </div>}
            </div>
          </div>
          <div class="col-md-4">
            <div class="mb-3">
              <label for="validationCustom02">Canal</label>
              <input type="text" class="form-control" id="validationCustom02" formControlName="canal"
               placeholder="Canal" [ngClass]="{'is-valid': submit && form.canal.errors}">
              @if(submit && form.canal.errors){
              <div class="valid-feedback">
                @if(form.canal.errors.required){
                <span>Looks good!</span>}
              </div>}
            </div>
          </div>

        </div>



      </form>


</ng-template>




<!-- Cargo -->
<ng-template #cargos>

    <form  class="needs-validation" [formGroup]="registerCargoForm" *ngIf="registerCargoForm">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom03">Entidade</label>
                  <div class="mb-3 row">
                    <div class="col-md-12">
                        <ng-select formControlName="entidade" [items]="listaEntidade" (change)="fetchDropsDownPorEntidade($event)"
                            bindLabel="razaoSocial" bindValue="entidadeId" placeholder="Escolha...">
                        </ng-select>
                    </div>
                  </div>
                  @if(submit && form.entidade.errors){
                  <div class="invalid-feedback">
                    @if(form.entidade.errors.required){
                    <span>Please provide a valid city.</span>}
                  </div>}
                </div>
              </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="validationCustom01">Nome Cargo</label>
              <input type="text" class="form-control" id="validationCustom01" formControlName="descricao" placeholder="Nome Cargo"
                [ngClass]="{'is-valid': submit && form.descricao.errors}">
              @if(submit && form.descricao.errors){
              <div class="valid-feedback">
                @if(form.descricao.errors.required){
                <span>Looks good!</span>
                }
              </div>}
            </div>
          </div>

        </div>



      </form>

</ng-template>

<!-- Area -->
<ng-template #areas>

    <form  class="needs-validation" [formGroup]="registerAreaForm" *ngIf="registerAreaForm">
        <div class="row">
            <div class="col-md-6">
                <div class="mb-3">
                  <label for="validationCustom03">Entidade</label>
                  <div class="mb-3 row">
                    <div class="col-md-12">
                        <ng-select formControlName="entidade" [items]="listaEntidade" (change)="fetchDropsDownPorEntidade($event)"
                            bindLabel="razaoSocial" bindValue="entidadeId" placeholder="Escolha...">
                        </ng-select>
                    </div>
                  </div>
                  @if(submit && form.entidade.errors){
                  <div class="invalid-feedback">
                    @if(form.entidade.errors.required){
                    <span>Please provide a valid city.</span>}
                  </div>}
                </div>
              </div>
          <div class="col-md-6">
            <div class="mb-3">
              <label for="validationCustom01">Nome Área</label>
              <input type="text" class="form-control" id="validationCustom01" formControlName="descricao" placeholder="Nome Área"
                [ngClass]="{'is-valid': submit && form.descricao.errors}">
              @if(submit && form.descricao.errors){
              <div class="valid-feedback">
                @if(form.descricao.errors.required){
                <span>Looks good!</span>
                }
              </div>}
            </div>
          </div>

        </div>



      </form>

</ng-template>

<!-- Centro de Custo -->
<ng-template #centroCustos>

  <form  class="needs-validation" [formGroup]="registerCentroCustoForm" *ngIf="registerCentroCustoForm">
      <div class="row">
          <div class="col-md-6">
              <div class="mb-3">
                <label for="validationCustom03">Entidade</label>
                <div class="mb-3 row">
                  <div class="col-md-12">
                      <ng-select formControlName="entidade" [items]="listaEntidade" (change)="fetchDropsDownPorEntidade($event)"
                          bindLabel="razaoSocial" bindValue="entidadeId" placeholder="Escolha...">
                      </ng-select>
                  </div>
                </div>
                @if(submit && form.entidade.errors){
                <div class="invalid-feedback">
                  @if(form.entidade.errors.required){
                  <span>Please provide a valid city.</span>}
                </div>}
              </div>
            </div>
        <div class="col-md-6">
          <div class="mb-3">
            <label for="validationCustom01">Nome Centro Custo</label>
            <input type="text" class="form-control" id="validationCustom01" formControlName="nome" placeholder="Nome Centro de Custo"
              [ngClass]="{'is-valid': submit && form.nome.errors}">
            @if(submit && form.nome.errors){
            <div class="valid-feedback">
              @if(form.nome.errors.required){
              <span>Looks good!</span>
              }
            </div>}
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="mb-3">
            <label for="validationCustom01">Código Centro Custo</label>
            <input type="text" class="form-control" id="validationCustom01" formControlName="codigo" placeholder="Código Centro Custo"
              [ngClass]="{'is-valid': submit && form.codigo.errors}">
            @if(submit && form.codigo.errors){
            <div class="valid-feedback">
              @if(form.codigo.errors.required){
              <span>Looks good!</span>
              }
            </div>}
          </div>
        </div>
        <div class="col-lg-1">
          <p>Tarifavel</p>
          <div>

            <ui-switch  color="#50a5f1" formControlName="tarifavel" [checked]="false" uncheckedLabel="Não" checkedLabel="Sim" size="small" class="me-1"></ui-switch>

          </div>
      </div>

    </div>



    </form>

</ng-template>
