import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, of } from "rxjs";
import { Pagination } from "src/app/core/models/pagination.model";
import { environment } from "src/environments/environment";
import { PacoteRI } from "../models/pacoteRI.model";

@Injectable({
  providedIn: "root",
})
export class PacoteLinhaService {
  baseUrl = environment.apiBaseHom + "/pacoteri";

  constructor(private http: HttpClient) {}

  getPacoteRI(
    pagination: Pagination,
    entidadeId: number
  ): Observable<PacoteRI[]> {
    return this.http.get<any>(
      `${this.baseUrl}/listpacoteri?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPacoteRIEntidadeFornecedor(
    pagination: Pagination,
    entidadeId: number,
    fornecedorId: number
  ): Observable<PacoteRI[]> {
    return this.http.get<any>(
      `${this.baseUrl}/listpacoterifornecedor?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&entidadeId=${entidadeId}&fornecedorId=${fornecedorId}`
    );
  }

  getListPacotesRIPesquisaTodos(
    searchText: string,
    pagination: Pagination,
    entidadeId: number
  ): Observable<PacoteRI[]> {
    return this.http.get<any>(
      `${this.baseUrl}/pacoteripesquisatodos?searchText=${searchText}&EntidadeId=${entidadeId}&UnidadeId=1&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPacoteRIById(id: number): Observable<PacoteRI[]> {
    return this.http.get<any>(`${this.baseUrl}/getonepacoteri?id=${id}`);
  }

  addPacoteRI(pacoteri: PacoteRI): Observable<PacoteRI[]> {
    return this.http.post<any>(`${this.baseUrl}/inserepacoteri`, pacoteri);
  }

  updatePacoteRI(pacoteri: PacoteRI): Observable<PacoteRI[]> {
    return this.http.put<any>(`${this.baseUrl}/atualizapacoteri`, pacoteri);
  }

  deletePacoteRI(pacoteId: number): Observable<PacoteRI | HttpErrorResponse> {
    return this.http.delete<PacoteRI>(`${this.baseUrl}/${pacoteId}`).pipe(
      map((res) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  }
}
