<p-dialog
  [(visible)]="isOpen"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [closable]="false"
>
  <ng-template pTemplate="header">
    <div
      class="dialog-header"
      style="width: 100%; display: flex; justify-content: space-between"
    >
      <h6>{{ title }}</h6>
      <div class="btn-close" (click)="closeLoteModal()"></div>
    </div>
  </ng-template>

  @if (progress) {
  <div class="progress my-3">
    <div
      class="progress-bar progress-bar-info"
      role="progressbar"
      attr.aria-valuenow="{{ progress }}"
      aria-valuemin="0"
      aria-valuemax="100"
      [ngStyle]="{ width: progress + '%' }"
    >
      {{ progress }}%
    </div>
  </div>
  }
  <div class="input-default input-lg">
    <label>Ação</label>
    <ng-select
      placeholder="Escolha..."
      (change)="mudarLote($event)"
    >
      <ng-option [value]="0">Inserir</ng-option>
      <ng-option [value]="1">Atualizar</ng-option>
    </ng-select>
  </div>
  <!-- <p-tabView *ngIf="this.insertURL !== 'fatura'" (onChange)="onTabChange()">
    <p-tabPanel header="Inserir em lote"> -->

  @if(metodo === null || metodo === undefined) {
  <div style="margin: 40px 0px">
    Selecione um tipo de arquivo a ser enviado antes de enviar.
  </div>
  } @if(metodo === 0) {
  <div
    class="row"
    style="margin: 10px 0px"
    [style]="{ 'justify-content': 'center' }"
  >
    <div
      id="campoDeArrastarArquivo"
      ng2FileDrop
      disableMultipart
      (fileOver)="fileOverBase($event)"
      [uploader]="uploader"
      class="well my-drop-zone"
      [class.drag-over]="isDragOver || uploader.queue[0]?.file"
      (dragenter)="onDragEnter()"
      (dragleave)="onDragLeave()"
    >
      <p>Clique para escolher um arquivo para inserir ou arraste ele aqui.</p>
      <label *ngIf="!uploader.queue[0]" for="input-btn" class="select-btn"
        >clique ou arraste aqui</label
      >
      <input
        type="file"
        class="btn btn-modal"
        accept=".csv"
        id="input-btn"
        ng2FileSelect
        [uploader]="uploader"
        [disabled]="hasBaseDropZoneOver"
        *ngIf="!uploader.queue[0]"
      />
      {{ uploader.queue[0]?.file?.name }}<br />
    </div>
    <br />
    <div class="table-responsive">
      <table class="table" *ngIf="errorList?.length !== 0">
        <thead>
          <tr class="text-center">
            <th>Descricao</th>
            <th>Fornecedor</th>
            <th>Entidade</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of errorList" class="file-name">
            <td class="status">{{ item?.descricao }}</td>
            <td class="text-center">{{ item?.fornecedor }}</td>
            <td class="status">{{ item?.entidade }}</td>
            <td class="status">{{ item?.error }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  }
  <!-- </p-tabPanel>
    <p-tabPanel header="Atualizar em lote"> -->

  @if(metodo === 1) {
  <div
    class="row"
    style="margin: 10px 0px"
    [style]="{ 'justify-content': 'center' }"
  >
    <div
      ng2FileDrop
      disableMultipart
      (fileOver)="fileOverBase($event)"
      [uploader]="uploader"
      class="well my-drop-zone"
      [class.drag-over]="isDragOver || uploader.queue[0]?.file"
      (dragenter)="onDragEnter()"
      (dragleave)="onDragLeave()"
    >
      <p>Clique para escolher um arquivo para atualiar ou arraste ele aqui.</p>
      <label *ngIf="!uploader.queue[0]" for="input-btn" class="select-btn"
        >clique ou arraste aqui</label
      >
      <input
        type="file"
        class="btn btn-modal"
        accept=".csv"
        id="input-btn"
        ng2FileSelect
        [uploader]="uploader"
        [disabled]="hasBaseDropZoneOver"
        *ngIf="!uploader.queue[0]"
      />
      {{ uploader.queue[0]?.file?.name }}<br />
    </div>
    <br />
    <div class="table-responsive">
      <table class="table" *ngIf="errorList.length > 0">
        <thead>
          <tr class="text-center">
            <th>Descricao</th>
            <th>Fornecedor</th>
            <th>Entidade</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of errorList" class="file-name">
            <td class="status">{{ item?.descricao }}</td>
            <td class="text-center">{{ item?.fornecedor }}</td>
            <td class="status">{{ item?.entidade }}</td>
            <td class="status">{{ item?.error }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  }

  <!-- </p-tabPanel>
  </p-tabView> -->

  <p-tabView
    *ngIf="this.insertURL === 'fatura'"
    (onChange)="onTabFaturaChange()"
  >
    <p-tabPanel header="Fatura">
      <div class="row" [style]="{ 'justify-content': 'center' }">
        <div
          ng2FileDrop
          disableMultipart
          (fileOver)="fileOverBase($event)"
          [uploader]="uploader"
          class="well my-drop-zone"
          [class.drag-over]="isDragOver || uploader.queue[0]?.file"
          (dragenter)="onDragEnter()"
          (dragleave)="onDragLeave()"
        >
          <p>Clique para escolher um arquivo ou arraste ele aqui.</p>
          <label *ngIf="!uploader.queue[0]" for="input-btn" class="select-btn"
            >clique ou arraste aqui</label
          >
          <input
            type="file"
            class="btn btn-modal"
            accept=".pdf"
            id="input-btn"
            ng2FileSelect
            [uploader]="uploader"
            [disabled]="hasBaseDropZoneOver"
            *ngIf="!uploader.queue[0]"
          />
          {{ uploader.queue[0]?.file?.name }}<br />
        </div>
        <br />
        <div class="table-responsive">
          <table class="table" *ngIf="errorList?.length !== 0">
            <thead>
              <tr class="text-center">
                <th>Descricao</th>
                <th>Fornecedor</th>
                <th>Entidade</th>
                <th>Error</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of errorList" class="file-name">
                <td class="status">{{ item?.descricao }}</td>
                <td class="text-center">{{ item?.fornecedor }}</td>
                <td class="status">{{ item?.entidade }}</td>
                <td class="status">{{ item?.error }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

  <ng-template pTemplate="footer">
    <div class="btn-group">
      <app-action-btn
        [type]="'danger'"
        [icon]="'mdi mdi-close'"
        [text]="'Cancelar'"
        (click)="closeLoteModal()"
      ></app-action-btn>
      <!-- <app-action-btn
        [icon]="'mdi mdi-export font-size-20 '"
        [type]="''"
        [text]="'Confirmar'"
        (click)="upLoad()"
      ></app-action-btn> -->
      <button class="buttonCsv" (click)="upLoad()" [disabled]="loading">
        @if(!loading) {
        <i class="mdi mdi-export font-size-20"></i>
        Confirmar }@else {
        <i class="mdi mdi-account-plus font-size-20 mdi-spin mdi-loading"></i>
        }
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-confirmDialog></p-confirmDialog>
