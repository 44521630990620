import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AreaEntidade } from '../../models/areaEntidade.model';
import { Pagination } from 'src/app/core/models/pagination.model';
import { Entidade } from '../../models/entidade.model';



@Injectable({
  providedIn: 'root'
})
export class AreaEntidadeService {

  baseUrl = environment.apiBaseHom +'/areaentidade';
  //baseUrl = sessionStorage.getItem('urlAPI')+'/v1/deposito/pedidovenda'





  private _area = new BehaviorSubject<any[]>([]);
  get area() {
    return this._area.asObservable();
  }

  retornaParams(){

    let queryParams = new HttpParams();
    queryParams = queryParams.append("auth",environment.apiBaseHom)

    return queryParams;
  }

  constructor(private http: HttpClient) { }


  getAreas(): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}`)

  }

  getAreaPorId(areaEntidadeId:number): Observable<AreaEntidade> {
    return this.http.get<any>(`${this.baseUrl}/AreaPorID?Id=${areaEntidadeId}`)

  }


  getAreaEntidades(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAreaEntidade: AreaEntidade[],
    quantidade: number
  }> {
    return this.http.get<{
      listaAreaEntidade: AreaEntidade[],
      quantidade: number
    }>(
      `${this.baseUrl}?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
  
  getPesquisaTodosAreaEntidades(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaEntidade: AreaEntidade[],
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaEntidade: AreaEntidade[],
    quantidadeTotal: number;
    }>(
      `${this.baseUrl}/AreaEntidadePesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAreasPorIdEntidade(entidadeId:number): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/areasporentidade?entidadeId=${entidadeId}`)

  }
  addAreaEntidade(areaEntidadeNovo: AreaEntidade): Observable<any> {
    return this.http.post(`${this.baseUrl}`, areaEntidadeNovo)
      .pipe(
        map(response => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return of(error);
        })
      );
  }

  updatAreaEntidade(areaEntidadeEdit: AreaEntidade): Observable<any> {
    return this.http.put(`${this.baseUrl}`, areaEntidadeEdit)
      .pipe(
        map(response => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return of(error);
        })
      );
  }
  public delete = (
    areaId: number
  ): Observable<AreaEntidade | HttpErrorResponse> => {
    return this.http.delete<AreaEntidade>(`${this.baseUrl}/${areaId}`).pipe(
      map((res: AreaEntidade) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };




  }

