import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TimepickerModule } from "ngx-bootstrap/timepicker";

import { NgSelectModule } from "@ng-select/ng-select";
import { ModalModule } from "ngx-bootstrap/modal";
import { DialogModule } from "primeng/dialog";
import { TableModule } from "primeng/table";
import { ModalCadastroFormComponent } from "./modals-forms/cadastro-entidade/modal-cadastro-form/modal-cadastro-form.component";
import { UppercaseDirective } from "../directives/inputUpperCase.directive";

import { UiSwitchModule } from "ngx-ui-switch";
import { AccordionModule } from "primeng/accordion";
import { TabViewModule } from "primeng/tabview";
import { ToastModule } from 'primeng/toast';
import { ActionBtnComponent } from "./actionBtn/actionBtn.component";
import { LoaderComponent } from "./loader/loader.component";
import { ChipsModalComponent } from "./modal-list/chips/chips.component";
import { ModalListComponent } from "./modal-list/contas-pacotes/modal-list.component";
import { DispositivosModalComponent } from "./modal-list/dispositivos/dispositivos.component";
import { LinhaModalComponent } from "./modal-list/ativos-linha/linha/linha.component";
import { ModalListPerfisComponent } from "./modal-list/perfis/modal-list-perfis.component";
import { UsuariosModalComponent } from "./modal-list/usuarios/usuarios.component";
import { ModalTabComponent } from "./modal-tab/modal-tab.component";
import { ModalEditLinhaComponent } from "./modals-forms/modal-edit-linha/modal-edit-linha.component";
import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { StyleLoaderComponent } from "./style-loader/style-loader.component";
import { TooltipModule } from "primeng/tooltip";
import { ToastrModule } from "ngx-toastr";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { FileUploadModule } from "ng2-file-upload";
import { BandaLargaComponent } from "./modal-list/ativos-linha/banda-larga/banda-larga.component";
import { LinksComponent } from "./modal-list/ativos-linha/links/links.component";
import { FixaComponent } from "./modal-list/ativos-linha/fixa/fixa.component";
import { DataTableComponent } from "./table/table.component";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ContaFaturaModalComponent } from "./modal-list/conta-fatura/conta-fatura-modal.component";
import { DownloadModalComponent } from "./modal-list/downloads/download-modal.component";
import { MultiSelectModule } from "primeng/multiselect";
import { ConfirmDialog, ConfirmDialogModule } from "primeng/confirmdialog";
import { FileFaturasUploadComponent } from "./file-fatura-upload/file-fatura-upload.component";
import { ProgressComponent } from "./progress/progress.component";
import { ModalConfirmationComponent } from "./modal-confirmation/modal-confirmation.component";
import { VinculoListComponent } from "./vinculo-list/vinculo-list.component";
import { ModalConfirmationBtnComponent } from "./modal-confirmation-btn/modal-confirmation-btn.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { DddChangeTemplateComponent } from "./ddd-change-template/ddd-change-template.component";
import { TooltipModule as ngxTolltip } from 'ngx-bootstrap/tooltip';
// import { PacotesComponent } from "./modal-list/pacotes/pacotes.component";
import {
  CURRENCY_MASK_CONFIG,
  CurrencyMaskConfig,
  CurrencyMaskModule,
} from "ng2-currency-mask";
import { LabelFormComponent } from "./label-form/label-form.component";
import { FormatCNPJPipeModals } from "./pipes/cnpjFormatModals.pipe";
import { ProgessCsvComponent } from './progess-csv/progess-csv.component';
import { ModalComponent } from "./modal/modal.component";

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: ".",
};

@NgModule({
  declarations: [
    ActionBtnComponent,
    BandaLargaComponent,
    ChipsModalComponent,
    ContaFaturaModalComponent,
    DataTableComponent,
    DispositivosModalComponent,
    DispositivosModalComponent,
    DownloadModalComponent,
    FileFaturasUploadComponent,
    FileUploadComponent,
    FixaComponent,
    LinhaModalComponent,
    LinksComponent,
    LoaderComponent,
    ModalCadastroFormComponent,
    ModalCadastroFormComponent,
    ModalEditLinhaComponent,
    ModalListComponent,
    ModalListPerfisComponent,
    // PacotesComponent,
    ModalTabComponent,
    PagetitleComponent,
    ProgressComponent,
    StyleLoaderComponent,
    StyleLoaderComponent,
    UsuariosModalComponent,
    ModalConfirmationComponent,
    VinculoListComponent,
    ModalConfirmationBtnComponent,
    SpinnerComponent,
    DddChangeTemplateComponent,
    LabelFormComponent,
    FormatCNPJPipeModals,
    ProgessCsvComponent,
    ModalComponent

  ],
  imports: [
    NgxMaskDirective,
    NgxMaskPipe,
    ToastModule,
    AccordionModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    CommonModule,
    ConfirmDialogModule,
    DialogModule,
    FileUploadModule,
    FormsModule,
    ModalModule.forRoot(),
    MultiSelectModule,
    NgSelectModule,
    OverlayPanelModule,
    ReactiveFormsModule,
    RouterModule,
    TableModule,
    TabViewModule,
    TimepickerModule.forRoot(),
    ToastrModule.forRoot(),
    TooltipModule,
    ngxTolltip,
    UiSwitchModule,
    CurrencyMaskModule,
  ],
  exports: [
    AccordionModule,
    ActionBtnComponent,
    BandaLargaComponent,
    ChipsModalComponent,
    ContaFaturaModalComponent,
    DataTableComponent,
    DispositivosModalComponent,
    DownloadModalComponent,
    FileFaturasUploadComponent,
    FileUploadComponent,
    FixaComponent,
    LinhaModalComponent,
    LinksComponent,
    LoaderComponent,
    ModalCadastroFormComponent,
    ModalEditLinhaComponent,
    ModalListComponent,
    ModalListPerfisComponent,
    ModalTabComponent,
    PagetitleComponent,
    StyleLoaderComponent,
    UsuariosModalComponent,
    ModalConfirmationComponent,
    VinculoListComponent,
    ModalConfirmationBtnComponent,
    SpinnerComponent,
    DddChangeTemplateComponent,
    ProgessCsvComponent,
  ],
  providers: [
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    provideNgxMask(),
  ],
})
export class UIModule {}
