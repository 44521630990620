 <!-- start page title -->
    <!-- <app-page-title title="Gestão de Contas e Faturas" [breadcrumbItems]="breadCrumbItems"></app-page-title> -->
    <div class="row">
      <div class="col-12">  
            <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-3">Upload Arquivos {{tipoUpload}}</h4>
            @if (progress) {
              <div class="progress my-3">
                <div
                  class="progress-bar progress-bar-info"
                  role="progressbar"
                  attr.aria-valuenow="{{ progress }}"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  [ngStyle]="{ width: progress + '%' }"
                >
                  {{ progress }}%
                </div>
              </div>
              }
      
        
              <div class="vertical-container flex-1 flex-col content-stretch" style="min-width: 0">
                  <div class="box-title"></div>
            
                  <div class="content-container flex flex-col content-stretch">
                    <dropzone *ngIf="type === 'component'" class="dropzone-container flex" 
                    [config]="config" [disabled]="disabled" [message]="'Clique aqui ou arraste e solte para upload'" 
                    (init)="onUploadInit($event)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
                   </dropzone>
            
                    <div *ngIf="type === 'directive'" class="dropzone dropzone-container justify-center flex flex-1" 
                    [dropzone]="config" [disabled]="disabled" (init)="onUploadInit($event)" 
                    (error)="onUploadError($event)" (success)="onUploadSuccess($event)"></div>
                  </div>
                </div>
            <!-- end dropzon-preview -->
          </div>
  
        </div>
      </div>
      <div class="row">
          <div class="card">
              <div class="card-body">
          <div class="d-flex flex-wrap gap-2">
              <button [disabled]="uploadedFiles.length ==0" type="submit"
               (click)="tipoUpload=='pdf'? uploadFiles(): tipoComponente =='fatura'? uploadDetalhadoFatura():uploadDetalhado()" 
              class="btn btn-primary">Upload Arquivos</button>
              <button type="submit" class="btn btn-secondary">Cancelar</button>
            </div>
              </div>
          </div>

      </div>
  
  </div>

