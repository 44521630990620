import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label-form',
  templateUrl: './label-form.component.html',
  styleUrls: ['./label-form.component.css']
})
export class LabelFormComponent {
  @Input() isRequired: boolean = false;
  @Input() for: string = "";
}
