<p-toast
  position="bottom-right"
  key="confirmDownload"  
  [baseZIndex]="5000"
  
>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        <span class="font-bold text-900"> Download CSV </span>
      </div>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <p style="margin-bottom: 0">Download do CSV em progresso...</p>
        <i class='bx bx-loader-circle bx-spin bx-rotate-90' ></i>
      </div>      
    </div>
  </ng-template>
</p-toast>

<p-toast
  position="bottom-right"
  key="confirmUpload"  
  [baseZIndex]="5000"
  
>
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        <span class="font-bold text-900"> Upload CSV </span>
      </div>
      <div style="display: flex; align-items: center; justify-content: space-between;">
        <p style="margin-bottom: 0">Upload do CSV em progresso...</p>
        <i class='bx bx-loader-circle bx-spin bx-rotate-90' ></i>
      </div>      
    </div>
  </ng-template>
</p-toast>
<!-- <p-button (onClick)="showConfirm()" label="View" /> -->
