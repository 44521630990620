import { Component, OnInit, Output, EventEmitter, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { Store } from "@ngrx/store";
import { Observable, Subscription, map } from "rxjs";
import { changesLayout } from "src/app/store/layouts/layout.actions";
import { getLayoutMode } from "src/app/store/layouts/layout.selector";
import { RootReducerState } from "src/app/store";
import { TokenStorageService } from "src/app/core/services/token-storage.service";
import { AuthenticationService } from "../../core/services/auth.service";
import { AuthfakeauthenticationService } from "../../core/services/authfake.service";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { NgSelectComponent } from '@ng-select/ng-select';

import { MENU } from "../sidebar/menu";
import { MenuItem } from "../sidebar/menu.model";
import { StyleManagerService } from "src/app/core/services/style-manager.service";
import { EntidadeStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/entidade.store.service";
import { FormBuilder, FormGroup, UntypedFormGroup } from "@angular/forms";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  private subscriptions: Subscription[] = [];
  icon: string;
  mode: any;
  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;
  theme: any;
  layout: string;
  dataLayout$: Observable<string>;

  openMobileMenu: boolean;
  menuItems: MenuItem[] = [];
  selectedMenuItem: MenuItem;

  entidadeSelecionada: string;
  entidadeForm: FormGroup;
  actualTheme: string;
  topbarChanged: string;
  themeChanged: boolean = false;

  // Define layoutMode as a property

  get entidadeListGlobal$() {
    return this.entidadeStoreService.entidadeListGlobal$;
  }

  get entidadeSelected$() {
    return this.entidadeStoreService.entidadeSelected$;
  }

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private authService: AuthenticationService,
    private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService,
    public tokenStorageService: TokenStorageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    public store: Store<RootReducerState>,
    private styleManager: StyleManagerService,
    private entidadeStoreService: EntidadeStoreService,
    private fb: FormBuilder
  ) { }

  listLang: any = [
    { text: "Portuguese", flag: "assets/images/flags/brasil.jpg", lang: "pt" },
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  ngOnInit() {
    this.entidadeStoreService.carregarEntidades();
    this.entidadeStoreService.carregarEntidadesGlobal();
    // window.location.reload();
    this.entidadeSelecionada = localStorage.getItem("entidadeSelecionada");
    if (localStorage.getItem('themeChanged') === 'false') {
      localStorage.setItem("theme", "dark");
    }

    if (this.tokenStorageService.getUser().entidadeId !== '1') {
      localStorage.setItem("colorChange", "false")
    }

    this.topbarChanged = localStorage.getItem("colorChange");

    this.actualTheme = localStorage.getItem("theme");

    this.entidadeForm = this.fb.group({
      entidade: [this.entidadeListGlobal$[this.entidadeSelecionada]]
    })

    this.entidadeListGlobal$.subscribe(entidades => {
      const index = entidades.findIndex(entidade => entidade.entidadeId === +this.entidadeSelecionada)
      if (entidades.length > 0) {
        this.entidadeForm.patchValue({
          entidade: entidades[index],
        });
      }
    });

    // this.initialAppState = initialState;
    this.subscriptions.push(
      this.store.select("layout").subscribe((data) => {
        this.theme = data.DATA_LAYOUT;
      })
    );

    this.subscriptions.push(
      this.styleManager.IconUrl$.subscribe((data) => {
        this.icon = data;


      })
    );

    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }

    this.menuItems = this.getMenuItems();
  }

  getMenuItems() {
    return MENU;
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  toggleTheme(isDark: string) {
    localStorage.setItem('themeChanged', 'true');
    localStorage.setItem('theme', isDark);
    localStorage.setItem('colorChange', 'false');
    this.topbarChanged = 'false'
    window.location.reload();
  }

  toggleTopbar(colorSwitch: boolean) {
    localStorage.setItem('colorChange', colorSwitch ? 'true' : 'false');
    console.log('COLOR SWITCH', colorSwitch)

    this.topbarChanged = colorSwitch ? 'true' : 'false'

    window.location.reload();
  }

  mudarEntidade(event) {
    const entidadeId = event?.value?.entidadeId;


    this.entidadeStoreService.setEntidade(+entidadeId);
    window.location.reload();
  }

  /**
   * Logout the user
   */
  logout() {
    localStorage.setItem('themeChanged', 'false');
    if (environment.defaultauth === "firebase") {
      this.authService.logout();
    } else {
      this.authService.logout();
    }
    this.router.navigate(["/auth/login"]);
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  changeLayout(layoutMode: string) {
    this.theme = layoutMode;
    this.store.dispatch(changesLayout({ layoutMode }));
    this.subscriptions.push(
      this.store.select(getLayoutMode).subscribe((layout) => {
        document.documentElement.setAttribute("data-layout", layout);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
