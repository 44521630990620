import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'dadosUsuario';
const ROLES_KEY = 'roles';
const IDENTIDADE_KEY = 'identidadeVisualEntidade';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  tokenparse: any;
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getEntidadeId(): string | null {
    return localStorage.getItem('entidadeId');
  }

  public getToken(): string | null {
    return localStorage.getItem('token');
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }
  public getRoles(): string | null {
    return localStorage.getItem(ROLES_KEY);
  }
  public getIdentidadeVisualEntidade(): string | null {
    return localStorage.getItem(IDENTIDADE_KEY);
  }

  public getUser(): any {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }

  public getTheme(): any {
    const theme = localStorage.getItem('theme');
    if (theme) {
      return theme;
    }

    return {};
  }

  public isLoggedIn(): boolean {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      return true;
    }

    return false;
  }


  public parseTokenUsuario(dataToken){
    // Decoding base64 ⇢ UTF-8
    /**
     * 
     * @param str A string de entrada a ser decodificada para uma string UTF-8
     * @returns Uma string decodificada de um base64 em UTF-8
     */
    const fromBinary = (str) =>  {
      return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
    }  
    let _token = dataToken.split('.')[1];
    this.tokenparse = JSON.parse(fromBinary(_token));
    localStorage.setItem('dadosUsuario', JSON.stringify(this.tokenparse));
    localStorage.setItem("entidadeSelecionada",this.tokenparse.entidadeId);



    var roles = this.tokenparse.roles
    localStorage.setItem('roles', roles);
    if (typeof (roles) == 'string') {
      roles = roles.split('.')
    }

    var identidade = this.tokenparse.colorStyle;

    localStorage.setItem('identidadeVisualEntidade', identidade);
  }
  public getEntidadeSelecionada(): string {
    const entidadeSelecionada = localStorage.getItem("entidadeSelecionada");
    return entidadeSelecionada;
  }

}
