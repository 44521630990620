<p-dialog
  style="width: 100%"
  [(visible)]="isOpen"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  (onHide)="fecharModal()"
  [draggable]="false"
  [resizable]="false"
>
  <ng-container [ngTemplateOutlet]="chips"></ng-container>
  <ng-template pTemplate="footer">
    <div style="display: flex; justify-content: space-between">
      <div
        [formGroup]="formSelects"
        *ngIf="formSelects"
        style="
          width: 750px;
          display: flex;
          align-items: flex-end;
          justify-content: space-around;
        "
        class="input-wrapper"
      ></div>
      <div style="display: flex; justify-content: flex-end; gap: 10px">
        <app-action-btn
          icon="fa fa-times font-size-20"
          [type]="'outlined'"
          [text]="'Limpar'"
          (click)="limparValor(chipSelecionado)"
        />
        <app-action-btn
          icon="fa fa-check font-size-20"
          [type]="''"
          id="btnSelecionarModal"
          [text]="'Confirmar'"
          (click)="selecionarValor(chipSelecionado)"
        />
      </div>
    </div>
  </ng-template>
</p-dialog>

<ng-template #chips>
  <app-table
    [data]="data$"
    [grid]="grid"
    [isOpen]="isOpen"
    (getData)="sendData($event)"
    (openModal)="editModal()"
    [title]="'Chips'"
    [dataTableHeaders]="dataTableHeadersChips"
    [exibirCheckbox]="false"
    [filterContext]="'ativoFisico'"
    [tipoSearch]="2"
    [placeholderPesquisaGlobal]="'Pesquisa em Chips'"
    [exibirFilterButton]="false"
  >
    <div
      class="dataTable-body"
      [ngStyle]="{ 'grid-template-columns': grid }"
      *ngFor="let items of tableRow$ | async; let i = index"
    >
      <div>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="items.isChecked"
          [disabled]="!items.isEnabled"
          (click)="checkOne(i)"
          [(ngModel)]="items[i]"
        />
      </div>
      <div>{{ items.descricao }}</div>
      <div style="display: flex; align-items: center">
        <span
          style="width: 50px"
          [ngClass]="
            items.status == 0
              ? 'active badge bg-danger me-1'
              : items.status == 1
              ? 'unactive badge bg-success me-1'
              : items.status == 2
              ? 'unactive badge bg-warning me-1'
              : items.status == 3
              ? 'unactive badge bg-primary me-1'
              : items.status == 4 || items.status == 5
              ? 'unactive badge bg-danger me-1'
              : 'unactive badge bg-warning me-1'
          "
        >
          {{
            items.status == 0
              ? "INATIVO"
              : items.status == 1
              ? "ATIVO"
              : items.status == 2
              ? "DESCARTE"
              : items.status == 3
              ? "NOVO"
              : items.status == 4
              ? "REPARO"
              : items.status == 5
              ? "ROUBO"
              : "USADO"
          }}
        </span>
        <span
          style="width: 100px"
          [ngClass]="
            items.estoque == 0
              ? 'active badge bg-danger me-1'
              :  items.estoque == 1
              ? 'active badge bg-info me-1'
              : 'active badge bg-warning me-1'
          "
        >
          {{ items.estoque == 0 ? "FORA DE ESTOQUE" :items.estoque == 2 ? "RESERVADO":"EM ESTOQUE" }}
        </span>
      </div>
      <div>{{ items?.ddd ?? "" }}</div>
      <div>{{ items.fabricanteDispositivo?.descricao ?? "" }}</div>
      <div>{{ items.entidade?.razaoSocial }}</div>
      <div>{{ items.titularidade?.razaoSocial }}</div>
      <div>{{ items.identificacao }}</div>
      @if(IndexChipUpdate === i){

      <div style="display: block">
        <div class="global-search">
          <input
            [(ngModel)]="numeroLinha"
            pInputText
            type="text"
            placeholder="Escreva um identificador (Número)"
            mask="(00) 00000-0000"
            [style]="ErrorNumberList ? 'border-color: red;' : ''"
          />
          <app-action-btn
            icon="mdi mdi-content-save font-size-20"
            type=""
            text=""
            pTooltip=""
            (click)="onBlurNumeroLinha(items)"
          ></app-action-btn>
        </div>
        @if(ErrorNumberList === true){
        <div class="invalid-feedback">
          <span>Informe o número da linha Valido</span>
        </div>
        }
      </div>

      }@else{
      <div>
        {{ items.numeroLinha }}

        @if(ativoLinha){
        <button
          class="table-edit-btn"
          [pTooltip]="'Alterar Numero linha'"
          (click)="OnEdit(i)"
        >
          <i class="mdi mdi-pencil"></i>
        </button>
        }
      </div>
      }
    </div>
  </app-table>
</ng-template>

<app-modal-confirmation
  [isOpen]="openEditarImei"
  customClassBtn="btn btn-success w-md"
  title="Atualizar"
  text="Realmente Deseja alterar O numero Da Linha ?"
  (fecharModalEvent)="OnChangeNumeroLinha($event)"
/>
