import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Entidade } from '../../models/entidade.model';
import { UnidadeEntidade } from '../../models/unidadeEntidade.model';
import { Pagination } from 'src/app/core/models/pagination.model';

@Injectable({
  providedIn: 'root'
})
export class UnidadeEntidadeService {

  baseUrl = environment.apiBaseHom + '/unidadeEntidade';

  constructor(private http: HttpClient) { }


 


  getUnidadeEntidades(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaUnidadeEntidade: UnidadeEntidade[],
    quantidade: number
  }> {
    return this.http.get<{
      listaUnidadeEntidade: UnidadeEntidade[],
      quantidade: number
    }>(
      `${this.baseUrl}/UnidadeEntidades?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

 


  getPesquisaTodosUnidadeEntidades(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaUnidadeEntidade: UnidadeEntidade[],
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaUnidadeEntidade: UnidadeEntidade[],
    quantidadeTotal: number;
    }>(
      `${this.baseUrl}/UnidadeEntidadePesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getListaUnionUnidadeEntidadesPorEntidadeId(entidadeId:number): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}/listaunionentidadesunidadesporentidadeid?entidadeid=${entidadeId}`)

  }

  getEnderecoPorCEP(cep:string):Observable<any>{

    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`)

  }
  

  getUnidadeEntidadePorId(Id: number): Observable<UnidadeEntidade> {
    return this.http.get<any>(`${this.baseUrl}/UnidadeEntidadePorID?Id=${Id}`)

  }


  getEntidadesGrupo(): Observable<Entidade[]> {
    return this.http.get<any>(`${this.baseUrl}/entidadesGrupo`)

  }

  getUnidadePorEntidadeId(entidadeId: number): Observable<UnidadeEntidade[]> {
    return this.http.get<any>(`${this.baseUrl}/unidadeporentidadeid?entidadeId=${entidadeId}`)

  }


  getUnidadesPrincipalPorEntidadeId(entidadeId: number): Observable<UnidadeEntidade[]> {
    return this.http.get<any>(`${this.baseUrl}/ListaUnidadesPrincipalPorEntidadeId?entidadeId=${entidadeId}`)

  }

  addUnidadeEntidade(unidadeEntidadeNovo: UnidadeEntidade): Observable<any> {
    return this.http.post(`${this.baseUrl}/unidade`, unidadeEntidadeNovo)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  addEntidade(entidadeNovo: Entidade): Observable<any> {
    return this.http.post(`${this.baseUrl}`, entidadeNovo)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  updatUnidadeEntidade(unidadeEntidadeEdit: UnidadeEntidade): Observable<any> {
    return this.http.put(`${this.baseUrl}/AtualizaUnidadeEntidade`, unidadeEntidadeEdit)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  public delete = (
    unidadeEntidadeId: number
  ): Observable<UnidadeEntidade | HttpErrorResponse> => {
    return this.http.delete<UnidadeEntidade>(`${this.baseUrl}/${unidadeEntidadeId}`).pipe(
      map((res: UnidadeEntidade) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };

}
