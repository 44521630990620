import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ContaFatura } from "../models/contaFatura.model";
import { Pagination } from "src/app/core/models/pagination.model";
import { ExportModel } from "src/app/core/models/exportModel";
import { CsvDownloadService } from "src/app/shared/services/csv-download.service";

@Injectable({
  providedIn: "root",
})
export class ContaFaturaService {
  baseUrl = environment.apiBaseHom + "/contaFatura";

  private _contaFatura = new BehaviorSubject<any[]>([]);
  get contaFatura() {
    return this._contaFatura.asObservable();
  }

  retornaParams() {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("auth", environment.apiBaseHom);

    return queryParams;
  }

  constructor(private http: HttpClient,private readonly csvDownloadService: CsvDownloadService) {}

  getContaFatura(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaContaFatura: ContaFatura[];
    quantidade: number;
  }> {
    return this.http.get<{
      listaContaFatura: ContaFatura[];
      quantidade: number;
    }>(
      `${this.baseUrl}/ListContaFatura?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getContaFaturaModal(
    pagination: Pagination,
    entidadeId: number,
    servicoId: number,
    fornecedorId: number,
    nomeFornecedor: string,
    titularidadeId: number
  ): Observable<{
    listaContaFatura: ContaFatura[];
    quantidade: number;
  }> {
    const searchTextEconding = encodeURI(nomeFornecedor.trim());

    return this.http.get<{
      listaContaFatura: ContaFatura[];
      quantidade: number;
    }>(
      `${this.baseUrl}/ListContaFaturaModal?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&servicoId=${servicoId}&fornecedorId=${fornecedorId}&nomeFornecedor=${searchTextEconding}&titularidade=${titularidadeId}`
    );
  }

  getContaFaturaPorId(contaFaturaId: number): Observable<ContaFatura> {
    return this.http.get<ContaFatura>(
      `${this.baseUrl}/ContaFaturaPorId?id=${contaFaturaId}`
    );
  }
  addContaFatura(contaFaturaNovo: ContaFatura): Observable<any> {
    return this.http.post(`${this.baseUrl}`, contaFaturaNovo).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }
  updateContaFatura(contaFatura: ContaFatura): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/AtualizaContaFatura`, contaFatura)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          return of(false);
        })
      );
  }

  getPesquisaTodosContaFatura(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaContaFatura: ContaFatura[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaContaFatura: ContaFatura[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/contaFaturapesquisatodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPesquisaTodosContaFaturaModal(
    pagination: Pagination,
    searchText: string,
    entidadeId: number,
    fornecedorId: number,
    nomeFornecedor: string,
    titularidadeId: number
  ): Observable<{
    listaContaFatura: ContaFatura[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaContaFatura: ContaFatura[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/contaFaturapesquisatodosModal?searchText=${searchText}
      &EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}
      &fornecedorId=${fornecedorId}&nomeFornecedor=${nomeFornecedor}&titularidade=${titularidadeId}`
    );
  }

  verificaReferenciaContaFatura(contaFaturaId: number): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.baseUrl}/verificaReferenciaContaFatura?contaFaturaId=${contaFaturaId}`
    );
  }
  downloadCsv(exportReq: ExportModel<any>)
    : Observable<Object> 
  {
    // const params = Object
    //   .keys(exportReq)
    //   .map(param => `${param}=${exportReq[param]}`)
    //   .join("&")
    // const url = `${this.baseDetalhadoUrl}/export?${params}`
    const url = `/contaFatura/export`
    return this.csvDownloadService.downloadCsv(url,exportReq)
    
    // return this.http.post(url,exportReq,{      
    //   responseType: 'blob'
    //   ,observe: 'response'
    // })
    // .pipe(map((response) => {
    //   return {response: response,headers: response.headers}
    // }));
  }
  public delete = (
    contaFaturaId: number
  ): Observable<ContaFatura | HttpErrorResponse> => {
    return this.http
      .delete<ContaFatura>(`${this.baseUrl}/${contaFaturaId}`)
      .pipe(
        map((res: ContaFatura) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return of(err);
        })
      );
  };  
}
