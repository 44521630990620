<!-- <section
  class="loader-section"
  *ngIf="loadActions$ | async"
  [ngStyle]="
    isFull && {
      width: '100svw',
      height: '100svh',
      'margin-left': '0',
      'margin-top': '0',
    }
  "
>
  <div class="loader">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</section> -->
<div *ngIf="loadActions$ | async">
  <span class="loader"></span>
</div>
