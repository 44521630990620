import { Component,  Input } from '@angular/core';
import { paywallStoreService } from 'src/app/core/store/paywall.store.service';
import { MenuService } from '../../sidebar-refac/menu.service';
import { menuItem } from '../../sidebar-refac/types/menu-accordion.type';


@Component({
  selector: 'app-accordion-menu-oficial',
  templateUrl: './accordion-menu-oficial.component.html',
  styleUrls: ['./accordion-menu-oficial.component.scss']
})
export class AccordionMenuOficialComponent {
  @Input() menuItems: menuItem[] = [];

  constructor(private menuService:MenuService,private PaywallStoreService : paywallStoreService) {}

  clickOnAccordion(item : menuItem,value : boolean) : void {
    console.log('teste', item)

    this.PaywallStoreService.clickedItem(item);

    item.isOpen = value;
    this.menuService.updateLocalStorage(item,value)

    if (value) { //se estiver abrindo algum accordion
      this.menuItems.forEach((menu)=> {
        if (menu !== item) {
          this.menuService.updateLocalStorage(menu,false)
          menu.isOpen = false;
        }
      })
    }
  }

  trackByFn(index: number, item: menuItem): number | undefined {
    return item.id;
  }
}
