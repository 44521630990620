import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  throwError,
} from "rxjs";
import { environment } from "src/environments/environment";
import { AtivoFisico } from "../../models/ativos-fisicos/ativoFisico.model";
import { Pagination } from "src/app/core/models/pagination.model";
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpStatusCode,
} from "@angular/common/http";
import { FabricanteDispositivo } from "../../models/dispositivo/fabricanteDispositivo.model";
import { ModeloDispositivo } from "../../models/dispositivo/modeloDispositivo.model";
import { ToastrService } from "ngx-toastr";
import { AtivoCsv } from "../../models/ativos-fisicos/ativoFisicoCsv.model";
import type { AtivoFisicoStats } from "../../models/ativos-fisicos/ativoFisicoStats";
import { TokenStorageService } from "src/app/core/services/token-storage.service";

@Injectable({
  providedIn: "root",
})
export class AtivoFisicoService {
  baseUrl = environment.apiBaseHom + "/ativofisico";
  dashboardBaseUrl = environment.apiBaseHom + "/ativofisicodashboard";
  baseUrlPeriferico = environment.apiBaseHom + "/perifericotelecom"

  private _ativoFisco = new BehaviorSubject<AtivoFisico[]>([]);
  get ativoFisco() {
    return this._ativoFisco.asObservable();
  }

  constructor(
    private http: HttpClient,
    private toastr: ToastrService,
    private tokenStorageService: TokenStorageService // private tokenStorageService: TokenStorageService
  ) {}

  getAtivosFisicos(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivoChips: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoChips: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/AtivoFisico?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
  getPesquisaTodosAtivosFisicos(
    entidadeId: number,
    searchText: string,
    pagination: Pagination
  ): Observable<{
    listaAtivoChips: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoChips: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/TodosAtivoFisicos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  GetPesquisaTodosAtivoFisicoChipsEstoqueFornecedorEntidade(
    entidadeId: number,
    unidadeId: number,
    fornecedor: string,
    searchText: string,
    pagination: Pagination
  ): Observable<{}> {
    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/pesquisaativofisicochipsporentidadefornecedor?searchText=${searchText}&EntidadeID=${entidadeId}&UnidadeID=${unidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&fornecedor=${fornecedor}`
    );
  }

  GetTodosAtivoFisicoChipsEstoqueFornecedorEntidade(
    entidadeId: number,
    unidadeId: number,
    fornecedor: string,
    pagination: Pagination,
    numeroDaLinha: string = null,
    todosMaisLinha: boolean = false,
    titularidadeId: number
  ): Observable<{}> {
    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/ativofisicochipsporentidadefornecedor?EntidadeID=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&fornecedor=${fornecedor}&categoria=2&numeroDaLinha=${numeroDaLinha}&todosMaisLinha=${todosMaisLinha}&titularidadeId=${titularidadeId}`
    );
  }

  GetTodosAtivoFisicoChipsEstoque(
    entidadeId: number,
    pagination: Pagination,
    titularidadeId: number
  ): Observable<{}> {
    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/AtivoFisicoChipsPorEntidade?EntidadeID=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&titularidadeId=${titularidadeId}`
    );
  }

  getAtivosFisicoChipsPorEntidadeTitularidade(
    pagination: Pagination,
    entidadeId: number,
    entidadeBuscaId: number,
    titularidadeId: number
  ): Observable<{
    listaAtivoDispositivo: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/ativofisicochipsporentidadetitularidade?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&entidadeBuscaId=${entidadeBuscaId}&titularidadeId=${titularidadeId}`
    );
  }

  getAtivosFisicoChips(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivoChips: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoChips: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/AtivoFisicoChips?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosFisicoChipsEstoque(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivoChips: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoChips: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/AtivoFisicoChipsEstoque?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosFisicoDispositivos(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivoDispositivo: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${
        this.baseUrl
      }/AtivoFisicoDispositivos?EntidadeId=${entidadeId}&PageNumber=${Math.ceil(
        pagination.pageNumber
      )}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosFisicoAcessorios(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivoDispositivo: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{listaAtivoDispositivo: AtivoFisico[] ;quantidadeTotal: number}>(`${this.baseUrl}/acessoriotelecom?EntidadeId=${entidadeId}&PageNumber=${Math.ceil(pagination.pageNumber)}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosFisicoDispositivosPorEntidadeTitularidade(
    pagination: Pagination,
    entidadeId: number,
    entidadeBuscaId: number,
    titularidadeId: number
  ): Observable<{
    listaAtivoDispositivo: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/ativofisicodispositivosporentidadetitularidade?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&entidadeBuscaId=${entidadeBuscaId}&titularidadeId=${titularidadeId}`
    );
  }

  getAtivosFisicoDispositivosEstoque(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivoDispositivo: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/AtivoFisicoDispositivosEstoque?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPesquisaTodosAtivoFisicoChips(
    entidadeId: number,
    searchText: string,
    pagination: Pagination
  ): Observable<{
    listaAtivoChips: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/todosativofisicochips?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getTiposAtivosFisicos(categoriaId: number): Observable<any[]> {
    return this.http.get<any>(
      `${this.baseUrl}/tiposativofisicoporcategoriaid?categoriaId=${categoriaId}`
    );
  }

  getTiposAtivosFisicosMateriais(): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}/tipoativomateriais`);
  }

  /**
   * Busca uma lista de fabricantes com base na categoria enviada
   */
  getFabricantesAtivosFisicos(
    categoriaId: number,
    tipoAtivoId: number
  ): Observable<FabricanteDispositivo[]> {
    return this.http.get<any>(
      `${this.baseUrl}/fabricanteativofisicoporcategoriaid?categoriaId=${categoriaId}&tipoAtivoId=${tipoAtivoId}`
    );
  }

  getFabricantesPerifericos() {
    return this.http.get<any>(`${this.baseUrlPeriferico}/fabricantes`);
  }

  /**
   * Busca uma lista de modelos com base na categoria e no fabricante
   */
  getModeloAtivoFisicoPorCategoriaIdFabricanteId(
    categoriaId: number,
    fabricanteId: number
  ): Observable<ModeloDispositivo[]> {
    return this.http.get<any>(
      `${this.baseUrl}/modeloativofisicoporcategoriaidfabricanteid?categoriaId=${categoriaId}&fabricanteId=${fabricanteId}`
    );
  }

  getModeloAtivoFisicoPeriferico(fabricanteId: number) {
    return this.http.get<any>(`${this.baseUrlPeriferico}/modeloFabricante?fabricanteId=${fabricanteId}`);
  }
  getAtivoFisicoChipPorId(Id: number): Observable<AtivoFisico> {
    return this.http.get<any>(
      `${this.baseUrl}/getativofisicochipporid?Id=${Id}`
    );
  }

  getAtivoFisicoDispositivoPorId(Id: number): Observable<AtivoFisico> {
    return this.http.get<any>(
      `${this.baseUrl}/getativofisicodispositivoporid?Id=${Id}`
    );
  }

  getPesquisaTodosAtivoFisicoDispositivos(
    entidadeId: number,
    searchText: string,
    pagination: Pagination
  ): Observable<AtivoFisico[]> {
    return this.http.get<any>(
      `${this.baseUrl}/PesquisaTodosAtivoFisicoDispositivos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivoFisicoPorNumeroMovel(
    entidadeId: number,
    numero: string,
    fornecedor: string
  ): Observable<AtivoFisico> {
    return this.http.get<any>(
      `${this.baseUrl}/AtivoFisicoPorNumeroMovel?movel=${numero}&entidadeId=${entidadeId}&fornecedor=${fornecedor}`
    );
  }

  getPesquisaTodoAtivosFisicosDispostivosEstoque(
    searchText: string,
    entidadeId: number,
    unidadeId: number,
    pagination: Pagination
  ): Observable<AtivoFisico[]> {
    return this.http.get<any>(
      `${this.baseUrl}/pesquisatodosativofisicodispositivoestoque?searchText=${searchText}&EntidadeID=${entidadeId}&UnidadeID=${unidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  // POSTS

  /**
   * Adiciona um novo ativo físico
   */
  addAtivoFisico(ativoFisico: AtivoFisico): Observable<any> {
    return this.http.post(`${this.baseUrl}`, ativoFisico).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(error);
      })
    );
  }

  // EDIT

  updateAtivoFisicoChip(ativoFisico: AtivoFisico[]): Observable<any> {
    return this.http.put(`${this.baseUrl}/atualizachip`, ativoFisico).pipe(
      map((response) => {
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === HttpStatusCode.Conflict
        ) {
          this.toastr.error(
            "Não é possivel alterar a entidade de um chip que esta ativo em outra entidade ",
            "Chip",
            { timeOut: 5000, progressBar: true }
          );
        }
        if (
          err instanceof HttpErrorResponse &&
          err.status === HttpStatusCode.NotModified
        ) {
          this.toastr.error(
            "Não é posivel alterar entidade pois este ativo não pertence a mobit",
            "Chip",
            {
              timeOut: 5000,
              progressBar: true,
            }
          );
        }
        if (
          err instanceof HttpErrorResponse &&
          err.status === HttpStatusCode.NotAcceptable
        ) {
          if (err.error) {
            this.toastr.error(err.error, "Chip", {
              timeOut: 5000,
              progressBar: true,
            });
          } else {
            this.toastr.error(
              "Não é possivel alterar a entidade de um chip que esta vinculodo a um ativo em outra entidade ",
              "Chip",
              { timeOut: 5000, progressBar: true }
            );
          }
        }

        return of(err);
      })
    );
  }

  updateAtivoFisicoDispositivo(ativoFisico: AtivoFisico[]): Observable<any> {
    return this.http
      .put(`${this.baseUrl}/atualizadispositivo`, ativoFisico)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          if (
            err instanceof HttpErrorResponse &&
            err.status === HttpStatusCode.Conflict
          ) {
            this.toastr.error(
              "Não é possivel alterar a entidade de um Dispositivo que esta ativo em outra entidade ",
              "Chip",
              { timeOut: 5000, progressBar: true }
            );
          }
          if (
            err instanceof HttpErrorResponse &&
            err.status === HttpStatusCode.NotModified
          ) {
            this.toastr.error(
              "Não é posivel alterar entidade pois este ativo não pertence a mobit",
              "Chip",
              {
                timeOut: 5000,
                progressBar: true,
              }
            );
          }

          return of(err);
        })
      );
  }

  // Delete
  inativarAtivoFisicoPorErro(ativoFisicoId: number): Observable<any> {
    return this.http
      .delete(`${this.baseUrl}/inativarativoporerro?Id=${ativoFisicoId}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          return of(false);
        })
      );
  }

  /**
   * Baixo o csv de ativos físicos
   */
  downloadCsv(ativoFisicoCsv: AtivoCsv): Observable<any> {    
    return this.http
      .post(`${this.baseUrl}/downloadcsv`, ativoFisicoCsv, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((response) => {
          return { response: response, headers: response.headers };
        })
      );
  }

  inativarAtivo(ativoFisicoId: number): Observable<any> {
    return this.http
      .delete(`${this.baseUrl}/inativarativo?Id=${ativoFisicoId}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          return of(false);
        })
      );
  }

  EditarEmei(
    AtivoFisicoId: number,
    newDiscription: string,
    AtivoFisicoDescricao: string
  ): Observable<any> {
    return this.http
      .patch(`${this.baseUrl}/AlterarIMAI`, {
        AtivoFisicoId,
        newDiscription,
        AtivoFisicoDescricao,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err: HttpErrorResponse) => {
          if (
            err instanceof HttpErrorResponse &&
            err.status === HttpStatusCode.Conflict
          ) {
            this.toastr.error(
              "Não foi Possivel alterar pois esse ativo Ja existe",
              "Negado",
              { timeOut: 5000, progressBar: true }
            );
          }

          return of(err);
        })
      );
  }

  // START DASHBOARDS ROUTES

  GetAtivoFisicoStats(
    entidadeId: number,
    categoria: number,
    isAll: boolean
  ): Observable<{
    listaAtivoDispositivo: AtivoFisico[];
    stats: AtivoFisicoStats;
    quantidadeTotal: number;
  }> {
    let ativoFisicoStatsParams = new HttpParams();
    ativoFisicoStatsParams = isAll
      ? ativoFisicoStatsParams.append("isAll", isAll)
      : ativoFisicoStatsParams;

    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      stats: AtivoFisicoStats;
      quantidadeTotal: number;
    }>(
      `${this.dashboardBaseUrl}/ativofisicostatporcategoria?EntidadeID=${entidadeId}&categoria=${categoria}`, {params: ativoFisicoStatsParams}
    );
  }

  GetDispositivoDashboardsDrillDown(
    entidadeId?: number,
    emEstoque?: boolean,
    areaId?: number,
    centroCustoId?: number,
    isAll?: boolean,
    locacaoId?: number,
    tipoDispositivoId?: number,
    marcaId?: number,
    modelo?: string
  ): Observable<{
    idTipo: number;
    idMarca: number;
    value: number;
    category: string;
  }> {
    let dispositivoParams = new HttpParams();

    dispositivoParams = areaId
      ? dispositivoParams.append("areaId", areaId.toString())
      : dispositivoParams;
    dispositivoParams = centroCustoId
      ? dispositivoParams.append("CentroDeCustoId", centroCustoId.toString())
      : dispositivoParams;
    dispositivoParams = tipoDispositivoId
      ? dispositivoParams.append(
          "tipoDispositivoId",
          tipoDispositivoId.toString()
        )
      : dispositivoParams;
    dispositivoParams = marcaId
      ? dispositivoParams.append("marcaId", marcaId.toString())
      : dispositivoParams;
    dispositivoParams = modelo
      ? dispositivoParams.append("modelo", modelo)
      : dispositivoParams;
    dispositivoParams =
      typeof emEstoque === "boolean"
        ? dispositivoParams.append("emEstoque", emEstoque.toString())
        : dispositivoParams;
    dispositivoParams = dispositivoParams.append("EntidadeId", entidadeId);
    dispositivoParams = isAll
      ? dispositivoParams.append("isAll", isAll)
      : dispositivoParams;
    // dispositivoParams = dispositivoParams.append("LocacaoId", locacaoId);

    dispositivoParams = locacaoId
      ? dispositivoParams.append("LocacaoId", locacaoId)
      : dispositivoParams;

    return this.http.get<{
      idTipo: number;
      idMarca: number;
      value: number;
      category: string;
    }>(`${this.dashboardBaseUrl}/dispositivo?`, {
      params: dispositivoParams,
    });
  }

  GetChipDashboardsDrillDown(
    entidadeId?: number,
    emEstoque?: boolean,
    areaId?: number,
    centroCustoId?: number,
    isAll?: boolean,
    unidadeId?: number,
    operadoraId?: number,
    ddd?: number,
    alocacao?: string
  ): Observable<{
    operadoraId?: number;
    ddd?: number;
    value?: number;
    category?: string;
  }> {
    let chipParams = new HttpParams();

    chipParams = areaId
      ? chipParams.append("areaId", areaId.toString())
      : chipParams;
    chipParams = centroCustoId
      ? chipParams.append("CentroDeCustoId", centroCustoId.toString())
      : chipParams;
    chipParams = operadoraId
      ? chipParams.append("operadoraId", operadoraId.toString())
      : chipParams;
    chipParams = ddd ? chipParams.append("ddd", ddd.toString()) : chipParams;
    chipParams =
      typeof emEstoque === "boolean"
        ? chipParams.append("emEstoque", emEstoque.toString())
        : chipParams;
    chipParams = chipParams.append("EntidadeId", entidadeId);
    chipParams = isAll ? chipParams.append("isAll", isAll) : chipParams;
    chipParams = unidadeId
      ? chipParams.append("unidadeId", unidadeId)
      : chipParams;
    chipParams = alocacao
      ? chipParams.append("Alocacao", alocacao)
      : chipParams;

    return this.http.get<{
      operadoraId?: number;
      ddd?: number;
      value?: number;
      category?: string;
    }>(`${this.dashboardBaseUrl}/chips?`, { params: chipParams });
  }

  GetDispositivoTable(
    entidadeId?: number,
    emEstoque?: boolean,
    areaId?: number,
    centroCustoId?: number,
    isAll?: boolean,
    locacaoId?: number,
    pagination?: Pagination,
    tipoDispositivoId?: number,
    marcaId?: number,
    modelo?: string
  ): Observable<{
    idTipo: number;
    idMarca: number;
    value: number;
    category: string;
  }> {
    let dispositivoParams = new HttpParams();

    dispositivoParams = areaId
      ? dispositivoParams.append("areaId", areaId.toString())
      : dispositivoParams;
    dispositivoParams = centroCustoId
      ? dispositivoParams.append("CentroDeCustoId", centroCustoId.toString())
      : dispositivoParams;
    dispositivoParams = dispositivoParams.append("isAll", isAll);
    dispositivoParams = locacaoId
      ? dispositivoParams.append("LocacaoId", locacaoId)
      : dispositivoParams;
    dispositivoParams = tipoDispositivoId
      ? dispositivoParams.append(
          "tipoDispositivoId",
          tipoDispositivoId.toString()
        )
      : dispositivoParams;
    dispositivoParams = marcaId
      ? dispositivoParams.append("marcaId", marcaId.toString())
      : dispositivoParams;
    dispositivoParams = modelo
      ? dispositivoParams.append("modelo", modelo)
      : dispositivoParams;
    dispositivoParams =
      typeof emEstoque === "boolean"
        ? dispositivoParams.append("emEstoque", emEstoque.toString())
        : dispositivoParams;
    dispositivoParams = dispositivoParams.append("EntidadeId", entidadeId);
    dispositivoParams = dispositivoParams.append(
      "PageNumber",
      pagination.pageNumber
    );
    dispositivoParams = dispositivoParams.append(
      "PageSize",
      pagination.pageSize
    );

    return this.http.get<{
      idTipo: number;
      idMarca: number;
      value: number;
      category: string;
    }>(`${this.dashboardBaseUrl}/tabledispositivo?`, {
      params: dispositivoParams,
    });
  }

  GetChipTable(
    entidadeId?: number,
    emEstoque?: boolean,
    areaId?: number,
    centroCustoId?: number,
    isAll?: boolean,
    unidadeId?: number,
    pagination?: Pagination,
    operadoraId?: number,
    ddd?: number,
    alocacao?: string
  ): Observable<AtivoFisico> {
    let chipParams = new HttpParams();

    chipParams = areaId
      ? chipParams.append("areaId", areaId.toString())
      : chipParams;
    chipParams = centroCustoId
      ? chipParams.append("centroDeCustoId", centroCustoId.toString())
      : chipParams;
    chipParams = unidadeId
      ? chipParams.append("UnidadeId", unidadeId.toString())
      : chipParams;
    chipParams = operadoraId
      ? chipParams.append("operadoraId", operadoraId.toString())
      : chipParams;
    chipParams = ddd ? chipParams.append("ddd", ddd.toString()) : chipParams;
    chipParams = alocacao
      ? chipParams.append("alocacao", alocacao.toString())
      : chipParams;
    chipParams =
      typeof emEstoque === "boolean"
        ? chipParams.append("emEstoque", emEstoque.toString())
        : chipParams;
    chipParams = chipParams.append("EntidadeId", entidadeId);
    chipParams = chipParams.append("PageNumber", pagination.pageNumber);
    chipParams = chipParams.append("PageSize", pagination.pageSize);

    return this.http.get<AtivoFisico>(`${this.dashboardBaseUrl}/tablechip?`, {
      params: chipParams,
    });
  }

  // END DASHBOARDS ROUTES
}
