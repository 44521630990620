import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { UIModule } from "./ui/ui.module";

import { WidgetModule } from "./widget/widget.module";

import { DROPZONE_CONFIG, DropzoneConfigInterface, DropzoneModule } from "ngx-dropzone-wrapper";
import { FileListComponent } from './pages/file-list/file-list.component';
import { FileFaturasUploadComponent } from "./pages/file-upload/file-upload.component";
import {  UppercaseDirective } from "./directives/inputUpperCase.directive";
import { ReadonlyAllDirective } from "./directives/readonly-all.directive";
import { OnlyNumbersDirective } from "./directives/onlyNumbers.directive";
import { CopyDateDirective } from "./directives/copyDate.directive";
import { FormatCNPJPipe } from "./pipes/cnpjFormat.pipe";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: 'https://httpbin.org/post',
  maxFilesize: 200,
  
  //acceptedFiles: 'image/*'
};

@NgModule({
  declarations: [
    FileFaturasUploadComponent,
    FileListComponent,
    UppercaseDirective,    
    OnlyNumbersDirective,
    ReadonlyAllDirective,
    FormatCNPJPipe,
    CopyDateDirective
  ],
  imports: [
    CommonModule, 
    UIModule, 
    WidgetModule, 
    RouterModule,
    DropzoneModule,
  ],
  providers: [

    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ],
  exports: [UIModule,FileFaturasUploadComponent,UppercaseDirective,FormatCNPJPipe, OnlyNumbersDirective, ReadonlyAllDirective],
})
export class SharedModule {}
