import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Entidade } from "../../models/entidade.model";
import { UnidadeEntidade } from "../../models/unidadeEntidade.model";
import { Pagination } from "src/app/core/models/pagination.model";

@Injectable({
  providedIn: "root",
})
export class EntidadeService {
  baseUrl = environment.apiBaseHom + "/entidade";

  constructor(private http: HttpClient) {}

  getEntidades(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaEntidade: Entidade[];
    quantidade: number;
  }> {
    return this.http.get<{
      listaEntidade: Entidade[];
      quantidade: number;
    }>(
      `${this.baseUrl}/Entidades?entidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  GetListaEntidadeGlobal(): Observable<Entidade[]> {
    return this.http.get<Entidade[]>(
      `${this.baseUrl}/ListaEntidadesGlobal`
    );
  }

  getEntidadesLista(entidadeId?: number): Observable<Entidade[]> {
    return this.http.get<any>(`${this.baseUrl}/ListaEntidades?entidadeId=${entidadeId}`);
  }

  getPesquisaTodosEntidades(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaEntidade: Entidade[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaEntidade: Entidade[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/EntidadePesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPesquisaTodosUnidadeEntidades(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaUnidadeEntidade: UnidadeEntidade[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaUnidadeEntidade: UnidadeEntidade[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/UnidadeEntidadePesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getEnderecoPorCEP(cep: string): Observable<any> {
    return this.http.get<any>(`https://viacep.com.br/ws/${cep}/json/`);
  }

  getEntidadePorId(Id: number): Observable<Entidade> {
    return this.http.get<any>(`${this.baseUrl}/entidadeporid?Id=${Id}`);
  }

  getEntidadesGrupo(): Observable<Entidade[]> {
    return this.http.get<any>(`${this.baseUrl}/entidadesGrupo`);
  }

  getUnidadePorEntidadeId(entidadeId: number): Observable<UnidadeEntidade[]> {
    return this.http.get<any>(
      `${this.baseUrl}/unidadeporentidadeid?entidadeId=${entidadeId}`
    );
  }

  addUnidadeEntidade(unidadeEntidadeNovo: UnidadeEntidade): Observable<any> {
    return this.http.post(`${this.baseUrl}/unidade`, unidadeEntidadeNovo).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  addEntidade(entidadeNovo: Entidade): Observable<any> {
    return this.http.post(`${this.baseUrl}`, entidadeNovo).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  updatEntidade(entidadeEdit: Entidade): Observable<any> {
    return this.http.put(`${this.baseUrl}/AtualizaEntidade`, entidadeEdit).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return of(false);
      })
    );
  }

  public delete = (
    entidadeId: number
  ): Observable<Entidade | HttpErrorResponse> => {
    return this.http.delete<Entidade>(`${this.baseUrl}/${entidadeId}`).pipe(
      map((res: Entidade) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };


}
