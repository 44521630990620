import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { EntidadeStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/entidade.store.service";
import { PerfilCatalogo } from "src/app/modulos/telecom/ativo-linha/core/models/ativos-linha/perfilCatalogo.model";
import { ContaLinha } from "src/app/modulos/telecom/ativo-linha/core/models/contaLinha.model";
import { PacoteRI } from "src/app/modulos/telecom/ativo-linha/core/models/pacoteRI.model";
import { Fornecedor } from "src/app/modulos/telecom/cadastro/core/models/fornecedor.model";
import { FornecedorStoreService } from "src/app/modulos/telecom/cadastro/core/store/fornecedor.store.service";
import { ModalEditLinhaFormService } from "./services/modal-edit-linha-form.service";
import { AtivoLinha } from "src/app/modulos/telecom/ativo-linha/core/models/ativoLinha.model";
import { AtivoLinhaService } from "src/app/modulos/telecom/ativo-linha/core/services/ativo-linha.service";
import { ToastrService } from "ngx-toastr";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { FilterStoreService } from "src/app/core/store/filter.store.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Entidade } from "src/app/modulos/telecom/cadastro/core/models/entidade.model";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { PaginatorStoreService } from "src/app/core/store/paginator.store.service";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";

@Component({
  selector: "app-modal-edit-linha",
  templateUrl: "./modal-edit-linha.component.html",
  styleUrls: ["./modal-edit-linha.component.css"],
  providers: [
    TableStoreService,
    FilterStoreService,
    PaginatorStoreService,
    PaginatorGenericStoreService,
  ],
})
export class ModalEditLinhaComponent {
  @Input() isOpen: boolean = false;
  @Input() isLinha: boolean = false;
  @Input() title: string;
  // Movel - Banda Larga - Link - Fixa
  @Input() ativoTitle: string;

  @Input() selectedAtivos: AtivoLinha[];

  @Input() isServiceIquals$: Observable<boolean> = new Observable<boolean>();
  isServiceIquals = false;

  @Output() closeModal = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  filtredListSubject: BehaviorSubject<Entidade[]> = new BehaviorSubject<
    Entidade[]
  >([]);
  entidadesFiltradas: Entidade[];

  // Selecionados
  fornecedorSelecionado: Fornecedor = null;
  entidadeSelecionada: number = 0;
  titularidadeSelecionada: number = 0;
  servicoLinhaSelecionado: number = 0;

  titularidadeParaContaSelecionada: number = 0;
  // formLinhaMovel: FormGroup;
  // formFixaLinkDados: FormGroup;
  formAtual: FormGroup;

  txtConfirmacao: string;

  public modalConfirmIsOpen: boolean = false;

  get entidadesList$() {
    return this.entidadeStore.entidadeList$;
  }
  get fornecedorList$() {
    return this.fornecedorStoreService.$FornecedorList;
  }
  get filtredList$() {
    return this.filtredListSubject.asObservable();
  }

  get entidade() {
    return this.formAtual.get("entidade").value;
  }
  get titularidade() {
    return this.formAtual.get("titularidade").value;
  }
  get fornecedor() {
    return this.formAtual.get("fornecedor").value;
  }

  contaSelecionada: ContaLinha;
  perfilSelecionado: PerfilCatalogo;
  pacoteSelecionado: PacoteRI;
  categoria: number = 0;

  openModalConta: boolean = false;
  openModalPerfil: boolean = false;
  openModalPacote: boolean = false;
  public mostrarCamposRelacionadosEntidade: boolean = false;

  constructor(
    private entidadeStore: EntidadeStoreService,
    private fornecedorStoreService: FornecedorStoreService,
    private toastr: ToastrService,
    private modelEditFormService: ModalEditLinhaFormService,
    private ativoLinhaService: AtivoLinhaService
  ) {}

  ngOnInit() {
    // this.formAtual.reset();
    // this.entidadeSelecionada = null;
    // this.titularidadeSelecionada = null;
    this.fornecedorStoreService.carregarListaOperadoras("M");
    // Movel - Banda Larga - Link - Fixa
    const servicos = {
      Movel: 1,
      Fixa: 2,
      "Banda Larga": 3,
      Link: 6,
    };

    this.categoria = servicos[this.ativoTitle];

    this.ativoTitle === "Movel"
      ? (this.formAtual =
          this.modelEditFormService.getAtivoLinhaMovelEditForm())
      : (this.formAtual =
          this.modelEditFormService.getAtivoLinhaFixaDadosEditForm());

    this.isServiceIquals$.subscribe((b) => {
      this.isServiceIquals = b;
      if (!b) {
        this.formAtual.get("entidade").enable();
        this.formAtual.get("titularidade").enable();
        this.formAtual.get("fornecedor").enable();
        this.formAtual.get("conta").enable();
        this.formAtual.get("perfil").enable();
        this.formAtual.get("pacoteRI")?.enable();
      } else {
        this.formAtual.get("entidade").disable();
        this.formAtual.get("titularidade").disable();
        this.formAtual.get("fornecedor").disable();
        this.formAtual.get("conta").disable();
        this.formAtual.get("perfil").disable();
        this.formAtual.get("pacoteRI")?.disable();
      }
    });

    this.entidadeStore.titularidadeList$.subscribe((entidade) => {
      this.entidadesFiltradas = entidade;
      this.getTitularidadeList();
    });

    this.formAtual.get("uf").valueChanges.subscribe((value) => {
      if (value) {
        this.formAtual
          .get("uf")
          .setValue(value.toUpperCase(), { emitEvent: false });
      }
    });

    const idEntidadesSelecionadas = [
      ...new Set(this.selectedAtivos.map((x) => x.entidadeId)),
    ];

    if (idEntidadesSelecionadas.length > 1) {
      this.mostrarCamposRelacionadosEntidade = false;
    } else {
      this.mostrarCamposRelacionadosEntidade = true;
      const controlEntidade = this.formAtual.get("entidade");
      controlEntidade.disable();

      this.formAtual.patchValue({
        entidade: idEntidadesSelecionadas[0],
      });

      this.entidadeSelecionada !== idEntidadesSelecionadas[0] && this.formAtual.get("perfil").setValue(null);
      this.entidadeSelecionada = idEntidadesSelecionadas[0];

      this.getTitularidadeList();
    }    
  }

  editModal() {
    this.formAtual.reset();
    this.closeModal.emit();
  }

  editModalConta() {
    if (this.fornecedorSelecionado === null || this.entidadeSelecionada === 0) {
      this.toastr.error(
        "Selecione um fornecedor e entidade primeiro!",
        "Faltando fornecedor ou entidade",
        { positionClass: "toast-top-center" }
      );
      this.formAtual.get("fornecedor").markAsTouched();
      this.formAtual.get("entidade").markAsTouched();
      return;
    }

    this.openModalConta = !this.openModalConta;
  }

  editModalPerfil() {
    if (
      this.fornecedorSelecionado === null ||
      this.entidadeSelecionada === 0 ||
      this.categoria === 0
    ) {
      this.toastr.error(
        "Selecione um fornecedor, entidade e serviço primeiro!",
        "Faltando fornecedor, entidade ou serviço",
        { positionClass: "toast-top-center" }
      );
      this.formAtual.get("fornecedor").markAsTouched();
      this.formAtual.get("entidade").markAsTouched();
      this.formAtual.get("categoria").markAsTouched();
      return;
    } else if (!this.isServiceIquals)
      this.openModalPerfil = !this.openModalPerfil;
  }

  editModalPacote() {
    if (
      this.fornecedorSelecionado === null ||
      this.entidadeSelecionada === 0 ||
      this.categoria === 0
    ) {
      this.toastr.error(
        "Selecione um fornecedor, entidade e serviço primeiro!",
        "Faltando fornecedor, entidade ou serviço",
        { positionClass: "toast-top-center" }
      );
      this.formAtual.get("fornecedor").markAsTouched();
      this.formAtual.get("entidade").markAsTouched();
      this.formAtual.get("categoria").markAsTouched();
      return;
    } else if (!this.isServiceIquals)
      this.openModalPacote = !this.openModalPacote;
  }

  salvarValor(valor: any) {
    if (valor.type === "Conta") {
      this.contaSelecionada = valor.value;

      this.formAtual.patchValue({
        conta: `${this.contaSelecionada.identificador} - ${this.contaSelecionada.fornecedor.nomeUsual}`,
      });
    } else if (valor.type === "Perfil") {
      this.perfilSelecionado = valor.value;

      this.formAtual.patchValue({
        perfil: `${this.perfilSelecionado.descricao} - ${this.perfilSelecionado.fornecedor.nomeUsual}`,
      });
    } else if (valor.type === "PacoteRI") {
      this.pacoteSelecionado = valor.value;

      this.formAtual.patchValue({
        pacoteRI: `${this.pacoteSelecionado.descricaoPlano} - ${this.pacoteSelecionado.fornecedor.nomeUsual}`,
      });
    }
  }

  mudarFornecedor(fornecedor: Fornecedor) {
    if (fornecedor !== undefined) {
      this.fornecedorSelecionado = fornecedor;

      this.formAtual.get("perfil").setValue(null);
      this.formAtual.get("conta").setValue(null);
    }
  }

  mudarEntidade(entidade: Entidade) {
    if (entidade !== undefined) {
      this.entidadeSelecionada !== entidade.entidadeId &&
        this.formAtual.get("perfil").setValue(null);

      this.entidadeSelecionada = entidade.entidadeId;
    }

    this.getTitularidadeList();
  }

  mudarTitularidade(entidade: Entidade) {
    if (entidade !== undefined) {
      this.titularidadeSelecionada = entidade.entidadeId;
    }

    this.getTitularidadeList();
  }

  mudarCategoria(servicoId: number) {
    if (servicoId !== undefined) {
      this.servicoLinhaSelecionado = servicoId;
    }
  }

  getTitularidadeList() {
    const filtredList = this.entidadesFiltradas
      .filter(
        (entidade) =>
          entidade.entidadeId === 1 ||
          this.entidadeSelecionada === entidade.entidadeId ||
          this.entidade === entidade?.entidadeId
      )
      .map((entidade) => {
        const newEntidade = { ...entidade };

        if (entidade.entidadeId !== 1) {
          newEntidade.razaoSocial = "CLIENTE";
          newEntidade.nomeUsual = "CLIENTE";
          return newEntidade;
        }

        return entidade;
      });
    this.filtredListSubject.next(filtredList);
  }

  limparValor(valor: string) {
    if (valor === "Conta") {
      this.contaSelecionada = null;
      this.editModalConta();

      this.formAtual.patchValue({
        conta: null,
      });
    } else if (valor === "Perfil") {
      this.perfilSelecionado = null;
      this.editModalPerfil();

      this.formAtual.patchValue({
        perfil: null,
        inicioVigencia: null,
        fimVigencia: null,
      });
    } else if (valor === "PacoteRI") {
      this.pacoteSelecionado = null;
      this.editModalPacote();

      this.formAtual.patchValue({
        pacoteRI: null,
      });
    }
  }

  preSubmit() {
    this.modalConfirmIsOpen = true;

    const newArray = [...this.selectedAtivos];
    let updatedArray: AtivoLinha[] = [];

    this.selectedAtivos.forEach((element) => {
      if (
        this.entidadeSelecionada &&
        this.entidadeSelecionada !== element.entidadeId
      ) {
        this.txtConfirmacao =
          "Você mudou a entidade de um dos itens selecionados, com isso você irá mudar de entidade da linha, você tem certeza disso?";
      } else {
        this.txtConfirmacao =
          "Você tem certeza que alterar em massa esse(s) item(s)";
      }
    });
  }

  public confirmSubmit = (action: boolean): void => {
    action ? this.submitForm() : false;
    this.modalConfirmIsOpen = false;
  };

  submitForm() {
    const newArray = [...this.selectedAtivos];
    let updatedArray: AtivoLinha[] = [];

    let ativoLinha: AtivoLinha;
    if (this.ativoTitle === "Movel") {
      ativoLinha = this.modelEditFormService.pegarDadosMovelEditForm(
        this.formAtual,
        this.contaSelecionada?.contaFaturaId,
        this.perfilSelecionado?.perfilCatalogoId,
        this.pacoteSelecionado?.pacoteRIId
      );

      const updatedProperties = [
        "entidadeId",
        "titularidadeId",
        "status",
        "fornecedorId",
        "contaFaturaId",
        "perfilCatalogoId",
        "pacoteRIId",
        "ddd",
        "uf",
        "recebimentoLinha",
        "inicioVigencia",
        "fimVigencia",
        "protocoloCancelamento",
        "bloqueioSuspensao",
        "protocoloBloqueio",
        "motivoBloqueio",
        "previsaoReativacaoAutomatico",
        "desbloqueioReativacao",
        "ativacaoLinhaOperadora",
        "inativacaoLinhaOperadora",
        "usuarioLog",
      ];

      updatedArray = newArray.map((ativo) => {
        updatedProperties.forEach((p) => {
          ativo[p] = ativoLinha[p] !== null ? ativoLinha[p] : ativo[p];
        });

        return ativo;
      });
    } else {
      ativoLinha = this.modelEditFormService.pegarDadosLinhaFixaEditForm(
        this.formAtual,
        this.contaSelecionada?.contaFaturaId,
        this.perfilSelecionado?.perfilCatalogoId
      );

      const updatedProperties = [
        "servicoId",
        "entidadeId",
        "titularidadeId",
        "status",
        "fornecedorId",
        "contaFaturaId",
        "perfilCatalogoId",
        "uf",
        "nomeFixaLinkDados",
        "ativacaoLinhaOperadora",
        "inativacaoLinhaOperadora",
        "recebimentoLinha",
        "inicioVigencia",
        "fimVigencia",
        "usuarioLog",
        "enderecoInstalacao",
      ];

      updatedArray = newArray.map((ativo) => {
        let newAtivo = { ...ativo };
        updatedProperties.forEach((p) => {
          newAtivo[p] = ativoLinha[p] !== null ? ativoLinha[p] : ativo[p];
        });
        return newAtivo as AtivoLinha;
      });
    }

    this.ativoLinhaService
      .updateAtivoLinha(updatedArray)
      .subscribe((result) => {
        if (result instanceof HttpErrorResponse) {
          if (result.status !== HttpStatusCode.Conflict) {
            this.toastr.error("Erro ao atualizar", "Error");
          }
          return;
        }

        this.toastr.success("Linhas editadas com sucesso!", "Sucesso");
        this.formAtual.reset();
        this.closeModal.emit(true);
      });
  }
}
