import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DropzoneComponent, DropzoneConfigInterface, DropzoneDirective } from 'ngx-dropzone-wrapper';
import { Observable } from 'rxjs';

import { HttpEventType, HttpResponse } from '@angular/common/http';
import { FileService } from '../../services/file.service';
import { FileModel } from 'src/app/core/models/fileModel';
import { DomSanitizer } from '@angular/platform-browser';
import { Fatura } from 'src/app/modulos/telecom/conta-fatura/core/models/fatura.model';

@Component({
  selector: 'app-file-fatura-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileFaturasUploadComponent {
  @Input() dadosArquivo: any;
  @Input() tipoUpload:string;
  @Input() dadosContaFatura:Fatura;
  @Input() fileModel:FileModel;
  @Input() tipoComponente:string;


  @Output() enviaNomeArquivo = new EventEmitter<string>();

  @ViewChild(DropzoneComponent, { static: false })
  componentRef?: DropzoneComponent;
  @ViewChild(DropzoneDirective, { static: false })
  directiveRef?: DropzoneDirective;

  fileUrl;
  selectedFiles?: FileList;

  nomeArquivo: string = "";
  showSpinner: Boolean = false;

  public config: DropzoneConfigInterface = {
    clickable: true,
    maxFiles: 10,
    autoReset: null,
    errorReset: null,
    cancelReset: null,
  };
  currentFile?: File;
  progress = 0;
  message = "";
  fileInfos?: Observable<any>;
  loading:boolean = false;



  //fileModel:FileModel = <FileModel>{}; ;


  public type: string = 'component';

  public disabled: boolean = false;



  constructor(private fileService:FileService,private sanitizer: DomSanitizer)  { }


  // bread crumb items
  breadCrumbItems: Array<{}>;
  // Form submition
  submit: boolean;
  files: File[] = [];

  ngOnInit() {
    this.breadCrumbItems = [{ label: 'Gestão de Contas e Faturas' }, { label: 'Upload Arquivos', active: true }];



  }

  // file upload
  public dropzoneConfig: DropzoneConfigInterface = {
    clickable: true,
    addRemoveLinks: true,
    previewsContainer: false,
  };

  uploadedFiles: any[] = [];

  // File Upload
  imageURL: any;


  uploadFiles()
  {


    // this.loading = true
    // this.fileService.upload(this.uploadedFiles,'faturas-claro',this.dadosArquivo).subscribe({
    //   next: (event: any) => {
    //     if (event.type === HttpEventType.UploadProgress) {
    //       this.progress = Math.round((100 * event.loaded) / event.total);
    //     } else if (event instanceof HttpResponse) {
    //       this.message = event.body.message;
    //       // this.fileInfos = this.uploadService.getFiles();
    //     }
    //   },
    //   error: (err: any) => {
    //     if (err.error && err.error.message) {
    //       this.message = err.error.message;
    //     } else {
    //       this.message = "Could not upload the file!";
    //     }

    //     this.currentFile = undefined;
    //     this.progress = 0;
    //   },
    //   complete: () => {
    //     this.currentFile = undefined;




    //   //  this.enviaExtensaoArquivo.emit()
    //   }
    // });
  }

 downloadDetalhadoCSV(){

  this.fileService.uploadDetalhado(this.uploadedFiles,this.fileModel).subscribe(
    (event: any) => {

      if (event.type === HttpEventType.UploadProgress) {
        this.progress = Math.round(100 * event.loaded / event.total);

      } else if (event instanceof HttpResponse) {


         // const blob = new Blob([event.body], { type: 'application/octet-stream' });
         const blob = new Blob([event.body],   { type: event.type.toString() });
         const contentDispositionHeader = event.headers.get('content-disposition');

         let content = contentDispositionHeader?.split(";")
         this.nomeArquivo = content? content[1].replace("filename=",""): "";
         this.nomeArquivo = this.nomeArquivo.trim();

         this.showSpinner = false;


        // saveAs(event, event.bo);
          this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));






          let url = window.URL.createObjectURL(blob);
                  let a = document.createElement('a');
                  document.body.appendChild(a);
                  a.setAttribute('style', 'display: none');
                  a.href = url;
                  a.download = this.nomeArquivo;
                  a.click();
                  window.URL.revokeObjectURL(url);
                  a.remove();
       // this.message = event.body.message;
      //  this.fileInfos = this.uploadService.getFiles();
      }
    },
    (err: any) => {

      this.progress = 0;
      this.showSpinner = false;

      if (err.error && err.error.message) {
        this.message = err.error.message;
      } else {
        this.message = 'Could not upload the file!';
      }

      this.currentFile = undefined;
    });



 }



  uploadDetalhado(): void {
    this.progress = 0;



    if (this.uploadedFiles) {
     // const file: File | null = this.selectedFiles.item(0);

     // if (file) {
       // this.currentFile = file;

        this.fileService.uploadDetalhado(this.uploadedFiles,this.fileModel).subscribe(
          (event: any) => {

            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);

            } else if (event instanceof HttpResponse) {


               // const blob = new Blob([event.body], { type: 'application/octet-stream' });
               const blob = new Blob([event.body],   { type: event.type.toString() });
               const contentDispositionHeader = event.headers.get('content-disposition');

               let content = contentDispositionHeader?.split(";")
               this.nomeArquivo = content? content[1].replace("filename=",""): "";
               this.nomeArquivo = this.nomeArquivo.trim();

               this.showSpinner = false;


              // saveAs(event, event.bo);
                this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));






                let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = this.nomeArquivo;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
             // this.message = event.body.message;
            //  this.fileInfos = this.uploadService.getFiles();
            }
          },
          (err: any) => {

            this.progress = 0;
            this.showSpinner = false;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }

            this.currentFile = undefined;
          });

    //  }

      this.selectedFiles = undefined;
    }
  }

  uploadDetalhadoFatura(): void {
    this.progress = 0;

    this.fileModel.fileType = this.tipoUpload == 'txt'? 3 :5// verifica se é txt ou febraban
    //this.showSpinner = true;
   // this.nomeArquivo = "";

  //  this.fileModel.fileOperadora = 1;
  //  this.fileModel.fileType = 3;
  //  this.fileModel.fileTipoAtivo = 4;
  //  this.fileModel.nomeEntidade = 'MOBIT';

    if (this.uploadedFiles) {
     // const file: File | null = this.selectedFiles.item(0);

     // if (file) {
       // this.currentFile = file;

        this.fileService.uploadDetalhadoFatura(this.uploadedFiles,this.fileModel,this.dadosContaFatura).subscribe(
          (event: any) => {

            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);

            } else if (event instanceof HttpResponse) {


               // const blob = new Blob([event.body], { type: 'application/octet-stream' });
               const blob = new Blob([event.body],   { type: event.type.toString() });
               const contentDispositionHeader = event.headers.get('content-disposition');

               let content = contentDispositionHeader?.split(";")
               this.nomeArquivo = content? content[1].replace("filename=",""): "";
               this.nomeArquivo = this.nomeArquivo.trim();

               this.showSpinner = false;


              // saveAs(event, event.bo);
                this.fileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));






                let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        document.body.appendChild(a);
                        a.setAttribute('style', 'display: none');
                        a.href = url;
                        a.download = this.nomeArquivo;
                        a.click();
                        window.URL.revokeObjectURL(url);
                        a.remove();
             // this.message = event.body.message;
            //  this.fileInfos = this.uploadService.getFiles();
            }
          },
          (err: any) => {

            this.progress = 0;
            this.showSpinner = false;

            if (err.error && err.error.message) {
              this.message = err.error.message;
            } else {
              this.message = 'Could not upload the file!';
            }

            this.currentFile = undefined;
          });

    //  }

      this.selectedFiles = undefined;
    }
  }
  public toggleType(): void {
    this.type = this.type === "component" ? "directive" : "component";
  }

  public toggleDisabled(): void {
    this.disabled = !this.disabled;
  }

  public toggleAutoReset(): void {
    this.config.autoReset = this.config.autoReset ? null : 5000;
    this.config.errorReset = this.config.errorReset ? null : 5000;
    this.config.cancelReset = this.config.cancelReset ? null : 5000;
  }

  public toggleMultiUpload(): void {
    this.config.maxFiles = this.config.maxFiles ? 0 : 1;
  }

  public toggleClickAction(): void {
    this.config.clickable = !this.config.clickable;
  }

  public resetDropzoneUploads(): void {
    if (this.type === "directive" && this.directiveRef) {
      this.directiveRef.reset();
    } else if (
      this.type === "component" &&
      this.componentRef &&
      this.componentRef.directiveRef
    ) {
      this.componentRef.directiveRef.reset();
    }
  }

  public onUploadInit(args: any): void {}

  public onUploadError(args: any): void {}

  public onUploadSuccess(args: any): void {


    this.uploadedFiles.push(args[0]);

    this.enviaNomeArquivo.emit(this.uploadedFiles[0].name.substring(this.uploadedFiles[0].name.length -3))
  }

  // File Remove
  removeFile(event: any) {
    this.uploadedFiles.splice(this.uploadedFiles.indexOf(event), 1);
  }
  selectFile(event: any): void {
    this.selectedFiles = event.target.files;


  }

}
