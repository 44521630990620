import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // Adicionado ReactiveFormsModule

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SimplebarAngularModule } from 'simplebar-angular';

import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { LanguageService } from '../core/services/language.service';
import { UIModule } from '../shared/ui/ui.module';
import { ContainerComponent } from './container/container.component';
import { FooterComponent } from './footer/footer.component';
import { HorizontalComponent } from './horizontal/horizontal.component';
import { HorizontaltopbarComponent } from './horizontaltopbar/horizontaltopbar.component';
import { LayoutComponent } from './layout.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { VerticalComponent } from './vertical/vertical.component';

import { NgSelectModule } from "@ng-select/ng-select";
import { SidebarRolesComponent } from './sidebar-roles/sidebar-roles.component';
import { SidebarRefacComponent } from './sidebar-refac/sidebar-refac.component';
import { MenuAccordionComponent } from './sidebar-refac/components/menu-accordion/menu-accordion.component';
import { DialogModule } from 'primeng/dialog';
import { SidebarOficialComponent } from './sidebar-oficial/sidebar-oficial.component';
import { AccordionMenuOficialComponent } from './sidebar-oficial/accordion-menu-oficial/accordion-menu-oficial.component';


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    ContainerComponent,
    LayoutComponent,
    SidebarComponent,
    TopbarComponent,
    FooterComponent,
    RightsidebarComponent,
    HorizontalComponent,
    VerticalComponent,
    HorizontaltopbarComponent,
    SidebarRolesComponent,
    SidebarRefacComponent,
    MenuAccordionComponent,
    SidebarOficialComponent,
    AccordionMenuOficialComponent],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    DropdownModule,
    BsDropdownModule.forRoot(),
    UIModule,
    DialogModule,
    SimplebarAngularModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [ContainerComponent],
  providers: [LanguageService]
})
export class LayoutsModule { }
