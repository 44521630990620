import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TokenStorageService } from "src/app/core/services/token-storage.service";
import { SubAreaEntidade } from "src/app/modulos/tecnologia/cadastro/core/models/subAreaEntidade.model";
import { TipoAtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/tipoAtivoFisico.model";
import { FabricanteDispositivo } from "src/app/modulos/telecom/ativo-fisico/models/dispositivo/fabricanteDispositivo.model";
import { ModeloDispositivo } from "src/app/modulos/telecom/ativo-fisico/models/dispositivo/modeloDispositivo.model";
import { PerfilCatalogo } from "src/app/modulos/telecom/ativo-linha/core/models/ativos-linha/perfilCatalogo.model";
import { PacoteRI } from "src/app/modulos/telecom/ativo-linha/core/models/pacoteRI.model";
import { AreaEntidade } from "../../models/areaEntidade.model";
import { CanalEntidade } from "../../models/canalEntidade.model";
import { CargoEntidade } from "../../models/cargoEntidade.model";
import { CentroCustoEntidade } from "../../models/centroCustoEntidade.model";
import { Entidade } from "../../models/entidade.model";
import { Fornecedor } from "../../models/fornecedor.model";
import { UnidadeEntidade } from "../../models/unidadeEntidade.model";
import { UsuarioEntidade } from "../../models/usuarioEntidade.model";

@Injectable({
  providedIn: "root",
})
export class FormCadastroSubmitService {
  private user: any;
  constructor(private tokenService: TokenStorageService) {
    this.user = this.tokenService.getUser();
  }

  /**
   * Busca os dados do formulário e retorna um objeto Usuario Entidade
   *
   * @returns O objeto Usuario Entidade
   */
  pegarDadosUsuarioEntidade(
    registerForm: FormGroup,
    dadosUsuarioEntidade: UsuarioEntidade
  ) {
    var usuarioEntidade: any = {
      nomeCompleto: registerForm.get(["nomeCompleto"])?.value,
      matricula: registerForm.get(["matricula"])?.value,
      entidadeId: registerForm.get(["entidade"])?.value,
      email: registerForm.get(["email"])?.value,
      documento: registerForm.get(["documento"])?.value,
      cnh: registerForm.get(["cnh"])?.value,
      vencimentoCNH: registerForm.get(["vencimentoCNH"])?.value,
      cargoEntidadeId: registerForm.get(["cargo"])?.value,
      areaEntidadeId: registerForm.get(["area"])?.value,
      subAreaEntidadeId: registerForm.get(["subArea"])?.value,
      centroCustoEntidadeId: registerForm.get(["centroCusto"])?.value,
      responsavelId: registerForm.get(["responsavel"])?.value,
      unidadeEntidadeId: registerForm.get(["unidadeEntidade"])?.value,
      canalEntidadeId:registerForm.get(["canal"])?.value,
      vip:
        registerForm.get(["vip"])?.value == undefined
          ? false
          : registerForm.get(["vip"])?.value,
      lider:
        registerForm.get(["lider"])?.value == undefined
          ? false
          : registerForm.get(["lider"])?.value,
      acessoSistema:
        registerForm.get(["acessoSistema"])?.value == undefined
          ? false
          : registerForm.get(["acessoSistema"])?.value,
      cep: registerForm.get(["cep"])?.value,
      logradouro: registerForm.get(["logradouro"])?.value,
      uf: registerForm.get(["uf"])?.value,
      bairro: registerForm.get(["bairro"])?.value,
      cidade: registerForm.get(["cidade"])?.value,
      numero: registerForm.get(["numero"])?.value,
      complemento: registerForm.get(["complemento"])?.value,
      status:
        registerForm.get(["status"])?.value == undefined
          ? "0"
          : registerForm.get(["status"])?.value == "1"
          ? "1"
          : "0",
      roles: registerForm.get(["roles"])?.value,
      userId: dadosUsuarioEntidade == null ? null : dadosUsuarioEntidade.userId,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
      visibilidadeUnidadesId: registerForm.get(["visibilidadeUnidadesId"])?.value,
      visibilidadeAreaId: registerForm.get(["visibilidadeAreaId"])?.value,
      visibilidadeCentroDeCustoId: registerForm.get(["visibilidadeCentroDeCustoId"])?.value,
      visibilidadeCanalId: registerForm.get(["visibilidadeCanalId"])?.value,
      visibilidadeTodos: registerForm.get(["visibilidadeTodos"])?.value,
    };

    return usuarioEntidade;
  }

  pegarDadosSubAreaEntidade(registerForm: FormGroup) {
    var subAreaEntidade: SubAreaEntidade = {
      descricao: registerForm.get(["descricao"])?.value,
      entidadeId: registerForm.get(["entidade"])?.value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return subAreaEntidade;
  }

  pegarDadosEntidade(registerForm: FormGroup) {
    var entidade: Entidade = {
      segmentoEntidadeId: registerForm.get(["segmentoEntidade"])?.value,
      razaoSocial: registerForm.get(["razaoSocial"])?.value?.toUpperCase(),
      nomeUsual: registerForm.get(["nomeUsual"])?.value?.toUpperCase(),
      entidadeId: registerForm.get(["entidade"])?.value,
      canal: registerForm.get(["canal"])?.value,
      tipo: "C",
      tipoPessoa: "F",
      numeroTipoPessoa: registerForm.get(["numeroTipoPessoa"])?.value,
      inscricaoEstadual: registerForm.get(["inscricaoEstadual"])?.value,
      cep: registerForm.get(["cep"])?.value,
      uf: registerForm.get(["uf"])?.value,
      cidade: registerForm.get(["cidade"])?.value,
      bairro: registerForm.get(["bairro"])?.value,
      logradouro: registerForm.get(["logradouro"])?.value,
      numero: registerForm.get(["numero"])?.value,
      complemento: registerForm.get(["complemento"])?.value,
      entidadePrincipal: registerForm.get(["entidadePrincipal"])?.value,
      tipoAtualizacao: registerForm.get(["tipoAtualizacao"])?.value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      matriculaObrigatoria:
        registerForm.get(["matriculaObrigatoria"])?.value == undefined
          ? false
          : registerForm.get(["matriculaObrigatoria"])?.value,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return entidade;
  }

  pegarDadosUnidadeEntidade(registerForm: FormGroup) {
    var unidadeEntidade: UnidadeEntidade = {
      razaoSocial: registerForm.get(["razaoSocial"])?.value,
      nomeUsual: registerForm.get(["nomeUsual"])?.value,
      entidadeId: registerForm.get(["entidade"])?.value,
      canal: registerForm.get(["canal"])?.value,
      segmentoEntidadeId: registerForm.get(["segmentoEntidade"])?.value,
      tipo: "C",
      tipoPessoa: "F",
      numeroTipoPessoa: registerForm.get(["numeroTipoPessoa"])?.value,
      inscricaoEstadual: registerForm.get(["inscricaoEstadual"])?.value,
      unidadePrincipalId:registerForm.get(["unidadePrincipal"])?.value,
      storage:
        registerForm.get(["storage"])?.value == undefined
          ? false
          : registerForm.get(["storage"])?.value,
      cep: registerForm.get(["cep"])?.value,
      uf: registerForm.get(["uf"])?.value,
      cidade: registerForm.get(["cidade"])?.value,
      bairro: registerForm.get(["bairro"])?.value,
      logradouro: registerForm.get(["logradouro"])?.value,
      numero: registerForm.get(["numero"])?.value,
      complemento: registerForm.get(["complemento"])?.value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return unidadeEntidade;
  }

  pegarDadosCanalEntidade(registerForm: FormGroup) {
    var canalEntidade: CanalEntidade = {
      razaoSocial: registerForm.get(["razaoSocial"])?.value,
      nomeUsual: registerForm.get(["nomeUsual"])?.value,
      entidadeId: registerForm.get(["entidade"])?.value,      
      tipo: "C",
      tipoPessoa: "F",
      numeroTipoPessoa: registerForm.get(["numeroTipoPessoa"])?.value,
      inscricaoEstadual: registerForm.get(["inscricaoEstadual"])?.value, 
      cep: registerForm.get(["cep"])?.value,
      uf: registerForm.get(["uf"])?.value,
      cidade: registerForm.get(["cidade"])?.value,
      bairro: registerForm.get(["bairro"])?.value,
      logradouro: registerForm.get(["logradouro"])?.value,
      numero: registerForm.get(["numero"])?.value,
      complemento: registerForm.get(["complemento"])?.value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return canalEntidade;
  }

  pegarDadosCargoEntidade(registerForm: FormGroup) {
    var cargoEntidade: CargoEntidade = {
      descricao: registerForm.get(["descricao"])?.value,
      entidadeId: registerForm.get(["entidade"])?.value,
      areaEntidadeId: registerForm.get(["areaEntidade"])?.value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return cargoEntidade;
  }
  pegarDadosAreaEntidade(registerForm: FormGroup) {
    var areaEntidade: AreaEntidade = {
      descricao: registerForm.get(["descricao"])?.value,
      entidadeId: registerForm.get(["entidade"])?.value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return areaEntidade;
  }
  pegarCentroCustoEntidade(registerForm: FormGroup) {
    var centroCusto: CentroCustoEntidade = {
      areaEntidadeId: registerForm.get(["areaEntidade"])?.value,
      nome: registerForm.get(["nome"])?.value,
      codigo: registerForm.get(["codigo"])?.value,
      tarifavel:
        registerForm.get(["tarifavel"])?.value === null
          ? false
          : registerForm.get(["tarifavel"])?.value,
      entidadeId: registerForm.get(["entidade"])?.value,
      unidadeEntidadeId: registerForm.get(["unidade"])?.value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return centroCusto;
  }

  pegarTipoAtivo(registerForm: FormGroup): TipoAtivoFisico {
    let tipoAtivo: TipoAtivoFisico = {      
      moduloCategoriaId : registerForm.get(["categoriaAtivo"]).value,
      descricao: registerForm.get(["descricao"]).value,
      categoriaAtivoId: registerForm.get(["categoriaAtivo"]).value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return tipoAtivo;
  }

  pegarFabricante(registerForm: FormGroup): FabricanteDispositivo {
    let fabricanteDispositivo: FabricanteDispositivo = {
      moduloId: registerForm.get(["modulo"]).value,
      descricao: registerForm.get(["descricao"]).value,
      categoriaAtivoId: registerForm.get(["categoriaAtivo"]).value,
      tipoAtivoId: registerForm.get(["tipoAtivo"]).value,
      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return fabricanteDispositivo;
  }

  pegarModelo(registerForm: FormGroup): ModeloDispositivo {
    let memoria = null;
    if (registerForm.get(["memoria"]).value !== null) {
      memoria =
        registerForm.get(["memoria"]).value +
        registerForm.get(["memoriaUnidade"]).value;
    }

    let tamanhoDiscoRigidoUnidade = null;
    if (registerForm.get(["tamanhoDiscoRigido"]) !== null) {
      tamanhoDiscoRigidoUnidade =
        registerForm.get(["tamanhoDiscoRigido"]).value +
        registerForm.get(["tamanhoDiscoRigidoUnidade"]).value;
    }

    let modeloDispositivo: ModeloDispositivo = {
      descricao: registerForm.get(["descricao"]).value,
      categoriaAtivoId: registerForm.get(["categoriaAtivo"]).value,
      fabricanteDispositivoId: registerForm.get(["fabricanteDispositivo"])
        .value,
      memoria: memoria,
      conexao: registerForm.get(["conexao"]).value,
      cor: registerForm.get(["cor"]).value,
      processador: registerForm.get(["processador"]).value,
      tamanhoDiscoRigido: tamanhoDiscoRigidoUnidade,
      numeroSerie: registerForm.get(["numeroSerie"]).value,

      status:
      registerForm.get(["status"])?.value == undefined
        ? 0
        : registerForm.get(["status"])?.value == 1
        ? 1
        : 0,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };

    return modeloDispositivo;
  }

  getPacote(registerForm: FormGroup): PacoteRI {
    let modeloPacoteRI: PacoteRI = {
      descricaoPlano: registerForm.get(["descricaoPlano"]).value.toUpperCase(),
      entidadeId: registerForm.get(["entidadeId"]).value,
      fornecedorId: registerForm.get(["fornecedorId"]).value,
      regiao: registerForm.get(["regiao"]).value,
      vigencia: registerForm.get(["vigencia"]).value,
      status: registerForm.get(["status"]).value,
      valorTTCliente: +registerForm.get(["valorTTCliente"]).value,
      valorTTMobit: +registerForm.get(["valorTTMobit"]).value,
      incluso: registerForm.get(["incluso"]).value,
      sugestaoOutros: registerForm.get(["sugestaoOutros"]).value,
      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
      multa: registerForm.get(["multa"]).value
    };

    return modeloPacoteRI;
  }

  getFornecedorForm(registerForm: FormGroup) {
    const fornecedor: Fornecedor = {
      fornecedorId: registerForm.get(["fornecedorId"]).value,
      tipo: registerForm.get(["tipo"]).value,
      razaoSocial: registerForm.get(["razaoSocial"]).value,
      nomeUsual: registerForm.get(["nomeUsual"]).value,
      numeroTipoPessoa: registerForm.get(["numeroTipoPessoa"]).value,
      status: registerForm.get(["status"]).value,
    };

    return fornecedor;
  }

  getPerfilCatalogoForm(registerForm: FormGroup) {
    let perfilCatalogo: PerfilCatalogo = {
      descricao: registerForm.get(["descricao"]).value.toUpperCase(),
      fornecedorId: registerForm.get(["fornecedorId"]).value,
      ttRevisao: registerForm.get(["ttRevisao"]).value,
      entidadeId: registerForm.get(["entidadeId"]).value,
      servicoId: registerForm.get(["servicoId"]).value,
      dataCriacaoPerfil: registerForm.get(["dataCriacaoPerfil"]).value,
      inicioVigencia: registerForm.get(["inicioVigencia"]).value,
      fimVigencia: registerForm.get(["fimVigencia"]).value,
      vigencia: registerForm.get(["vigencia"]).value,
      dataUltimoReajuste: registerForm.get(["dataUltimoReajuste"]).value,
      multa: registerForm.get(["multa"]).value,
      totalAssinaturaMensalUnitaria: registerForm.get([
        "totalAssinaturaMensalUnitaria",
      ]).value,
      totalAssinaturaSemAparelho: registerForm.get([
        "totalAssinaturaSemAparelho",
      ]).value,
      status: registerForm.get(["status"]).value,
      titularidadeId: registerForm.get(["titularidadeId"]).value,
      tipoRenovacao: registerForm.get(["tipoRenovacao"]).value,
      indiceReajuste: registerForm.get(["indiceReajuste"]).value,
      valorTotalMensal: registerForm.get(["valorTotalMensal"]).value,
      assinaturaUnitariaMensal: registerForm.get(["assinaturaUnitariaMensal"])
        .value,
      valorOriginal: registerForm.get(["valorOriginal"]).value,
      chamado: registerForm.get(["chamado"]).value,
      qtdePacoteDadosDisponivelGestorOnline: registerForm.get([
        "qtdePacoteDadosDisponivelGestorOnline",
      ]).value,
      observacoes: registerForm.get(["observacoes"]).value,

      //Assinaturas Mensais Perfil - Accordion
      aparelho: registerForm.get(["aparelho"]).value,
      valorAparelho: registerForm.get(["valorAparelho"]).value,
      descontoAparelho: registerForm.get(["descontoAparelho"]).value,
      protecaoRouboFurto: registerForm.get(["protecaoRouboFurto"]).value,
      qtDeParcelas: registerForm.get(["qtDeParcelas"]).value,
      assinaturaMensal: registerForm.get(["assinaturaMensal"]).value,
      gestaoSmartEmpresasDados: registerForm.get(["gestaoSmartEmpresasDados"])
        .value,
      gestaoSmartEmpresasVoz: registerForm.get(["gestaoSmartEmpresasVoz"])
        .value,
      vivoNewPlus: registerForm.get(["vivoNewPlus"]).value,
      gestaoEquipeCompleto: registerForm.get(["gestaoEquipeCompleto"]).value,
      gestaoOnlineValor: registerForm.get(["gestaoOnlineValor"]).value,
      vivoSegurancaOnlineVivoNews: registerForm.get([
        "vivoSegurancaOnlineVivoNews",
      ]).value,
      valorFranquiaMinutosIndividual: registerForm.get([
        "valorFranquiaMinutosIndividual",
      ]).value,
      qtDeMinutosFranquiaIndividual: registerForm.get([
        "qtDeMinutosFranquiaIndividual",
      ]).value,
      descontoMarketingInternet: registerForm.get(["descontoMarketingInternet"])
        .value,
      excedenteGb: registerForm.get(["excedenteGb"]).value,
      valorPacotesDados: registerForm.get(["valorPacotesDados"]).value,
      qtdePacoteDadosContratado: registerForm.get(["qtdePacoteDadosContratado"])
        .value,
      valorPacotesRecorrentesRI: registerForm.get(["valorPacotesRecorrentesRI"])
        .value,
      inclusoPacoteRI: registerForm.get(["inclusoPacoteRI"]).value,

      //Descrições Assinatura Mensal
      consumoMinimo: registerForm.get(["consumoMinimo"]).value,
      qtdRamais: registerForm.get(["qtdRamais"]).value,
      qtdE1: registerForm.get(["qtdE1"]).value,
      servicosInclusos: registerForm.get(["servicosInclusos"]).value,
      qtdMinMovel: registerForm.get(["qtdMinMovel"]).value,
      totalInternet: registerForm.get(["totalInternet"]).value,
      tarifacaoInicial: registerForm.get(["tarifacaoInicial"]).value,
      cadencia: registerForm.get(["cadencia"]).value,

      //Definição de Bloqueios
      chamadaLocalVC1: registerForm.get(["chamadaLocalVC1"]).value,
      chamadaLongaDistanciaVC2VC3: registerForm.get([
        "chamadaLongaDistanciaVC2VC3",
      ]).value,
      recebimentoACobrarVC1VC2VC3: registerForm.get([
        "recebimentoACobrarVC1VC2VC3",
      ]).value,
      chamadaLongaDistanciaInternacional: registerForm.get([
        "chamadaLongaDistanciaInternacional",
      ]).value,
      chamaRoamingInternacional: registerForm.get(["chamaRoamingInternacional"])
        .value,
      mensagemTextoSMS: registerForm.get(["mensagemTextoSMS"]).value,
      mensagemTextoRecebimentoSMS: registerForm.get([
        "mensagemTextoRecebimentoSMS",
      ]).value,
      mensagemTextoRoamingInternacionalSMS: registerForm.get([
        "mensagemTextoRoamingInternacionalSMS",
      ]).value,
      dadosNacional: registerForm.get(["dadosNacional"]).value,
      dadosRoamingInternacional: registerForm.get(["dadosRoamingInternacional"])
        .value,
      restricaoCSP: registerForm.get(["restricaoCSP"]).value,
      caixaPostal: registerForm.get(["caixaPostal"]).value,
      gestorOnline: registerForm.get(["gestorOnline"]).value,
      numerosEspeciais: registerForm.get(["numerosEspeciais"]).value,
      internet: registerForm.get(["internet"]).value,
      csp: registerForm.get(["csp"]).value,
      chamadaLocalFixoFixo: registerForm.get(["chamadaLocalFixoFixo"]).value,
      chamadaLocalFixoMovel: registerForm.get(["chamadaLocalFixoMovel"]).value,
      chamadaLongaDistanciaFixoFixo: registerForm.get([
        "chamadaLongaDistanciaFixoFixo",
      ]).value,
      chamadaLongaDistanciaFixoMovel: registerForm.get([
        "chamadaLongaDistanciaFixoMovel",
      ]).value,
      chamadaInternacional: registerForm.get(["chamadaInternacional"]).value,

      usuarioCadastro: this.user.email,
      usuarioLog: this.user.email,
    };
    return perfilCatalogo;
  }
}
