import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CentroCustoEntidade } from '../../models/centroCustoEntidade.model';
import { Pagination } from 'src/app/core/models/pagination.model';



@Injectable({
  providedIn: 'root'
})
export class CentroCustoEntidadeService {

  baseUrl = environment.apiBaseHom + '/centrocustoentidade';
  //baseUrl = sessionStorage.getItem('urlAPI')+'/v1/deposito/pedidovenda'





  private _centroCusto = new BehaviorSubject<any[]>([]);
  get centroCusto() {
    return this._centroCusto.asObservable();
  }

  retornaParams() {

    let queryParams = new HttpParams();
    queryParams = queryParams.append("auth", environment.apiBaseHom)

    return queryParams;
  }

  constructor(private http: HttpClient) { }


  getCentroCusto(): Observable<any[]> {
    return this.http.get<any>(`${this.baseUrl}`)

  }

  getCentroCustoEntidades(
    pagination: Pagination,
    entidadeId: number,
    unidadeEntidadeId: number,
  ): Observable<{
    listaCentroCustoEntidade: CentroCustoEntidade[],
    quantidade: number
  }> {
    return this.http.get<{
      listaCentroCustoEntidade: CentroCustoEntidade[],
      quantidade: number
    }>(
      `${this.baseUrl}?entidadeId=${entidadeId}&unidadeID=${unidadeEntidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
  getPesquisaTodosCentroCustoEntidades(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaCentroCustoEntidade: CentroCustoEntidade[],
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaCentroCustoEntidade: CentroCustoEntidade[],
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/CentroCustoEntidadePesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getCentroCustoPorIdEntidade(entidadeId: number): Observable<any> {

    return this.http.get<any>(`${this.baseUrl}/centrocustoporentidadeid?entidadeId=${entidadeId}`)

  }

  getCentroCustoPorId(centroCustoEntidadeId: number): Observable<CentroCustoEntidade> {
    return this.http.get<any>(`${this.baseUrl}/CentroCustoPorID?Id=${centroCustoEntidadeId}`)

  }
  addCentroCustoEntidade(CentroCustoEntidadeNovo: CentroCustoEntidade): Observable<any> {
    return this.http.post(`${this.baseUrl}`, CentroCustoEntidadeNovo)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }
  updatCentroCustoEntidade(centroCustoEntidadeEdit: CentroCustoEntidade): Observable<any> {
    return this.http.put(`${this.baseUrl}`, centroCustoEntidadeEdit)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }

  public delete = (
    centroCustoId: number
  ): Observable<CentroCustoEntidade | HttpErrorResponse> => {
    return this.http.delete<CentroCustoEntidade>(`${this.baseUrl}/${centroCustoId}`).pipe(
      map((res: CentroCustoEntidade) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  };



}

