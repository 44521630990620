import { Component } from '@angular/core';
import { MenuService } from '../sidebar-refac/menu.service';
import { menuItem } from '../sidebar-refac/types/menu-accordion.type';

@Component({
  selector: 'app-sidebar-oficial',
  templateUrl: './sidebar-oficial.component.html',
  styleUrls: ['./sidebar-oficial.component.scss']
})
export class SidebarOficialComponent {
  menu: menuItem[];
  
  constructor(private menuService: MenuService){}

  ngOnInit(): void {
    let rolesStorage = localStorage.getItem('roles');
    
    let roles = rolesStorage.split(',');
    // console.log('ROLES', roles)

    this.menuService.getMenu(roles).subscribe((res) => {
      // console.log('MENU', res.modulos)
      this.menu = res.modulos;
    })

    console.log("MENU")
    console.log(this.menu)
  }
}
