import { Injectable } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AtivoAcessorio } from "../../../models/acessorio/ativoAcessorio.model";

@Injectable({
  providedIn: "root",
})
/**
 * Serviço responsável pela criação dos formulários.
 */
export class FormFisicoService {
  constructor(private formBuilder: FormBuilder) {}

  getAtivoAcessorioForm() {
    return this.formBuilder.group({
      categoria: [null, [Validators.required, Validators.pattern("[0-9]+")]],
      // descricao: [null, Validators.required],
      comentarios: [null],
      tipo: [{value: null, disabled: true}],
      cor: [null],
      quantidade: [null, Validators.required],
      modelo: [null, Validators.required],
      status: [1]
    });
  }

  getAtivoFisicoChipForm() {
    return this.formBuilder.group({
      nome: [null, [Validators.required, Validators.minLength(20)]],
      entidade: [null, Validators.required],
      canal: [null],
      solicitante: [null],
      numeroPedido: [null],
      tipo: [null, Validators.required],
      status: [null, Validators.required],
      estoque: [1, Validators.required],
      localizacaoUnidadeEntidade: [null, Validators.required],
      centroCustoEntidade:[null],
      fabricante: [{value: null, disabled: true}, Validators.required],
      chamadoIdentificacao: [null, Validators.required],
      chamadoReservado: [null],
      numeroLinha: [{value: null, disabled: true}, Validators.required],
      ddd: [null, Validators.required],
      hlr: [null],
      titularidade: [null, Validators.required],
      alocacaoUnidadeEntidade: [null, Validators.required],
      observacao: [null],
      numeroNotaEntrada: [null, Validators.required],
      tipoNotaEntrada: [null],
      posicao: [null],
      valorNotaEntrada: [null],
      dataEmissaoEntrada: [null],
      perfilAtivo:[null],

      numeroNotaSaida: [null, Validators.required],
      tipoNotaSaida: [null],
      // motivoSaida: [null],
      valorNotaSaida: [null],
      dataEmissaoSaida: [null],
      // Vinculo Usuário
      nomeUsuario: [{value: null, disabled: true}],
      documentoUsuario: [{value: null, disabled: true}],
      matriculaUsuario: [{value: null, disabled: true}],
      vip: [{value: null, disabled: true}],
      // Vínculo Dispositivo
      tipoDispositivo: [{value: null, disabled: true}],
      marcaDispositivo: [{value: null, disabled: true}],
      modeloDispositivo: [{value: null, disabled: true}],
      imeiDispositivo: [{value: null, disabled: true}],
      // Vínculo Linha
      operadora: [{value: null, disabled: true}],
      numeroLinhaVinculo: [{value: null, disabled: true}],
      dddVinculo: [{value: null, disabled: true}],
      perfilLinha: [{value: null, disabled: true}],
      // Novos vínculos
      categoriaAtivo: [null],
      tipoAtivo: [{value: null, disabled: true}],
      fabricanteVinculo: [{value: null, disabled: true}],
      identificacaoAtivo: [{value: null, disabled: true}],
    });
  }

  getAtivoFisicoDispositivoForm() {
    return this.formBuilder.group({
      nome: [null, Validators.required],    
      entidade: [null, Validators.required],
      tipo: [null, Validators.required],
      status: [null, Validators.required],
      estoque: [1, Validators.required],
      localizacaoUnidadeEntidade: [null, Validators.required],
      centroCustoEntidade:[null],
      areaEntidade:[null],
      perfilAtivo:[null],
      fabricante: [{value: null, disabled: true}, Validators.required],
      modelo: [{value: null, disabled: true}, Validators.required],
      observacao: [null],
      chamadoIdentificacao: [null, Validators.required],
      chamadoReservado: [null],
      titularidade: [null, Validators.required],
      alocacaoUnidadeEntidade: [null, Validators.required],
      canal: [null],
      solicitante: [null],
      numeroPedido: [null],
      devolucao: [null, [Validators.required]],
      caixa: [null, [Validators.required]],
      carregador: [null, [Validators.required]],
      fone: [null, [Validators.required]],
      capa: [null, [Validators.required]],
      pelicula: [null, [Validators.required]],
      numeroNotaEntrada: [null, Validators.required],
      tipoNotaEntrada: [null],
      posicao: [null, Validators.required],
      condicao: [null, Validators.required],
      valorNotaEntrada: [null],
      dataEmissaoEntrada: [null],
      quantidadeParcelas: [null],
      produto: [null],
      numeroNotaSaida: [null, Validators.required],
      tipoNotaSaida: [null],
      // motivoSaida: [null],
      valorNotaSaida: [null],
      dataEmissaoSaida: [null],
      // Vínculo Usuário
      nomeUsuario: [{value: null, disabled: true}],
      documentoUsuario: [{value: null, disabled: true}],
      matriculaUsuario: [{value: null, disabled: true}],
      vip: [{value: null, disabled: true}],
      // Vínculo Linha
      operadora: [{value: null, disabled: true}],
      numeroLinha: [{value: null, disabled: true}],
      ddd: [{value: null, disabled: true}],
      perfilLinha: [{value: null, disabled: true}],
      // Novos vínculos
      modeloDispositivo: [{value: null, disabled: true}],
      categoriaAtivo: [null],
      tipoAtivo: [{value: null, disabled: true}],
      fabricanteVinculo: [{value: null, disabled: true}],
      identificacaoAtivo: [{value: null, disabled: true}],
    });
  }

  getAtivoFisicoMaterialForm() {
    return this.formBuilder.group({
      nome: [null, Validators.required],
      entidade: [null, Validators.required],
      tipo: [null, Validators.required],
      status: [null, Validators.required],
      estoque: [1, Validators.required],
      localizacaoUnidadeEntidade: [null, Validators.required],
      centroCustoEntidade:[null],
      areaEntidade:[null],
      fabricante: [{value: null, disabled: true}, Validators.required],
      modelo: [{value: null, disabled: true}, Validators.required],
      observacao: [null],
      chamadoIdentificacao: [null, Validators.required],
      chamadoReservado: [null],
      //titularidade: [null, Validators.required],
      //alocacaoUnidadeEntidade: [null, Validators.required],
      devolucao: [null, [Validators.required]],
      //caixa: [null, [Validators.required]],
      //carregador: [null, [Validators.required]],
      //fone: [null, [Validators.required]],
      //capa: [null, [Validators.required]],
      //pelicula: [null, [Validators.required]],
     // numeroNotaEntrada: [null, Validators.required],
     // tipoNotaEntrada: [null],
     // posicao: [null, Validators.required],
      condicao: [null, Validators.required],
     // valorNotaEntrada: [null],
     // dataEmissaoEntrada: [null],
     // quantidadeParcelas: [null],
     // produto: [null],
     // numeroNotaSaida: [null, Validators.required],
     // tipoNotaSaida: [null],
      // motivoSaida: [null],
     // valorNotaSaida: [null],
     // dataEmissaoSaida: [null],
      // Vínculo Usuário
     // nomeUsuario: [{value: null, disabled: true}],
     // documentoUsuario: [{value: null, disabled: true}],
     // matriculaUsuario: [{value: null, disabled: true}],
     // vip: [{value: null, disabled: true}],

    });
  }
}
