import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
  HttpStatusCode,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, of, tap, throwError } from "rxjs";
import { Pagination } from "src/app/core/models/pagination.model";
import { environment } from "src/environments/environment";
import { AtivoLinha } from "../models/ativoLinha.model";
import { AtivoLinhaResult } from "../models/ativos-linha/ativoLinhaResult.model";
import { ToastrService } from "ngx-toastr";
import { AtivoCsv } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisicoCsv.model";
import type { AtivoLinhaStats } from "../models/ativos-linha/ativoLinhaStats.model";

@Injectable({
  providedIn: "root",
})
export class DispositivoFisicoService {
  baseUrl = environment.apiBaseHom;
  dashboardBaseUrl = environment.apiBaseHom;

  constructor(private http: HttpClient, private toastr: ToastrService) {}

  getAtivosLinha(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivosLinha: AtivoLinhaResult[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/ativoslinha?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPesquisaTodosAtivosLinha(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaAtivosLinha: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/pesquisatodosativoslinha?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
  AtivosLinhaTelemetria;

  getAtivosLinhaMovel(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivosLinha: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/ativoslinhamovel?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosLinhaTelemetria(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivosLinha: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/AtivosLinhaTelemetria?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosLinhaMovelPorEntidadeTitularidade(
    pagination: Pagination,
    entidadeId: number,
    entidadeBuscaId: number,
    titularidadeId: number
  ): Observable<{
    listaAtivosLinha: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivosLinha: AtivoLinha[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/ativoslinhamovelporentidadetitularidade?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&entidadeBuscaId=${entidadeBuscaId}&titularidadeId=${titularidadeId}`
    );
  }

  getAtivosLinhaMovelEstoque(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivosLinha: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/ativoslinhamovelestoque?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivoLinhaMovelPorId(ativoLinhaId: number): Observable<AtivoLinhaResult> {
    return this.http.get<AtivoLinhaResult>(
      `${this.baseUrl}/getativolinhaporid?Id=${ativoLinhaId}`
    );
  }

  getAtivoLinhaBandaLargaPorId(
    ativoLinhaId: number
  ): Observable<AtivoLinhaResult> {
    return this.http.get<AtivoLinhaResult>(
      `${this.baseUrl}/GetLinhaBandaLargaPorId?ativoLinhaId=${ativoLinhaId}`
    );
  }

  getAtivoLinhaLinksPorId(ativoLinhaId: number): Observable<AtivoLinhaResult> {
    return this.http.get<AtivoLinhaResult>(
      `${this.baseUrl}/GetLinksPorId?ativoLinhaId=${ativoLinhaId}`
    );
  }

  getAtivoLinhaFixaPorId(ativoLinhaId: number): Observable<AtivoLinhaResult> {
    return this.http.get<AtivoLinhaResult>(
      `${this.baseUrl}/GetLinhaFixaPorId?ativoLinhaId=${ativoLinhaId}`
    );
  }

  // Verificar o retorno dos métodos e adaptar o observable
  getPesquisaTodosAtivosLinhaMovel(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaAtivosLinhaBandaLarga: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/pesquisaativoslinhamovel?searchText=${searchText}&&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPesquisaTodosAtivosLinhaBandaLarga(
    pagination: Pagination,
    entidadeId: number,
    searchText: string
  ): Observable<{
    listaAtivosLinhaBandaLarga: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/pesquisatodosativoslinhabandalarga?searchText=${searchText}&&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosLinhaBandaLarga(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivosLinhaBandaLarga: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<any>(
      `${this.baseUrl}/ativoslinhabandalarga?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosLinhaFixo(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivosLinhaFixa: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivosLinhaFixa: AtivoLinha[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/ativoslinhaFixa?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
  getPesquisaTodosAtivosLinhaFixo(
    pagination: Pagination,
    searchText: string,
    entidadeId: number
  ): Observable<{
    listaAtivosLinhaFixa: AtivoLinha[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivosLinhaFixa: AtivoLinha[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/ativospesquisatodoslinhafixa?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getAtivosLinks(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{ ativoLinhaLink: AtivoLinha[]; quantidadeTotal: number }> {
    return this.http.get<any>(
      `${this.baseUrl}/ativoslinks?EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  getPesquisaTodosAtivosLinks(
    searchText: string,
    pagination: Pagination,
    entidadeId: number
  ): Observable<{ ativoLinhaLink: AtivoLinha[]; quantidadeTotal: number }> {
    return this.http.get<any>(
      `${this.baseUrl}/pesquisatodosativoslinks?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  GetAtivoLinhaStats(entidadeId: number, isAll: boolean): Observable<{
    listaAtivoDispositivo: AtivoLinha[];
    stats: AtivoLinhaStats;
    quantidadeTotal: number;
  }> {
    let ativoLinhaParamStats = new HttpParams();

    ativoLinhaParamStats = isAll
      ? ativoLinhaParamStats.append("isAll", isAll)
      : ativoLinhaParamStats;

    return this.http.get<{
      listaAtivoDispositivo: AtivoLinha[];
      stats: AtivoLinhaStats;
      quantidadeTotal: number;
    }>(`${this.dashboardBaseUrl}/ativolinhastat?EntidadeID=${entidadeId}`, {params: ativoLinhaParamStats});
  }

  GetLinhaDashboardsDrillDown(
    entidadeId?: number,
    emEstoque?: boolean,
    areaId?: number,
    centroCustoId?: number,
    isAll?: boolean,
    unidadeId?: number,
    servicoId?: number,
    operadoraId?: number,
    perfilId?: number
  ): Observable<{
    value?: number;
    category?: number;
    servicoId?: number;
    operadoraId?: number;
    perfilId?: number;
  }> {
    let chipParams = new HttpParams();

    chipParams = chipParams.append("EntidadeId", entidadeId);
    chipParams = isAll ? chipParams.append("isAll", isAll) : chipParams;
    chipParams =
      typeof emEstoque === "boolean"
        ? chipParams.append("emEstoque", emEstoque.toString())
        : chipParams;
    chipParams = areaId
      ? chipParams.append("areaId", areaId.toString())
      : chipParams;
    chipParams = centroCustoId
      ? chipParams.append("centroDeCustoId", centroCustoId.toString())
      : chipParams;
    chipParams = servicoId
      ? chipParams.append("servicoId", servicoId.toString())
      : chipParams;
    chipParams = operadoraId
      ? chipParams.append("operadoraId", operadoraId.toString())
      : chipParams;
    chipParams = perfilId
      ? chipParams.append("perfilId", perfilId.toString())
      : chipParams;
    chipParams = unidadeId
      ? chipParams.append("UnidadeId", unidadeId.toString())
      : chipParams;

    return this.http.get<{
      value?: number;
      category?: number;
      servicoId?: number;
      operadoraId?: number;
      perfilId?: number;
    }>(`${this.dashboardBaseUrl}`, { params: chipParams });
  }

  GetLinhaTable(
    entidadeId?: number,
    emEstoque?: boolean,
    areaId?: number,
    centroCustoId?: number,
    isAll?: boolean,
    unidadeId?: number,
    pagination?: Pagination,
    servicoId?: number,
    operadoraId?: number,
    perfilId?: number,
    ddd?: string
  ): Observable<{
    value?: number;
    category?: number;
    servicoId?: number;
    operadoraId?: number;
    perfilId?: number;
    ddd?: string;
  }> {
    let linhaParams = new HttpParams();

    linhaParams = linhaParams.append("EntidadeId", entidadeId);
    linhaParams =
      typeof emEstoque === "boolean"
        ? linhaParams.append("emEstoque", emEstoque.toString())
        : linhaParams;
    linhaParams = areaId
      ? linhaParams.append("areaId", areaId.toString())
      : linhaParams;
    linhaParams = centroCustoId
      ? linhaParams.append("centroDeCustoId", centroCustoId.toString())
      : linhaParams;
    linhaParams = unidadeId
      ? linhaParams.append("UnidadeId", unidadeId.toString())
      : linhaParams;
    linhaParams = servicoId
      ? linhaParams.append("servicoId", servicoId.toString())
      : linhaParams;
    linhaParams = operadoraId
      ? linhaParams.append("operadoraId", operadoraId.toString())
      : linhaParams;
    linhaParams = perfilId
      ? linhaParams.append("perfilId", perfilId.toString())
      : linhaParams;
    linhaParams = ddd ? linhaParams.append("ddd", ddd) : linhaParams;
    linhaParams = linhaParams.append("PageNumber", pagination.pageNumber);
    linhaParams = linhaParams.append("PageSize", pagination.pageSize);

    return this.http.get<{
      value?: number;
      category?: number;
      servicoId?: number;
      operadoraId?: number;
      perfilId?: number;
    }>(`${this.dashboardBaseUrl}/linhatable`, { params: linhaParams });
  }

  addAtivoLinha(ativoLinha: AtivoLinha): Observable<any> {
    return this.http.post(`${this.baseUrl}`, ativoLinha).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 409) {
          this.toastr.error(
            "Existe um ativo linha com este identificador. Tente novamente!",
            "Cadastro Falhou",
            { timeOut: 7500, progressBar: true }
          );
        }

        return of(error);
      })
    );
  }

  updateAtivoLinha(ativosLinha: AtivoLinha[]): Observable<any> {
    return this.http.put(`${this.baseUrl}/atualizalinha`, ativosLinha).pipe(
      map((res) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === HttpStatusCode.Conflict
        )
          this.toastr.error(
            "Não é possivel alterar a entidade de um chip que esta ativo em outra entidade ",
            "Chip",
            { timeOut: 5000, progressBar: true }
          );

        return of(err);
      })
    );
  }

  /**
   * Baixo o csv de ativos linha
   */
  downloadCsv(ativoCsv: AtivoCsv): Observable<any> {
    return this.http
      .post(`${this.baseUrl}/dispositivofisico/downloadcsv`, ativoCsv, {
        observe: "response",
        responseType: "blob",
      })
      .pipe(
        map((response) => {
          return { response: response, headers: response.headers };
        })
      );
  }

  // Delete
  inativarAtivo(ativoLinhaId: number): Observable<any> {
    return this.http
      // Ft
      .delete(`${this.baseUrl}/ativofisico?Id=${ativoLinhaId}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error) => {
          return of(false);
        })
      );
  }

  //Altera DDD
  alteraDDD(
    model: AtivoLinha,
    newLine: string,
    ativoFisicoId: number,
    dataAtivacao: string
  ): Observable<any> {
    return this.http
      .patch(`${this.baseUrl}/AlterarDDD`, {
        ativoLinha: model,
        newLine: newLine,
        ativoFisicoId: ativoFisicoId,
        dataAtivacao: dataAtivacao,
      })
      .pipe(
        tap((response) => {
          this.toastr.success("DDD alterado com sucesso!", "Linha");
          return response;
        }),
        catchError((err: unknown) => {
          if (err instanceof HttpErrorResponse)
            this.toastr.error(
              !err ? "Não é possivel alterar o DDD" : err.error,
              "Linha",
              {
                timeOut: 5000,
                progressBar: true,
              }
            );

          return throwError(() => err);
        })
      );
  }
}
