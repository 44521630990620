export enum SERVICES_NAMES {
  TIPO_ATIVO_SEARCH_REQUEST="tipoAtivoSearchRequest",
  ATIVO_FISICO_REQUEST = "ativoFisicoRequest",
  ATIVO_FISICO_SEARCH_REQUEST = "ativoFisicoSearchRequest",
  CHIP_REQUEST = "chipRequest",
  CHIP_ESTOQUE_REQUEST = "chipEstoqueRequest",
  CHIP_SEARCH_REQUEST = "chipSearchRequest",
  DISPOSITIVOS_REQUEST = "dispositivosRequest",
  DISPOSITIVOS_ESTOQUE_REQUEST = "dispositivosEstoqueRequest",
  DISPOSITIVOS_SEARCH_REQUEST = "dispositivosSearchRequest",
  ACESSORIOS_REQUEST = "acessoriosRequest",
  ACESSORIOS_SEARCH_REQUEST = "acessoriosSearchRequest",
  ATIVO_LINHA_REQUEST = "ativoLinhaRequest",
  ATIVO_LINHA_SEARCH_REQUEST = "ativoLinhaSearchRequest",
  MOVEL_REQUEST = "movelRequest",
  MOVEL_ESTOQUE_REQUEST = "movelEstoqueRequest",
  MOVEL_SEARCH_REQUEST = "movelSearchRequest",
  LINKS_REQUEST = "linksRequest",
  LINKS_SEARCH_REQUEST = "linksSearchRequest",
  BANDA_LARGA_REQUEST = "bandaRequest",
  BANDA_LARGA_SEARCH_REQUEST = "bandaSearchRequest",
  FIXA_REQUEST = "fixaRequest",
  FIXA_SEARCH_REQUEST = "fixaSearchRequest",
  USUARIO_ENTIDADE_REQUEST = "usuarioEntidadeRequest",
  USUARIO_ENTIDADE_SEARCH_REQUEST = "usuarioEntidadeSearchRequest",
  DOWNLOAD_REQUEST = "downloadRequest",
  DOWNLOAD_SEARCH_REQUEST = "downloadSearchRequest",
  CONTA_FATURA_REQUEST = "contaFaturaRequest",
  CONTA_FATURA_SEARCH_REQUEST = "contaFaturaSearchRequest",
  FATURA_REQUEST = "faturaRequest",
  FATURA_SEARCH_REQUEST = "faturaSearchRequest",
  // PERFIL, CONTA, PACOTE
  PERFIL_REQUEST = "perfilRequest",
  // PERFIL_SEARCH_REQUEST = "faturaSearchRequest",
  CONTA_REQUEST = "contaLinhaRequest",
  // CONTA_SEARCH_REQUEST = "faturaSearchRequest",
  PACOTERI_REQUEST = "pacoteRIRequest",
  // PACOTE_SEARCH_REQUEST = "faturaSearchRequest",

  ENTIDADE_REQUEST = "entidadeRequest",
  ENTIDADE_SEARCH_REQUEST = "entidadeSearchRequest",
  AREA_ENTIDADE_REQUEST = "areaEntidadeRequest",
  AREA_ENTIDADE_SEARCH_REQUEST = "areaEntidadeSearchRequest",
  CARGO_ENTIDADE_REQUEST = "cargoEntidadeRequest",
  CARGO_ENTIDADE_SEARCH_REQUEST = "cargoEntidadeSearchRequest",
  CENTRO_CUSTO_ENTIDADE_REQUEST = "centroCustoEntidadeRequest",
  CENTRO_CUSTO_ENTIDADE_SEARCH_REQUEST = "centroCustoEntidadeSearchRequest",
  FORNECEDOR_REQUEST = "fornecedorRequest",
  FORNECEDOR_SEARCH_REQUEST = "fornecedorSearchRequest"
}
