import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";

@Injectable({
  providedIn: "any",
})
export class AlterEntidadeService {
  confirmation: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get confirmation$() {
    return this.confirmation.asObservable();
  }

  constructor(
    private confirmationService: ConfirmationService,
    private toastr: ToastrService
  ) {}

  confirmarDelecao() {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        "Tem certeza que deseja alterar a entidade? essa alteração fica registrada!",
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      acceptIcon: "none",
      acceptLabel: "alterar",
      rejectLabel: "Cancelar",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => this.confirmation.next(1),
      reject: () => this.toastr.warning("alteração cancelada", "Cancelado"),
    });
  }

  confirmarAlteracao() {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: "Tem certeza que deseja alterar o registro?",
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      acceptIcon: "none",
      acceptLabel: "alterar",
      rejectLabel: "Cancelar",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => this.confirmation.next(2),
      reject: () => this.toastr.warning("alteração cancelada", "Cancelado"),
    });
  }

  confirmarVinculoAlteracao() {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message:
        "Tem certeza que deseja vincular esse chip? esta ação tambem altera a entidade do chip",
      header: "Confirmação",
      icon: "pi pi-exclamation-triangle",
      acceptIcon: "none",
      acceptLabel: "vincular",
      rejectLabel: "Cancelar",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => this.confirmation.next(3),
      reject: () => this.toastr.warning("alteração cancelada", "Cancelado"),
    });
  }
}
