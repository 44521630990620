import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { Table } from "primeng/table";
import { Subscription } from "rxjs";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { Pagination } from "src/app/core/models/pagination.model";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { AtivoFisicoService } from "src/app/modulos/telecom/ativo-fisico/core/service/ativo-fisico.service";
import { EntidadeStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/entidade.store.service";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { AtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model";
import { STATUS } from "src/app/modulos/telecom/ativo-fisico/models/enum/status.enum";
import { AtivoLinha } from "src/app/modulos/telecom/ativo-linha/core/models/ativoLinha.model";
import { AtivoLinhaResult } from "src/app/modulos/telecom/ativo-linha/core/models/ativos-linha/ativoLinhaResult.model";
import { AtivoLinhaService } from "src/app/modulos/telecom/ativo-linha/core/services/ativo-linha.service";
import { Entidade } from "src/app/modulos/telecom/cadastro/core/models/entidade.model";

@Component({
  selector: "app-modal-list-linhas",
  templateUrl: "./linha.component.html",
  styleUrls: ["./linha.component.css"],
})
export class LinhaModalComponent {
  linhaSelecionada: AtivoLinha;

  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<any>();
  @Output() limparValorEvent = new EventEmitter<any>();

  @Input() entidadeId: number;
  @Input() labelBtn: string = "Selecionar";

  @ViewChild("dt", { static: true }) dt: Table;
  isOpen: boolean = true;
  tooltipText = "pesquisa da pagina atual";

  // bread crumb items
  breadCrumbItems: Array<{}>;

  grid: string =
    "minmax(20px, 0fr) minmax(140px, 1fr) minmax(150px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr) minmax(100px, 1fr)";
  dataTableHeaders: string[] = [
    "",
    "Ação",
    "Nome",
    "Status",
    "Entidade",
    "Titularidade",
    "Operadora",
    "Perfil",
  ];

  isChecked: boolean = false;

  enumTeste = SERVICES_NAMES;

  valorSelecionado!: any;

  formSelects: FormGroup;

  basePagination: Pagination = { pageNumber: 1, pageSize: 50 };

  entidadeIdSelecionada: number = 0;
  titularidadeIdSelecionada: number = 0;

  private subscription: Subscription[] = [];

  get data$() {
    return this.paginatorStore.dataObservable$;
  }

  get tableRow$() {
    return this.tableStore.tableRow$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  get entidadeList$() {
    return this.entidadeStoreService.entidadeList$;
  }

  constructor(
    private paginatorStore: PaginatorGenericStoreService,
    private toastr: ToastrService,
    private tableStore: TableStoreService,
    private ativoLinhaService: AtivoLinhaService,
    private entidadeStoreService: EntidadeStoreService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.formSelects = this.fb.group({
      entidade: [null],
      titularidade: [null],
    });

    this.subscription.push(
      this.rowsSelected.subscribe((data: any) => (this.valorSelecionado = data))
    );
  }

  sendData(event$: { paginator: Pagination; searchText: string }) {
    if (event$.searchText !== null) {
      this.paginatorStore.getDataObservable(
        this.ativoLinhaService.getPesquisaTodosAtivosLinhaMovel(
          event$.paginator,
          event$.searchText,
          this.entidadeId
        )
      );
    } else {
      this.paginatorStore.getDataObservable(
        this.ativoLinhaService.getAtivosLinhaMovel(
          event$.paginator,
          // event$.searchText,
          this.entidadeId
        )
      );
    }
  }

  guardarEntidade(event: Entidade) {
    if (!event) {
      this.entidadeIdSelecionada = 0;
      this.sendData({ paginator: this.basePagination, searchText: null });
      return;
    }

    this.entidadeIdSelecionada = event.entidadeId;

    this.paginatorStore.getDataObservable(
      this.ativoLinhaService.getAtivosLinhaMovelPorEntidadeTitularidade(
        { pageNumber: 1, pageSize: 50 },
        this.entidadeId,
        this.entidadeIdSelecionada,
        this.titularidadeIdSelecionada
        // event$.searchText,
      )
    );
  }

  guardarTitularidade(event: Entidade) {
    if (!event) {
      this.titularidadeIdSelecionada = 0;
      this.sendData({ paginator: this.basePagination, searchText: null });
      return;
    }

    this.titularidadeIdSelecionada = event.entidadeId;

    this.paginatorStore.getDataObservable(
      this.ativoLinhaService.getAtivosLinhaMovelPorEntidadeTitularidade(
        { pageNumber: 1, pageSize: 50 },
        this.entidadeId,
        this.entidadeIdSelecionada,
        this.titularidadeIdSelecionada
        // event$.searchText,
      )
    );
  }

  // Transformar numero em texto
  transformarStatus(valor: number) {
    return STATUS[valor];
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  selecionarValor() {
    this.linhaSelecionada = this.valorSelecionado[0];

    if (this.linhaSelecionada.estoque === 0) {
      this.toastr.error(
        "Linha não está em estoque, selecione outra",
        "Linha Inválida"
      );
      return;
    }

    this.selecionarValorEvent.emit(this.linhaSelecionada);
    this.fecharModal();
  }

  limparValor() {
    this.limparValorEvent.emit();
  }

  checkOne(index: number) {
    this.tableStore.checkOne(index, true);
  }
}
