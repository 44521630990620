import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  Observable,
  catchError,
  map,
  of,
  throwError,
} from "rxjs";
import { Pagination } from "src/app/core/models/pagination.model";
import { environment } from "src/environments/environment";
import { AtivoFisico } from "../../../models/ativos-fisicos/ativoFisico.model";
import { HttpClient } from "@angular/common/http";
import { TipoAtivoFisico } from "../../../models/ativos-fisicos/tipoAtivoFisico.model";

@Injectable({
  providedIn: "root",
})
export class tecAcessorioService {
  private baseUrl = environment.apiBaseHom + "/acessoriotecnologia";

  constructor(private readonly http: HttpClient) {}

  getAtivosFisicoAcessorios(
    pagination: Pagination,
    entidadeId: number
  ): Observable<{
    listaAtivoDispositivo: AtivoFisico[];
    quantidadeTotal: number;
  }> {
    return this.http.get<{
      listaAtivoDispositivo: AtivoFisico[];
      quantidadeTotal: number;
    }>(
      `${this.baseUrl}/?EntidadeId=${entidadeId}&PageNumber=${Math.ceil(
        pagination.pageNumber
      )}&PageSize=${pagination.pageSize}`
    );
  }

  getTipoAtivoAcessorio() {
    return this.http.get<TipoAtivoFisico[]>(`${this.baseUrl}/tipoAtivo`);
  }

  getPesquisaTodosAcessorios(
    entidadeId: number,
    searchText: string,
    pagination: Pagination
  ) {
    return this.http.get<any>(
      `${this.baseUrl}/pesquisaTodos?searchText=${searchText}&EntidadeId=${entidadeId}&PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }
}
