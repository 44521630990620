import { Injectable } from "@angular/core";
import { FilterService } from "../services/filter.service";
import { BehaviorSubject, Subscription } from "rxjs";
import { Pagination } from "../models/pagination.model";
import { SearchRequest } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/searchRequest.model";
import { PaginatorStoreService } from "./paginator.store.service";

@Injectable({
  providedIn: "root",
})
export class FilterStoreService {
  private subscription: Subscription[] = [];
  private constestSubject: BehaviorSubject<string>;
  private columnsListSubject: BehaviorSubject<string[]>;
  private filteredTableSubject: BehaviorSubject<{
    listaAtivo: any[];
    quantidadeTotal: number;
  }>;
  public totalItemsSubject: BehaviorSubject<number> = new BehaviorSubject(0);

  public queryController = "";

  get constest$() {
    return this.constestSubject.asObservable();
  }

  get columnsList$() {
    return this.columnsListSubject.asObservable();
  }

  get hasQuery() {
    return this.queryController.length > 0;
  }

  get filteredTableList$() {
    return this.filteredTableSubject.asObservable();
  }

  constructor(private filterService: FilterService) {
    this.columnsListSubject = new BehaviorSubject<string[]>([]);
    this.constestSubject = new BehaviorSubject<string>("");
    this.filteredTableSubject = new BehaviorSubject<any>({});

    // this.subscription.push(
    // this.constest$.subscribe((route) => {
    //   this.filterService.getColumnsList(route)?.subscribe((result) => {
    //     if (result !== null) this.columnsListSubject.next(result);
    //   });
    // })
    // );
  }

  carregarColunasFilter(hasFilter) {
    // if (hasFilter) {
    this.subscription.push(
      this.constest$.subscribe((route) => {
        if (route)
          this.filterService.getColumnsList(route)?.subscribe((result) => {
            if (result !== null) return this.columnsListSubject.next(result);
          });
      })
    );
    // }
  }

  createQuerry(data: SearchRequest) {
    const { operators, searchText, column, validation } = data;
    let columnQuery = "";
    let validationQuery = "";
    let searchTextQuery = "";
    let operatorsQuery = "";

    for (let index = 0; index < column.length; index++) {
      const searchColumnTextEncondig = encodeURI(column[index])

      columnQuery += `Column=${searchColumnTextEncondig}&`;
      validationQuery += `Validation=${validation[index]}&`;

      const searchTextEconding = encodeURIComponent(searchText[index].trim());

      searchTextQuery += `SearchText=${searchTextEconding}&`;

      if (operators[index])
        operatorsQuery += `operators=${operators[index].trim()}&`;
    }

    this.queryController = `${columnQuery}${validationQuery}${searchTextQuery}${operatorsQuery}`;
  }

  cleanQuery() {
    this.queryController = "";
  }

  returnQuery() {
    return this.queryController;
  }

  inicializeFilter(pagination: Pagination, tipoLinha?: number) {
    if (this.hasQuery) {
      this.subscription.push(
        this.filterService
          .getFilters(
            this.queryController,
            pagination,
            this.constestSubject.value,
            tipoLinha || 0
          )
          .subscribe((list) => {
            if (this.queryController !== null)
              this.filteredTableSubject.next(list);

            this.totalItemsSubject.next(list.quantidadeTotal);
          })
      );
    }
  }

  changeContext(route: string) {
    this.constestSubject.next(route);
  }

  onDestroy() {
    this.subscription.forEach((result) => result?.unsubscribe());
    this.constestSubject.next("");
    this.columnsListSubject.next(null);
    this.filteredTableSubject.next(null);
    this.cleanQuery();
  }
}
