import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AtivoLinhaUtilsService {

  constructor() { }

  retornarLink(descricao: string): string {
    const _descricao = descricao.toLowerCase();

    switch(_descricao){
      case 'banda larga':
        return 'banda_larga';
      case 'vpn ip':
        return 'link';
      case 'link dedicado':
        return 'link';
      case 'mdm':
        return 'link';
      default:
        break;     
    }

    return _descricao;
  }
}
