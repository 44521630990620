import { MenuItem } from "./menu.model";

export const MENUSIDEBAR: MenuItem[] = [
  {
    label: "MENUITEMS.TEM.TEXT",
    isTitle: true,
  },

  {
    label: "MENUITEMS.ATIVO_FISICO.TEXT",
    icon: "bx bx-phone",
    subItems: [
      // {
      //   label: "MENUITEMS.ATIVO_FISICO.LIST.DASHBOARD",
      //   link: "/tem/ativoFisico/dashboard",
      // },
      {
        label: "MENUITEMS.ATIVO_FISICO.LIST.LISTAR_TODOS",
        link: "/tem/ativoFisico/lista",
      },
      {
        label: "MENUITEMS.ATIVO_FISICO.LIST.CHIP",
        link: "/tem/ativoFisico/chips",
        subItems: [
          {
            label: "MENUITEMS.ATIVO_FISICO.LIST.CHIPS.LISTA",
            link: "/tem/ativoFisico/chips/lista",
          },
          {
            label: "MENUITEMS.ATIVO_FISICO.LIST.CHIPS.NOVO",
            link: "/tem/ativoFisico/chips/form",
          },
        ],
      },
      {
        label: "MENUITEMS.ATIVO_FISICO.LIST.DISPOSITIVO",
        link: "/tem/ativoFisico/dispositivos",
        subItems: [
          {
            label: "MENUITEMS.ATIVO_FISICO.LIST.DISPOSITIVOS.LISTA",
            link: "/tem/ativoFisico/dispositivos/lista",
          },
          {
            label: "MENUITEMS.ATIVO_FISICO.LIST.DISPOSITIVOS.NOVO",
            link: "/tem/ativoFisico/dispositivos/form",
          },
        ],
      },
      {
        label: "MENUITEMS.ATIVO_FISICO.LIST.ACESSORIO",
        link: "/tem/ativoFisico/acessorios",
        subItems: [
          {
            label: "MENUITEMS.ATIVO_FISICO.LIST.ACESSORIOS.LISTA",
            link: "/tem/ativoFisico/acessorios/lista",
          },
          {
            label: "MENUITEMS.ATIVO_FISICO.LIST.ACESSORIOS.NOVO",
            link: "/tem/ativoFisico/acessorios/form",
          },
        ],
      },
    ],
  },
  {
    label: "MENUITEMS.ATIVO_LINHA.TEXT",
    icon: "bx bx-phone",
    subItems: [
      // {
      //   label: "MENUITEMS.ATIVO_LINHA.LIST.DASHBOARD",
      //   link: "/tem/ativoLinha/dashboard",
      // },
      {
        label: "MENUITEMS.ATIVO_LINHA.LIST.LISTAR_TODOS",
        link: "/tem/ativoLinha/lista",
      },
      {
        label: "MENUITEMS.ATIVO_LINHA.LIST.LINHA",
        link: "/tem/ativoLinha/movel",
        subItems: [
          {
            label: "MENUITEMS.ATIVO_LINHA.LIST.LINHAS.LISTA",
            link: "/tem/ativoLinha/movel/lista",
          },
          {
            label: "MENUITEMS.ATIVO_LINHA.LIST.LINHAS.NOVO",
            link: "/tem/ativoLinha/movel/form",
          },
        ],
      },
      {
        label: "MENUITEMS.ATIVO_LINHA.LIST.LINK",
        link: "/tem/ativoLinha/link",
        subItems: [
          {
            label: "MENUITEMS.ATIVO_LINHA.LIST.LINKS.LISTA",
            link: "/tem/ativoLinha/link/lista",
          },
          {
            label: "MENUITEMS.ATIVO_LINHA.LIST.LINKS.NOVO",
            link: "/tem/ativoLinha/link/form",
          },
        ],
      },
      {
        label: "MENUITEMS.ATIVO_LINHA.LIST.BANDA_LARGA",
        link: "/tem/ativoLinha/banda_larga",
        subItems: [
          {
            label: "MENUITEMS.ATIVO_LINHA.LIST.BANDAS_LARGAS.LISTA",
            link: "/tem/ativoLinha/banda_larga/lista",
          },
          {
            label: "MENUITEMS.ATIVO_LINHA.LIST.BANDAS_LARGAS.NOVO",
            link: "/tem/ativoLinha/banda_larga/form",
          },
        ],
      },
      {
        label: "MENUITEMS.ATIVO_LINHA.LIST.FIXA",
        link: "/tem/ativoLinha/fixa",
        subItems: [
          {
            label: "MENUITEMS.ATIVO_LINHA.LIST.FIXAS.LISTA",
            link: "/tem/ativoLinha/fixa/lista",
          },
          {
            label: "MENUITEMS.ATIVO_LINHA.LIST.FIXAS.NOVO",
            link: "/tem/ativoLinha/fixa/form",
          },
        ],
      },
    ],
  },
  {
    label: "MENUITEMS.CADASTRO.TEXT",
    icon: "bx bx-pencil",
    subItems: [
      {
        label: "MENUITEMS.CADASTRO.LIST.USUARIO",
        link: "/tem/ativoLinha/movel",
        subItems: [
          {
            label: "MENUITEMS.CADASTRO.LIST.USUARIOS.LISTA",
            link: "/tem/cadastro/usuarioentidade/lista",
          },
          {
            label: "MENUITEMS.CADASTRO.LIST.USUARIOS.NOVO",
            link: "/tem/cadastro/usuarioentidade/form",
          },
        ],
      },
      {
        label: "MENUITEMS.CADASTRO.LIST.ENTIDADE",
        link: "/tem/cadastro/entidades",
        subItems: [
          {
            label: "MENUITEMS.CADASTRO.LIST.ENTIDADES.LISTA",
            link: "/tem/cadastro/entidades/lista",
          },
          {
            label: "MENUITEMS.CADASTRO.LIST.ENTIDADES.NOVO",
            link: "/tem/cadastro/entidades/form",
          },
        ],
      },
      {
        label: "MENUITEMS.CADASTRO.LIST.MODELOS_FABRICANTE",
        link: "/tem/cadastro/modelos_fabricantes",
        subItems: [
          {
            label: "MENUITEMS.CADASTRO.LIST.ATIVOS.LISTA",
            link: "/tem/cadastro/ativos/lista",
          },
          {
            label: "MENUITEMS.CADASTRO.LIST.MODELOS.LISTA",
            link: "/tem/cadastro/modelos/lista",
          },
          {
            label: "MENUITEMS.CADASTRO.LIST.FABRICANTES.LISTA",
            link: "/tem/cadastro/fabricantes/lista",
          },
        ],
      },
      {
        label: "MENUITEMS.CADASTRO.LIST.FORNECEDOR",
        link: "/tem/cadastro/modelos_fabricantes",
        subItems: [
          {
            label: "MENUITEMS.CADASTRO.LIST.FORNECEDORES.LISTA",
            link: "/tem/cadastro/fornecedor/lista",
          },
          {
            label: "MENUITEMS.CADASTRO.LIST.FORNECEDORES.NOVO",
            link: "/tem/cadastro/fornecedor/form",
          },
        ],
      },
      {
        label: "MENUITEMS.CADASTRO.LIST.PERFIL_PACOTE",
        link: "/tem/cadastro/entidades",
        subItems: [
          {
            label: "MENUITEMS.CADASTRO.LIST.PERFIL_PACOTES.LISTA",
            link: "/tem/cadastro/perfil_pacote/lista",
          },
          {
            label: "MENUITEMS.CADASTRO.LIST.PERFIL_PACOTES.NOVO",
            link: "/tem/cadastro/perfil_pacote/form",
          },
        ],
      },
    ],
  },
  {
    label: "MENUITEMS.CONTAFATURA.TEXT",
    icon: "bx bx-file",
    subItems: [
      {
        label: "MENUITEMS.CONTAFATURA.LIST.DOWNLOAD",
        link: "/tem/ativoLinha/movel",
        subItems: [
          {
            label: "MENUITEMS.CONTAFATURA.LIST.DOWNLOADS.LISTA",
            link: "/tem/contaFatura/listaDownload",
          },
          {
            label: "MENUITEMS.CONTAFATURA.LIST.DOWNLOADS.NOVA",
            link: "/tem/contaFatura/formDownload",
          },
        ],
      },

      {
        label: "MENUITEMS.CONTAFATURA.LIST.CONTA",
        link: "/tem/ativoLinha/movel",
        subItems: [
          {
            label: "MENUITEMS.CONTAFATURA.LIST.CONTAS.LISTA",
            link: "/tem/contaFatura/listaConta",
          },
          {
            label: "MENUITEMS.CONTAFATURA.LIST.CONTAS.NOVA",
            link: "/tem/contaFatura/form",
          },
        ],
      },

      {
        label: "MENUITEMS.CONTAFATURA.LIST.FATURA",
        link: "/tem/ativoLinha/movel",
        subItems: [
          {
            label: "MENUITEMS.CONTAFATURA.LIST.FATURAS.LISTA",
            link: "/tem/contaFatura/listaFatura",
          },
          {
            label: "MENUITEMS.CONTAFATURA.LIST.FATURAS.NOVA",
            link: "/tem/contaFatura/formFatura",
          },
        ],
      },
    ],
  },

  {
    label: "MENUITEMS.FACILITIES.TEXT",
    isTitle: true,
  },
  {
    label: "MENUITEMS.FROTAS.TEXT",
    icon: "bx bx-car",
    subItems: [
      {
        label: "MENUITEMS.FROTAS.LIST.FROTA",
        link: "/tem/ativoFisico/dashboard",
        subItems: [
          {
            label: "MENUITEMS.FROTAS.LIST.FROTAS.LISTA",
            link: "/facilities/frotas/lista",
          },
          {
            label: "MENUITEMS.FROTAS.LIST.FROTAS.NOVA",
            link: "/facilities/frotas/form",
          },
        ],
      },
    ],
  },
  {
    label: "MENUITEMS.DASH.TEXT",
    icon: "bx bx-car",
    subItems: [
      {
        label: "MENUITEMS.FROTAS.LIST.FROTA",
        link: "/tem/ativoFisico/dashboard",
        subItems: [
          {
            label: "MENUITEMS.FROTAS.LIST.FROTAS.LISTA",
            link: "/facilities/frotas/lista",
          },
          {
            label: "MENUITEMS.FROTAS.LIST.FROTAS.NOVA",
            link: "/facilities/frotas/form",
          },
        ],
      },
    ],
  },
];
