<div
  class="container-wrapper"
  [ngStyle]="topbarChanged === 'false' ? {
    border:
      this.tokenStorageService.getUser().entidadeId === 1
        ? '0px'
        : '1px solid #eeeeee',
  } : {
    border: 'none'
  }"
>
  <ng-content> </ng-content>

  <p-dialog
    style="width: 100%"
    header=""
    [visible]="access"
    [modal]="true"
    [style]="{ width: '50rem' }"
    [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
  >
  <div class="paywall-container">
    <div class="paywall-dialog">
      <h2>Acesso Restrito</h2>
      <p>Você não possui acesso a essa página, contate seu gestor.</p>
      <button class="paywall-button" (click)="goBack()">Voltar</button>
    </div>
  </div>
  </p-dialog>
</div>
