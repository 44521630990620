<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      @if(dispositivoData?.roles?.includes('ADMINRH', 'CLIENTE', 'ATENDIMENTO') && this.tokenStorageService.getRoles().includes('ADMINRH', 'CLIENTE', 'ATENDIMENTO')){
        <li>
          <a
            class="side-nav-link-a-ref has-arrow"
            href="javascript:void(0);"
            [attr.data-parent]="subitem?.parentId"
          >
            {{ dispositivoData?.label | translate }}
          </a>
          <ul class="sub-menu mm-collapse" aria-expanded="false">
            @for (subSubitem of dispositivoData?.subItems; track $index) {
            <li>
              <a
                [attr.data-parent]="subSubitem?.parentId"
                routerLink="{{ subSubitem?.link }}"
                routerLinkActive="active"
                class="side-nav-link-ref"
              >
                {{ subSubitem?.label | translate }}
              </a>
            </li>
            }
          </ul>
        </li>
        <!-- <ul> -->
          <li *ngFor="let item of cadastroData.subItems">
            <a
              class="side-nav-link-a-ref has-arrow"
              href="javascript:void(0);"
              [attr.data-parent]="item.parentId"
            >
              {{ item.label | translate }}
            </a>

            <!-- Verifica se o item tem subItems antes de renderizar -->
            <ul *ngIf="item.subItems && item.subItems.length > 0" class="sub-menu mm-collapse" aria-expanded="false">
              <li *ngFor="let subItem of item.subItems">
                <a
                  [attr.data-parent]="subItem.parentId"
                  [routerLink]="subItem.link"
                  routerLinkActive="active"
                  class="side-nav-link-ref"
                >
                  {{ subItem.label | translate }}
                </a>

                <!-- Verifica se o subItem tem subItems antes de renderizar -->
                <ul *ngIf="subItem.subItems && subItem.subItems.length > 0" class="sub-sub-menu">
                  <li *ngFor="let subSubItem of subItem.subItems">
                    <a
                      [attr.data-parent]="subSubItem.parentId"
                      [routerLink]="subSubItem.link"
                      routerLinkActive="active"
                      class="side-nav-link-ref"
                    >
                      {{ subSubItem.label | translate }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        <!-- </ul> -->
        }
      @for (item of menuItems; track $index) { @if(hasRoles(item.roles)){
       @if(this.tokenStorageService.getRoles() != 'ADMINRH') {
      <ng-container>
        @if(item.isTitle){
        <li class="menu-title">{{ item.label | translate }}</li>
        }@else if(!item.isTitle && !item.isLayout){
        <li>
          @if(hasItems(item)){

          <a
            href="javascript:void(0);"
            class="is-parent"
            [ngClass]="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
          >
            @if(item.icon){
            <i class="bx {{ item.icon }}"></i>
            }
            <span> {{ item.label | translate }}</span>
            @if(item.badge){
            <span
              class="badge rounded-pill float-end bg-{{ item.badge.variant }}"
              >{{ item.badge.text | translate }}</span
            >
            } </a
          >} @else{
          <a
            routerLink="{{ item.link }}"
            class="side-nav-link-ref"
            routerLinkActive="active"
          >
            @if(item.icon){
            <i class="bx {{ item.icon }}"></i>
            }
            <span> {{ item.label | translate }}</span>
            @if(item.badge){
            <span
              class="badge rounded-pill bg-{{ item.badge.variant }} float-end"
              >{{ item.badge.text | translate }}</span
            >
            }
          </a>

          } @if(hasItems(item)){

          <ul class="sub-menu" aria-expanded="false">
            @for (subitem of item.subItems; track $index) {
            <li>
              @if(hasItems(subitem)){
              <a
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                [attr.data-parent]="subitem.parentId"
              >
                {{ subitem.label | translate }}
              </a>
              <ul class="sub-menu mm-collapse" aria-expanded="false">
                @for (subSubitem of subitem.subItems; track $index) {
                <li>
                  <a
                    [attr.data-parent]="subSubitem.parentId"
                    routerLink="{{ subSubitem.link }}"
                    routerLinkActive="active"
                    class="side-nav-link-ref"
                  >
                    {{ subSubitem.label | translate }}
                  </a>
                </li>
                }
              </ul>
              } @else{
              <a
                routerLink="{{ subitem.link }}"
                class="side-nav-link-ref"
                [attr.data-parent]="subitem.parentId"
                routerLinkActive="active"
              >
                {{ subitem.label | translate }}
                @if(subitem.badge){
                <span
                  class="badge rounded-pill bg-{{
                    subitem.badge.variant
                  }} float-end"
                  >{{ subitem.badge.text | translate }}</span
                >
                }
              </a>
              }
            </li>
            }
          </ul>
          }
        </li>
        }
      </ng-container>
      } } }
    </ul>
  </div>
</ng-template>
<!-- Sidebar -->

<div
  class="vertical-menu"
  [ngStyle]="{
    backgroundColor:

      this.tokenStorageService.getTheme() === 'dark'
        ? 'var(--primary)'
        : '#fbf8f7'
  }"
>
  <ngx-simplebar class="h-100" #componentRef>
    @if(isCondensed){
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }@else {
    <div>
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    }
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->
