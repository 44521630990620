import { Component } from "@angular/core";
import { Subscription } from "rxjs";
import { LoadActionService } from "src/app/core/actions/load-action.service";

@Component({
  selector: "app-style-loader",
  templateUrl: "./style-loader.component.html",
  styleUrl: "./style-loader.component.scss",
})
export class StyleLoaderComponent {
  subscriptions: Subscription[] = [];
  isFull = true;

  ngOnInit(): void {
    this.subscriptions.push(
      this.loadActions.isFull$.subscribe((isFull) => (this.isFull = isFull))
    );
  }

  constructor(private loadActions: LoadActionService) {}

  get loadActions$() {
    return this.loadActions.loadAction$;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
