export enum DDDEstadosEnum {
  AC = 68,
  AL = 82,
  AP = 96,
  AM1 = 92,
  AM2 = 97,
  BA1 = 71,
  BA2 = 73,
  BA3 = 74,
  BA4 = 75,
  BA5 = 77,
  CE1 = 85,
  CE2 = 88,
  DF = 61,
  ES1 = 27,
  ES2 = 28,
  GO1 = 62,
  GO2 = 64,
  MA1 = 98,
  MA2 = 99,
  MT1 = 65,
  MT2 = 66,
  MS = 67,
  MG1 = 31,
  MG2 = 32,
  MG3 = 33,
  MG4 = 34,
  MG5 = 35,
  MG6 = 37,
  MG7 = 38,
  PA1 = 91,
  PA2 = 93,
  PA3 = 94,
  PB = 83,
  PR1 = 41,
  PR2 = 42,
  PR3 = 43,
  PR4 = 44,
  PR5 = 45,
  PR6 = 46,
  PE1 = 81,
  PE2 = 87,
  PI1 = 86,
  PI2 = 89,
  RJ1 = 21,
  RJ2 = 22,
  RJ3 = 24,
  RN = 84,
  RS1 = 51,
  RS2 = 53,
  RS3 = 54,
  RS4 = 55,
  RO = 69,
  RR = 95,
  SC1 = 47,
  SC2 = 48,
  SC3 = 49,
  SP1 = 11,
  SP2 = 12,
  SP3 = 13,
  SP4 = 14,
  SP5 = 15,
  SP6 = 16,
  SP7 = 17,
  SP8 = 18,
  SP9 = 19,
  SE = 79,
  TO = 63,
}
