import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, catchError, of, throwError } from "rxjs";

import { TokenStorageService } from "./token-storage.service";

import { getFirebaseBackend } from "../../authUtils";
import { User } from "src/app/store/Authentication/auth.models";
import { from, map } from "rxjs";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { StyleManagerService } from "./style-manager.service";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  apiurl = environment.apiBaseHom + "/usuario";

  user: User;

  constructor(
    private http: HttpClient,
    private tokenStorageService: TokenStorageService,
    private styleManagerService: StyleManagerService,
    private router: Router
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  changePassword(passwordReset?: {email?: string, senha?: string}) {
    return this.http.post<any>(`${this.apiurl}/mudarsenha`, passwordReset).pipe(
      map((response) => {

        return response;
      }),
      catchError((error) => {


        return of(error);
      })
    );
  }

  forgotPassword(formData:any){
    return this.http.post<any>(`${this.apiurl}/forgot-password`, formData).pipe(
      map((response) => {

        return response;
      }),
      catchError((error) => {
        // Aqui, o catchError vai apenas capturar erros reais (status >= 400)
        if (error.status >= 400) {
          return throwError(() => error);
        }
        // Se o status não for erro, deixe a execução seguir normalmente
        return of(error);
      })
    );
  }

  

  login(userCred: any) {
    return this.http.post<any>(`${this.apiurl}/login`, userCred).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        if (user && user.accessToken) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem("currentUser", JSON.stringify(user));
          localStorage.setItem("token", user?.accessToken);
          this.tokenStorageService.parseTokenUsuario(user.accessToken);
          this.currentUserSubject.next(user);


          const colors = this.tokenStorageService.getIdentidadeVisualEntidade();
          this.styleManagerService.setVariables(colors);
          if (this.tokenStorageService.getUser().entidadeId == 1) {
            this.router.navigate(["tem/relatorios/dashboards"]);
          } else {
            this.router.navigate(["tem/relatorios/dashboards"]);
          }
        }
        return user;
      })
    ); //  catchError(err => of('error', err))
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("dadosUsuario");
    localStorage.removeItem("roles");
    localStorage.removeItem("entidadeSelecionada");
    this.currentUserSubject.next(null);
  }

  /**
   * Performs the auth
   * @param email email of user
   * @param password password of user
   */
  loginFireBase(email: string, password: string) {
    return from(
      getFirebaseBackend()
        .loginUser(email, password)
        .pipe(
          map((user) => {
            return user;
          })
        )
    );
  }

  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(user: User) {
    // return from(getFirebaseBackend().registerUser(user));

    return from(
      getFirebaseBackend()
        .registerUser(user)
        .then((response: any) => {
          const user = response;
          return user;
        })
    );
  }

  /**
   * Reset password
   * @param email email
   */

  
  resetPassword(resetPasswordData: any) {
    return this.http.post<any>(`${this.apiurl}/reset-password`, resetPasswordData).pipe(
      map((response) => {

        return response;
      }),
      catchError((error) => {


        return of(error);
      })
    );
  }

  /**
   * Logout the user
   */
  logoutFireBase() {
    // logout the user
    getFirebaseBackend().logout();
  }
}
