import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, map, of } from "rxjs";
import { environment } from "src/environments/environment";
import { Operadora } from "../../../ativo-linha/core/models/operadora.model";
import { Fornecedor } from "../models/fornecedor.model";
import { Pagination } from "src/app/core/models/pagination.model";

@Injectable({
  providedIn: "root",
})
export class FornecedorService {
  baseUrl = environment.apiBaseHom + "/fornecedor";

  constructor(private http: HttpClient) {}

  getFornecedor(tipo?:string): Observable<Fornecedor[]> {
    const fornecedorUrl = `fornecedorordem?tipo=${tipo}`

    return this.http.get<Fornecedor[]>(`${this.baseUrl}/${tipo ? fornecedorUrl : 'Fornecedorordem'}`);
  }

  getFornecedores(): Observable<Fornecedor[]> {
    return this.http.get<Fornecedor[]>(`${this.baseUrl}/Fornecedores`);
  }

  getPagedFornecedor(
    pagination: Pagination
  ): Observable<{ quantidadeTotal: number; itensList: Fornecedor[] }> {
    return this.http.get<{ quantidadeTotal: number; itensList: Fornecedor[] }>(
      `${this.baseUrl}/paged?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}`
    );
  }

  findOneFornecedor(
    fornecedorId: number
  ): Observable<Fornecedor | HttpErrorResponse> {
    return this.http.get<Fornecedor>(`${this.baseUrl}/${fornecedorId}`).pipe(
      map((res: Fornecedor) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  }

  getSearchTextFornecedor(
    pagination: Pagination,
    searchText: string
  ): Observable<{ quantidadeTotal: number; itensList: Fornecedor[] }> {
    return this.http.get<{ quantidadeTotal: number; itensList: Fornecedor[] }>(
      `${this.baseUrl}/pesquisaTodos?PageNumber=${pagination.pageNumber}&PageSize=${pagination.pageSize}&searchText=${searchText}`
    );
  }

  addFornecdor(
    fornecedor: Fornecedor
  ): Observable<Fornecedor | HttpErrorResponse> {
    return this.http.post<Fornecedor>(`${this.baseUrl}`, fornecedor).pipe(
      map((res: Fornecedor) => {
        return res;
      }),
      catchError((err: HttpErrorResponse) => {
        return of(err);
      })
    );
  }

  getTipoFornecedorList(): Observable<string[]> {
    return this.http
      .get<string[]>(`${this.baseUrl}/ListaTiposFornecedores`)
      .pipe(
        map((res: string[]) => {
          return res;
        }),        
      );
  }

  updateFornecedor(
    fornecedor: Fornecedor
  ): Observable<Fornecedor | HttpErrorResponse> {
    return this.http
      .put<Fornecedor | HttpErrorResponse>(
        `${this.baseUrl}/${fornecedor.fornecedorId}`,
        fornecedor
      )
      .pipe(
        map((res: Fornecedor) => {
          return res;
        }),
        catchError((err: HttpErrorResponse) => {
          return of(err);
        })
      );
  }
}
