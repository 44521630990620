<p-dialog
  style="width: 75%"
  header="Usuários"
  [(visible)]="isOpen"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  (onHide)="fecharModal()"
  [draggable]="false"
  [resizable]="false"
>
  <ng-container [ngTemplateOutlet]="usuarios"></ng-container>
  <ng-template pTemplate="footer">
    <div style="display: flex; justify-content: flex-end; gap: 10px">
      <!-- <button (click)="limparValor()" class="btn btn-modal">
      <i class="fas fa-plus"></i> Usuário não informado
    </button> -->

      <!-- <app-action-btn
        icon="fas fa-plus font-size-20"
        [type]="''"
        text="Usuário não informado"
        (click)="limparValor(chipSelecionado)"
      /> -->

      <!-- <button (click)="limparValor()" class="btn btn-modal">
      <i class="fa fa-times"></i> Limpar
    </button> -->

    <app-action-btn
      icon="fa fa-user"
      [type]="''"
      text="Criar Usuário"
      isInput="true"
      [route]="'/tem/cadastro/usuarioentidade/form'"
    />
    
      <app-action-btn
        icon="fa fa-times font-size-20"
        [type]="''"
        text="Limpar"
        (click)="limparValor()"
      />
      <!-- Vincular ou Selecionar -->
      <!-- <button (click)="selecionarValor()" class="btn btn-modal">
      <i class="fa fa-times"></i> {{labelBtn}}
    </button> -->
      <app-action-btn
        icon="fa fa-check font-size-20"
        [type]="'aposkdpoaskdopakksd'"
        [text]="labelBtn"
        (click)="selecionarValor(chipSelecionado)"
        id="btnSelecionarModal"
      />
    </div>
  </ng-template>
</p-dialog>

<ng-template #usuarios>
  <app-table
    [data]="data$"
    [grid]="grid"
    [isOpen]="isOpen"
    (getData)="sendData($event)"
    (openModal)="editModal()"
    [title]="''"
    [dataTableHeaders]="dataTableHeadersUsuarios"
    [exibirCheckbox]="false"
    [filterContext]="'usuarioentidade'"
    [exibirFilterButton]="true"
    [placeholderPesquisaGlobal]="'Pesquisa em Usuários'"
    [style]="{ maxWidth: '90% !important' }"    
  >
    <div
      class="dataTable-body"
      [ngStyle]="{ 'grid-template-columns': grid }"
      *ngFor="let items of tableRow$ | async; let i = index"
    >
      <div>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="items.isChecked"
          [disabled]="!items.isEnabled"
          (click)="checkOne(i)"
          [(ngModel)]="items[i]"
        />
      </div>
      <div>{{ items.entidade?.razaoSocial ?? "" }}</div>
      <div>{{ items.unidadeEntidade?.razaoSocial ?? "" }}</div>
      <div style="display: flex; justify-content: space-between; margin-right: 20px;">
        {{ items.nomeCompleto ?? "" }}
        @if (items.vip) {

        <i
          class="bx bx-award font-size-24 yellow"
          style="color: rgb(250, 235, 29)"
          aria-hidden="true"
          tooltip="Usuário VIP"
        ></i>
        }
      </div>
      <div>{{ items.matricula ?? "" }}</div>
      <div>{{ items.areaEntidade?.descricao ?? "" }}</div>
      <div>{{ items.centroCustoEntidade?.nome ?? "" }}</div>
      <div>{{ items.cargoEntidade?.descricao ?? "" }}</div>
      <div style="text-overflow: ellipsis">{{ items.email ?? "" }}</div>
      <div>{{ items.dataCadastro | date : "dd/MM/yyyy - H:mm" }}</div>
      <div style="display: flex; flex-direction: row; align-items: flex-start">
        <span
          style="width: 80%; margin-bottom: 3px"
          [ngClass]="
            items.status == 0
              ? 'active badge bg-danger me-1'
              : 'active badge bg-success me-1'
          "
        >
          {{ items.status == 0 ? "INATIVO" : "ATIVO" }}
        </span>
      </div>
    </div>
  </app-table>
</ng-template>
