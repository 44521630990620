import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ModalDirective } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { Table } from "primeng/table";
import { finalize, Subscription } from "rxjs";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { Pagination } from "src/app/core/models/pagination.model";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { AtivoFisicoService } from "src/app/modulos/telecom/ativo-fisico/core/service/ativo-fisico.service";
import { EntidadeStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/entidade.store.service";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { AtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/ativoFisico.model";
import { STATUS } from "src/app/modulos/telecom/ativo-fisico/models/enum/status.enum";
import { AtivoLinha } from "src/app/modulos/telecom/ativo-linha/core/models/ativoLinha.model";
import { AtivoLinhaService } from "src/app/modulos/telecom/ativo-linha/core/services/ativo-linha.service";
import { Fornecedor } from "src/app/modulos/telecom/cadastro/core/models/fornecedor.model";

@Component({
  selector: "app-modal-list-chips",
  templateUrl: "./chips.component.html",
  styleUrls: ["./chips.component.css"],
})
export class ChipsModalComponent {
  @ViewChild("showModal", { static: false }) showModal?: ModalDirective;
  @ViewChild("dt", { static: true }) dt: Table;
  @ViewChild("removeItemModal", { static: false })
  removeItemModal?: ModalDirective;

  chipSelecionado: AtivoFisico;

  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<any>();
  @Output() limparValorEvent = new EventEmitter<any>();

  @Input() entidade: number;
  @Input() labelBtn: string = "Selecionar";
  @Input() entidadeId: number;
  @Input() fornecedor: Fornecedor;

  @Input() numeroDaLinha: string = null;
  @Input() todosMaisLinha: boolean = false;

  @Input() ativoLinha: AtivoLinha;
  @Input() titularidadeId: number;

  breadCrumbItems: Array<{}>;
  tooltipText = "pesquisa da pagina atual";

  isOpen: boolean = true;
  isEditing = false;

  // Update numero Da linha !
  numeroLinha: string = "";
  IndexChipUpdate: number = -1;
  chipUpdate: AtivoFisico = null;
  openEditarImei = false;
  ErrorNumberList = false;
  // Update numero Da linha !

  grid: string =
    "0.3fr minmax(60px, 0.6fr) minmax(80px, 1fr) minmax(50px, 0.6fr) minmax(50px, 0.6fr) minmax(70px, 0.8fr) minmax(50px, 0.6fr)  minmax(50px, 0.6fr) minmax(100px, 1fr) ";
  dataTableHeadersChips: string[] = [
    "",
    "Identificador",
    "Situação",
    "DDD",
    "Fabricante",
    "Entidade",
    "Titularidade",
    "Chamado",
    "Numero Da linha",
  ];

  isChecked: boolean = false;

  enumTeste = SERVICES_NAMES;

  valorSelecionado!: any;

  formSelects: FormGroup;

  basePagination: Pagination = { pageNumber: 1, pageSize: 50 };

  entidadeIdSelecionada: number = 0;
  titularidadeIdSelecionada: number = 0;

  private subscription: Subscription[] = [];

  get data$() {
    return this.paginatorStore.dataObservable$;
  }

  get tableRow$() {
    return this.tableStore.tableRow$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  get entidadeList$() {
    return this.entidadeStoreService.entidadeList$;
  }

  constructor(
    private paginatorStore: PaginatorGenericStoreService,
    private toastr: ToastrService,
    private tableStore: TableStoreService,
    private ativoFisicoService: AtivoFisicoService,
    private entidadeStoreService: EntidadeStoreService,
    private ativoLinhaService: AtivoLinhaService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.formSelects = this.fb.group({
      entidade: [null],
      titularidade: [null],
    });

    this.subscription.push(
      this.rowsSelected.subscribe((data: any) => (this.valorSelecionado = data))
    );

    this.IndexChipUpdate = -1;
  }

  sendData(event$: { paginator: Pagination; searchText: string }) {
   if(window.location.pathname.includes('dispositivos/form')){

    this.paginatorStore.getDataObservable(
      this.ativoFisicoService.GetTodosAtivoFisicoChipsEstoque(
        this.entidadeId,  
        event$.paginator,
        this.titularidadeId
      )
    );
        return;
   }
    if (event$.searchText) {
      this.paginatorStore.getDataObservable(
        this.ativoFisicoService.GetPesquisaTodosAtivoFisicoChipsEstoqueFornecedorEntidade(
          this.entidadeId,
          1,
          this.fornecedor.nomeUsual,
          event$.searchText,
          event$.paginator
        )
      );
    } else {
      console.log(this.titularidadeId);

      this.paginatorStore.getDataObservable(
        this.ativoFisicoService.GetTodosAtivoFisicoChipsEstoqueFornecedorEntidade(
          this.entidadeId,
          this.entidadeId,
          this.fornecedor?.nomeUsual,
          event$.paginator,
          this.numeroDaLinha,
          this.todosMaisLinha,
          this.titularidadeId
        )
      );
    }
  }

  // Transformar numero em texto
  transformarStatus(valor: number) {
    return STATUS[valor];
  }

  checkOne(index: number) {
    this.tableStore.checkOne(index, true);
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  selecionarValor() {
    this.chipSelecionado = this.valorSelecionado[0];

    this.selecionarValorEvent.emit(this.chipSelecionado);
    this.fecharModal();
  }

  limparValor() {
    this.limparValorEvent.emit();
  }

  onBlurNumeroLinha(chip: AtivoFisico) {
    this.chipUpdate = chip;

    if (this.numeroLinha.length !== 11) {
      this.ErrorNumberList = true;
    } else {
      this.ErrorNumberList = false;
      this.openEditarImei = true;
    }
  }

  OnChangeNumeroLinha(event: boolean) {
    const ChipWithNewLine = [];
    ChipWithNewLine.push({
      ...this.chipUpdate,
    });

    if (event) {
      this.openEditarImei = false;

      this.ativoLinhaService
        .alteraDDD(
          this.ativoLinha,
          this.numeroLinha,
          ChipWithNewLine[0].ativoFisicoId,
          ""
        )
        .pipe(
          finalize(() => {
            this.paginatorStore.firstOrLastPage(true);
            this.openEditarImei = false;
            this.IndexChipUpdate = -1;
          })
        )
        .subscribe((val) => {
          if (!val.error) {
            this.toastr.success("Numero Alterado com sucesso", "chip", {
              timeOut: 5000,
              progressBar: true,
            });
          }
        });
    } else {
      this.openEditarImei = false;
    }
  }

  OnEdit(index: number) {
    this.IndexChipUpdate = index;
  }
}
