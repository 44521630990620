import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FileS3 } from '../models/fileS3.model';
import { FileModel } from 'src/app/core/models/fileModel';
import { Fatura } from 'src/app/modulos/telecom/conta-fatura/core/models/fatura.model';
import { toOpResult } from 'src/app/core/models/operationResult.model';


@Injectable({
  providedIn: 'root'
})
export class FileService {  


 private baseUrlFile = environment.apiBaseHom +'/files';

  constructor(private http: HttpClient) { }

  async upload(files: File[],filemodel:FileModel): Promise<Observable<HttpEvent<any>>> {
    const formData: FormData = new FormData();
    const uploadableFiles = files.map(async file => {         
      const fileContent = await file.arrayBuffer()
      const uploadableName = encodeURIComponent(file.name)
      console.log('uploadableName:',uploadableName)
      return new File([fileContent],uploadableName,{type: file.type, lastModified: file.lastModified  })
    })
    // const uploadableFiles = files
    for (const file of uploadableFiles)
    { 
      try{     
        // const uploadableFile = await file
        // formData.append('ListFileDetails', uploadableFile)
        formData.append('ListFileDetails', await file)
      }
      catch(err){
        console.error("Wasn't possible to upload file ",err)
      }
        // .then(uploadableFile => formData.append('ListFileDetails', uploadableFile))

    }
    formData.append('Source',filemodel.source.toString());  
    formData.append('Fornecedor',filemodel.fornecedor.toString());      
    formData.append('NomeEntidade',filemodel.nomeEntidade.toString());

    const req = new HttpRequest('POST', `${this.baseUrlFile}/upload`, formData, {
      reportProgress: true,
      responseType: 'blob' 
    });
    return this.http.request(req);
  }
  uploadDetalhadoFatura(files: File[], filemodel: FileModel, dadosContaFatura: Fatura): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    for (const file of files) {
      formData.append('ListFileDetails', file);
    }

    formData.append('Content-Type', 'application/json');
    formData.append('FileDetails', files[0]);
    formData.append('FileType', filemodel.fileType.toString());
    formData.append('FileTipoAtivo', '1'); // tanto faz para fatura
    // formData.append('FileOperadora', filemodel.fileOperadora.toString());
    formData.append('Fornecedor', filemodel.fornecedor);
    formData.append('DadosConta', JSON.stringify(dadosContaFatura));
    formData.append('NomeEntidade', filemodel.nomeEntidade.toString().trim());
    formData.append('Source', 'ArquivosEletronico');

    const req = new HttpRequest('POST', `${this.baseUrlFile}/PostFileTXTFebraban`, formData, {
      reportProgress: true,
      responseType: 'blob'
    });

    return this.http.request(req).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.error instanceof Blob) {
      return new Observable(observer => {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const errorText = e.target.result;
          try {
            const errorJson = JSON.parse(errorText);
            observer.error({ Success: false, Message: errorJson.message || 'An unknown error occurred!' });
          } catch (err) {
            observer.error({ Success: false, Message: 'An unknown error occurred!' });
          }
        };
        reader.readAsText(error.error);
      });
    } else if (error.error instanceof ErrorEvent) {
      // Erro do lado do cliente
      const errorMessage = `Error: ${error.error.message}`;
      return throwError({ Success: false, Message: errorMessage });
    } else {
      // Erro do lado do servidor
      const errorMessage = error.error.message || 'An unknown error occurred!';
      return throwError({ Success: false, Message: errorMessage });
    }
  }


  uploadDetalhado(files: File[], filemodel:FileModel): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    for (const file of files)
    {
      formData.append('ListFileDetails', file);
     }


        formData.append('Content-Type', 'application/json');
        formData.append('FileDetails', files[0]);
        formData.append('FileType', filemodel.fileType.toString());
        formData.append('FileTipoAtivo', '1');//tanto faz para fatura
       // formData.append('FileOperadora', filemodel.fileOperadora.toString());
       formData.append('Fornecedor',filemodel.fornecedor);
       // formData.append('NomeEntidade', filemodel.nomeEntidade.toString().trim());
       formData.append('NomeEntidade', '112152298_118679603_53_06_2023_FebrabanV3.txt');
        

        const req = new HttpRequest('POST', `${this.baseUrlFile}/DownloadArquivo`, formData, {
          reportProgress: true,
          responseType: 'blob' });



          return this.http.request(req);




  }
  downloadDetalhadoWith(reqModel:{entidadeId: number, faturaHeaderId: number }): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    const body = {
      entidadeId: reqModel.entidadeId
      // ,identificadorContaUnica: 
      ,headerId: reqModel.faturaHeaderId
    }
    // formData.append('Content-Type', 'application/json');    
    // formData.append('FileType', reqModel.fileModel.fileType.toString());    
    // formData.append('Fornecedor',reqModel.fileModel.fornecedor);    
    // formData.append('NomeEntidade', reqModel.fileModel.nomeEntidade.toString());
    // formData.append('NomeArquivoDownload', reqModel.fileModel.nomeArquivoDownload.toString());
    // formData.append('BucketName',reqModel.fileModel.bucketName.toString());

    const req = new HttpRequest('POST', `${this.baseUrlFile}/export/detalhado`, formData, {
      reportProgress: true,
      responseType: 'blob' 
    });
    return this.http.request(req);
  }
  downloadDetalhado(files: File[], filemodel:FileModel): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

  //  for (const file of files)
   // {
     // formData.append('ListFileDetails', file);
    // }

    formData.append('Content-Type', 'application/json');
    // formData.append('FileDetails', files[0]);
    formData.append('FileType', filemodel.fileType.toString());
    // formData.append('FileTipoAtivo', '1');//tanto faz para fatura
    // formData.append('FileOperadora', filemodel.fileOperadora.toString());
    formData.append('Fornecedor',filemodel.fornecedor);
    // formData.append('NomeEntidade', filemodel.nomeEntidade.toString().trim());
    formData.append('NomeEntidade', filemodel.nomeEntidade.toString());
    formData.append('NomeArquivoDownload', filemodel.nomeArquivoDownload.toString());
    formData.append('BucketName',filemodel.bucketName.toString());    
    formData.append('ArquivoFaturaId',filemodel.arquivoFaturaId?.toString());
    const req = new HttpRequest('POST', `${this.baseUrlFile}/DownloadArquivoEletronico`, formData, {
      reportProgress: true,
      responseType: 'blob' 
    });
    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${this.baseUrlFile}/files`);
  }


  getArquivosPorNome(bucketName:string,key:string): Observable<FileS3> {
    return this.http.get<any>(`${this.baseUrlFile}/get-by-key2?bucketName=${bucketName}&key=${key}`)

  }

  // downloadFile(bucketName: string, objectKey: string) {
  //   const url = `${this.baseUrlFile}/get-by-key2?bucketName=${bucketName}&key=${objectKey}`;
  //   return this.http.get(url, { responseType: 'blob' });
  // }

  // downloadFile(bucketName: string, objectKey: string) {
  //   const url = `${this.baseUrlFile}/get-by-key2?bucketName=${bucketName}&key=${objectKey}`;
  //   return this.http.get(url, { responseType: 'blob' });
  // }
  downloadFile(bucketName: string, nomeArquivo: string) {
    // const bucketUrl = URL.()    
    const url = `${this.baseUrlFile}/download?bucketName=${bucketName}&fileName=${nomeArquivo}`;    
    const encodedUrl = encodeURI(url)
    return this.http.get(encodedUrl, { responseType: 'blob' });
  }
  downloadFileById(id: number) : Observable<Blob> {
    const url = `${this.baseUrlFile}/DownloadFile/${id}`
    return this.http.get(url,{
      responseType: 'blob'
      // ,reportProgress: true
    })
  }
  deleteFile(bucketName: string, nomeArquivo: string): Observable<{ id, fullPath: string}> {
    const url = `${this.baseUrlFile}/deleteFile?bucketName=${bucketName}&fileName=${nomeArquivo}`;
    console.log('URL de deleção:', url); // Adiciona um log para verificar a URL
    return this.http.get(url).pipe(
      map((data:any) => ({id: data.id, fullPath: data.fullPath }))
    );
  }
  deleteFileById(idArquivoSendoDeletado: number) {
    const url = `${this.baseUrlFile}/${idArquivoSendoDeletado}`
    return this.http.delete(url).pipe(
      map(toOpResult)
      ,catchError((err) => of(err.error) )
    )
  }
}
