import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Pagination } from "src/app/core/models/pagination.model";
import { ContaLinha } from "src/app/modulos/telecom/ativo-linha/core/models/contaLinha.model";
import { PacoteRI } from "src/app/modulos/telecom/ativo-linha/core/models/pacoteRI.model";
import { PacoteLinhaService } from "src/app/modulos/telecom/ativo-linha/core/services/pacote-linha.service";
import { Fornecedor } from "src/app/modulos/telecom/cadastro/core/models/fornecedor.model";
import { SERVICES_NAMES } from "src/app/core/models/enums/listagemServicesEnum";
import { TableStoreService } from "src/app/modulos/telecom/ativo-fisico/core/store/table.store.service";
import { Subscription } from "rxjs";
import { PaginatorGenericStoreService } from "src/app/core/store/paginatorGeneric.store.service";
import { ContaFaturaService } from "src/app/modulos/telecom/conta-fatura/core/services/contaFatura.service";
import { LoadActionService } from "src/app/core/actions/load-action.service";

@Component({
  selector: "app-modal-list",
  templateUrl: "./modal-list.component.html",
  styleUrls: ["./modal-list.component.css"],
})
export class ModalListComponent {
  @Input() headerTitle: string;
  @Input() buttonFunction: () => void;
  @Input() buttonText: string;
  @Input() reference: string;

  @Input() entidade: number;
  @Input() fornecedor: Fornecedor;
  @Input() titularidade?: number;
  @Input() servicoId?: number;

  @Output() fecharModalEvent = new EventEmitter<boolean>();
  @Output() selecionarValorEvent = new EventEmitter<any>();
  @Output() limparValorEvent = new EventEmitter<any>();

  loading: boolean = true;
  pagination: Pagination = <Pagination>{};
  entidadeID: number = 1;

  isOpen: boolean = true;

  listConta: ContaLinha[];
  listPacoteRI: PacoteRI[];

  grid: string = "0.3fr 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr";
  dataTableHeadersConta: string[] = [
    "",
    "Ação",
    "Nome",
    "Situação",
    "Entidade",
    "Serviço Linha",
    "Fornecedor",
    "Ciclo Faturamento",
  ];
  dataTableHeadersPacote: string[] = [
    "",
    "Ação",
    "Descrição",
    "Fornecedor",
    "Região",
    "Vigência",
    "Valor Cliente",
    "Valor Mobit",
  ];
  isChecked: boolean = false;

  enumTeste = SERVICES_NAMES;

  valorSelecionado!: any;

  private subscription: Subscription[] = [];

  isLoading: boolean;

  get data$() {
    return this.paginatorStore.dataObservable$;
  }

  get tableRow$() {
    return this.tableStore.tableRow$;
  }

  get rowsSelected() {
    return this.tableStore.rowsSelected;
  }

  get isLoading$() {
    return this.loadService.loadAction$;
  }

  constructor(
    private contaFaturaService: ContaFaturaService,
    private pacotesLinhaService: PacoteLinhaService,
    private tableStore: TableStoreService,
    private paginatorStore: PaginatorGenericStoreService,
    private loadService: LoadActionService
  ) {}

  ngOnInit() {
    this.subscription.push(
      this.rowsSelected.subscribe((data: any) => {
        (this.valorSelecionado = data)
        data !== null ? this.isLoading = false : '';
      })
    );
  }

  sendDataConta(event$: { paginator: Pagination; searchText: string }) {
    if (event$.searchText !== null) {
      this.paginatorStore.getDataObservable(
        this.contaFaturaService.getPesquisaTodosContaFaturaModal(
          event$.paginator,
          event$.searchText,
          this.entidade,
          this.fornecedor.fornecedorId,
          this.fornecedor.nomeUsual,
          this.titularidade
        )
      );
    } else {
      this.paginatorStore.getDataObservable(
        this.contaFaturaService.getContaFaturaModal(
          event$.paginator,
          this.entidade,
          this.servicoId,
          this.fornecedor.fornecedorId,
          this.fornecedor.nomeUsual,
          this.titularidade
        )
      );
    }
  }

  sendDataPacote(event$: { paginator: Pagination; searchText: string }) {
    if (event$.searchText !== null) {
      this.paginatorStore.getDataObservable(
        this.pacotesLinhaService.getPacoteRIEntidadeFornecedor(
          event$.paginator,
          this.entidade,
          this.fornecedor.fornecedorId
        )
      );
    } else {
      this.paginatorStore.getDataObservable(
        this.pacotesLinhaService.getPacoteRIEntidadeFornecedor(
          event$.paginator,
          this.entidade,
          this.fornecedor.fornecedorId
        )
      );
    }
  }

  checkOne(index: number) {
    this.tableStore.checkOne(index);
  }

  fecharModal() {
    this.fecharModalEvent.emit();
  }

  selecionarValor() {
    let value: any;
    let type: string;

    if (this.reference === "Conta") {
      value = this.valorSelecionado[0];
      type = this.reference;
    } else if (this.reference === "PacoteRI") {
      value = this.valorSelecionado[0];
      type = this.reference;
    }

    this.selecionarValorEvent.emit({ value, type });
    this.fecharModal();
  }

  limparValor() {
    this.limparValorEvent.emit(this.reference);
  }
}
