<p-dialog
  style="width: 100%"
  [(visible)]="isOpen"
  [modal]="true"
  [style]="{ width: '50rem' }"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  (onHide)="fecharModal()"
  [draggable]="false"
  [resizable]="false"
>
  <ng-container [ngTemplateOutlet]="perfis"></ng-container>
  <ng-template pTemplate="footer">
    <div style="display: flex; justify-content: flex-end; gap: 10px;">
      <app-action-btn
        [icon]="'fa fa-times font-size-20 '"
        [type]="'outlined'"
        [text]="'Limpar'"
        (click)="limparValor()"
      ></app-action-btn>
      <app-action-btn
        [icon]="'fa fa-check font-size-20 '"
        [type]="''"
        [text]="'Selecionar'"
        id="btnSelecionarModal"
        (click)="selecionarValor()"
      ></app-action-btn>
    </div>
  </ng-template>
</p-dialog>

<!-- Template Perfis -->
<ng-template #perfis>
  <app-table
    [data]="data$"
    [grid]="grid"
    [isOpen]="isOpen"
    (getData)="sendData($event)"
    (openModal)="editModal()"
    [title]="'Perfis'"
    [dataTableHeaders]="dataTableHeaders"
    [exibirCheckbox]="false"
    [placeholderPesquisaGlobal]="'Pesquisa em Perfis'"
    [exibirFilterButton]="false"
  >
    <div
      class="dataTable-body"
      [ngStyle]="{ 'grid-template-columns': grid }"
      *ngFor="let items of tableRow$ | async; let i = index"
    >
      <div>
        <input
          type="checkbox"
          class="checkbox"
          [checked]="items.isChecked"
          [disabled]="!items.isEnabled"
          (click)="checkOne(i)"
          [(ngModel)]="items[i]"
        />
      </div>
      <!-- <div>
        <button
          class="table-edit-btn"
          [routerLink]="'/tem/ativoLinha/movel/form/' + items.ativoLinhaId"
        >
          <i class="mdi mdi-pencil"></i>
          <p>Editar</p>
        </button>
      </div> -->
      <div>{{ items.descricao }}</div>
      <div>{{ items.entidade?.razaoSocial }}</div>
      <div>{{ items.fornecedor?.nomeUsual }}</div>
      <div>{{ items.multa | currency: 'BRL' }}</div>
      <div>{{ items.vigencia }}</div>
    </div>
  </app-table>
</ng-template>
