<div id="layout-wrapper">
  <app-topbar
    (settingsButtonClicked)="onSettingsButtonClicked()"
    (mobileMenuButtonClicked)="onToggleMobileMenu()"
  >
  </app-topbar>

  <!-- <app-sidebar-roles></app-sidebar-roles> -->
  <!-- <app-sidebar></app-sidebar> -->
  <!-- <app-sidebar-refac></app-sidebar-refac> -->
   <app-sidebar-oficial></app-sidebar-oficial>

  
  <div class="main-content">
    <div
      class="page-content"
      [ngStyle]="{
        backgroundColor:

           this.tokenStorageService.getTheme() === 'dark'
            ? 'var(--primary)'
            : '#fbf8f7'
      }"

      [ngStyle]="topbarChanged === 'false' ? {
        backgroundColor:

           this.tokenStorageService.getTheme() === 'dark'
            ? 'var(--primary)'
            : '#fbf8f7'
      } : {
        backgroundColor: actualTheme !== 'dark' || topbarChanged ? '#fff' : 'var(--primary)'
      }"
    >
      <!-- content -->
      <router-outlet></router-outlet>
    </div>

    <!-- footer -->
    <app-footer></app-footer>
  </div>
</div>

<app-rightsidebar></app-rightsidebar>
