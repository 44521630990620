<div>
  @if(linhaSelecionada || ativoAtual !== undefined &&
  ativoAtual?.ativosLinhaVinculos?.length > 0) {
  <div [class]="hiddenBottons ? 'grid-wrapper-col-5' : 'grid-wrapper-col-7'">
    @for(item of vinculosTableHeader; track $index) {
    <div style="margin-bottom: 15px">
      <div class="vinculos-table-header">{{ item }}</div>
    </div>
    }
  </div>

  @if(metodo === "Novo") {
    <div [class]="hiddenBottons ? 'grid-wrapper-col-5' : 'grid-wrapper-col-7'">
      <div class="vinculos-table-item">
        {{ linhaSelecionada?.servico?.descricao }}
      </div>
      <div class="vinculos-table-item">
        {{ linhaSelecionada?.fornecedor?.razaoSocial }}
      </div>
      <div class="vinculos-table-item">
        {{ linhaSelecionada?.perfilCatalogo?.descricao }}
      </div>
      <div class="vinculos-table-item">
        {{ linhaSelecionada?.nomeFixaLinkDados }}
      </div>
      <div class="vinculos-table-item">
        {{ linhaSelecionada?.descricao }}
      </div>
    </div>
  }
  @for(vinculo of linhasVinculadas; track ativoAtual?.linha?.ativoLinhaId) {
  <div
    [class]="hiddenBottons ? 'grid-wrapper-col-5' : 'grid-wrapper-col-7'"
    style="margin: 5px 0px"
  >
    <div class="vinculos-table-item">
      {{ vinculo?.servicoLinhaDescription }}
    </div>
    <div class="vinculos-table-item">
      {{ vinculo?.fornecedorRazaoSocial }}
    </div>
    <div
      class="vinculos-table-item"
      style="
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
      "
    >
      {{ vinculo?.perfioCatalogoDescricao }}
    </div>
    <div class="vinculos-table-item">
      {{ vinculo?.servicoLinhaNomeFixaLinkDados }}
    </div>
    <div class="vinculos-table-item">
      {{ vinculo?.descricao }}
    </div>
    @if(!hiddenBottons){
    <div class="vinculos-table-item">
      <app-action-btn
        [icon]="'mdi mdi-eye font-size-20 '"
        [type]="'outlined'"
        [text]="'Visualizar'"
        [route]="[
          '/tem/ativoLinha/' +
            retornarRota(vinculo?.servicoLinhaDescription?.toLowerCase()) +
            '/form/' +
            vinculo?.ativoLinhaId
        ]"
      ></app-action-btn>
    </div>
    <div style="justify-content: flex-end">
      <app-action-btn
        [icon]="'bx bx-unlink font-size-20 '"
        [type]="'danger'"
        [text]="'Desvincular'"
        (click)="removerVinculoIndividual('Linha', vinculo?.id)"
      ></app-action-btn>
    </div>
    }
  </div>
  } }
</div>
