import { PerfilCatalogo } from "./../../../../ativo-linha/core/models/ativos-linha/perfilCatalogo.model";
import { PacoteRI } from "./../../../../ativo-linha/core/models/pacoteRI.model";
import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UsuarioEntidade } from "../../models/usuarioEntidade.model";
import { UsuarioEntidadeDTO } from "../../models/dto/usuarioEntidade.model";
import { Entidade } from "../../models/entidade.model";
import { CentroCustoEntidade } from "../../models/centroCustoEntidade.model";
import { AreaEntidade } from "../../models/areaEntidade.model";
import { CargoEntidade } from "../../models/cargoEntidade.model";
import { TipoAtivoFisico } from "src/app/modulos/telecom/ativo-fisico/models/ativos-fisicos/tipoAtivoFisico.model";
import { FabricanteDispositivo } from "src/app/modulos/telecom/ativo-fisico/models/dispositivo/fabricanteDispositivo.model";
import { ModeloDispositivo } from "src/app/modulos/telecom/ativo-fisico/models/dispositivo/modeloDispositivo.model";
import { UnidadeEntidade } from "../../models/unidadeEntidade.model";
import { Fornecedor } from "../../models/fornecedor.model";
import { CanalEntidade } from "../../models/canalEntidade.model";
import { SubAreaEntidade } from "src/app/modulos/tecnologia/cadastro/core/models/subAreaEntidade.model";
import { EModulo } from "src/app/core/models/enums/ModuloEnum";

@Injectable({
  providedIn: "root",
})
export class FormCadastroPatchService {
  constructor() {}

  patchUsuarioEntidadeForm(
    registerForm: FormGroup,
    usuarioEntidade: any
  ) {
    registerForm.patchValue({
      nomeCompleto: usuarioEntidade.usuarioEntidade.nomeCompleto,
      entidade: usuarioEntidade.usuarioEntidade.entidadeId,
      unidadeEntidade: usuarioEntidade.usuarioEntidade.unidadeEntidadeId,
      area: usuarioEntidade.usuarioEntidade.areaEntidadeId,
      centroCusto: usuarioEntidade.usuarioEntidade.centroCustoEntidadeId,
      cargo: usuarioEntidade.usuarioEntidade.cargoEntidadeId,
      documento: usuarioEntidade.usuarioEntidade.documento,
      canal:usuarioEntidade.usuarioEntidade.canalEntidadeId,
      matricula: usuarioEntidade.usuarioEntidade.matricula,
      email: usuarioEntidade.usuarioEntidade.email,
      telefone: usuarioEntidade.usuarioEntidade.telefone,
      cnh: usuarioEntidade.usuarioEntidade.cnh,
      vencimentoCNH:
        usuarioEntidade.usuarioEntidade.vencimentoCNH === null
          ? null
          : usuarioEntidade.usuarioEntidade.vencimentoCNH
              .toString()
              .substr(0, 10),
      cep: usuarioEntidade.usuarioEntidade.cep,
      uf: usuarioEntidade.usuarioEntidade?.uf ? usuarioEntidade.usuarioEntidade.uf.toUpperCase() : "",
      cidade: usuarioEntidade.usuarioEntidade.cidade,
      bairro: usuarioEntidade.usuarioEntidade.bairro,
      logradouro: usuarioEntidade.usuarioEntidade.logradouro,
      numero: usuarioEntidade.usuarioEntidade.numero,
      complemento: usuarioEntidade.usuarioEntidade.complemento,
      responsavel: usuarioEntidade.usuarioEntidade.responsavelId,
      lider: usuarioEntidade.usuarioEntidade.lider,
      vip: usuarioEntidade.usuarioEntidade.vip,
      acessoSistema: usuarioEntidade.usuarioEntidade.acessoSistema,
      status: usuarioEntidade.usuarioEntidade.status === "1" ? true : false,
      roles: usuarioEntidade.roles,
      userId: usuarioEntidade.usuarioEntidade.userId,
      visibilidadeUnidadesId: usuarioEntidade.usuarioEntidade.usuarioEntidadeUnidadeVisibilidades.map(x => x.unidadeEntidadeId),
      visibilidadeAreaId: usuarioEntidade.usuarioEntidade.usuarioEntidadeAreaVisibilidades.map(x => x.areaEntidadeId),
      visibilidadeCentroDeCustoId: usuarioEntidade.usuarioEntidade.usuarioEntidadeCentroCustoVisibilidade.map(x => x.centroCustoEntidadeId),
      visibilidadeCanalId:usuarioEntidade.usuarioEntidade.usuarioEntidadeCanalVisibilidade.map(x => x.canalEntidadeId),
      visibilidadeTodos: usuarioEntidade.usuarioEntidade.visibilidadeTodos
    });
  }

  patchSubAreaEntidadeForm(
    registerForm: FormGroup,
    subAreaEntidade: SubAreaEntidade,
    term: string = ""
  ) {
    registerForm.patchValue({
      descricao: term == "" ? subAreaEntidade.descricao : term,
      entidade: subAreaEntidade.entidadeId,
      status: subAreaEntidade.status === 1 ? true : false,
    });
  }

  patchEntidadeForm(
    registerForm: FormGroup,
    entidade: Entidade,
    term: string = ""
  ) {
    registerForm.patchValue({
      segmentoEntidade: entidade?.segmentoEntidadeId,
      razaoSocial: entidade?.razaoSocial,
      nomeUsual: entidade?.nomeUsual,
      canal: entidade?.canal,
      tipoPessoa: entidade?.tipoPessoa,
      numeroTipoPessoa: entidade?.numeroTipoPessoa,
      inscricaoEstadual: entidade?.inscricaoEstadual,
      cep: entidade?.cep,
      logradouro: entidade?.logradouro,
      uf: entidade?.uf,
      bairro: entidade?.bairro,
      cidade: entidade?.cidade,
      numero: entidade?.numero,
      complemento: entidade?.complemento,
      tipoAtualizacao: entidade?.tipoAtualizacao.toString(),
      entidadePrincipal: entidade?.entidadePrincipal,
      matriculaObrigatoria: entidade?.matriculaObrigatoria,
      status: entidade?.status === 1 ? true : false,
    });
  }

  patchUnidadeEntidadeForm(
    registerForm: FormGroup,
    unidadeEntidade: UnidadeEntidade,
    term: string = ""
  ) {
    registerForm.patchValue({
      entidade: unidadeEntidade.entidadeId,
      segmentoEntidade: unidadeEntidade.segmentoEntidadeId,
      razaoSocial: unidadeEntidade.razaoSocial,
      nomeUsual: unidadeEntidade.nomeUsual,
      canal: unidadeEntidade.canal,
      tipoPessoa: unidadeEntidade.tipoPessoa,
      numeroTipoPessoa: unidadeEntidade.numeroTipoPessoa,
      inscricaoEstadual: unidadeEntidade.inscricaoEstadual,
      unidadePrincipal:unidadeEntidade.unidadePrincipalId,
      storage: unidadeEntidade.storage,
      cep: unidadeEntidade.cep,
      logradouro: unidadeEntidade.logradouro,
      uf: unidadeEntidade.uf,
      bairro: unidadeEntidade.bairro,
      cidade: unidadeEntidade.cidade,
      numero: unidadeEntidade.numero,
      complemento: unidadeEntidade.complemento,
      status: unidadeEntidade.status === 1 ? true : false,
    });
  }

  patchCanalEntidadeForm(
    registerForm: FormGroup,
    canalEntidade: CanalEntidade,
    term: string = ""
  ) {
    registerForm.patchValue({
      entidade: canalEntidade.entidadeId,  
      razaoSocial: canalEntidade.razaoSocial,
      nomeUsual: canalEntidade.nomeUsual,     
      tipoPessoa: canalEntidade.tipoPessoa,
      numeroTipoPessoa: canalEntidade.numeroTipoPessoa,
      inscricaoEstadual: canalEntidade.inscricaoEstadual,
      cep: canalEntidade.cep,
      logradouro: canalEntidade.logradouro,
      uf: canalEntidade.uf,
      bairro: canalEntidade.bairro,
      cidade: canalEntidade.cidade,
      numero: canalEntidade.numero,
      complemento: canalEntidade.complemento,
      status: canalEntidade.status === 1 ? true : false,
    });
  }

  patchCargoEntidadeForm(
    registerForm: FormGroup,
    cargoEntidade: CargoEntidade,
    term: string = ""
  ) {
    registerForm.patchValue({
      descricao: term == "" ? cargoEntidade.descricao : term,
      areaEntidade: cargoEntidade.areaEntidade?.areaEntidadeId,
      entidade: cargoEntidade.entidadeId,
      status: cargoEntidade.status === 1 ? true : false,
    });
  }

  patchAreaEntidadeForm(
    registerForm: FormGroup,
    areaEntidade: AreaEntidade,
    term: string = ""
  ) {
    registerForm.patchValue({
      descricao: term == "" ? areaEntidade.descricao : term,
      entidade: areaEntidade.entidadeId,
      status: areaEntidade.status === 1 ? true : false,
    });
  }

  patchCentroCustoEntidadeForm(
    registerForm: FormGroup,
    centroCustoentidade: CentroCustoEntidade,
    term: string = ""
  ) {
    registerForm.patchValue({
      areaEntidade: centroCustoentidade.areaEntidade?.areaEntidadeId,
      codigo: term == "" ? centroCustoentidade.codigo : term,
      nome: term == "" ? centroCustoentidade.nome : term,
      entidade: centroCustoentidade.entidadeId,
      unidade: centroCustoentidade.unidadeEntidadeId,
      tarifavel: centroCustoentidade.tarifavel,
      status: centroCustoentidade.status === 1 ? true : false,
    });
  }

  patchPacoteRI(registerForm: FormGroup, pacoteRI: PacoteRI) {
    registerForm.patchValue({
      descricaoPlano: pacoteRI.descricaoPlano,
      entidadeId: pacoteRI.entidadeId,
      fornecedorId: pacoteRI.fornecedorId,
      regiao: pacoteRI.regiao,
      vigencia: pacoteRI.vigencia,
      status: pacoteRI.status,
      valorTTCliente: pacoteRI.valorTTCliente,
      valorTTMobit: pacoteRI.valorTTMobit,
      incluso: pacoteRI.incluso,
      sugestaoOutros: pacoteRI.sugestaoOutros,
      usuarioCadastro: pacoteRI.usuarioCadastro,
      usuarioLog: pacoteRI.usuarioLog,
      multa: pacoteRI.multa
    });
  }

  patchPerfilCatalogo(registerForm: FormGroup, perfilCatalogo: any) {
    registerForm.patchValue({
      descricao: perfilCatalogo.descricao,
      fornecedorId: perfilCatalogo.fornecedorId,
      ttRevisao: perfilCatalogo.ttRevisao,
      entidadeId: perfilCatalogo.entidadeId,
      servicoId: perfilCatalogo.servicoId,
      dataCriacaoPerfil: perfilCatalogo.dataCriacaoPerfil,
      inicioVigencia: perfilCatalogo.inicioVigencia,
      fimVigencia: perfilCatalogo.fimVigencia,
      vigencia: perfilCatalogo.vigencia,
      dataUltimoReajuste: perfilCatalogo.dataUltimoReajuste,
      multa: perfilCatalogo.multa,
      totalAssinaturaMensalUnitaria:
        perfilCatalogo.totalAssinaturaMensalUnitaria,
      totalAssinaturaSemAparelho: perfilCatalogo.totalAssinaturaSemAparelho,
      status: perfilCatalogo.status,
      titularidadeId: perfilCatalogo.titularidadeId,
      tipoRenovacao: perfilCatalogo.tipoRenovacao,
      indiceReajuste: perfilCatalogo.indiceReajuste,
      valorTotalMensal: perfilCatalogo.valorTotalMensal,
      assinaturaUnitariaMensal: perfilCatalogo.assinaturaUnitariaMensal,
      valorOriginal: perfilCatalogo.valorOriginal,
      chamado: perfilCatalogo.chamado,
      qtdePacoteDadosDisponivelGestorOnline:
        perfilCatalogo.qtdePacoteDadosDisponivelGestorOnline,
      observacoes: perfilCatalogo.observacoes,

      //Assinaturas Mensais Perfil - Accordion
      aparelho: perfilCatalogo.aparelho,
      valorAparelho: perfilCatalogo.valorAparelho,
      descontoAparelho: perfilCatalogo.descontoAparelho,
      protecaoRouboFurto: perfilCatalogo.protecaoRouboFurto,
      qtDeParcelas: perfilCatalogo.qtdeParcelas,
      assinaturaMensal: perfilCatalogo.assinaturaMensal,
      gestaoSmartEmpresasDados: perfilCatalogo.gestaoSmartEmpresasDados,
      gestaoSmartEmpresasVoz: perfilCatalogo.gestaoSmartEmpresasVoz,
      vivoNewPlus: perfilCatalogo.vivoNewPlus,
      gestaoEquipeCompleto: perfilCatalogo.gestaoEquipeCompleto,
      gestaoOnlineValor: perfilCatalogo.gestaoOnlineValor,
      vivoSegurancaOnlineVivoNews: perfilCatalogo.vivoSegurancaOnlineVivoNews,
      valorFranquiaMinutosIndividual: perfilCatalogo.valorFranquiaMinutosIndividual,
      qtDeMinutosFranquiaIndividual: perfilCatalogo.qtdeMinutosFranquiaIndividual,
      descontoMarketingInternet: perfilCatalogo.descontoMarketingInternet,
      excedenteGb: perfilCatalogo.excedenteGB,
      valorPacotesDados: perfilCatalogo.valorPacotesDados,
      qtdePacoteDadosContratado: perfilCatalogo.qtdePacoteDadosContratado,
      valorPacotesRecorrentesRI: perfilCatalogo.valorPacotesRecorrenteRI,
      inclusoPacoteRI: perfilCatalogo.inclusoPacoteRI,

      //Descrições Assinatura Mensal
      consumoMinimo: perfilCatalogo.consumoMinimo,
      qtdRamais: perfilCatalogo.qtdRamais,
      qtdE1: perfilCatalogo.qtdE1,
      servicosInclusos: perfilCatalogo.servicosInclusos,
      qtdMinMovel: perfilCatalogo.qtdMinMovel,
      totalInternet: perfilCatalogo.totalInternet,
      tarifacaoInicial: perfilCatalogo.tarifacaoInicial,
      cadencia: perfilCatalogo.cadencia,

      //Definição de Bloqueios
      chamadaLocalVC1: perfilCatalogo.chamadaLocalVC1,
      chamadaLongaDistanciaVC2VC3: perfilCatalogo.chamadaLongaDistanciaVC2VC3,
      recebimentoACobrarVC1VC2VC3: perfilCatalogo.recebimentoACobrarVC1VC2VC3,
      chamadaLongaDistanciaInternacional:
        perfilCatalogo.chamadaLongaDistanciaInternacional,
      chamaRoamingInternacional: perfilCatalogo.chamaRoamingInternacional,
      mensagemTextoSMS: perfilCatalogo.mensagemTextoSMS,
      mensagemTextoRecebimentoSMS: perfilCatalogo.mensagemTextoRecebimentoSMS,
      mensagemTextoRoamingInternacionalSMS:
        perfilCatalogo.mensagemTextoRoamingInternacionalSMS,
      dadosNacional: perfilCatalogo.dadosNacional,
      dadosRoamingInternacional: perfilCatalogo.dadosRoamingInternacional,
      restricaoCSP: perfilCatalogo.restricaoCSP,
      caixaPostal: perfilCatalogo.caixaPostal,
      gestorOnline: perfilCatalogo.gestorOnline,
      numerosEspeciais: perfilCatalogo.numerosEspeciais,
      internet: perfilCatalogo.internet,
      csp: perfilCatalogo.csp,
      chamadaLocalFixoFixo: perfilCatalogo.chamadaLocalFixoFixo,
      chamadaLocalFixoMovel: perfilCatalogo.chamadaLocalFixoMovel,
      chamadaLongaDistanciaFixoFixo:
        perfilCatalogo.chamadaLongaDistanciaFixoFixo,
      chamadaLongaDistanciaFixoMovel:
        perfilCatalogo.chamadaLongaDistanciaFixoMovel,
      chamadaInternacional: perfilCatalogo.chamadaInternacional,
    });
  }

  // Tipos, Fabricantes, Modelos
  patchTipoAtivo(registerForm: FormGroup, tipoAtivo: TipoAtivoFisico) {
    registerForm.patchValue({
      modulo:tipoAtivo.moduloCategoria.moduloId,
      descricao: tipoAtivo.descricao,
      categoriaAtivo: tipoAtivo.moduloCategoriaId,
      status: tipoAtivo.status === 1? true : false,
    });
  }

  patchFornecedor(registerForm: FormGroup, fornecedor: Fornecedor) {
    registerForm.patchValue({
      fornecedorId: fornecedor.fornecedorId,
      tipo: fornecedor.tipo,
      razaoSocial: fornecedor.razaoSocial,
      nomeUsual: fornecedor.nomeUsual,
      numeroTipoPessoa: fornecedor.numeroTipoPessoa,
      status: fornecedor.status,
    })
  }


  patchFabricante(
    registerForm: FormGroup,
    fabricanteDispositivo: FabricanteDispositivo
  ) {
    const moduloId = fabricanteDispositivo.tipoAtivo.moduloCategoria.moduloId;

    const obj = {
      modulo: moduloId,
      descricao: fabricanteDispositivo.descricao,
      categoriaAtivo: moduloId == EModulo.Tecnologia == moduloId ? fabricanteDispositivo.tipoAtivo.categoriaAtivoId : fabricanteDispositivo.tipoAtivo.moduloCategoria.moduloCategoriaId,
      tipoAtivo: fabricanteDispositivo.tipoAtivoId,
      status: fabricanteDispositivo.status === 1? true : false,
    }

    console.log(obj);

    registerForm.patchValue({
      modulo: moduloId,
      descricao: fabricanteDispositivo.descricao,
      categoriaAtivo: moduloId == EModulo.Tecnologia == moduloId ? fabricanteDispositivo.tipoAtivo.categoriaAtivoId : fabricanteDispositivo.tipoAtivo.moduloCategoria.moduloCategoriaId,
      tipoAtivo: fabricanteDispositivo.tipoAtivoId,
      status: fabricanteDispositivo.status === 1? true : false,
    });
  }

  patchModelo(registerForm: FormGroup, modeloDispositivo: ModeloDispositivo) {
    let medidaMemoria = modeloDispositivo.memoria?.includes("TB")
      ? "TB"
      : modeloDispositivo.memoria?.includes("MB")
      ? "MB"
      : "GB";
    let medidaDiscoRigido = modeloDispositivo.tamanhoDiscoRigido?.includes("TB")
      ? "TB"
      : modeloDispositivo.memoria?.includes("MB")
      ? "MB"
      : "GB";

    let memoria = modeloDispositivo.memoria
      ?.replace("MB", "")
      .replace("GB", "")
      .replace("TB", "");
    let discoRigido = modeloDispositivo.tamanhoDiscoRigido
      ?.replace("MB", "")
      .replace("GB", "")
      .replace("TB", "");

    registerForm.patchValue({
      descricao: modeloDispositivo.descricao,
      categoriaAtivo: modeloDispositivo.categoriaAtivoId,
      tipoAtivo: modeloDispositivo.fabricanteDispositivo?.tipoAtivoId,
      fabricanteDispositivo: modeloDispositivo.fabricanteDispositivoId,
      memoria: memoria,
      memoriaUnidade: medidaMemoria,
      conexao: modeloDispositivo.conexao,
      cor: modeloDispositivo.cor,
      processador: modeloDispositivo.processador,
      tamanhoDiscoRigido: discoRigido,
      tamanhoDiscoRigidoUnidade: medidaDiscoRigido,
      numeroSerie: modeloDispositivo.numeroSerie,
      status: modeloDispositivo.status === 1? true : false,
    });
  }
}
