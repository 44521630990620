import { LoadActionService } from 'src/app/core/actions/load-action.service';
import { TokenStorageService } from './../../core/services/token-storage.service';
import { Component, OnInit } from '@angular/core';
import { paywallStoreService } from 'src/app/core/store/paywall.store.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})

export class ContainerComponent implements OnInit {

  constructor(
    public tokenStorageService: TokenStorageService,
    public loadService: LoadActionService,
    private paywallServiceStore: paywallStoreService,
    private router: Router,
  ) { }

  actualUrl: boolean = location.pathname.includes('perfil_pacote/lista');
  topbarChanged: string;
  access: boolean = false;

  get isLoading$() {
    return this.loadService.loadAction$
  }

  get hasAccess$() {
    return this.paywallServiceStore.accessSubject$
  }

  ngOnInit() {
    this.topbarChanged = localStorage.getItem("colorChange");

    this.paywallServiceStore.accessSubject$.subscribe((res) => {
      console.log('ACCESS', res)
      this.access = res
    })
  }

  goBack() {
    this.router.navigate(['/tem/relatorios/dashboards']);
    this.paywallServiceStore.setAccess(true);
  }
}
