
@if (!isHidden) {
  <button type="button" [class]="customClassBtn" (click)="onClickBtn()" [disabled]="isLoading">
    @if(icon){
    <i [class]="icon"></i>
    } 
    @if(!isLoading){
      {{ btnLabel }}
    } 
  
    @if(isLoading){
      <i class="mdi mdi-account-plus font-size-16 mdi-spin mdi-loading"></i>
    }
  
  </button>
}

<app-modal-confirmation
  [isOpen]="modalConfirmationOpen"
  [title]="titleModal"
  [text]="textModal"
  (fecharModalEvent)="onCloseModal($event)"
>
</app-modal-confirmation>
